import styled from 'styled-components';
import { FC } from 'react';
import { ChevronLeft } from 'components/icons/ChevronLeft';
import { Clickable } from '../atoms/Clickable';
import { Body2Bold, Body4 } from 'styles/FontStyles';
import { getCurrentDayDate, getDayOfWeekDate, getWeekOfYear } from 'utils/dateUtils';
import { Calendar } from 'components/common/inputs/Calendar';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;

  position: relative;
  z-index: 1;

  ${Body4};

  svg {
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  flex: 1;
  overflow: hidden;
`;

const Label = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  text-align: center;

  b {
    ${Body2Bold};
    flex-shrink: 0;
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ChevronRight = styled(ChevronLeft)`
  transform: rotate(180deg);
`;

interface IDateWeekOfYearInputProps {
  className?: string;
  value: string;
  setValue: (value: string) => void;
}

export const DateWeekOfYearInput: FC<IDateWeekOfYearInputProps> = (props) => {
  const { className, value, setValue } = props;

  const setWeek = (offset: number) => {
    const date = getCurrentDayDate(value);
    date.setDate(date.getDate() + offset * 7);
    setValue(date.toISOString());
  };

  const startDate = getDayOfWeekDate(1, value);
  const endDate = getDayOfWeekDate(7, startDate);

  return (
    <Wrapper className={className}>
      <Clickable onClick={() => setWeek(-1)}>
        <ChevronLeft />
      </Clickable>

      <Content>
        <Label>
          <b>KW {getWeekOfYear(value)}</b>
          <span>
            (
            {[startDate, endDate]
              .filter(Boolean)
              .map((date) => date.toLocaleDateString(undefined, { dateStyle: 'medium' }))
              .join(' - ')}
            )
          </span>
        </Label>
        <Calendar
          hideFieldValue
          hideDeleteButton
          startDate={startDate}
          endDate={endDate}
          value={[startDate, endDate]}
          setValue={(date) => {
            setValue(getDayOfWeekDate(1, getCurrentDayDate(date as Date)).toISOString());
          }}
        />
      </Content>

      <Clickable onClick={() => setWeek(1)}>
        <ChevronRight />
      </Clickable>
    </Wrapper>
  );
};
