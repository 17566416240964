import { FC, memo, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
// import { useMapboxAPI } from 'api/controllers/MapboxAPI';
// import { IAddress } from 'api/models/Address';
import { ICustomer } from 'api/models/Customer';
import { IPassenger } from 'api/models/Passenger';
import { IScheduledTour } from 'api/models/ScheduledTour';
import { useShowDialog } from 'state/DialogState';
import { Animations } from 'styles/Animations';
import { Colors } from 'styles/Colors';
import { Body1Bold, Body2, Body4 } from 'styles/FontStyles';
import { getCurrentDayDate, getDayOfWeekDate, getFormattedValue } from 'utils/dateUtils';
import { formatName } from 'utils/nameUtils';
import { ChipTile } from 'components/common/atoms/ChipTile';
import BlockedByBookingCompletionOverlay from 'components/common/elements/BlockedByBookingCompletionOverlay';
import { ContextOptions } from 'components/common/elements/ContextOptions';
import { Dialog } from 'components/common/elements/Dialog';
import DriverPicker from 'components/common/elements/DriverPicker';
import MapView from 'components/common/elements/MapView';
import TourPickupTimes from 'components/common/elements/TourPickupTimes';
import PassengersTrack from 'components/common/elements/tours/PassengersTrack';
import PassengerAdditionOverlay from 'components/common/elements/tours/tour-details/PassengerAdditionOverlay';
import { ButtonPrimary, ButtonSecondary, ButtonTertiary } from 'components/common/inputs/Button';
import { Calendar } from 'components/common/inputs/Calendar';
import { QuickInput } from 'components/common/inputs/QuickInput';
import { TourLabel } from 'components/common/labels/TourLabel';
import { Edit } from 'components/icons/Edit';
import { Plus } from 'components/icons/Plus';
import { Trash } from 'components/icons/Trash';
import { Walker } from 'components/icons/Walker';
import { Wheelchair } from 'components/icons/Wheelchair';
import {
  TItemsInAdditionState,
  useAvailableDragDropItems,
  useDragDropContainer,
  useDragDropItemsInAdditionsState,
  useDragDropItemsMoveState,
  useDropOverItems,
  useEditDragDropContainer,
  useSelectedDragDropItems,
} from '../DragDropContext';
import { DropOverItems } from 'components/content/customers/tourplan/tiles/DropOverItems';
import { TourplanAvailablePassengerView } from 'components/content/customers/tourplan/tiles/TourplanPassengerView';
import { TileItem } from 'components/content/customers/tourplan/tiles/TourplanTourTile';

const TourItem = styled(TileItem)`
  gap: 0.5rem;
  padding: 1rem;

  position: relative;
`;

const TourItemHead = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.5rem;

  padding-block-end: 0.5rem;
  margin-block-end: 0.5rem;
  border-bottom: 1px solid ${Colors.grey500};
`;

const TourItemNameRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.5rem;

  ${Body4};

  & > * {
    min-block-size: 1.75rem;
  }
`;

const TourItemNameRowContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;

  flex: 1;
`;

const DirectionHint = styled.span`
  margin-inline-end: auto;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const DateRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;

  z-index: 2;

  & > span {
    ${Body4};
    color: ${Colors.grey600};
  }
`;

const DriversRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  z-index: 1;

  & > * {
    flex: 1;
    flex-shrink: 0;
  }

  padding-block-end: 0.5rem;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.5rem;

  button {
    max-width: none;
  }
`;

const OverlayAddPassenger = styled.div`
  position: absolute;
  inset: 0;
  inline-size: 100%;
  block-size: 100%;
  background-color: ${Colors.white50};
  z-index: 5;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;

  padding: 0.75rem;

  ${Body2};
  text-align: center;
  color: ${Colors.secondary};

  b {
    ${Body1Bold};
    margin-block-end: 0.375rem;
  }

  animation: ${Animations.Appear} 150ms ease-out;
`;

const TourplanItemDropContainer = memo(
  (
    props: PropsWithChildren<{ scheduledTour: TTourplanScheduledTour; onDropListener?: (props: TItemsInAdditionState<any, any>) => void }>,
  ) => {
    const { scheduledTour, onDropListener, children } = props;

    const dragDropContainerProps = useDragDropContainer<TTourplanScheduledTour, IPassenger>(scheduledTour, undefined, onDropListener);

    return <TourItem {...dragDropContainerProps}>{children}</TourItem>;
  },
);

const DropOverItemsOverlay = memo((props: { scheduledTour: TTourplanScheduledTour }) => {
  const { scheduledTour } = props;

  const dropOverItems = useDropOverItems(scheduledTour);

  return (
    <>
      {dropOverItems.length > 0 && (
        <OverlayAddPassenger>
          <Plus />
          <b>{dropOverItems.length > 1 ? `${dropOverItems.length} Fahrgäste` : formatName(dropOverItems[0] as IPassenger)}</b>
          <span>
            zu <b>{scheduledTour.name}</b>
          </span>
          <span>hinzufügen</span>
        </OverlayAddPassenger>
      )}
    </>
  );
});

export type TTourplanScheduledTour = Partial<IScheduledTour> & { selectedDayOfWeek?: string; hasChanged?: boolean };

interface ITourplanScheduledTourTileProps {
  editable?: boolean;
  editForTimestamp?: string;
  showDriverAvailabilities?: boolean;
  promptForTimeSupplement?: boolean;
  scheduledTour: TTourplanScheduledTour;
  customer: ICustomer | null;
  update?: (scheduledTour: TTourplanScheduledTour, hasChanged?: boolean) => void;
  remove?: (scheduledTour: TTourplanScheduledTour, onRemoved: () => void) => void;
}

/**
 * for tourplan view
 */
export const TourplanScheduledTourTile: FC<ITourplanScheduledTourTileProps> = memo((props) => {
  const {
    editable = true,
    editForTimestamp,
    showDriverAvailabilities = false,
    promptForTimeSupplement = false,
    scheduledTour,
    // customer,
    update,
    remove,
  } = props;

  const showDialog = useShowDialog();

  // const mapboxAPI = useMapboxAPI();
  const [showMap, setShowMap] = useState(false);

  const [renameTour, setRenameTour] = useEditDragDropContainer<TTourplanScheduledTour>();
  const [passengersInAdditionState, setPassengersInAdditionState] = useDragDropItemsInAdditionsState<
    TTourplanScheduledTour,
    Partial<IPassenger>
  >();

  const selectedDayOfWeek = scheduledTour.selectedDayOfWeek || getDayOfWeekDate(1).toISOString();

  const [, setAvailablePassengers] = useAvailableDragDropItems<IPassenger>();

  const [passengersWheelchair, passengersWalking] = useMemo(() => {
    return (scheduledTour.passengers || []).reduce(
      (res, item) => {
        res[item.hasWheelchair ? 0 : 1].push(item);
        return res;
      },
      [[], []] as Partial<IPassenger>[][],
    );
  }, [scheduledTour]);

  // const { dataSource } = useDragDropDataProvider();

  // const plannedDrivers = useMemo(() => {
  //   return dataSource.fields.flatMap((tour) => [tour.driverId, tour.companionId].filter(Boolean) as unknown as string[]);
  // }, [dataSource.fields]);

  const timeAtCustomer = ((scheduledTour.direction === 'return' ? scheduledTour.departureDate : scheduledTour.arrivalDate) || {})[
    new Date(selectedDayOfWeek).getDay()
  ];

  const onDropListener = useCallback(
    (props: TItemsInAdditionState<TTourplanScheduledTour, IPassenger>) => {
      if (
        promptForTimeSupplement &&
        scheduledTour.id &&
        typeof props?.targetContainer === 'object' &&
        scheduledTour.id === props?.targetContainer?.id
      ) {
        // moving passengers to existing tour
        setPassengersInAdditionState(props);
      } else {
        if (typeof props?.srcContainer === 'object') {
          // moving passengers from existing tour
          const isEmptyAfterTransaction =
            typeof props?.srcContainer === 'object' &&
            (props?.srcContainer?.passengers || []).filter((passenger) => !(props?.items || []).includes(passenger)).length < 1;
          if (isEmptyAfterTransaction) {
            const srcTourName = (typeof props?.srcContainer === 'object' && props?.srcContainer?.name) || '';
            // prompt to delete empty tour
            showDialog({
              headline: 'Keine Fahrgäste in Tour',
              body: (
                <span>
                  Es sind keine Fahrgäste mehr in der Tour <b>{srcTourName}</b>. Möchten Sie diese Tour löschen?
                </span>
              ),
              buttons: (
                <>
                  <ButtonPrimary
                    onClick={() => {
                      showDialog(null);
                      if (props?.srcContainer) {
                        remove?.(props.srcContainer as TTourplanScheduledTour, () => {});
                      }
                    }}
                  >
                    Entfernen
                  </ButtonPrimary>
                  <ButtonSecondary onClick={() => showDialog(null)}>Abbrechen</ButtonSecondary>
                </>
              ),
            });
          }
        }

        // do transaction
        props?.commitTransaction();
      }
    },
    [promptForTimeSupplement, setPassengersInAdditionState, showDialog, remove, scheduledTour.id],
  );

  const [selectedItems, resetSelectedItems] = useSelectedDragDropItems<IScheduledTour, IPassenger>(scheduledTour);
  const [moveItemsState, setMoveItemsState] = useDragDropItemsMoveState();

  const tourItemNameView = useMemo(
    () => (
      <TourItemNameRowContent>
        {renameTour === scheduledTour && (
          <QuickInput
            id={'tourName'}
            placeholder={'Tournummer eingeben'}
            defaultValue={scheduledTour.name}
            onSubmit={(name) => {
              // update tour name
              update?.({
                ...scheduledTour,
                name,
              });
            }}
            onBlur={() => setRenameTour(null)}
          />
        )}
        {renameTour !== scheduledTour && (
          <>
            <TourLabel>{scheduledTour.name}</TourLabel>
            <DirectionHint>{scheduledTour.direction === 'return' ? 'Rück' : 'Hin'}</DirectionHint>
            {passengersWheelchair.length > 0 && (
              <IconWrapper>
                {passengersWheelchair.length} <Wheelchair />
              </IconWrapper>
            )}
            {passengersWalking.length > 0 && (
              <IconWrapper>
                {passengersWalking.length} <Walker />
              </IconWrapper>
            )}
          </>
        )}
      </TourItemNameRowContent>
    ),
    [renameTour, scheduledTour, update, setRenameTour, passengersWheelchair, passengersWalking],
  );

  const contextMenuView = useMemo(
    () => (
      <>
        {editable && (
          <ContextOptions
            items={
              [
                {
                  content: (
                    <>
                      <Trash /> Entfernen
                    </>
                  ),
                  onClick: () => {
                    showDialog({
                      headline: 'Tour entfernen',
                      body: <>Möchten Sie diese Tour wirklich löschen?</>,
                      buttons: (
                        <>
                          <ButtonPrimary
                            onClick={() => {
                              showDialog(null);
                              remove?.(scheduledTour, () => {
                                // add removed passengers to available passengers
                                setAvailablePassengers((items) => [...items, ...(scheduledTour.passengers || [])]);
                              });
                            }}
                          >
                            Entfernen
                          </ButtonPrimary>
                          <ButtonSecondary onClick={() => showDialog(null)}>Abbrechen</ButtonSecondary>
                        </>
                      ),
                    });
                  },
                },
                {
                  content: (
                    <>
                      <Edit /> Umbenennen
                    </>
                  ),
                  onClick: () => {
                    setRenameTour(scheduledTour);
                  },
                },
              ].filter(Boolean) as any[]
            }
          />
        )}
      </>
    ),
    [editable, scheduledTour, setRenameTour, remove, setAvailablePassengers, showDialog],
  );

  const dateRow = useMemo(
    () => (
      <DateRow>
        <span>Laufzeit:</span>
        {editable && (
          <Calendar
            requireSubmit
            hideDeleteButton
            selectType={'range'}
            value={[
              (scheduledTour.startDate && new Date(scheduledTour.startDate)) || null,
              (scheduledTour.endDate && new Date(scheduledTour.endDate)) || null,
            ]}
            setValue={([startDate, endDate]: any) => {
              update?.({
                ...scheduledTour,
                startDate,
                endDate,
              });
            }}
            {
              // if timestamp > startDate only the endDate should be editable
              ...(scheduledTour.id && getCurrentDayDate(editForTimestamp) > getCurrentDayDate(scheduledTour.startDate)
                ? ({
                    startDate: (scheduledTour.startDate && new Date(scheduledTour.startDate)) || null,
                    selectType: 'end',
                    minDate: getCurrentDayDate(editForTimestamp),
                    displayValue: [
                      (scheduledTour.startDate && new Date(scheduledTour.startDate)) || null,
                      (scheduledTour.endDate && new Date(scheduledTour.endDate)) || null,
                    ],
                    value: (scheduledTour.endDate && new Date(scheduledTour.endDate)) || null,
                    setValue: (endDate: Date | null) => {
                      update?.({
                        ...scheduledTour,
                        endDate: (endDate && getCurrentDayDate(endDate)) || null,
                      });
                    },
                  } as any)
                : {})
            }
          />
        )}
        {!editable && (
          <ChipTile>
            {getFormattedValue(
              [scheduledTour.startDate, scheduledTour.endDate].map((date) => (date ? new Date(date) : null)) as [Date | null, Date | null],
            )}
          </ChipTile>
        )}
      </DateRow>
    ),
    [editable, scheduledTour, editForTimestamp, update],
  );

  const driversRow = useMemo(
    () => (
      <DriversRow>
        <DriverPicker
          title={'Fahrer*in'}
          unavailableDrivers={[scheduledTour.companionId].filter(Boolean) as string[]}
          timestamp={showDriverAvailabilities ? editForTimestamp : undefined}
          driver={scheduledTour.driver}
          onSelect={
            editable
              ? (driver) => {
                  // update tour driver
                  update?.({
                    ...scheduledTour,
                    driver,
                    driverId: driver?.id || null,
                  } as IScheduledTour);
                }
              : undefined
          }
        />
        <DriverPicker
          title={'Begleitung'}
          unavailableDrivers={[scheduledTour.driverId].filter(Boolean) as string[]}
          timestamp={showDriverAvailabilities ? editForTimestamp : undefined}
          driver={scheduledTour.companion}
          onSelect={
            editable
              ? (companion) => {
                  // update tour companion
                  update?.({
                    ...scheduledTour,
                    companion,
                    companionId: companion?.id || null,
                  } as IScheduledTour);
                }
              : undefined
          }
        />
      </DriversRow>
    ),
    [scheduledTour, editable, update, editForTimestamp, showDriverAvailabilities],
  );

  const pickupTimes = useMemo(
    () => (
      <TourPickupTimes
        editable={editable}
        pickupTimes={(scheduledTour.direction === 'return' ? scheduledTour.departureDate : scheduledTour.arrivalDate) || {}}
        direction={scheduledTour.direction || undefined}
        onChange={(pickupTimes) => {
          update?.({
            ...scheduledTour,
            ...(scheduledTour.direction === 'return' ? { departureDate: pickupTimes } : { arrivalDate: pickupTimes }),
          });
        }}
        timestamp={selectedDayOfWeek}
        onTimestampChange={(selectedDayOfWeek) => {
          // update selected day of week in form state (for rendering only)
          update?.(
            {
              ...scheduledTour,
              selectedDayOfWeek,
            },
            false,
          );
        }}
      />
    ),
    [editable, scheduledTour, update, selectedDayOfWeek],
  );

  const passengersList = useMemo(
    () => (
      <PassengersTrack
        direction={scheduledTour.direction}
        customer={scheduledTour.customer}
        passengers={scheduledTour.passengers || []}
        customerTime={timeAtCustomer && new Date(timeAtCustomer).toLocaleTimeString(undefined, { timeStyle: 'short' })}
        renderPassenger={(passenger) => (
          <TourplanAvailablePassengerView
            tour={scheduledTour}
            editable={editable}
            passenger={passenger}
            showPickupTimes
            selectedDayOfWeek={selectedDayOfWeek}
            onPickupDateChange={(pickupDate) => {
              const dayOfWeek = getCurrentDayDate(selectedDayOfWeek).getDay().toString();
              const customerPickupDates = scheduledTour.direction === 'return' ? scheduledTour.departureDate : scheduledTour.arrivalDate;
              const daysWithSameCustomerTime = Object.keys(customerPickupDates || {}).filter((key) => {
                return key.toString() !== dayOfWeek && (customerPickupDates || {})[key] === (customerPickupDates || {})[dayOfWeek];
              });

              // update passenger pickup date
              update?.({
                ...scheduledTour,
                passengers: [
                  ...(scheduledTour.passengers || []).filter((item) => item !== passenger),
                  {
                    ...passenger,
                    ScheduledTourPassenger: {
                      ...(passenger.ScheduledTourPassenger || {}),
                      pickupDate: {
                        ...pickupDate,
                        ...daysWithSameCustomerTime.reduce((res, key) => {
                          // apply passenger pickup time for all days with same customer time
                          return {
                            ...res,
                            [key]: pickupDate[dayOfWeek],
                          };
                        }, {}),
                      },
                    },
                  },
                ]
                  .sort((a, b) => {
                    // sort passengers by pickup time
                    const pickupTimeA = a.ScheduledTourPassenger?.pickupDate?.[dayOfWeek] || null;
                    const pickupTimeB = b.ScheduledTourPassenger?.pickupDate?.[dayOfWeek] || null;

                    if (pickupTimeA && pickupTimeB) {
                      return pickupTimeA.localeCompare(pickupTimeB);
                    } else if (pickupTimeA && !pickupTimeB) {
                      return -1;
                    } else if (!pickupTimeA && pickupTimeB) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((passenger, index) => ({
                    // map index on position
                    ...passenger,
                    ScheduledTourPassenger: {
                      ...passenger.ScheduledTourPassenger,
                      position: index,
                    },
                  })),
              } as IScheduledTour);
            }}
          />
        )}
        renderDropOverItems={() => <DropOverItems tour={scheduledTour} onDropListener={onDropListener} />}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scheduledTour, onDropListener, selectedDayOfWeek, timeAtCustomer, update],
  );

  const selectedItemsButtons = useMemo(
    () => (
      <>
        {selectedItems.length > 0 && (
          <ButtonsWrapper>
            <ButtonTertiary
              disabled={moveItemsState !== null}
              onClick={(e: Event) => {
                e.stopPropagation();
                resetSelectedItems();
              }}
            >
              Auswahl verwerfen
            </ButtonTertiary>
            <ButtonPrimary
              onClick={(e: Event) => {
                e.stopPropagation();
                if (moveItemsState !== null) {
                  setMoveItemsState(null);
                } else {
                  setMoveItemsState({ srcContainer: scheduledTour, items: selectedItems });
                }
              }}
            >
              {moveItemsState !== null
                ? 'Verschieben abbrechen'
                : `${selectedItems.length} ${selectedItems.length === 1 ? 'Fahrgast' : 'Fahrgäste'} verschieben`}
            </ButtonPrimary>
          </ButtonsWrapper>
        )}
      </>
    ),
    [selectedItems, moveItemsState, setMoveItemsState, resetSelectedItems, scheduledTour],
  );

  const passengerAdditionOverlay = useMemo(
    () => (
      <>
        {scheduledTour?.id &&
          typeof passengersInAdditionState?.targetContainer === 'object' &&
          passengersInAdditionState?.targetContainer?.id === scheduledTour?.id && (
            <PassengerAdditionOverlay
              passengersInAdditionState={passengersInAdditionState.items}
              onSubmit={(amount) => {
                // commit transaction
                passengersInAdditionState?.commitTransaction((passenger) => ({
                  ...passenger,
                  ScheduledTourPassenger: {
                    ...(passenger.ScheduledTourPassenger || {}),
                    timeSupplement: amount, // add time supplement to ScheduledTourPassenger
                  },
                }));

                // close overlay
                setPassengersInAdditionState(null);
              }}
              onCancel={() => {
                // close overlay
                setPassengersInAdditionState(null);
              }}
            />
          )}
      </>
    ),
    [scheduledTour, passengersInAdditionState, setPassengersInAdditionState],
  );

  // const passengerAddresses: IAddress[] =
  //   scheduledTour.passengers?.map((passenger) => passenger.addresses?.[0]).filter((address): address is IAddress => !!address) || [];
  return (
    <TourplanItemDropContainer scheduledTour={scheduledTour} onDropListener={onDropListener}>
      <TourItemHead>
        <TourItemNameRow>
          {tourItemNameView}
          {contextMenuView}
        </TourItemNameRow>
        {dateRow}
        {driversRow}
        {pickupTimes}
        <DropOverItemsOverlay scheduledTour={scheduledTour} />
      </TourItemHead>
      {passengersList}
      {/* <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', flexWrap: 'wrap', paddingBlock: '.5rem', fontSize: '1rem' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '.5rem',
            paddingInline: '.75rem',
            borderRadius: 12,
            backgroundColor: Colors.grey400,
          }}
        >
          <Tours />
          <span>{(+((scheduledTour as any).distanceTotal || 0) / 1000).toFixed(2)} km</span>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '.5rem',
            paddingInline: '.75rem',
            borderRadius: 12,
            backgroundColor: Colors.grey400,
          }}
        >
          <Clock />
          <span>{getFormattedTime(+((scheduledTour as any).durationTotal || 0) / 3600)} Std.</span>
        </div>
        <Clickable
          onClick={() =>
            mapboxAPI.getOptimizedRoute([scheduledTour.driver!.address!, ...passengerAddresses, scheduledTour.customer!.address!])
          }
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '.5rem',
              paddingInline: '.75rem',
              borderRadius: 12,
              backgroundColor: Colors.grey400,
            }}
          >
            <Map style={{ strokeWidth: 1 }} />
          </div>
        </Clickable>
      </div> */}
      {process.env.NODE_ENV === 'development' && false && (
        <Dialog open={showMap} onClose={() => setShowMap(false)} text={<MapView geometry={(scheduledTour as any).geometry} />} />
      )}
      {selectedItemsButtons}
      {passengerAdditionOverlay}
      {editable && <BlockedByBookingCompletionOverlay timestamp={editForTimestamp} style={{ borderRadius: 8 }} />}
    </TourplanItemDropContainer>
  );
});
