// General
import { FC, HTMLAttributes, MouseEvent, ReactNode, useState } from 'react';
import styled from 'styled-components';

// Components
import { Tooltip } from '../atoms/Tooltip';
import { ContextMenuItem } from '../atoms/ContextMenu';
import { Clickable } from '../atoms/Clickable';

// Icons
import { Details } from 'components/icons/Details';

const Wrapper = styled(Clickable)<{ detailsMenuOpen: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;

  z-index: ${({ detailsMenuOpen }) => (detailsMenuOpen ? 5 : 0)};

  svg {
    width: 1.5rem;
    height: 1.5rem;
    object-fit: contain;
  }
`;

interface IContextOptionsProps extends HTMLAttributes<HTMLElement> {
  items: {
    onClick?: () => void;
    href?: string;
    content: ReactNode;
  }[];
  as?: 'button' | 'div' | 'span';
}

export const ContextOptions: FC<IContextOptionsProps> = (props) => {
  const { items, as } = props;
  const [detailsMenuOpen, setDetailsMenuOpen] = useState(false);

  return (
    <Wrapper
      detailsMenuOpen={detailsMenuOpen}
      as={as}
      onClick={(e: MouseEvent<HTMLButtonElement | HTMLSpanElement | HTMLDivElement>) => {
        e.stopPropagation();
        setDetailsMenuOpen(!detailsMenuOpen);
      }}
    >
      <Details />
      <Tooltip open={detailsMenuOpen} setOpen={setDetailsMenuOpen}>
        {items.map(({ content, onClick, href }, index) => (
          <object key={index}>
            <ContextMenuItem
              href={href}
              onClick={(e: any) => {
                e.stopPropagation();
                setDetailsMenuOpen(false);
                onClick?.();
              }}
            >
              {content}
            </ContextMenuItem>
          </object>
        ))}
      </Tooltip>
    </Wrapper>
  );
};
