import { forwardRef, HTMLProps } from 'react';
import { useFormContext } from 'react-hook-form';

interface IFieldProps {
  name: string;
  optional?: boolean;
}

export const Field = forwardRef((props: IFieldProps & HTMLProps<HTMLInputElement>, ref) => {
  const { name, optional = false, ...inputProps } = props;
  const fieldProps = useFieldProps(name, optional);

  return <input {...(inputProps as any)} {...fieldProps} ref={ref} />;
});

export const useFieldProps = (name: string, optional: boolean = false) => {
  const formContext = useFormContext();

  return (
    formContext?.register(name, {
      validate: (value) => (optional || typeof value === 'boolean' || !!value ? true : 'Bitte ausfüllen'),
    }) || {}
  );
};

export const useFieldValue = (name: string) => {
  const formContext = useFormContext();
  return formContext?.getValues(name);
};

export const useFieldError = (name: string) => {
  const formContext = useFormContext();
  const { error } = formContext?.getFieldState(name, formContext?.formState) || {};

  return error;
};

export const useWatchFieldValue = (name: string, fallbackValue?: any) => {
  const formContext = useFormContext();
  return formContext.watch(name, fallbackValue);
};
