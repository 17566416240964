import { Breadcrumb } from 'components/common/elements/Breadcrumb';
import { VehicleForm } from 'components/content/vehicles/VehicleForm';
import { ContentPageLayout } from 'components/common/atoms/Layout';

export const VehiclesAdd = () => {
  return (
    <ContentPageLayout>
      <Breadcrumb
        items={[
          {
            title: 'Fuhrpark Übersicht',
            href: '/vehicles',
          },
        ]}
      />
      <VehicleForm />
    </ContentPageLayout>
  );
};
