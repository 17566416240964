import { atom, useRecoilState } from 'recoil';
import { IBookingCompletion } from 'api/models/BookingCompletion';
import { getCurrentDayDate } from 'utils/dateUtils';
import { useEffect } from 'react';
import { useBookingCompletionsAPI } from 'api/controllers/BookingCompletionsAPI';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist({
  key: 'cache:LatestBookingCompletionState',
  storage: sessionStorage,
});

export const LatestBookingCompletionState = atom<IBookingCompletion | null>({
  key: 'LatestBookingCompletionState',
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export const useLatestBookingCompletion = (refresh: boolean = true) => {
  const [latestBookingCompletion, setLatestBookingCompletion] = useRecoilState(LatestBookingCompletionState);
  const bookingCompletionsAPI = useBookingCompletionsAPI();

  useEffect(() => {
    if (refresh) {
      bookingCompletionsAPI
        .getBookingCompletions({ offset: 0, limit: 1 })
        .then((res) => {
          setLatestBookingCompletion(res.data.rows[0] || null);
        })
        .catch(() => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return latestBookingCompletion;
};

export const useLatestBookingCompletionDate = (refresh: boolean = true) => {
  const latestBookingCompletion = useLatestBookingCompletion(refresh);

  if (latestBookingCompletion?.timestamp) {
    return getCurrentDayDate(latestBookingCompletion.timestamp);
  }
  return null;
};
