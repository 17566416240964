import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useCustomerCategoryAPI } from 'api/controllers/CustomerCategoryAPI';
import { ICustomerCategory } from 'api/models/CustomerCategory';
import { ContentPageHead } from 'components/common/elements/ContentPageHead';
import { CustomerCategoryListRow } from 'components/common/elements/customer-categories/CustomerCategoryListRow';
import { ButtonSecondary } from 'components/common/inputs/Button';
import { Plus } from 'components/icons/Plus';
import { List, ListHead } from 'components/common/atoms/List';

const StyledListHead = styled(ListHead)`
  top: 10rem;
  margin-block-start: -2.5rem;
  padding-block-start: 2.5rem;
`;

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
`;

export const CustomerCategoryList = () => {
  const [customerCategories, setCustomerCategories] = useState<ICustomerCategory[] | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const calendarExceptionAPI = useCustomerCategoryAPI();

  const fetchCustomerCategories = useCallback(() => {
    setLoading(true);
    setCustomerCategories([]);
    calendarExceptionAPI
      .getCustomerCategories({})
      .then((res) => {
        setCustomerCategories(res.data.data || []);
      })
      .catch(() => setCustomerCategories([]))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchCustomerCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <ContentPageHead title={`Kunden-Typen`}>
        <ButtonSecondary href={'/customer-categories/add'}>
          <Plus /> Kunden-Typen anlegen
        </ButtonSecondary>
      </ContentPageHead>

      <List>
        <StyledListHead>
          <div>ID</div>
          <div>Einrichtung</div>
        </StyledListHead>
        {customerCategories !== undefined &&
          customerCategories.length > 0 &&
          customerCategories.map((customerCategory) => (
            <CustomerCategoryListRow
              key={customerCategory.id}
              customerCategory={customerCategory}
              onUpdate={() => {}} //removeItem(calendarException.id)}
            />
          ))}
      </List>
      {!loading && (customerCategories === undefined || customerCategories?.length === 0) && <p>Keine Kunden-Typen gefunden</p>}
    </Wrapper>
  );
};
