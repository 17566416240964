import { EmployeeForm } from 'components/content/employees/EmployeeForm';
import { Breadcrumb } from 'components/common/elements/Breadcrumb';
import { ContentPageLayout } from 'components/common/atoms/Layout';

export const EmployeesAdd = () => {
  return (
    <ContentPageLayout>
      <Breadcrumb
        items={[
          {
            title: 'Mitarbeiter Übersicht',
            href: '/employees',
          },
        ]}
      />
      <EmployeeForm />
    </ContentPageLayout>
  );
};
