// General
import { FC } from 'react';
import styled from 'styled-components';
import { createPortal } from 'react-dom';

// Components
import { Dialog } from 'components/common/elements/Dialog';
import { PassengerForm } from 'components/content/passengers/PassengerForm';

const StyledDialog = styled(Dialog)`
  text-align: start;
  max-width: 64rem;
  max-height: calc(100vh - 6rem);
  overflow-y: auto;
  gap: 0;
`;

interface CreatePassengerDialogPortalProps {
  customerId: string;
  onClose: () => void;
}

const CreatePassengerDialogPortal: FC<CreatePassengerDialogPortalProps> = (props) => {
  const { onClose, customerId } = props;

  return createPortal(
    <StyledDialog
      open
      onClose={onClose}
      text={
        <PassengerForm
          onSuccess={() => {
            onClose();
            document.dispatchEvent(new Event('passengers-update'));
          }}
          defaultCustomerId={customerId}
        />
      }
      buttons={null}
    />,
    document.querySelector('#portal') as Element,
  );
};

export default CreatePassengerDialogPortal;
