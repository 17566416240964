import { IScheduledTour } from 'api/models/ScheduledTour';
import styled, { css } from 'styled-components';
import { Colors } from 'styles/Colors';
import { memo, PropsWithChildren, useMemo } from 'react';
import TourName from './molecules/TourName';
import TourPassengersCount from './molecules/TourPassengersCount';
import { Card } from 'components/common/atoms/Card';
import { Body4, Headline4 } from 'styles/FontStyles';
import TourRuntime from './molecules/TourRuntime';
import DriverPicker from '../DriverPicker';
import TourPickupTimes from '../TourPickupTimes';
import PassengerTile from './molecules/PassengerTile';
import {
  useDragDropContainer,
  useDragDropItemsMoveState,
  useMoveItems,
  useSelectedDragDropItems,
} from 'components/content/customers/tourplan/DragDropContext';
import { IPassenger } from 'api/models/Passenger';
import { Checkmark } from 'components/icons/Checkmark';
import { ButtonPrimary, ButtonTertiary } from 'components/common/inputs/Button';

const Wrapper = styled(Card)`
  gap: 1rem;
  padding: 1rem;
`;

const TileHeadWrapper = styled.div<{ spaceBetween?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ spaceBetween = false }) => (spaceBetween ? 'space-between' : 'flex-start')};
  gap: 1rem;
  flex-wrap: wrap;
`;

const CustomerName = styled.h4`
  ${Headline4};
`;

const HeadRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1.5rem;

  & > :first-child {
    flex: 1;
  }

  & > :last-child {
    flex-shrink: 0;
  }
`;

const StyledTourRuntime = styled(TourRuntime)`
  margin-block: -0.25rem;
`;

const StyledPassengersCount = styled(TourPassengersCount)`
  padding-block: 0.125rem;
`;

const Separator = styled.div`
  display: flex;
  block-size: 1px;
  background-color: ${Colors.grey500};
`;

const TilesWrapper = styled.div<{ highlight?: boolean }>`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
  gap: 1rem;

  margin: -1rem;
  padding: 1rem;
  border-radius: 0.5rem;

  border: 1px solid transparent;
  transition: border-color 150ms ease-out;

  ${({ highlight = false }) =>
    highlight &&
    css`
      border-color: ${Colors.secondary};
    `};
`;

const TaskCompletedHint = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;

  grid-area: 1 / 1 / -1 / -1;

  ${Body4};
  color: ${Colors.signalGreen900};

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
`;

const RevertDropContainer = memo((props: PropsWithChildren) => {
  return <TilesWrapper {...useDragDropContainer<IScheduledTour, IPassenger>('revert')}>{props.children}</TilesWrapper>;
});

interface ISplitTourDetailsTileProps {
  schedule: IScheduledTour;
  availablePassengers?: IPassenger[];
  timestamp?: string;
}
function SplitTourDetailsTile(props: ISplitTourDetailsTileProps) {
  const { schedule, availablePassengers = schedule.passengers || [], timestamp } = props;

  const [selectedItems, resetSelectedItems] = useSelectedDragDropItems<IScheduledTour, IPassenger>('revert');
  const [moveItemsState, setMoveItemsState] = useDragDropItemsMoveState<IScheduledTour, IPassenger>();
  const moveItems = useMoveItems({});

  const scheduleInfoView = useMemo(
    () => (
      <>
        <HeadRow>
          <TileHeadWrapper spaceBetween>
            <TileHeadWrapper>
              <TourName scheduledTour={schedule} />
              <CustomerName>{schedule.customer?.displayName}</CustomerName>
            </TileHeadWrapper>
            <StyledTourRuntime scheduledTour={schedule} />
          </TileHeadWrapper>
          <StyledPassengersCount scheduledTour={schedule} />
        </HeadRow>
        <TileHeadWrapper spaceBetween>
          <TileHeadWrapper spaceBetween>
            <DriverPicker title={'Fahrer*in'} driver={schedule.driver} />
            <DriverPicker title={'Begleitung'} driver={schedule.companion} />
          </TileHeadWrapper>
          <TourPickupTimes
            pickupTimes={(schedule.direction === 'return' ? schedule.departureDate : schedule.arrivalDate) || {}}
            direction={schedule.direction || undefined}
            timestamp={timestamp}
          />
        </TileHeadWrapper>
      </>
    ),
    [schedule, timestamp],
  );

  const availablePassengersList = useMemo(
    () => (
      <>
        {availablePassengers.map((passenger) => (
          <PassengerTile key={passenger.id} passenger={passenger} timestamp={timestamp} />
        ))}
        {availablePassengers.length === 0 && (
          <TaskCompletedHint>
            <Checkmark /> alle Fahrgäste wurden verteilt
          </TaskCompletedHint>
        )}
      </>
    ),
    [availablePassengers, timestamp],
  );

  const selectedItemsButtonsView = useMemo(
    () => (
      <>
        {selectedItems.length > 0 && (
          <ButtonsWrapper>
            <ButtonTertiary
              disabled={moveItemsState !== null}
              onClick={(e: Event) => {
                e.stopPropagation();
                resetSelectedItems();
              }}
            >
              Auswahl verwerfen
            </ButtonTertiary>
            <ButtonPrimary
              onClick={(e: Event) => {
                e.stopPropagation();
                if (moveItemsState !== null) {
                  setMoveItemsState(null);
                } else {
                  setMoveItemsState({ srcContainer: 'revert', items: selectedItems });
                }
              }}
            >
              {moveItemsState !== null
                ? 'Verschieben abbrechen'
                : `${selectedItems.length} ${selectedItems.length === 1 ? 'Fahrgast' : 'Fahrgäste'} verschieben`}
            </ButtonPrimary>
          </ButtonsWrapper>
        )}
      </>
    ),
    [selectedItems, moveItemsState, setMoveItemsState, resetSelectedItems],
  );

  const moveButtonsView = useMemo(
    () => (
      <>
        {moveItemsState !== null && moveItemsState.srcContainer !== 'revert' && (
          <ButtonPrimary
            onClick={(e: Event) => {
              e.stopPropagation();
              moveItems('revert');
            }}
          >
            {typeof schedule === 'object' ? `In ${schedule.name}` : 'Hierher'} verschieben
          </ButtonPrimary>
        )}
      </>
    ),
    [moveItemsState, moveItems, schedule],
  );

  return (
    <Wrapper>
      {scheduleInfoView}
      <Separator />
      <RevertDropContainer>{availablePassengersList}</RevertDropContainer>

      {selectedItemsButtonsView}
      {moveButtonsView}
    </Wrapper>
  );
}

export default memo(SplitTourDetailsTile);
