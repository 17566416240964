import { IAPIModelBase } from 'api/models/index';
import { IEmployee } from 'api/models/Employee';

export interface IUser extends IAPIModelBase {
  email: string;
  permissions: string;
  employeeId: string;
  employee?: IEmployee;
}

export const USER_PERMISSION_TITLES = {
  admin: 'Admin',
  login: 'Login',
  'region:d': 'Region Düsseldorf',
  'region:n': 'Region Niederrhein',
  'region:w': 'Region Wuppertal',
  'region:r': 'Region Ruhrgebiet',
  'booking-completion': 'Buchungsabschluss',
  'user-management': 'Nutzerverwaltung',
} as const;

export type TUserPermission = keyof typeof USER_PERMISSION_TITLES;
