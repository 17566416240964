import styled, { css } from 'styled-components';
import { Colors } from 'styles/Colors';
import { Body3, Body4 } from 'styles/FontStyles';
import { useCallback, useEffect, useState } from 'react';
import { Dialog } from 'components/common/elements/Dialog';
import Upload from 'components/icons/Upload';
import { ButtonPrimary, ButtonSecondary } from '../inputs/Button';
import { LoadingSpinner } from '../loaders/LoadingSpinner';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.5rem;
`;

const Dropzone = styled.div.attrs({ role: 'button', tabIndex: 0 })<{ dragOver?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;

  padding: 2.5rem;
  border: 1px dashed ${Colors.grey600};
  border-radius: 8px;

  cursor: pointer;

  & > * {
    pointer-events: none;
  }

  ${Body3};
  color: ${Colors.grey900};

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  transition: color 150ms ease-out, border-color 150ms ease-out;

  ${({ dragOver = false }) =>
    dragOver &&
    css`
      color: ${Colors.secondary};
      border-color: ${Colors.secondary};
    `};
`;

const HintsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;

  ${Body4};
  color: ${Colors.grey700};
`;

interface IFileImportDialogProps {
  onImport: (file: File, setLoading: (loading: boolean) => void) => void;
  onClose: () => void;
}

export default function FileImportDialog(props: IFileImportDialogProps) {
  const { onImport, onClose } = props;

  const [loading, setLoading] = useState(false);

  const [dragCounter, setDragCounter] = useState(0);

  const [file, setFile] = useState<File | null>(null);

  const handleFileInput = useCallback(
    (fileInput: FileList) => {
      const file = fileInput.length > 0 ? fileInput.item(0) : null;
      if (file) {
        setFile(file);
      } else {
        setFile(null);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onImport],
  );

  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    setShowDialog(true);
  }, []);

  return (
    <Dialog
      open={showDialog}
      onClose={() => setShowDialog(false)}
      onClosed={onClose}
      headline={<>Dokument hier hochladen oder per Drag & Drop hineinziehen</>}
      text={
        <Wrapper>
          <Dropzone
            dragOver={dragCounter > 0}
            onClick={(e) => {
              const fileInput = document.createElement('input');
              fileInput.type = 'file';
              fileInput.addEventListener('change', (e) => {
                handleFileInput((e.target as any).files);
              });
              fileInput.click();
            }}
            onDragOver={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onDrop={(e) => {
              e.preventDefault();
              e.stopPropagation();
              const files = e.dataTransfer.files || [];
              if (files.length > 0) {
                handleFileInput(files);
                e.dataTransfer.clearData();
                setDragCounter(0);
              }
            }}
            onDragEnter={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setDragCounter((count) => count + 1);
            }}
            onDragLeave={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setDragCounter((count) => count - 1);
            }}
          >
            {dragCounter > 0 ? (
              <>
                <Upload /> Drop zum hochladen
              </>
            ) : file ? (
              <>{file.name}</>
            ) : (
              <>
                <Upload /> Datei auswählen
              </>
            )}
          </Dropzone>
          <HintsWrapper>
            <span>zulässiges Format: .xlxs</span>
            <span>Dateigröße: max. 5 MB</span>
          </HintsWrapper>
        </Wrapper>
      }
      buttons={
        <>
          <ButtonSecondary disabled={loading} onClick={() => setShowDialog(false)}>
            Abbrechen
          </ButtonSecondary>
          <ButtonPrimary
            disabled={!file || loading}
            onClick={() => {
              if (file) {
                onImport(file, setLoading);
              }
            }}
          >
            {loading ? <LoadingSpinner /> : <>Weiter</>}
          </ButtonPrimary>
        </>
      }
    />
  );
}
