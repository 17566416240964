import styled, { css } from 'styled-components';
import { FC, MouseEventHandler, useState } from 'react';
import { Colors } from 'styles/Colors';
import { Body4, Headline3 } from 'styles/FontStyles';
import { IScheduledTourWithMetaData } from 'api/models/ScheduledTour';
import { TourLabel } from 'components/common/labels/TourLabel';
import { Close } from 'components/icons/Close';
import { getCurrentDayDate, getISODate } from 'utils/dateUtils';
import { Checkmark } from 'components/icons/Checkmark';
import { formatAddress } from 'utils/addressUtils';
import { Collapsible } from 'components/common/atoms/Collapsible';
import { Clock } from 'components/icons/Clock';
import { Wheelchair } from 'components/icons/Wheelchair';
import { Walker } from 'components/icons/Walker';
import { Location } from 'components/icons/Location';
import { Clickable } from 'components/common/atoms/Clickable';
import { ChevronDown } from 'components/icons/ChevronDown';
import { IConflict } from 'api/models/Conflict';
import { Link } from 'components/common/atoms/Link';
import { Facilities } from 'components/icons/Facilities';
import SelectedDriverView from './molecules/SelectedDriverView';
import { useNavigate } from 'hooks/useNavigate';
import { DIRECTION_LABEL } from 'utils/directionLabelUtil';
import ConflictsForTourTooltips from './ConflictsForTourTooltips';
import PassengersContainer from 'container/PassengersContainer';

const TileWrapper = styled.div.attrs(({ onClick = null }) => (onClick ? { as: 'button', type: 'button' } : {}))<{
  onClick?: MouseEventHandler;
  cancelled?: boolean;
  conflict?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.5rem;

  background: ${Colors.white50};
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
  border-radius: 8px;

  padding-inline: 1rem;
  padding-block: 0.625rem;

  transition: box-shadow 150ms ease-out, border-radius 150ms ease-out;

  &:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    border-radius: 8px;
  }

  ${({ cancelled = false }) =>
    cancelled &&
    css`
      opacity: 0.5;
    `};

  ${({ conflict = false }) =>
    conflict &&
    css`
      border: 1px solid ${Colors.signalRed900};
    `};
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  ${Body4};
`;

const HeaderRowContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const Title = styled.h3`
  ${Headline3};
  margin-block: 0 -0.25rem;
  text-align: start;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  svg {
    flex-shrink: 0;
    margin-block: -1rem;
    opacity: 0;
    margin-inline-end: -1.5rem;
    transition: opacity 150ms ease-out, margin-inline-end 150ms ease-out;
  }

  &:hover {
    svg {
      opacity: 1;
      margin-inline-end: 0;
    }
  }
`;

const Flexbox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const IconWrapper = styled(Flexbox)`
  gap: 0;
`;

const FooterRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  margin-block-end: -0.5rem;

  ${Body4};

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const CancelledIcon = styled(Close)`
  width: 1.5rem;
  height: 1.5rem;
  color: ${Colors.signalRed900};
`;

const CollapseButton = styled(Clickable)`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  margin: -0.5rem;
  padding: 0.5rem;
`;

const SeparatorRow = styled.div`
  block-size: 1px;
  margin-block: 1rem;
  background-color: ${Colors.grey500};
`;

const Details = styled(Collapsible)`
  margin-inline: -1rem;
  padding-inline: 1rem;
  margin-block: -0.625rem;
  padding-block: 0.625rem;
`;

interface ITourTileRefactoredProps {
  date: Date;
  day?: String;
  schedule: IScheduledTourWithMetaData;
  conflictsForTour?: IConflict[];
}

// TODO there is also TourTile, make that obsolete and rename this!
export const TourTileRefactored: FC<ITourTileRefactoredProps> = (props) => {
  const { date, schedule, conflictsForTour = [] } = props;

  const { startTimeStr, endTimeStr, isTourCancelled, isTourSplit, originalTourId, numberOfWalkers, numberOfWheelchairs, tour, reason } =
    schedule;

  const { name, direction, customer, driver, companion, departureDate, arrivalDate, isException } = tour;

  const navigate = useNavigate(true);

  const [detailsOpen, setDetailsOpen] = useState(false);

  const timeRange = (startTimeStr || 'n/a') + ' - ' + (endTimeStr || 'n/a');

  return (
    <TileWrapper
      onClick={(e) => {
        if (!e.isDefaultPrevented()) {
          navigate(`/tours/${originalTourId}?date=${getISODate(date)}`); // open details for original tour
        }
      }}
      cancelled={isTourCancelled || isTourSplit}
      conflict={conflictsForTour.length > 0}
    >
      <HeaderRow>
        <HeaderRowContentWrapper>
          <TourLabel>
            {
              // finished
              date && !isTourCancelled && new Date(date) < getCurrentDayDate() && <Checkmark />
            }
            {
              // cancelled
              (isTourCancelled || isTourSplit) && <CancelledIcon />
            }
            {name?.startsWith('F ') ? (
              <>
                <b>{name.substring(0, 1)}</b>
                {name.substring(0, 1)}
              </>
            ) : (
              name
            )}
          </TourLabel>
          <ConflictsForTourTooltips
            conflictsForTour={conflictsForTour}
            date={date}
            isException={isException}
            isTourCancelled={isTourCancelled}
            reason={reason}
          />
          <span>{DIRECTION_LABEL[direction || 'na']}</span>
        </HeaderRowContentWrapper>
        <HeaderRowContentWrapper>
          {numberOfWheelchairs > 0 && (
            <IconWrapper>
              {numberOfWheelchairs} <Wheelchair />
            </IconWrapper>
          )}
          {numberOfWalkers > 0 && (
            <IconWrapper>
              {numberOfWalkers} <Walker />
            </IconWrapper>
          )}
        </HeaderRowContentWrapper>
      </HeaderRow>

      <Link href={`/customers/${customer?.id}`} target={'_blank'} onClick={(e) => e.stopPropagation()} $displayContents={true}>
        <Title>
          <span>{customer?.displayName}</span>
          <Facilities />
        </Title>
      </Link>
      <Flexbox>
        <Location /> {formatAddress(customer?.address)}
      </Flexbox>

      <HeaderRow>
        <Link href={driver?.id ? `/employees/${driver.id}` : ''} onClick={(e) => e.stopPropagation()} $displayContents={true}>
          <HeaderRowContentWrapper>
            <SelectedDriverView
              title={'Fahrer'}
              driver={driver}
              conflict={
                date &&
                driver &&
                conflictsForTour.find(({ reason }) =>
                  ['driver_duplicated', 'driver_unavailable', 'driver_not_set', 'unset_pickupdate'].includes(reason),
                )
              }
            />
          </HeaderRowContentWrapper>
        </Link>

        {companion && (
          <Link href={companion?.id ? `/employees/${companion.id}` : ''} onClick={(e) => e.stopPropagation()} $displayContents={true}>
            <HeaderRowContentWrapper>
              <SelectedDriverView
                title={'Begleitung'}
                driver={companion}
                conflict={
                  date &&
                  driver &&
                  conflictsForTour.find(({ reason }) => ['companion_duplicated', 'companion_unavailable'].includes(reason))
                }
              />
            </HeaderRowContentWrapper>
          </Link>
        )}
      </HeaderRow>

      <FooterRow>
        <Flexbox>
          <Clock /> {timeRange}
        </Flexbox>
        <CollapseButton
          as="div"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setDetailsOpen(!detailsOpen);
          }}
        >
          <ChevronDown invert={detailsOpen} />
        </CollapseButton>
      </FooterRow>

      <Details open={detailsOpen}>
        <SeparatorRow />
        <PassengersContainer
          date={date}
          show={detailsOpen}
          direction={direction}
          customer={customer}
          originalTourId={originalTourId}
          departureDate={departureDate}
          arrivalDate={arrivalDate}
          type="tile"
        />
      </Details>
    </TileWrapper>
  );
};
