import { FC } from 'react';
import { useOrderAPI } from 'api/controllers/OrderAPI';
import { ITour } from 'api/models/Tour';
import FileImportDialog from 'components/common/elements/FileImportDialog';
import { useShowDialog } from 'state/DialogState';

interface ICustomerOrderFormPageImportDialogProps {
  onImport: (data: ITour[]) => void;
  onClose: () => void;
}

export const CustomerOrderFormPageImportDialog: FC<ICustomerOrderFormPageImportDialogProps> = (props) => {
  const { onImport, onClose } = props;

  const showDialog = useShowDialog();
  const orderAPI = useOrderAPI();

  return (
    <FileImportDialog
      onImport={(file, setLoading) => {
        setLoading(true);
        orderAPI
          .importOrder(file)
          .then((res) => onImport(res.data))
          .catch(() => {
            showDialog({
              headline: 'Aus Excel importieren',
              body: 'Die ausgewählte Datei konnte nicht importiert werden.',
            });
          })
          .finally(() => setLoading(false));
      }}
      onClose={onClose}
    />
  );
};
