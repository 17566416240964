import { useAuthHeader } from 'state/AuthState';
import axios from 'axios';

interface ICreateCalendarExceptionProps {
  calendar_exception: {
    name: string;
    begin_date: Date;
    end_date: Date;
  };
}

interface IGetCalendarExceptionsProps {}

interface IGetCalendarExceptionByIdProps {
  id: string;
}

interface IUpdateCalendarExceptionProps extends ICreateCalendarExceptionProps {}

export const useCalendarExceptionAPI = () => {
  const authHeader = useAuthHeader();

  const checkAuth = async () => {
    if (!authHeader) {
      throw new Error('401');
    }
  };

  const createCalendarException = (props: ICreateCalendarExceptionProps) => {
    return checkAuth().then(() => {
      return axios.post(`/api/calendar-exceptions`, props, { ...authHeader });
    });
  };

  const getCalendarExceptions = (_props: IGetCalendarExceptionsProps) => {
    return checkAuth().then(() => {
      return axios.get(`/api/calendar-exceptions`, { ...authHeader });
    });
  };

  const getCalendarExceptionById = (props: IGetCalendarExceptionByIdProps) => {
    return checkAuth().then(() => {
      return axios.get(`/api/calendar-exceptions/${props.id}`, { ...authHeader });
    });
  };

  const updateCalendarException = (id: string, props: IUpdateCalendarExceptionProps) => {
    return checkAuth().then(() => {
      return axios.patch(`/api/calendar-exceptions/${id}`, props, { ...authHeader });
    });
  };

  const deleteCalendarException = (id: string) => {
    return checkAuth().then(() => {
      return axios.delete(`/api/calendar-exceptions/${id}`, { ...authHeader });
    });
  };

  const restoreCalendarException = (id: string) => {
    return checkAuth().then(() => {
      return axios.post(`/api/calendar-exceptions/${id}/restore`, {}, { ...authHeader });
    });
  };

  return {
    createCalendarException,
    getCalendarExceptions,
    getCalendarExceptionById,
    updateCalendarException,
    deleteCalendarException,
    restoreCalendarException,
  };
};
