import {
  createContext,
  memo,
  MutableRefObject,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { ITour } from 'api/models/Tour';
import { IPassenger } from 'api/models/Passenger';

export type TItemsInAdditionState<Container, Item> = {
  srcContainer: Partial<Container> | 'new' | 'revert';
  targetContainer: Partial<Container> | 'new' | 'revert';
  items: Partial<Item>[];
  commitTransaction: (itemInterceptor?: (item: Partial<Item>) => Partial<Item>) => void;
} | null;
type TMoveItemsState<Container, Item> = { srcContainer: Partial<Container> | 'new' | 'revert'; items: Partial<Item>[] } | null;

interface IDragDropContextProps<Container, Item> {
  editContainer: Partial<Container> | null;
  editItem: Partial<Item> | null;
  multipleSelectedItems: { srcContainer: Partial<Container> | 'new' | 'revert'; item: Partial<Item> }[] | null;
  draggingItems: Partial<Item>[] | null;
  draggingOverContainer: Partial<Container> | 'new' | 'revert' | null;
  draggingSrcContainer: Partial<Container> | 'new' | 'revert' | null;
  availableItems: Partial<Item>[];
  itemsInAdditionState: TItemsInAdditionState<Container, Item>;
  moveItemsState: TMoveItemsState<Container, Item>;
}

const DragDropState = atom<IDragDropContextProps<any, any>>({
  key: 'DragDropState',
  default: {
    editContainer: null,
    editItem: null,
    multipleSelectedItems: null,
    draggingItems: null,
    draggingOverContainer: null,
    draggingSrcContainer: null,
    availableItems: [],
    itemsInAdditionState: null,
    moveItemsState: null,
  },
});

interface IDataSource<Container> {
  fields: Container[];
  append: (item: Container & any) => void;
  update: (index: number, item: Container & any) => void;
  remove: (index: number) => void;
}

interface IDragDropDataProviderProps<Container, Item> {
  dataSource: IDataSource<Partial<Container> & Partial<Record<any, Partial<Item>[]>>>;
  itemsKey: keyof Container | string;
  tourPrefix?: string;
  direction: 'outwards' | 'return';
  dragOverContainerRef?: MutableRefObject<any>;
}

const DragDropDataProviderContext = createContext<IDragDropDataProviderProps<any, any>>({
  dataSource: {
    fields: [],
    append: () => {},
    update: () => {},
    remove: () => {},
  },
  itemsKey: 'items',
  direction: 'outwards',
});

export const useDragDropDataProvider = <Container, Item>() =>
  useContext(DragDropDataProviderContext) as IDragDropDataProviderProps<Container, Item>;

export const DragDropContext = memo(<Container, Item>(props: PropsWithChildren<IDragDropDataProviderProps<Container, Item>>) => {
  const { children, dataSource, itemsKey, tourPrefix, direction } = props;

  const [dragDropState, setDragDropState] = useRecoilState(DragDropState);
  const dragOverContainerRef = useRef<Partial<Container> | 'new' | 'revert' | null>(null);

  useEffect(() => {
    const listener = (e: MouseEvent) => {
      if (!e.defaultPrevented) {
        setDragDropState((state) => ({ ...state, multipleSelectedItems: null, moveItemsState: null }));
      }
    };

    if (dragDropState.multipleSelectedItems) {
      setTimeout(() => {
        document.addEventListener('click', listener, { passive: true });
      }, 100);
    }

    return () => {
      document.removeEventListener('click', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dragDropState.multipleSelectedItems]);

  const context = useMemo(
    () => ({
      dataSource,
      itemsKey,
      tourPrefix,
      direction,
      dragOverContainerRef,
    }),
    [dataSource, itemsKey, tourPrefix, direction, dragOverContainerRef],
  );

  return <DragDropDataProviderContext.Provider value={context}>{children}</DragDropDataProviderContext.Provider>;
});

export const useMoveItemsToContainer = <Container, Item>(props: {
  getCreationAttributes?: (draggingItems: Partial<Item>[] | null, creationProps: {} & any) => {} & any;
  onDropListener?: (props: TItemsInAdditionState<Container, Item>) => void;
}) => {
  const { getCreationAttributes, onDropListener } = props;

  const { dataSource, itemsKey, tourPrefix, direction } = useDragDropDataProvider<Container, Item>();
  const [, setDragDropState] = useRecoilState<IDragDropContextProps<Container, Item>>(DragDropState);

  return useCallback(
    (props: {
      srcContainer: Partial<Container> | 'new' | 'revert' | null;
      itemsToMove: Partial<Item>[] | null;
      targetContainer: Partial<Container> | 'new' | 'revert';
      creationProps?: {} & any;
    }) => {
      const { srcContainer, targetContainer, itemsToMove, creationProps = {} } = props;

      if (srcContainer !== targetContainer) {
        const commitTransaction = (itemInterceptor?: (item: Partial<Item>) => Partial<Item>) => {
          // remove items from old containers
          itemsToMove?.forEach((item) => {
            const updateContainerIndex = dataSource.fields.findIndex((container) => (container && container[itemsKey])?.includes(item));
            if (updateContainerIndex >= 0) {
              const updateContainer = dataSource.fields.at(updateContainerIndex)!;
              dataSource.update(updateContainerIndex, {
                ...updateContainer,
                hasChanged: true,
                [itemsKey]: ((updateContainer && updateContainer[itemsKey]) || [])
                  .filter((item) => !itemsToMove?.includes(item))
                  .map((item, index) => ({ ...item, position: index })), // update positions
              });
            }
          });

          if (targetContainer !== 'revert' && targetContainer !== 'new') {
            // move passengers to new tour when moved to tour (not new tour)
            const updateDestContainerIndex = dataSource.fields.findIndex((item) => item === targetContainer);
            if (updateDestContainerIndex >= 0) {
              const updateDestContainer = dataSource.fields.at(updateDestContainerIndex);
              dataSource.update(updateDestContainerIndex, {
                ...updateDestContainer,
                hasChanged: true,
                [itemsKey]: [
                  ...((updateDestContainer && updateDestContainer[itemsKey]) || []).filter((item) => !itemsToMove?.includes(item)),
                  ...(itemsToMove || []),
                ].map((item, index) => ({ ...(itemInterceptor ? itemInterceptor(item) : item), position: index })), // update positions
              });
            }
          }

          if (targetContainer !== 'revert') {
            // remove items from available items when moved to tour (or new tour)
            setDragDropState((state) => ({
              ...state,
              availableItems: state.availableItems.filter((item) => !itemsToMove?.includes(item)),
            }));
          }

          if (targetContainer === 'new') {
            // move passengers to new tour when moved to new tour
            dataSource.append({
              name: `${tourPrefix || 'Tour'} ${(dataSource.fields.length + 1).toString().padStart(2, '0')}`,
              comment: null,
              direction,
              hasChanged: true,
              [itemsKey]: (itemsToMove || []).map((item, index) => ({
                ...(itemInterceptor ? itemInterceptor(item) : item),
                position: index,
              })), // update positions
              ...(getCreationAttributes?.(itemsToMove || [], creationProps) || {}),
            });
          }

          if (targetContainer === 'revert') {
            // add passengers to available passengers when moved to revert
            type GenericPositionItem = { position?: number };
            type GenericAvailableItem = {
              ScheduledTourPassenger?: GenericPositionItem;
              TourPassenger?: GenericPositionItem;
            } & GenericPositionItem;
            setDragDropState((state) => ({
              ...state,
              availableItems: [...state.availableItems.filter((item) => !itemsToMove?.includes(item)), ...(itemsToMove || [])].sort(
                (a: GenericAvailableItem, b: GenericAvailableItem) =>
                  a.ScheduledTourPassenger !== undefined && b.ScheduledTourPassenger !== undefined
                    ? (a.ScheduledTourPassenger.position || 0) - (b.ScheduledTourPassenger.position || 0)
                    : a.TourPassenger !== undefined && b.TourPassenger !== undefined
                    ? (a.TourPassenger.position || 0) - (b.TourPassenger.position || 0)
                    : (a.position || 0) - (b.position || 0),
              ),
            }));
          }
        };

        if (onDropListener) {
          // drop listener
          onDropListener({
            srcContainer: srcContainer!,
            targetContainer,
            items: itemsToMove || [],
            commitTransaction,
          });
        } else {
          // transaction without drop listener
          commitTransaction();
        }
      }
    },
    [dataSource, direction, getCreationAttributes, itemsKey, onDropListener, setDragDropState, tourPrefix],
  );
};

export const useDragDropContainer = <Container, Item>(
  container: Partial<Container> | 'new' | 'revert',
  getCreationAttributes?: (draggingItems: Partial<Item>[] | null) => {} & any,
  onDropListener?: (props: TItemsInAdditionState<Container, Item>) => void,
) => {
  const { dataSource, itemsKey, tourPrefix, direction, dragOverContainerRef } = useDragDropDataProvider<Container, Item>();
  const [dragDropState, setDragDropState] = useRecoilState<IDragDropContextProps<Container, Item>>(DragDropState);
  const dragCounter = useRef(0);

  const moveItemsToContainer = useMoveItemsToContainer({
    getCreationAttributes,
    onDropListener,
  });

  const onPointerEnter = useCallback(
    (e: any) => {
      if (!(dragDropState.draggingItems || []).length) {
        return;
      }

      // prevent default
      e.preventDefault();
      e.stopPropagation();

      dragCounter.current++;

      if (dragOverContainerRef && dragOverContainerRef.current !== container) {
        dragOverContainerRef.current = container;
        setDragDropState((state) => ({ ...state, draggingOverContainer: container }));
      }
    },
    [container, dragDropState.draggingItems, dragOverContainerRef, setDragDropState],
  );

  const onPointerLeave = useCallback(
    (e: any) => {
      if (!(dragDropState.draggingItems || []).length) {
        return;
      }

      // prevent default
      e.preventDefault();
      e.stopPropagation();

      dragCounter.current--;

      if (dragOverContainerRef && dragOverContainerRef.current === container && dragCounter.current < 1) {
        dragOverContainerRef.current = null;
        setDragDropState((state) => ({ ...state, draggingOverContainer: null }));
      }
    },
    [container, dragDropState.draggingItems, dragOverContainerRef, setDragDropState],
  );

  const onPointerUp = useCallback(
    (e: any) => {
      if (!(dragDropState.draggingItems || []).length || e.defaultPrevented) {
        return;
      }

      // prevent default
      e.preventDefault();

      // move items
      moveItemsToContainer({
        srcContainer: dragDropState.draggingSrcContainer,
        targetContainer: container,
        itemsToMove: dragDropState.draggingItems,
      });

      // reset states
      setDragDropState((state) => ({
        ...state,
        draggingItems: null,
        draggingSrcContainer: null,
        draggingOverContainer: null,
        multipleSelectedItems: null,
        moveItemsState: null,
      }));
    },
    [container, dragDropState.draggingItems, dragDropState.draggingSrcContainer, setDragDropState, moveItemsToContainer],
  );

  return useMemo(
    () => ({
      highlight:
        (dragDropState.draggingOverContainer === container && dragDropState.draggingSrcContainer !== container) ||
        dragDropState.editContainer === container,
      onPointerEnter,
      onPointerLeave,
      onPointerUp,
      onPointerCancel: onPointerUp,
      ...(container === 'new'
        ? {
            onClick: () => {
              // add new empty tour
              dataSource.append({
                name: `${tourPrefix || 'Tour'} ${(dataSource.fields.filter((item: any) => item.direction === direction).length + 1)
                  .toString()
                  .padStart(2, '0')}`,
                comment: null,
                direction,
                hasChanged: true,
                [itemsKey]: [],
                ...(getCreationAttributes?.(null) || {}),
              });
            },
          }
        : {}),
    }),
    [
      container,
      dragDropState.draggingOverContainer,
      dragDropState.draggingSrcContainer,
      dragDropState.editContainer,
      onPointerEnter,
      onPointerLeave,
      onPointerUp,
      dataSource,
      tourPrefix,
      direction,
      itemsKey,
      getCreationAttributes,
    ],
  );
};

export const useDragDropItem = <Container, Item>(
  container: Partial<Container> | 'new' | 'revert',
  item: Partial<Item> | Partial<Item>[],
) => {
  const [dragDropState, setDragDropState] = useRecoilState<IDragDropContextProps<Container, Item>>(DragDropState);

  const draggableItems = useMemo(() => (Array.isArray(item) ? item : [item]), [item]);
  const draggable = useMemo(() => draggableItems.length > 0, [draggableItems]);

  const dragging = useMemo(
    () => draggableItems.length > 0 && draggableItems.every((item) => dragDropState.draggingItems?.includes(item)),
    [draggableItems, dragDropState.draggingItems],
  );

  const selected = useMemo(
    () => !!dragDropState.multipleSelectedItems?.find((selection) => selection.item === item),
    [dragDropState.multipleSelectedItems, item],
  );

  const selectMode = useMemo(() => (dragDropState.multipleSelectedItems || []).length > 0, [dragDropState.multipleSelectedItems]);

  const mouseDownTimeout = useRef<any>(null);

  const documentOnPointerMoveListener = useCallback(
    (e: MouseEvent) => {
      if (dragging) {
        const draggingItemNode = document.querySelector('#dragging-node') as HTMLDivElement;
        draggingItemNode?.style.setProperty('--x', `${Math.round(e.clientX)}px`);
        draggingItemNode?.style.setProperty('--y', `${Math.round(e.clientY)}px`);
      }
    },
    [dragging],
  );

  const documentOnPointerUpListener = useCallback(
    (e: MouseEvent) => {
      // dismiss dragging item
      const draggingItemNode = document.querySelector('#dragging-node') as HTMLDivElement;
      if (draggingItemNode) {
        document.body.removeChild(draggingItemNode);
      }

      if (dragging && !e.defaultPrevented) {
        setDragDropState((state) => ({
          ...state,
          draggingItems: null,
          draggingSrcContainer: null,
          draggingOverContainer: null,
          multipleSelectedItems: null,
          moveItemsState: null,
        }));
      }
    },
    [dragging, setDragDropState],
  );

  useEffect(() => {
    document.addEventListener('pointermove', documentOnPointerMoveListener, { passive: true });
    document.addEventListener('pointerup', documentOnPointerUpListener, { passive: true });
    document.addEventListener('pointercancel', documentOnPointerUpListener, { passive: true });

    return () => {
      document.removeEventListener('pointermove', documentOnPointerMoveListener);
      document.removeEventListener('pointerup', documentOnPointerUpListener);
      document.removeEventListener('pointercancel', documentOnPointerUpListener);
    };
  }, [documentOnPointerMoveListener, documentOnPointerUpListener]);

  useEffect(() => {
    // toggle no scroll on body
    document.body.classList.toggle('no-scroll', dragging);
    document.body.classList.toggle('dragging', dragging);

    if (!dragging) {
      // dismiss dragging item
      const draggingItemNode = document.querySelector('#dragging-node') as HTMLDivElement;
      if (draggingItemNode) {
        document.body.removeChild(draggingItemNode);
      }
    }
  }, [dragging]);

  const onPointerDown = useCallback(
    (e: any) => {
      if (draggable) {
        if (!e.target.hasAttribute('data-drag-item') || dragDropState.moveItemsState !== null) {
          return;
        }

        // release pointer capture (for mobile)
        e.target.releasePointerCapture(e.pointerId);

        if (mouseDownTimeout.current) {
          clearTimeout(mouseDownTimeout.current);
          mouseDownTimeout.current = null;
        }
        mouseDownTimeout.current = setTimeout(() => {
          setDragDropState((state) => ({
            ...state,
            draggingItems: [
              ...(dragDropState.multipleSelectedItems?.map(({ item }) => item) || []).filter(
                (selectedItem) => !draggableItems.includes(selectedItem),
              ),
              ...draggableItems,
            ],
            draggingSrcContainer: container,
          }));

          // setup dragging item
          let draggingItemNode = document.querySelector('#dragging-node') as HTMLDivElement;
          if (draggingItemNode) {
            document.body.removeChild(draggingItemNode);
          }
          const target = e.target.closest('[data-drag-item]');
          draggingItemNode = target?.cloneNode(true);
          draggingItemNode.id = 'dragging-node';
          draggingItemNode.style.position = 'fixed';
          draggingItemNode.style.zIndex = '10';
          draggingItemNode.style.pointerEvents = 'none';
          draggingItemNode.style.insetInlineStart = '0';
          draggingItemNode.style.insetBlockStart = '0';
          draggingItemNode.style.width = `${target.offsetWidth}px`;
          draggingItemNode.style.height = `${target.offsetHeight}px`;
          draggingItemNode.style.transform = 'translate(calc(-50% + var(--x, 0)), calc(-50% + var(--y, 0)))';
          draggingItemNode.style.setProperty('--x', `${Math.round(e.clientX)}px`);
          draggingItemNode.style.setProperty('--y', `${Math.round(e.clientY)}px`);
          document.body.append(draggingItemNode);

          mouseDownTimeout.current = null;
        }, 500);
      }
    },
    [container, dragDropState.moveItemsState, dragDropState.multipleSelectedItems, draggable, draggableItems, setDragDropState],
  );

  const handleEndDrag = useCallback(
    (e: any) => {
      if (draggable) {
        if (mouseDownTimeout.current) {
          clearTimeout(mouseDownTimeout.current);
          mouseDownTimeout.current = null;
          return true;
        }
      }
      return false;
    },
    [draggable],
  );

  const onClick = useCallback(() => {
    if (draggable) {
      // handle multi select
      if (draggableItems.length > 0 && dragDropState.moveItemsState === null) {
        setDragDropState((state) => ({
          ...state,
          multipleSelectedItems: [
            ...(state.multipleSelectedItems || []).filter(
              (selection) => !draggableItems.includes(selection.item) && selection.srcContainer === container,
            ),
            ...(draggableItems.every((item) => (state.multipleSelectedItems || []).some((selection) => selection.item === item))
              ? []
              : draggableItems.map((item) => ({ srcContainer: container, item }))),
          ],
        }));
      }
    }
  }, [container, draggable, draggableItems, dragDropState.moveItemsState, setDragDropState]);

  return useMemo(
    () => ({
      canBeDragged: draggable,
      dragging,
      selected,
      selectMode,
      'data-drag-item': true, // used as selector
      onPointerDown,
      onPointerUp: handleEndDrag,
      onPointerCancel: handleEndDrag,
      onMouseUp: handleEndDrag,
      onPointerMove: handleEndDrag,
      onClick,
    }),
    [draggable, dragging, selected, selectMode, onPointerDown, handleEndDrag, onClick],
  );
};

export const useDragDropPositionItem = <Container, Item>(container: Partial<Container> | 'new' | 'revert', item: Partial<Item>) => {
  const [dragDropState, setDragDropState] = useRecoilState<IDragDropContextProps<Container, Item>>(DragDropState);
  const { dataSource, itemsKey } = useDragDropDataProvider<ITour, IPassenger>();

  const [dropAction, setDropAction] = useState<'insertTop' | 'insertBottom' | 'none'>('none');

  const onPointerEnter = useCallback(
    (e: any) => {
      if ((dragDropState.draggingItems || []).length !== 1) {
        // only for single item drags
        return;
      }

      // prevent default
      e.preventDefault();
      e.stopPropagation();

      const action = e.nativeEvent.offsetY > 28 ? 'insertBottom' : 'insertTop';
      if (dropAction !== action) {
        setDropAction(action);
      }
    },
    [dragDropState.draggingItems, dropAction, setDropAction],
  );

  const onPointerLeave = useCallback(
    (e: any) => {
      // if ((dragDropState.draggingItems || []).length !== 1) {      // only for single item drags
      //   return;
      // }

      // prevent default
      e.preventDefault();
      e.stopPropagation();

      setDropAction('none');
    },
    [setDropAction],
  );

  const onDrop = useCallback(
    (e: any) => {
      if (!(dragDropState.draggingItems || []).length || e.defaultPrevented || typeof container !== 'object') {
        return;
      }

      // prevent default
      e.preventDefault();

      if (dropAction !== 'none') {
        const draggingItem = (dragDropState.draggingItems || [])[0];
        if (dragDropState.draggingSrcContainer === container && draggingItem) {
          // update passenger position
          const index = dataSource.fields.findIndex((field) => field === container);
          if (index >= 0) {
            const items = [...((typeof container === 'object' && (container as any)[itemsKey]) || [])];
            let targetIndex = items.findIndex((i) => i === item) + (dropAction === 'insertTop' ? 0 : 1);

            // when dragged item before targetIndex -> reduce target index by 1
            const draggingItemIndex = items.findIndex((i) => i === draggingItem);
            if (draggingItemIndex < targetIndex) {
              targetIndex--;
            }

            // delete dragging item from old position and add it to new position
            items.splice(draggingItemIndex, 1);
            items.splice(targetIndex, 0, draggingItem);

            // update data source
            dataSource.update(index, {
              ...container,
              hasChanged: true,
              [itemsKey]: items.map((item, index) => ({
                ...item,
                position: index,
              })),
            });
          }
        }
        setDropAction('none');
      }

      // reset states
      setDragDropState((state) => ({
        ...state,
        draggingItems: null,
        draggingSrcContainer: null,
        draggingOverContainer: null,
        multipleSelectedItems: null,
        moveItemsState: null,
      }));
    },
    [container, dataSource, dragDropState.draggingItems, dragDropState.draggingSrcContainer, dropAction, item, itemsKey, setDragDropState],
  );

  return useMemo(() => {
    if (typeof container !== 'object' || (dragDropState.draggingItems || []).length > 1) {
      return {
        dropAction: 'none',
      };
    } else {
      return {
        dropAction,
        onPointerEnter,
        onPointerLeave,
        onPointerUp: onDrop,
      };
    }
  }, [container, dragDropState.draggingItems, dropAction, onPointerEnter, onPointerLeave, onDrop]);
};

export const useDropOverItems = <Container, Item>(container: Partial<Container> | 'new' | 'revert'): Partial<Item>[] => {
  const dragDropState = useRecoilValue<IDragDropContextProps<Container, Item>>(DragDropState);
  return useMemo(
    () =>
      (dragDropState.draggingOverContainer === container &&
        dragDropState.draggingSrcContainer !== container &&
        dragDropState.draggingItems) ||
      [],
    [container, dragDropState.draggingOverContainer, dragDropState.draggingSrcContainer, dragDropState.draggingItems],
  );
};

export const useSelectedDragDropItems = <Container, Item>(container: Partial<Container> | 'new' | 'revert') => {
  const [dragDropState, setDragDropState] = useRecoilState<IDragDropContextProps<Container, Item>>(DragDropState);
  return useMemo(
    () =>
      [
        (dragDropState.multipleSelectedItems || []).filter((item) => item.srcContainer === container).map(({ item }) => item),
        function resetSelectedItems() {
          setDragDropState((state) => ({
            ...state,
            multipleSelectedItems: (state.multipleSelectedItems || []).filter((item) => item.srcContainer !== container),
          }));
        },
      ] as const,
    [container, dragDropState.multipleSelectedItems, setDragDropState],
  );
};

export const useAvailableDragDropItems = <Item,>() => {
  const [dragDropState, setDragDropState] = useRecoilState<IDragDropContextProps<any, Item>>(DragDropState);

  return useMemo(
    () =>
      [
        dragDropState.availableItems,
        (items: Partial<Item>[] | ((items: Partial<Item>[]) => Partial<Item>[])) => {
          setDragDropState((state) => ({
            ...state,
            availableItems: (typeof items === 'function' ? items(state.availableItems || []) : items) || [],
          }));
        },
      ] as const,
    [dragDropState.availableItems, setDragDropState],
  );
};

export const useEditDragDropContainer = <Container,>() => {
  const [dragDropState, setDragDropState] = useRecoilState<IDragDropContextProps<Container, any>>(DragDropState);

  return useMemo(
    () =>
      [
        dragDropState.editContainer,
        (container: Partial<Container> | null) => {
          setDragDropState((state) => ({ ...state, editContainer: container }));
        },
      ] as const,
    [dragDropState.editContainer, setDragDropState],
  );
};

export const useEditDragDropItem = <Item,>() => {
  const [dragDropState, setDragDropState] = useRecoilState<IDragDropContextProps<any, Item>>(DragDropState);

  return useMemo(
    () =>
      [
        dragDropState.editItem,
        (item: Partial<Item> | null) => {
          setDragDropState((state) => ({ ...state, editItem: item }));
        },
      ] as const,
    [dragDropState.editItem, setDragDropState],
  );
};

export const useDragDropItemsInAdditionsState = <Container, Item>() => {
  const [dragDropState, setDragDropState] = useRecoilState<IDragDropContextProps<any, Item>>(DragDropState);

  return useMemo(
    () =>
      [
        dragDropState.itemsInAdditionState,
        (itemsInAdditionState: TItemsInAdditionState<Container, Item>) => {
          setDragDropState((state) => ({ ...state, itemsInAdditionState }));
        },
      ] as const,
    [dragDropState.itemsInAdditionState, setDragDropState],
  );
};

export const useDragDropItemsMoveState = <Container, Item>() => {
  const [dragDropState, setDragDropState] = useRecoilState<IDragDropContextProps<Container, Item>>(DragDropState);

  return useMemo(
    () =>
      [
        dragDropState.moveItemsState,
        (moveItemsState: TMoveItemsState<Container, Item>) => {
          setDragDropState((state) => ({ ...state, moveItemsState }));
        },
      ] as const,
    [dragDropState.moveItemsState, setDragDropState],
  );
};

export const useMoveItems = <Container, Item>(props: {
  getCreationAttributes?: (draggingItems: Partial<Item>[] | null) => {} & any;
  onDropListener?: (props: TItemsInAdditionState<Container, Item>) => void;
}) => {
  const [dragDropState, setDragDropState] = useRecoilState<IDragDropContextProps<Container, Item>>(DragDropState);
  const moveItems = useMoveItemsToContainer<Container, Item>(props);

  return useCallback(
    (targetContainer: Partial<Container> | 'revert' | 'new') => {
      // move items
      if (dragDropState.moveItemsState && dragDropState.moveItemsState.srcContainer !== targetContainer) {
        moveItems({
          srcContainer: dragDropState.moveItemsState.srcContainer,
          targetContainer,
          itemsToMove: dragDropState.moveItemsState.items,
        });
      }

      // reset state
      setDragDropState((state) => ({
        ...state,
        multipleSelectedItems: null,
        moveItemsState: null,
      }));
    },
    [dragDropState.moveItemsState, moveItems, setDragDropState],
  );
};
