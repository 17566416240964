import { FC, memo, PropsWithChildren, useMemo } from 'react';
import { ITour } from 'api/models/Tour';
import {
  useAvailableDragDropItems,
  useDragDropContainer,
  useDragDropDataProvider,
  useDragDropItemsMoveState,
  useEditDragDropContainer,
  useSelectedDragDropItems,
} from '../DragDropContext';
import { useShowDialog } from 'state/DialogState';
import { IPassenger } from 'api/models/Passenger';
import { QuickInput } from 'components/common/inputs/QuickInput';
import { OrderTourLabel, TourLabel } from 'components/common/labels/TourLabel';
import { ContextOptions } from 'components/common/elements/ContextOptions';
import { Trash } from 'components/icons/Trash';
import { ButtonPrimary, ButtonSecondary, ButtonTertiary } from 'components/common/inputs/Button';
import { Edit } from 'components/icons/Edit';
import { Wheelchair } from 'components/icons/Wheelchair';
import { Walker } from 'components/icons/Walker';
import styled, { css } from 'styled-components';
import { Colors } from 'styles/Colors';
import { Body4 } from 'styles/FontStyles';
import TourPickupTimes from 'components/common/elements/TourPickupTimes';
import { getTourDaysOfWeek } from 'utils/tourUtils';
import { SimpleSwitchSmall } from 'components/common/inputs/Switch';
import DriverPicker from 'components/common/elements/DriverPicker';
import { ICustomer } from 'api/models/Customer';
import { TourplanAvailablePassengerView } from 'components/content/customers/tourplan/tiles/TourplanPassengerView';
import { DropOverItems } from 'components/content/customers/tourplan/tiles/DropOverItems';
import { ButtonsWrapper } from '../overlays/TourplanOverlay';

export const TourplanToursWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
  align-items: flex-start;
  justify-items: stretch;

  margin-inline: -1rem;
  padding: 1rem;
  gap: 1rem;
`;

export const TileItem = styled.div<{ highlight?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  background-color: ${Colors.white50};
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
  border-radius: 8px;

  border: 1px solid transparent;
  transition: border-color 150ms ease-out;

  ${({ highlight = false }) =>
    highlight &&
    css`
      border-color: ${Colors.secondary};

      & > * {
        pointer-events: none;
      }
    `};
`;

const TourItem = styled(TileItem)`
  gap: 0.5rem;
  padding: 1rem;

  button {
    max-width: none;
  }
`;

const TourItemNameRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.5rem;

  ${Body4};

  & > * {
    min-block-size: 1.75rem;
  }
`;

const TourItemNameRowContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;

  flex: 1;
`;

const TourItemHead = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.5rem;

  padding-block-end: 0.5rem;
  border-bottom: 1px solid ${Colors.grey500};
`;

const TourItemPassengersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.25rem;
`;

export const TourDragItemContent = styled.div<{ $pointerEvents?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.25rem;

  padding-inline: 0.5rem;
  padding-block: 0.25rem;

  background-color: ${Colors.white50};
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
  border-radius: 4px;

  ${Body4};
  text-align: start;

  border: 1px solid transparent;
  transition: border-color 150ms ease-out, opacity 150ms ease-out;

  pointer-events: ${({ $pointerEvents = false }) => ($pointerEvents ? 'auto' : 'none')};

  em {
    font-style: normal;
    color: ${Colors.secondary};
    padding-inline-end: 0.5rem;
  }
`;

export const TourDragItem = styled.div<{
  canBeDragged?: boolean;
  dragging?: boolean;
  selected?: boolean;
  selectMode?: boolean;
  empty?: boolean;
  dropAction?: 'insertTop' | 'insertBottom' | 'none';
}>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  user-select: none;
  ${({ empty = false }) =>
    empty &&
    css`
      ${TourDragItemContent} {
        color: ${Colors.grey600};
        align-items: center;
        justify-content: center;

        min-block-size: 2.5rem;
        border: 1px dashed ${Colors.grey600};

        svg {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    `};

  ${({ canBeDragged = false }) =>
    canBeDragged &&
    css`
      &:hover,
      &:active {
        cursor: move;
      }
    `};

  ${({ selectMode = false }) =>
    selectMode &&
    css`
      ${TourDragItemContent} {
        opacity: 0.3;
      }
    `};

  ${({ selected = false }) =>
    selected &&
    css`
      ${TourDragItemContent} {
        color: ${Colors.secondary};
        border-color: ${Colors.secondary};
        opacity: 1;
      }
    `};

  ${({ dragging = false }) =>
    dragging &&
    css`
      ${TourDragItemContent} {
        opacity: 0.3;
      }
    `};

  ${({ dragging = false, empty = false }) =>
    dragging &&
    empty &&
    css`
      ${TourDragItemContent} {
        opacity: 1;
      }
    `};

  &#dragging-node {
    ${TourDragItemContent} {
      background-color: ${Colors.secondary};
      --text-color: ${Colors.textInverted};
      color: var(--text-color);
    }
  }

  ${({ dragging = false, dropAction = 'none' }) =>
    !dragging &&
    dropAction !== 'none' &&
    css`
      position: relative;

      &::before {
        content: '';
        position: absolute;
        margin-block: 0.375rem;
        inset-inline: 0;
        block-size: 0.25rem;
        border-radius: 0.25rem;
        background-color: ${Colors.secondary};
      }
    `};

  ${({ dragging = false, dropAction = 'none' }) =>
    !dragging &&
    dropAction === 'insertTop' &&
    css`
      padding-block: 1.25rem 0;

      &::before {
        inset-block-start: 0;
      }
    `};
  ${({ dragging = false, dropAction = 'none' }) =>
    !dragging &&
    dropAction === 'insertBottom' &&
    css`
      padding-block: 0 1.25rem;

      &::before {
        inset-block-end: 0;
      }
    `};
`;

const OrderTourLabelTitle = styled(OrderTourLabel)`
  margin-inline-end: -0.25rem;
`;

const OrderTourLabelInput = styled(OrderTourLabel)`
  margin-inline-end: 0;
  margin-block-end: 0.375rem;
`;

const DirectionHint = styled.span`
  margin-inline-end: auto;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const DriverPickersRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  & > * {
    flex: 1;
    flex-shrink: 0;
  }
`;

const TourItemDropContainer = memo((props: PropsWithChildren<{ tour: Partial<ITour> }>) => {
  const { tour, children } = props;

  const dropContainerProps = useDragDropContainer<ITour, IPassenger>(tour);

  return <TourItem {...dropContainerProps}>{children}</TourItem>;
});

interface ITourplanTourTileProps {
  tour: Partial<ITour> & Partial<{ ignoreCalendarException: boolean }>;
  customer?: Partial<ICustomer>;
  copyForReturnTourDisabled?: boolean;
  copyForReturnTour?: boolean;
  onSetIgnoreCalenderExceptionTour: (ignore: boolean) => void;
  onSetCopyForReturnTour?: (copy: boolean) => void;
  onRemove: () => void;
}

/**
 * for create/edit order only
 */
export const TourplanTourTile: FC<ITourplanTourTileProps> = (props) => {
  const {
    tour,
    // customer,
    copyForReturnTourDisabled,
    copyForReturnTour,
    onSetIgnoreCalenderExceptionTour,
    onSetCopyForReturnTour,
    onRemove,
  } = props;

  const showDialog = useShowDialog();

  const [renameTour, setRenameTour] = useEditDragDropContainer<ITour>();

  const [, setAvailablePassengers] = useAvailableDragDropItems<IPassenger>();
  const { dataSource } = useDragDropDataProvider<ITour, IPassenger>();

  const [selectedItems, resetSelectedItems] = useSelectedDragDropItems<ITour, IPassenger>(tour);
  const [moveItemsState, setMoveItemsState] = useDragDropItemsMoveState();

  const [passengersWheelchair, passengersWalking] = useMemo(() => {
    return (tour.passengers || []).reduce(
      (res, item) => {
        res[item.hasWheelchair ? 0 : 1].push(item);
        return res;
      },
      [[], []] as Partial<IPassenger>[][],
    );
  }, [tour]);

  // const plannedDrivers = useMemo(() => {
  //   return dataSource.fields.flatMap((tour) => [tour.driverId, tour.companionId].filter(Boolean) as unknown as string[]);
  // }, [dataSource.fields]);

  const nameRow = useMemo(
    () => (
      <TourItemNameRowContent>
        {renameTour === tour && (
          <>
            <OrderTourLabelInput>V</OrderTourLabelInput>
            <QuickInput
              id={'tourName'}
              placeholder={'Tournummer eingeben'}
              defaultValue={tour.name}
              onSubmit={(value) => {
                // update tour name
                const index = dataSource.fields.findIndex((item) => item === tour);
                if (index >= 0) {
                  dataSource.update(index, {
                    ...tour,
                    name: value,
                  });
                }
              }}
              onBlur={() => setRenameTour(null)}
            />
          </>
        )}
        {renameTour !== tour && (
          <>
            <OrderTourLabelTitle>V</OrderTourLabelTitle>
            <TourLabel>{tour.name}</TourLabel>
            <DirectionHint>{tour.direction === 'return' ? 'Rück' : 'Hin'}</DirectionHint>
            {passengersWalking.length > 0 && (
              <IconWrapper>
                {passengersWalking.length} <Walker />
              </IconWrapper>
            )}
            {passengersWheelchair.length > 0 && (
              <IconWrapper>
                {passengersWheelchair.length} <Wheelchair />
              </IconWrapper>
            )}
            {/*<span>{(tour.passengers || []).length} Fahrgäste</span>*/}
          </>
        )}
      </TourItemNameRowContent>
    ),
    [renameTour, tour, dataSource, passengersWalking.length, passengersWheelchair.length, setRenameTour],
  );

  const contextMenu = (
    <ContextOptions
      as="div"
      items={[
        {
          content: (
            <>
              <Trash /> Entfernen
            </>
          ),
          onClick: () => {
            showDialog({
              headline: 'Tour entfernen',
              body: <>Möchten Sie diese Tour wirklich löschen?</>,
              buttons: (
                <>
                  <ButtonPrimary
                    onClick={() => {
                      setAvailablePassengers((items) => [...items, ...(tour.passengers || [])]);
                      const index = dataSource.fields.findIndex((item) => item === tour);
                      if (index >= 0) {
                        dataSource.remove(index);
                      }
                      onRemove();
                      showDialog(null);
                    }}
                  >
                    Entfernen
                  </ButtonPrimary>
                  <ButtonSecondary onClick={() => showDialog(null)}>Abbrechen</ButtonSecondary>
                </>
              ),
            });
          },
        },
        {
          content: (
            <>
              <Edit /> Umbenennen
            </>
          ),
          onClick: () => {
            setRenameTour(tour);
          },
        },
      ]}
    />
  );

  const pickupTimes = useMemo(
    () => (
      <TourPickupTimes
        editable
        timestamp={'none'} // hide current weekday in order tour view
        pickupTimes={(tour.direction === 'return' ? tour.departureDate : tour.arrivalDate) || {}}
        direction={tour.direction || undefined}
        onChange={(pickupTimes) => {
          // update tour name
          const index = dataSource.fields.findIndex((item) => item === tour);
          if (index >= 0) {
            dataSource.update(index, {
              ...tour,
              ...(tour.direction === 'return' ? { departureDate: pickupTimes } : { arrivalDate: pickupTimes }),
              daysOfWeek: getTourDaysOfWeek(pickupTimes, undefined).join(','),
            });
          }
        }}
      />
    ),
    [tour, dataSource],
  );

  const driverPickers = useMemo(
    () => (
      <DriverPickersRow>
        <DriverPicker
          title={'Fahrer*in'}
          unavailableDrivers={[tour.companionId].filter(Boolean) as string[]}
          driver={tour.driver}
          onSelect={(driver) => {
            // update tour driver
            const index = dataSource.fields.findIndex((item) => item === tour);
            if (index >= 0) {
              dataSource.update(index, {
                ...tour,
                driver,
                driverId: driver?.id || null,
              });
            }
          }}
        />
        <DriverPicker
          title={'Begleitung'}
          unavailableDrivers={[tour.driverId].filter(Boolean) as string[]}
          driver={tour.companion}
          onSelect={(companion) => {
            // update tour companion
            const index = dataSource.fields.findIndex((item) => item === tour);
            if (index >= 0) {
              dataSource.update(index, {
                ...tour,
                companion,
                companionId: companion?.id || null,
              });
            }
          }}
        />
      </DriverPickersRow>
    ),
    [tour, dataSource],
  );

  const passengersList = useMemo(
    () => (
      <TourItemPassengersWrapper>
        {(tour.passengers || []).map((passenger) => (
          <TourplanAvailablePassengerView key={passenger.id} tour={tour} passenger={passenger} />
        ))}
      </TourItemPassengersWrapper>
    ),
    [tour],
  );

  const selectedItemsButtons = useMemo(
    () => (
      <>
        {selectedItems.length > 0 && (
          <ButtonsWrapper style={{ marginBlockEnd: '.5rem' }}>
            <ButtonTertiary
              disabled={moveItemsState !== null}
              onClick={(e: Event) => {
                e.stopPropagation();
                resetSelectedItems();
              }}
            >
              Auswahl verwerfen
            </ButtonTertiary>
            <ButtonPrimary
              onClick={(e: Event) => {
                e.stopPropagation();
                if (moveItemsState !== null) {
                  setMoveItemsState(null);
                } else {
                  setMoveItemsState({ srcContainer: tour, items: selectedItems });
                }
              }}
            >
              {moveItemsState !== null
                ? 'Verschieben abbrechen'
                : `${selectedItems.length} ${selectedItems.length === 1 ? 'Fahrgast' : 'Fahrgäste'} verschieben`}
            </ButtonPrimary>
          </ButtonsWrapper>
        )}
      </>
    ),
    [selectedItems, moveItemsState, setMoveItemsState, resetSelectedItems, tour],
  );

  return (
    <TourItemDropContainer tour={tour}>
      <TourItemHead>
        <TourItemNameRow>
          {nameRow}
          {contextMenu}
        </TourItemNameRow>
        {pickupTimes}
        {driverPickers}
      </TourItemHead>
      {passengersList}
      <DropOverItems tour={tour} />
      {selectedItemsButtons}
      {tour.direction === 'outwards' && (
        <SimpleSwitchSmall
          placeholder={
            copyForReturnTourDisabled ? 'Fahrgäste sind bereits anders in Rückfahrten verplant' : 'Fahrgäste für Rückfahrt übernehmen'
          }
          disabled={copyForReturnTourDisabled}
          value={copyForReturnTour}
          onValueChange={onSetCopyForReturnTour}
        />
      )}
      <SimpleSwitchSmall
        placeholder="Schließzeiten ignorieren"
        disabled={false}
        value={tour.ignoreCalendarException}
        onValueChange={() => onSetIgnoreCalenderExceptionTour(!tour.ignoreCalendarException)}
      />
    </TourItemDropContainer>
  );
};
