import { createGlobalStyle } from 'styled-components';
import { FontFaces, FontFamilies, FontTheme } from 'styles/Fonts';
import { Colors, ColorTheme } from 'styles/Colors';
import { Body2, Body4, Headline1, Headline2, Headline3, Headline4, Headline5, Headline6 } from 'styles/FontStyles';
import { ScrollbarStyle } from 'styles/Scrollbars';

export const GlobalStyles = createGlobalStyle`
  ${FontFaces};
  ${FontTheme};
  ${ColorTheme};

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;

    // disable text selection on mobile
    // user-select: none;

    ${ScrollbarStyle};
  }

  html {
    font-size: 16px;
    overscroll-behavior-x: none;
    touch-action: none;
    overflow: visible;
  }

  body {
    background-color: ${Colors.background};
    font-family: ${FontFamilies.default};
    color: ${Colors.textDefault};
    ${Body4};
    max-width: 100%;
    overflow-x: hidden;

    scroll-behavior: smooth;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;

    &.dragging {
      &, * {
        cursor: grabbing !important;
      }
    }
  }

  html, body, body > #root {
    height: 100%;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${FontFamilies.headline};
  }

  h1 {
    ${Headline1};
  }

  h2 {
    ${Headline2};
  }

  h3 {
    ${Headline3};
  }

  h4 {
    ${Headline4};
  }

  h5 {
    ${Headline5};
  }

  h6 {
    ${Headline6};
  }

  p {
    ${Body2};
  }

  a {
    color: inherit;
    text-decoration: none;

    &[href=''] {
      cursor: default;
    }

    @media (hover: hover) {
      :hover {
        text-decoration: underline;
      }
    }
  }

  textarea,
  select,
  button,
  input {
    font: inherit;
    color: inherit;
    appearance: none;
    background: transparent;
    border: 0;
    border-radius: 0;
    outline: 0;
    cursor: pointer;

    &:disabled {
      cursor: default;
    }

    &:focus {
      outline: 0;
    }
  }

  p,
  span,
  a {
    font: inherit;
  }

  svg {
    width: 1.5rem;
    height: 1.5em;
  }

  ul {
    list-style: none;
  }

  img {
    max-width: 100%;
  }

  picture {
    display: contents;
  }

  q:before,
  q:after {
    content: "";
  }

  fieldset {
    appearance: none;
    border: 0;
  }
`;
