import { IPassenger } from 'api/models/Passenger';
import { CSSProperties, memo, PropsWithChildren, useMemo } from 'react';
import { getDayOfWeekDate } from 'utils/dateUtils';
import PassengerPickupTime from 'components/common/elements/PassengerPickupTime';
import { Wheelchair } from 'components/icons/Wheelchair';
import { Walker } from 'components/icons/Walker';
import HoverTooltip from 'components/common/elements/HoverTooltip';
import { ContextOptions } from 'components/common/elements/ContextOptions';
import { Edit } from 'components/icons/Edit';
import { Trash } from 'components/icons/Trash';
import { formatAddress } from 'utils/addressUtils';
import { TourDragItem, TourDragItemContent } from 'components/content/customers/tourplan/tiles/TourplanTourTile';
import styled from 'styled-components';
import { Body4, Body5 } from 'styles/FontStyles';
import { Colors } from 'styles/Colors';
import { InfoInverted } from 'components/icons/Info';
import { ITour } from 'api/models/Tour';
import { useDragDropItem, useDragDropPositionItem } from '../DragDropContext';
import { formatName } from 'utils/nameUtils';
import { BREAKPOINT_SM, BREAKPOINT_XS } from 'styles/Breakpoints';
import { Card } from 'components/common/atoms/Card';
import { useMatchMediaQuery } from 'hooks/useMatchBreakpoint';
import { Link } from 'components/common/atoms/Link';
import { Passengers } from 'components/icons/Passengers';
import { Close } from 'components/icons/Close';
import Play from 'components/icons/Play';

export const TourPassengerItemRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  min-block-size: 1.75rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
  }
`;

const TourPassengerItemRowMobileXS = styled(TourPassengerItemRow)`
  ${BREAKPOINT_XS} {
    display: none;
  }
`;

export const TourPassengerName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
`;

const TourPassengerNameSM = styled(TourPassengerName)`
  display: none;

  ${BREAKPOINT_XS} {
    display: flex;
  }
`;

const TourPassengerOptionsBox = styled.div<{ inSidebar?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 0.25rem;

  margin-inline-start: auto;

  min-inline-size: ${(props) => (props.inSidebar ? 'auto' : '3.25rem')};
  max-inline-size: 250px;
  flex: 1;

  & > * {
    pointer-events: auto;
  }
`;

// TODO change UI
const TourPassengerCommentWrapper = styled(Card)`
  ${Body4};
  text-align: start;

  padding-block: 0.5rem;
  padding-inline: 1rem;

  box-shadow: 0 0 24px rgba(0, 0, 0, 0.04);
`;

const TourPassengerInfoIcon = styled(InfoInverted)`
  color: ${Colors.grey400};
`;

const LoadingTime = styled.span`
  margin-inline: -0.25rem;
  ${Body5};
  color: ${Colors.grey700};

  display: none;

  ${BREAKPOINT_SM} {
    display: flex;
  }
`;

const DragItemContainer = memo((props: PropsWithChildren<{ isStatic: boolean; style?: CSSProperties }>) => {
  const { isStatic, style, children } = props;

  if (isStatic) {
    return <TourDragItem style={style}>{children}</TourDragItem>;
  }

  return <>{children}</>;
});

const TourPassengerNameRow = memo(
  (
    props: PropsWithChildren<{
      showPickupTimes?: boolean;
      selectedDayOfWeek: string;
      onPickupDateChange?: (value: { [day: string]: string }) => void;
      passenger: Partial<IPassenger>;
    }>,
  ) => {
    const { showPickupTimes, selectedDayOfWeek, onPickupDateChange, passenger, children } = props;

    const pickupDate = (passenger?.ScheduledTourPassenger?.pickupDate || {})[new Date(selectedDayOfWeek).getDay()];
    const loadingTime = +(passenger.loadingTime || 0);

    return (
      <>
        {showPickupTimes && (
          <PassengerPickupTime
            value={pickupDate}
            onChange={
              onPickupDateChange
                ? (value) => {
                    onPickupDateChange?.({
                      ...(passenger?.ScheduledTourPassenger?.pickupDate || {}),
                      [new Date(selectedDayOfWeek).getDay()]: !!value?.length
                        ? new Date(`2023-01-01 ${value}:00`).toISOString()
                        : undefined,
                    });
                  }
                : undefined
            }
          >
            {loadingTime > 0 && <LoadingTime>(+{loadingTime} Min.)</LoadingTime>}
            {children}
          </PassengerPickupTime>
        )}
        {!showPickupTimes && children}
      </>
    );
  },
);

export interface ITourplanPassengerViewProps {
  passenger: Partial<IPassenger>;
  position?: number;
  markAddressBold?: boolean;
  showInfoInline?: boolean;
  showNameSingleLine?: boolean;
  passengerClickable?: boolean;
  showPickupTimes?: boolean;
  selectedDayOfWeek?: string;
  onPickupDateChange?: (value: { [day: string]: string }) => void;
  onShowClick?: () => void;
  onEditClick?: () => void;
  onRemoveClick?: () => void;
  onAbsentClick?: () => void;
  onPresentClick?: () => void;
  style?: CSSProperties;
  isStatic?: boolean;
  inSidebar?: boolean;
}

export const TourplanPassengerView = memo((props: ITourplanPassengerViewProps) => {
  const {
    passenger,
    position,
    markAddressBold = false,
    showInfoInline = false,
    showNameSingleLine = false,
    passengerClickable = false,
    showPickupTimes = false,
    selectedDayOfWeek = getDayOfWeekDate(1).toISOString(),
    onPickupDateChange,
    onShowClick,
    onEditClick,
    onRemoveClick,
    onAbsentClick,
    onPresentClick,
    style,
    isStatic = true,
    inSidebar = false,
  } = props;

  const isOnBreakpointInfoInline = useMatchMediaQuery('((min-width: 1180px) and (max-width: 1399px)) or (min-width: 1580px)');

  const LinkWrapper = useMemo(
    () => (props: PropsWithChildren) =>
      passengerClickable ? (
        <Link
          href={passenger?.id ? `/passengers/${passenger.id}` : ''}
          target={'_blank'}
          onClick={(e) => e.stopPropagation()}
          $displayContents={true}
        >
          {props.children}
        </Link>
      ) : (
        <>{props.children}</>
      ),
    [passengerClickable, passenger],
  );

  const contextMenu = useMemo(() => {
    const items = [
      onShowClick && {
        content: (
          <>
            <Passengers /> Profil Anzeigen
          </>
        ),
        onClick: onShowClick,
      },
      onAbsentClick && {
        content: (
          <>
            <Close /> {new Date(selectedDayOfWeek).toLocaleString('de-DE', { weekday: 'long' })}s abwesend setzen
          </>
        ),
        onClick: onAbsentClick,
      },
      onPresentClick && {
        content: (
          <>
            <Play /> {new Date(selectedDayOfWeek).toLocaleString('de-DE', { weekday: 'long' })}s anwesend setzen
          </>
        ),
        onClick: onPresentClick,
      },
      onEditClick && {
        content: (
          <>
            <Edit /> Bearbeiten
          </>
        ),
        onClick: onEditClick,
      },
      onRemoveClick && {
        content: (
          <>
            <Trash /> Entfernen
          </>
        ),
        onClick: onRemoveClick,
      },
    ].filter(Boolean) as [];

    if (!items.length) return null;
    return <ContextOptions items={items} />;
  }, [onShowClick, onEditClick, onRemoveClick, onAbsentClick, onPresentClick, selectedDayOfWeek]);

  const tourPassengerContent = useMemo(
    () => (
      <>
        <TourPassengerItemRow>
          {position !== undefined && <b>{position}</b>}
          <TourPassengerNameRow
            passenger={passenger}
            showPickupTimes={showPickupTimes}
            onPickupDateChange={onPickupDateChange}
            selectedDayOfWeek={selectedDayOfWeek}
          >
            {!showNameSingleLine && (
              <TourPassengerNameSM>
                {passenger.hasWheelchair ? <Wheelchair /> : <Walker />}
                {formatName(passenger)}
              </TourPassengerNameSM>
            )}

            {/* options box */}
            <TourPassengerOptionsBox inSidebar={inSidebar}>
              {passenger.comment && (
                <>
                  {showInfoInline && isOnBreakpointInfoInline ? (
                    <TourPassengerCommentWrapper>{passenger.comment}</TourPassengerCommentWrapper>
                  ) : (
                    <HoverTooltip align={'right'} renderTooltipContent={() => <>{passenger.comment}</>}>
                      <TourPassengerInfoIcon />
                    </HoverTooltip>
                  )}
                </>
              )}
              {contextMenu}
            </TourPassengerOptionsBox>
          </TourPassengerNameRow>
        </TourPassengerItemRow>
        {!showNameSingleLine && (
          <TourPassengerItemRowMobileXS>
            <TourPassengerName>
              {passenger.hasWheelchair ? <Wheelchair /> : <Walker />}
              {formatName(passenger)}
            </TourPassengerName>
          </TourPassengerItemRowMobileXS>
        )}
        {showNameSingleLine && (
          <TourPassengerItemRow>
            <TourPassengerName>
              {passenger.hasWheelchair ? <Wheelchair /> : <Walker />}
              {formatName(passenger)}
            </TourPassengerName>
          </TourPassengerItemRow>
        )}
        <TourPassengerItemRow>
          {markAddressBold ? <b>{formatAddress((passenger.addresses || [])[0])}</b> : <>{formatAddress((passenger.addresses || [])[0])}</>}
        </TourPassengerItemRow>
      </>
    ),
    [
      position,
      passenger,
      contextMenu,
      isOnBreakpointInfoInline,
      showNameSingleLine,
      showInfoInline,
      showPickupTimes,
      onPickupDateChange,
      selectedDayOfWeek,
      markAddressBold,
      inSidebar,
    ],
  );

  return (
    <DragItemContainer isStatic={isStatic} style={style}>
      <LinkWrapper>
        <TourDragItemContent $pointerEvents={passengerClickable}>{tourPassengerContent}</TourDragItemContent>
      </LinkWrapper>
    </DragItemContainer>
  );
});

/**
 * TourplanPassengerView with drag drop functionality
 */
export const TourplanAvailablePassengerView = memo((props: { tour?: Partial<ITour>; editable?: boolean } & ITourplanPassengerViewProps) => {
  const { tour, editable = true, passenger, ...passengerViewProps } = props;

  const dragDropItemProps = {
    ...useDragDropItem<ITour, IPassenger>(tour || 'revert', passenger),
    ...useDragDropPositionItem<ITour, IPassenger>(tour || 'revert', passenger),
  };

  const contentView = useMemo(
    () => <TourplanPassengerView passenger={passenger} {...passengerViewProps} isStatic={!editable} />,
    [passenger, passengerViewProps, editable],
  );

  if (editable) {
    return <TourDragItem {...(dragDropItemProps as any)}>{contentView}</TourDragItem>;
  }

  return <>{contentView}</>;
});
