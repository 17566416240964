// General
import { FC } from 'react';
import styled from 'styled-components';

// Icons
import { ChevronDown } from 'components/icons/ChevronDown';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const PrevButton = styled.button`
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.25rem;

  transform: rotate(90deg);
`;

const NextButton = styled(PrevButton)`
  transform: rotate(-90deg);
`;

const Year = styled.p`
  font-size: 1.125rem;
  font-weight: 700;
`;

interface YearSelectProps {
  year: number;
  setYear: (value: number) => void;
}

const YearSelect: FC<YearSelectProps> = (props) => {
  const { year, setYear } = props;

  return (
    <Wrapper>
      <PrevButton onClick={() => setYear(year - 1)}>
        <ChevronDown />
      </PrevButton>
      <Year>{year}</Year>
      <NextButton onClick={() => setYear(year + 1)}>
        <ChevronDown />
      </NextButton>
    </Wrapper>
  );
};

export default YearSelect;
