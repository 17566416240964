import { FC, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Dialog } from '../Dialog';
import { Form } from 'components/common/inputs/Form';
import { ButtonPrimary, ButtonSecondary, WarningButton } from 'components/common/inputs/Button';
import { LoadingSpinner } from 'components/common/loaders/LoadingSpinner';
import { Dropdown } from 'components/common/inputs/Dropdown';
import { DateInput } from 'components/common/inputs/DateInput';
import { IEmployeeAbsence } from 'api/models/EmployeeAbsence';
import { useEmployeesAPI } from 'api/controllers/EmployeesAPI';
import { useForm } from 'react-hook-form';
import { ChevronDown } from 'components/icons/ChevronDown';
import { Body1Bold, Body4 } from 'styles/FontStyles';
import { BREAKPOINT_LG, BREAKPOINT_MD, BREAKPOINT_SM } from 'styles/Breakpoints';
import { getCurrentDayDate } from 'utils/dateUtils';
import { Colors } from 'styles/Colors';

const DropDownWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  text-align: start;

  ${BREAKPOINT_LG} {
    grid-template-columns: repeat(2, 1fr);

    & > *:first-child {
      grid-column: span 2;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin-block-start: 2rem;

  ${BREAKPOINT_SM} {
    justify-content: space-between;
  }
`;

const BackButton = styled.button`
  ${Body4};
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  grid-area: 1 / 1;

  svg {
    font-size: 1.5rem;
    stroke-width: 2px;
    transform: rotate(90deg);
  }

  @media (hover: hover) {
    :hover {
      cursor: pointer;
    }
  }
`;

const DeleteAbsenceDialogHeadline = styled.div`
  display: grid;
  grid-template-columns: 4.5rem 1fr 4.5rem;

  h4 {
    ${Body1Bold};
    grid-area: 2 / 1 / 2 / -1;

    ${BREAKPOINT_MD} {
      grid-area: 1 / 1 / 1 / -1;
    }
  }
`;

const DeleteScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-block: auto;
`;

const FirstSlideIn = keyframes`
  from {
    transform: translateX(-100%);
  }
`;

const FirstSlideOut = keyframes`
  to {
    transform: translateX(-100%);
  }
`;

const SecondSlideIn = keyframes`
  from {
    transform: translateX(100%);
  }
`;

const SecondSlideOut = keyframes`
  to {
    transform: translateX(100%);
  }
`;

const DialogContentWrapper = styled.div<{ progressStep: number | null }>`
  display: grid;
  overflow: hidden;
  overflow-y: auto;

  & > * {
    grid-area: 1 / -1;

    margin-inline: -2rem;
    padding-inline: 2rem;

    transition: visibility 150ms ease-out;

    &:first-child {
      animation: ${({ progressStep }) => (progressStep === 2 ? FirstSlideOut : progressStep === 1 ? FirstSlideIn : 'none')} 150ms ease-out;
      visibility: ${({ progressStep }) => (progressStep === null || progressStep === 1 ? 'visible' : 'hidden')};
    }

    &:last-child {
      animation: ${({ progressStep }) => (progressStep === 2 ? SecondSlideIn : SecondSlideOut)} 150ms ease-out;
      visibility: ${({ progressStep }) => (progressStep === 2 ? 'visible' : 'hidden')};
    }
  }
`;

const Headline = styled.h4`
  ${Body1Bold};
`;

const ErrorText = styled.p`
  ${Body4};
  text-align: center;
  color: ${Colors.signalRed800};
  margin-block-start: 1rem;
`;

interface FormProps {
  type: string;
  startDate: string;
  endDate?: string;
}

interface EmployeeAbsenceDialogProps {
  id: string;
  item?: IEmployeeAbsence;
  onSuccess?: () => void;
  onClosed: () => void;
}

export const EmployeeAbsenceDialog: FC<EmployeeAbsenceDialogProps> = (props) => {
  const { id, item, onSuccess, onClosed } = props;

  const employeesAPI = useEmployeesAPI();
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [progressStep, setProgressStep] = useState<number | null>(null);
  const [errorText, setErrorText] = useState<string>('');

  const form = useForm<FormProps>({
    defaultValues: {
      type: item?.type,
      startDate: (item?.startDate ? new Date(item.startDate) : new Date()).toISOString(),
      endDate: item?.endDate ? new Date(item.endDate).toISOString() : undefined,
    },
  });

  const currentStartDate = form.watch('startDate');
  const currentEndDate = form.watch('endDate');

  useEffect(() => {
    if (currentStartDate && currentEndDate && getCurrentDayDate(currentStartDate) > getCurrentDayDate(currentEndDate)) {
      form.setValue('endDate', undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStartDate]);

  useEffect(() => {
    setOpen(true);
  }, []);

  const submitHandler = async (values: FormProps) => {
    setLoading(true);
    try {
      if (item?.id) {
        await employeesAPI.patchEmployeeAbsence(id, values, item.id);
      } else {
        await employeesAPI.postEmployeeAbsence(id, values);
      }
      onSuccess?.();
      setOpen(false);
    } catch (error) {
      console.log('create/update absence error:', error);
      setErrorText('Fehler beim Speichern der Abwesenheitsperiode');
    } finally {
      setLoading(false);
    }
  };

  const deleteHandler = async () => {
    if (item?.id) {
      try {
        await employeesAPI.deleteEmployeeAbsence(id, item.id);
        onSuccess?.();
        setOpen(false);
      } catch (error) {
        console.log('delete absence error:', employeesAPI);
        setErrorText('Fehler beim Löschen der Abwesenheitsperiode');
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      onClosed={onClosed}
      headline={
        <DialogContentWrapper progressStep={progressStep}>
          <Headline>Abwesenheit eintragen</Headline>

          <DeleteAbsenceDialogHeadline onClick={() => setProgressStep(1)}>
            <BackButton>
              <ChevronDown />
              Zurück
            </BackButton>
            <h4>Abwesenheitsperiode löschen?</h4>
          </DeleteAbsenceDialogHeadline>
        </DialogContentWrapper>
      }
      text={
        <DialogContentWrapper progressStep={progressStep}>
          <Form form={form} onSubmit={(values: FormProps) => submitHandler(values)}>
            <DropDownWrapper>
              <Dropdown
                id={'type'}
                label={'Art der Abwesenheit*'}
                placeholder={'Bitte auswählen'}
                defaultValue={item?.type}
                items={[
                  { label: 'bezahlter Urlaub', value: 'vacation' },
                  { label: 'Krankheit', value: 'disease' },
                  { label: 'Inaktivität', value: 'inactive' },
                ]}
              />
              <DateInput
                label={'Von*'}
                name={'startDate'}
                endDate={(currentEndDate && getCurrentDayDate(currentEndDate)) || undefined}
                selectType={'start'}
              />
              <DateInput
                label={'Bis*'}
                name={'endDate'}
                minDate={(currentStartDate && getCurrentDayDate(currentStartDate)) || undefined}
                startDate={(currentStartDate && getCurrentDayDate(currentStartDate)) || undefined}
                selectType={'end'}
              />
            </DropDownWrapper>
            {errorText.length > 0 && (
              <DropDownWrapper>
                <ErrorText>{errorText}</ErrorText>
              </DropDownWrapper>
            )}

            <ButtonWrapper>
              {item?.id ? (
                <WarningButton type={'button'} disabled={loading} onClick={() => setProgressStep(2)}>
                  {loading ? <LoadingSpinner /> : 'Löschen'}
                </WarningButton>
              ) : (
                <ButtonSecondary type={'button'} onClick={() => setOpen(false)}>
                  Abbrechen
                </ButtonSecondary>
              )}
              <ButtonPrimary type={'submit'} disabled={loading}>
                {loading ? <LoadingSpinner /> : item?.id ? 'Aktualisieren' : 'Speichern'}
              </ButtonPrimary>
            </ButtonWrapper>
          </Form>

          <DeleteScreenWrapper>
            <TextWrapper>
              <p>
                Mit Klick auf <b>Wirklich löschen</b> wird diese Abwesenheitsperiode unwiederbringlich aus dem System gelöscht.
              </p>
              <b>Möchten Sie diese Abwesenheitsperiode wirklich löschen?</b>
            </TextWrapper>
            <ButtonWrapper>
              <ButtonSecondary onClick={() => setProgressStep(1)}>Abbrechen</ButtonSecondary>
              <WarningButton onClick={deleteHandler}>Wirklich löschen</WarningButton>
            </ButtonWrapper>
          </DeleteScreenWrapper>
        </DialogContentWrapper>
      }
      buttons={null}
    />
  );
};
