import styled, { css } from 'styled-components';
import { Body4 } from 'styles/FontStyles';
import { Colors } from 'styles/Colors';
import { Avatar } from 'components/common/atoms/Avatar';
import { formatName } from 'utils/nameUtils';
import HoverTooltip from 'components/common/elements/HoverTooltip';

import { IEmployee } from 'api/models/Employee';
import { IConflict } from 'api/models/Conflict';
import { Employee } from 'components/icons/Employee';
import { Alert } from 'components/icons/Alert';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const DriverName = styled.div<{ cancelled?: boolean; empty?: boolean; error?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  ${Body4};
  text-align: start;

  ${({ cancelled = false }) =>
    cancelled &&
    css`
      text-decoration: line-through;
      text-decoration-color: ${Colors.signalRed900};
    `};

  ${({ empty = false }) =>
    empty &&
    css`
      color: ${Colors.grey600};
    `};

  ${({ error = false }) =>
    error &&
    css`
      color: ${Colors.signalRed900};
    `};
`;

const EmployeeIcon = styled(Employee)`
  width: 1.5rem;
  height: 1.5rem;
  margin: auto;
`;

const AlertIcon = styled(Alert)`
  width: 1.5rem;
  height: 1.5rem;
  color: ${Colors.signalRed900};
`;

interface ISelectedDriverViewProps {
  title?: string;
  driver: IEmployee | null | undefined;
  conflict?: IConflict | null;
}

const CONFLICT_REASONS: { [key: string]: { message: string; marginInlineStart: string; align?: 'top' | 'right' } } = {
  driver_not_set: { message: 'Kein Fahrer geplant', marginInlineStart: '.25rem', align: 'top' },
  driver_unavailable: { message: 'Fahrer fällt aus', marginInlineStart: '.25rem', align: 'top' },
  companion_unavailable: { message: 'Begleitung fällt aus', marginInlineStart: '-.25rem', align: 'top' },
  driver_duplicated: { message: 'Fahrer mehrfach geplant', marginInlineStart: '.25rem', align: 'top' },
  companion_duplicated: { message: 'Begleitung mehrfach geplant', marginInlineStart: '-.25rem', align: 'right' },
};

export default function SelectedDriverView(props: ISelectedDriverViewProps) {
  const { title = 'Fahrer', driver, conflict } = props;
  const reason = conflict?.reason as keyof typeof CONFLICT_REASONS;
  const reasonConfig = CONFLICT_REASONS[reason];

  return (
    <Wrapper>
      <Avatar
        src={driver?.image?.url}
        firstName={driver?.firstName}
        lastName={driver?.lastName}
        size={'mini'}
        empty={!driver}
        error={!driver}
      >
        {!driver && <EmployeeIcon />}
      </Avatar>
      <DriverName
        empty={!driver}
        error={!driver}
        cancelled={['driver_unavailable', 'companion_unavailable'].includes(conflict?.reason || '')}
      >
        <b>{title}</b>
        <span>{!!driver ? formatName(driver) : 'nicht ausgewählt'}</span>
      </DriverName>
      {reasonConfig && (
        <HoverTooltip align={reasonConfig?.align} renderTooltipContent={() => reasonConfig?.message}>
          <AlertIcon style={{ marginInlineStart: reasonConfig?.marginInlineStart }} />
        </HoverTooltip>
      )}
    </Wrapper>
  );
}
