import { FC, memo, PropsWithChildren, ReactNode } from 'react';
import { IPassenger } from 'api/models/Passenger';
import { useDragDropContainer } from '../DragDropContext';
import { ITour } from 'api/models/Tour';
import styled, { css } from 'styled-components';
import { Colors } from 'styles/Colors';
import { Body2Bold, Body4 } from 'styles/FontStyles';
import { Clickable } from 'components/common/atoms/Clickable';
import { SearchInput } from 'components/common/inputs/SearchInput';

const Overlay = styled.div<{ highlight?: boolean }>`
  position: fixed;
  inset-block-start: 0;
  inset-inline-end: 0;

  z-index: 5;

  inline-size: 20rem;
  block-size: 100%;

  background-color: ${Colors.white50};
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);

  padding-inline: 1rem;
  padding-block: 2rem;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.5rem;

  ${Body4};

  h4 {
    ${Body2Bold};
  }

  border: 1px solid transparent;
  transition: border-color 150ms ease-out;

  ${({ highlight = false }) =>
    highlight &&
    css`
      border-color: ${Colors.secondary};
    `};
`;

const OverlayScrollableContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  overflow: auto;

  margin-inline: -1rem;
  margin-block: -2rem;
  padding-inline: 1rem;

  margin-block-start: 1.75rem;
`;

const CountLabel = styled.p`
  color: ${Colors.grey600};
  margin-block-end: 0.5rem;
`;

export const ListTitle = styled.div`
  ${Body4};

  position: sticky;
  top: 0;
  z-index: 1;
  line-height: 2.25rem;
  background-color: ${Colors.white50};
  margin-inline: -1rem;
  padding-inline: 1rem;
  margin-block: -0.5rem;
  padding-block: 0.5rem;
  &::before {
    content: '';
    position: absolute;
    inset-block-end: 0;
    inset-inline-end: 0;
    block-size: 1px;
    inline-size: 100%;
    background-color: ${Colors.grey600};
  }
`;

export const ClickableListTitle = styled(Clickable)`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  min-block-size: 1.5rem;
`;

export const ListTitleOverlay = styled.div`
  position: absolute;
  inset: 0;
  inline-size: 100%;
  block-size: 100%;

  background: ${Colors.white50};
  color: ${Colors.secondary};
  text-decoration: underline;

  display: flex;
  flex-direction: row;
  align-items: center;

  b {
    padding-inline: 0.5rem;
  }

  opacity: 0;
  transition: opacity 150ms ease-out;

  @media (hover: hover) {
    &:hover {
      opacity: 1;
    }
  }
`;

export const AvailablePassengersList = styled.div<{ inactive?: boolean }>`
  position: relative;

  margin-inline: -1rem;
  padding-inline: 1rem;
  padding-block: 1.5rem 3rem;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.25rem;

  ${({ inactive = false }) =>
    inactive &&
    css`
      & > * {
        opacity: 0.5;
      }
    `};
`;

export const TaskCompletedHint = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;

  ${Body4};
  color: ${Colors.signalGreen900};

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.5rem;
  margin-block: 1.5rem;

  button {
    max-width: none;
  }
`;

const RevertDropContainer = memo((props: PropsWithChildren) => {
  return <Overlay {...useDragDropContainer<ITour, IPassenger>('revert')}>{props.children}</Overlay>;
});

export const TourplanOverlay: FC<
  PropsWithChildren<{
    title: string | ReactNode;
    count?: number;
    searchString?: string;
    handleSearchChange?: (searchString: string) => void;
  }>
> = memo((props) => {
  const { title = 'Fahrgäste', count, children, searchString = '', handleSearchChange } = props;

  return (
    <RevertDropContainer>
      <h4>{title}</h4>
      {count !== undefined && (
        <CountLabel>
          {count} {count === 1 ? 'Ergebnis' : 'Ergebnisse'}
        </CountLabel>
      )}
      {handleSearchChange && <SearchInput placeholder="Fahrgast suchen" value={searchString} setValue={handleSearchChange} />}
      <OverlayScrollableContent>{children}</OverlayScrollableContent>
    </RevertDropContainer>
  );
});
