import { FC, HTMLAttributes, PropsWithChildren, useMemo } from 'react';
import styled from 'styled-components';
import { Success } from 'components/icons/Success';
import { Colors } from 'styles/Colors';
import { Close } from 'components/icons/Close';
import { Info } from 'components/icons/Info';
import { Alert } from 'components/icons/Alert';
import { Clickable } from '../atoms/Clickable';
import { Headline4 } from 'styles/FontStyles';

const Wrapper = styled.div<{ type: TToastTypes }>`
  ${({ type }) => {
    switch (type) {
      case 'success':
        return `
        --border-color: ${Colors.signalGreen900};
        --background-color: ${Colors.signalGreen800};
       `;
      case 'alert':
        return `
        --border-color: ${Colors.signalYellow900};
        --background-color: ${Colors.signalYellow800};
        `;
      case 'error':
        return `
        --border-color: ${Colors.signalRed900};
        --background-color: ${Colors.signalRed300};
        `;
      default:
      case 'info':
        return `
        --border-color: none;
        --background-color: ${Colors.grey400};
        `;
    }
  }}

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1rem;

  padding: 1rem;
  border-radius: 8px;
  border: 1px solid var(--border-color);
  background-color: var(--background-color);

  svg {
    width: 1.5rem;
    height: 1.5rem;
    color: var(--border-color);
    border-radius: 50%;
  }
`;

const InfoIcon = styled(Info)`
  && {
    color: ${Colors.grey800};
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;

  ${Headline4};
`;

interface ToastProps {
  type: TToastTypes;
  onClick?: () => void;
}

type TToastTypes = 'info' | 'success' | 'alert' | 'error';

type TToastProps = PropsWithChildren<HTMLAttributes<HTMLDivElement> & ToastProps>;

export const Toast: FC<TToastProps> = (props) => {
  const { children, onClick, ...toastProps } = props;

  const Icon = useMemo(() => {
    switch (props.type) {
      case 'success':
        return Success;
      case 'alert':
        return Alert;
      case 'error':
        return Alert;
      default:
      case 'info':
        return InfoIcon;
    }
  }, [props]);

  return (
    <Wrapper {...toastProps}>
      <Icon />
      <TextWrapper>{children}</TextWrapper>
      {onClick && (
        <Clickable onClick={onClick}>
          <Close />
        </Clickable>
      )}
    </Wrapper>
  );
};
