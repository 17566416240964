import { useEffect, useState } from 'react';
import { Breadcrumb } from 'components/common/elements/Breadcrumb';
import { CustomerForm } from 'components/content/customers/CustomerForm';
import { ContentPageLayout } from 'components/common/atoms/Layout';
import { useCustomerCategoryAPI } from 'api/controllers/CustomerCategoryAPI';
import { ICustomerCategory } from 'api/models/CustomerCategory';
import { LoadingSpinner } from 'components/common/loaders/LoadingSpinner';
import { ICustomerOrganization } from 'api/models/CustomerOrganization';
import { useCustomerOrganizationAPI } from 'api/controllers/CustomerOrganizationAPI';

export const CustomersAdd = () => {
  const customerCategoryAPI = useCustomerCategoryAPI();
  const customerOrganizationAPI = useCustomerOrganizationAPI();
  const [loading, setLoading] = useState(false);

  const [customerCategories, setCustomerCategories] = useState<ICustomerCategory[]>([]);
  const [customerOrganizations, setCustomerOrganizations] = useState<ICustomerOrganization[]>([]);

  useEffect(() => {
    customerCategoryAPI
      .getCustomerCategories({})
      .then((res) => {
        setCustomerCategories(res.data.data);
      })
      .catch(() => {})
      .finally(() => setLoading(false));

    customerOrganizationAPI.getCustomerOrganizations().then((res) => {
      setCustomerOrganizations(res.data.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentPageLayout>
      <Breadcrumb
        items={[
          {
            title: 'Einrichtungen Übersicht',
            href: '/customers',
          },
        ]}
      />
      {loading && customerCategories.length === 0 ? (
        <LoadingSpinner />
      ) : (
        <CustomerForm customerCategories={customerCategories} customerOrganizations={customerOrganizations} />
      )}
    </ContentPageLayout>
  );
};
