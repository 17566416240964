import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IScheduledTour } from 'api/models/ScheduledTour';
import { BREAKPOINT_MD } from 'styles/Breakpoints';
import { Colors } from 'styles/Colors';
import { getCurrentDayDate } from 'utils/dateUtils';
import { ButtonPrimary, ButtonSecondary, ChooseButton } from 'components/common/inputs/Button';
import { Calendar } from 'components/common/inputs/Calendar';
import { TextInput } from 'components/common/inputs/TextInput';
import { LoadingSpinner } from 'components/common/loaders/LoadingSpinner';
import { Dialog } from 'components/common/elements/Dialog';

const ButtonRed = styled(ButtonPrimary)`
  background-color: ${Colors.signalRed900};
  color: ${Colors.textInverted};
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;

  text-align: start;
`;

const GridRow = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;

  ${BREAKPOINT_MD} {
    grid-template-columns: 1fr 1fr;
  }
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.25rem;

  color: ${Colors.grey700};
`;

const CommentInput = styled(TextInput)`
  label {
    margin: 0;
  }

  textarea {
    max-width: none;
  }
`;

interface ISubmitPayload {
  startDate: Date | null;
  endDate: Date | null;
  includeTourOnInvoice: boolean;
  comment: string | null;
}
interface ICancelTourDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  scheduledTour: IScheduledTour;
  timestamp?: string;
  onSubmit: (config: ISubmitPayload, setLoading: (loading: boolean) => void) => void;
}
export default function CancelTourDialog(props: ICancelTourDialogProps) {
  const { open, setOpen, scheduledTour, timestamp, onSubmit } = props;

  const { name, direction } = scheduledTour;

  const [loading, setLoading] = useState(false);

  const [startDate, setStartDate] = useState<Date | null>(getCurrentDayDate(timestamp));
  const [endDate, setEndDate] = useState<Date | null>(getCurrentDayDate(timestamp));

  const [includeTourOnInvoice, setIncludeTourOnInvoice] = useState(undefined as boolean | undefined);
  const [comment, setComment] = useState('');

  useEffect(() => {
    if (open) {
      setStartDate(getCurrentDayDate(timestamp));
      setEndDate(getCurrentDayDate(timestamp));
    } else {
      setIncludeTourOnInvoice(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      headline={`Fahrt ${name} (${direction === 'return' ? 'Rück' : 'Hin'}) absagen`}
      text={
        <Body>
          <p>
            Soll die Fahrt{' '}
            <b>
              {name} ({direction === 'return' ? 'Rück' : 'Hin'})
            </b>{' '}
            im folgenden Zeitraum ersatzlos abgesagt werden?
          </p>
          <GridRow>
            <Label>
              <b>Von</b>
              <Calendar title={'TT/MM/JJJJ'} value={startDate} setValue={setStartDate as any} maxDate={endDate} dropdownStyling />
            </Label>
            <Label>
              <b>Bis</b>
              <Calendar title={'TT/MM/JJJJ'} value={endDate} setValue={setEndDate as any} minDate={startDate} dropdownStyling />
            </Label>
          </GridRow>
          <CommentInput
            id={'cancel-tour-dialog:comment'}
            type={'textarea'}
            independent
            value={comment}
            onValueChange={setComment}
            label={'Kommentar für den/die Fahrer'}
            placeholder={'Kommentar hinzufügen...'}
          />
          <b>Soll diese Fahrt trotzdem abgerechnet werden?</b>
          <GridRow>
            <ChooseButton onClick={() => setIncludeTourOnInvoice(true)} isActive={includeTourOnInvoice}>
              Ja
            </ChooseButton>
            <ChooseButton onClick={() => setIncludeTourOnInvoice(false)} isActive={includeTourOnInvoice === false}>
              Nein
            </ChooseButton>
          </GridRow>
        </Body>
      }
      buttons={
        <>
          <ButtonSecondary onClick={() => setOpen(false)}>Abbrechen</ButtonSecondary>
          <ButtonRed
            onClick={() =>
              includeTourOnInvoice !== undefined && onSubmit({ startDate, endDate, includeTourOnInvoice, comment }, setLoading)
            }
            disabled={includeTourOnInvoice === undefined}
          >
            {loading ? <LoadingSpinner /> : 'Fahrt absagen'}
          </ButtonRed>
        </>
      }
    />
  );
}
