import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { Colors } from 'styles/Colors';
import { Clickable } from 'components/common/atoms/Clickable';

const Wrapper = styled(Clickable)<{ active?: boolean; highlighted?: boolean; small?: boolean }>`
  display: flex;
  position: relative;
  flex-direction: row;
  padding: ${({ small = false }) => (small ? '.5rem 1.5rem' : '.5rem 2.25rem')};
  gap: 1rem;
  flex: 1;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  color: ${({ highlighted, active }) =>
    highlighted ? (active ? Colors.grey900 : Colors.grey600) : active ? Colors.grey300 : Colors.grey600};
  transition: color 300ms ease-out;
  z-index: 0;
`;

const Highlight = styled.div<{ position: number; highlighted?: boolean }>`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${({ highlighted }) => (highlighted ? Colors.primary : Colors.grey900)};

  border-radius: ${({ highlighted }) => (highlighted ? '8px' : '24px')};
  transform: translateX(calc(${({ position = 0 }) => position} * 100%));
  transition: transform 300ms ease-out;

  z-index: -1;
`;

interface ITabSwitchItemProps {
  content?: ReactNode;
  onClick: () => void;
  highlighted?: boolean;
  small?: boolean;
  active: boolean;
  index?: number;
}

export const TabSwitchItem: FC<ITabSwitchItemProps> = ({ active, content, onClick, highlighted, small, index }) => {
  return (
    <Wrapper active={active} highlighted={highlighted} small={small} onClick={onClick}>
      {content}
      {index === 0 && <Highlight position={active ? 0 : 1} highlighted={highlighted} />}
    </Wrapper>
  );
};
