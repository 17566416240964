import { FC } from 'react';
import styled, { css } from 'styled-components';
import { Colors } from 'styles/Colors';
import { Body4, Headline4 } from 'styles/FontStyles';
import { IInputProps } from 'components/common/inputs/TextInput';
import { useFieldError, useFieldProps } from 'components/common/inputs/Field';
import { FieldError } from 'react-hook-form';

const Wrapper = styled.label<{ disabled: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2.5rem;

  ${({ disabled = false }) =>
    !disabled &&
    css`
      cursor: pointer;
    `};
`;

const LabelsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.25rem;

  ${Body4};

  b {
    ${Headline4};
  }
`;

const Checkbox = styled.input.attrs({ type: 'checkbox' })<{ error: boolean }>`
  appearance: none;
  background-color: ${Colors.grey600};
  width: 4rem;
  height: 2rem;
  flex-shrink: 0;

  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  border: 1px solid transparent;
  border-radius: 34px;

  color: ${Colors.white50};

  padding: 0.25rem;

  transition: border-color 150ms ease-out, color 150ms ease-out, background-color 150ms ease-out;

  &:focus-visible {
    border-color: ${Colors.grey800};
  }

  &:disabled {
    background-color: ${Colors.grey300};
    color: ${Colors.grey700};
  }

  &::before {
    content: '';
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
    background: currentColor;
    border-radius: 50%;

    left: 0.25rem;
    margin-left: -1px; // compensate border width
    transition: left 150ms ease-out;
  }

  &:checked {
    background-color: ${Colors.primary};

    &::before {
      left: 2.25rem;
    }
  }

  ${({ error = false }) =>
    error &&
    css`
      border-color: ${Colors.signalRed900};
    `};
`;

export const Switch: FC<IInputProps<boolean>> = (props) => {
  const { id, name = id } = props;

  const fieldProps = useFieldProps(name, true);
  const error = useFieldError(name);

  return <SimpleSwitch {...props} inputProps={fieldProps} error={error} />;
};

interface ISimpleSwitchProps {
  id?: string;
  error?: FieldError | null;
  checked?: boolean;
  inputProps?: any;
}

export const SimpleSwitch: FC<Omit<IInputProps<boolean>, 'id'> & ISimpleSwitchProps> = (props) => {
  const {
    id,
    name = id,
    label,
    placeholder,
    disabled = false,
    error,
    className,
    style,
    value,
    checked = value,
    onValueChange,
    inputProps = {},
  } = props;

  return (
    <Wrapper className={className} style={style} disabled={disabled}>
      <Checkbox
        id={id}
        name={name}
        error={!!error}
        disabled={disabled}
        checked={checked}
        onChange={({ target }) => onValueChange?.(target.checked)}
        {...inputProps}
      />
      {(label || placeholder) && (
        <LabelsWrapper>
          {label && <b>{label}</b>}
          {placeholder}
        </LabelsWrapper>
      )}
    </Wrapper>
  );
};

export const SimpleSwitchSmall = styled(SimpleSwitch)`
  gap: 0.5rem;

  ${Body4};
  color: ${Colors.grey600};

  input {
    transform: scale(0.75);
    margin: -0.25rem -0.5rem;

    :disabled {
      background-color: ${Colors.grey500};
    }
  }
`;
