import styled, { css, keyframes } from 'styled-components';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { Body4, Body4Bold } from 'styles/FontStyles';
import { Colors } from 'styles/Colors';
import { IInputProps } from 'components/common/inputs/TextInput';
import { ChevronDown } from 'components/icons/ChevronDown';
import { Field, useFieldError, useFieldProps } from 'components/common/inputs/Field';
import { Clickable } from '../atoms/Clickable';
import { Card } from '../atoms/Card';
import { useFormContext } from 'react-hook-form';
import { LoadingSpinner } from '../loaders/LoadingSpinner';
import { SearchInput } from 'components/common/inputs/SearchInput';
import { BREAKPOINT_MD } from 'styles/Breakpoints';
import { Close } from 'components/icons/Close';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const Label = styled.label<{ disabled: boolean }>`
  ${Body4Bold};
  margin: 0;

  ${({ disabled = false }) =>
    disabled &&
    css`
      color: ${Colors.grey700};
    `};

  span {
    color: ${Colors.grey700};
  }
`;

const Select = styled(Clickable)<{ error: boolean; placeholderSelected: boolean }>`
  appearance: none;
  background: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  width: 100%;
  height: 2.75rem;

  padding: 0.75rem 3rem 0.75rem 1rem;

  border: 1px solid ${Colors.grey500};
  border-radius: 8px;

  ${Body4};
  color: ${({ placeholderSelected = false }) => (placeholderSelected ? Colors.grey700 : Colors.textDefault)};

  svg {
    inline-size: 1.5rem;
    block-size: 1.5rem;
  }

  span {
    ${Body4Bold};
  }

  transition: border-color 150ms ease-out, color 150ms ease-out;

  &:focus-visible {
    border-color: ${Colors.grey800};
  }

  &:disabled {
    background: ${Colors.grey300};
    color: ${Colors.grey700};
  }

  ${({ error = false }) =>
    error &&
    css`
      border-color: ${Colors.signalRed900};
    `};
`;

const HiddenField = styled(Field)`
  pointer-events: none;
  opacity: 0;
  position: absolute;
  visibility: hidden;
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

const ChevronDownIcon = styled(ChevronDown)`
  position: absolute;
  right: 1rem;
  width: 1rem;
  height: 1rem;
  object-fit: contain;
  pointer-events: none;
`;

const ErrorLabel = styled.p`
  ${Body4};
  color: ${Colors.signalRed900};
`;

const Appear = keyframes`
  from {
    opacity: 0;
    transform: scale(.98);
  }
`;

const DropdownPopup = styled(Card)`
  position: fixed;
  top: 0;
  left: 0;

  inline-size: 100%;
  block-size: 100%;

  ${BREAKPOINT_MD} {
    position: absolute;
    top: 100%;

    inline-size: max-content;
    block-size: auto;
    min-inline-size: 10rem;
    max-block-size: 20rem;
  }

  z-index: 10;

  padding: 1rem;
  padding-block: 0.75rem;
  gap: 0;

  overflow: auto;

  animation: ${Appear} 100ms ease-out;
`;

const DropdownItem = styled(Clickable)<{ active?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin-inline: -1rem;
  padding-inline: 1rem;
  padding-block: 0.75rem;

  ${Body4};

  transition: background-color 100ms ease-out;

  @media (hover: hover) {
    &:hover {
      background-color: ${Colors.grey400};
    }
  }

  &:focus-visible {
    background-color: ${Colors.grey400};
  }

  ${({ active }) =>
    active &&
    css`
      background-color: ${Colors.grey500};
    `};

  span {
    ${Body4Bold};
  }

  svg {
    inline-size: 1.5rem;
    block-size: 1.5rem;
  }
`;

const StyledSearchInput = styled(SearchInput)`
  width: auto;
  margin-block-end: 0.75rem;
`;

const PlaceholderNoItems = styled.span`
  ${Body4};
  text-align: center;
  padding: 1rem;
`;

const MobileCloseButton = styled(Clickable)`
  display: flex;
  align-self: flex-end;
  margin-block-end: 1rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  ${BREAKPOINT_MD} {
    display: none;
  }
`;

interface ISearchableDropdownProps {
  items: { value: string; label: string; view?: ReactNode }[] | null;
  searchPlaceholder?: string;
  onSearchInput: (query: string) => void;
  onSelectItem?: (item: string) => boolean;
}

export const SearchableDropdown: FC<IInputProps & ISearchableDropdownProps> = (props) => {
  const {
    id,
    name = id,
    label = '',
    placeholder = '',
    disabled = false,
    optional = false,
    optionalLabel = false,
    items,
    className,
    style,
    searchPlaceholder = 'Suchen...',
    onSearchInput,
    onSelectItem,
  } = props;

  const fieldProps = useFieldProps(name, optional);
  const error = useFieldError(name);

  const formContext = useFormContext();

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [fieldValue, setFieldValue] = useState(formContext.getValues(name));

  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    formContext.setValue(name, fieldValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValue]);

  useEffect(() => {
    onSearchInput?.(searchValue);
  }, [searchValue, onSearchInput]);

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    let listener = (e: MouseEvent) => {
      if (!(e.target === wrapperRef.current || wrapperRef.current?.contains(e.target as Node))) {
        setDropdownVisible(false);
      }
    };

    if (dropdownVisible) {
      setTimeout(() => {
        document.addEventListener('click', listener, { passive: true });
      }, 100);
    }

    return () => {
      document.removeEventListener('click', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownVisible]);

  return (
    <Wrapper className={className} style={style}>
      {label && (
        <Label disabled={disabled}>
          {label}
          {optionalLabel && <span> (optional)</span>}
        </Label>
      )}
      <SelectWrapper>
        <HiddenField {...fieldProps} id={id} name={name} optional={optional} disabled />
        <Select
          error={!!error}
          disabled={disabled}
          placeholderSelected={fieldValue === '' || (Array.isArray(fieldValue) && fieldValue.length === 0)}
          onClick={() => setDropdownVisible(!dropdownVisible)}
        >
          {!fieldValue
            ? placeholder
            : Array.isArray(fieldValue)
            ? `${fieldValue.length} ausgewählt`
            : items?.find((item) => item.value === fieldValue)?.view || items?.find((item) => item.value === fieldValue)?.label}
        </Select>
        <ChevronDownIcon />

        {dropdownVisible && (
          <DropdownPopup ref={wrapperRef}>
            <MobileCloseButton onClick={() => setDropdownVisible(false)}>
              <Close />
            </MobileCloseButton>
            <StyledSearchInput
              placeholder={searchPlaceholder}
              value={searchValue}
              setValue={setSearchValue}
              onReset={() => setSearchValue('')}
            />
            {items?.map(({ label, value, view }) => (
              <DropdownItem
                key={value}
                active={value === fieldValue || (Array.isArray(fieldValue) && fieldValue.includes(value))}
                onClick={() => {
                  if (!onSelectItem?.(value)) {
                    if (Array.isArray(fieldValue)) {
                      setFieldValue(
                        (
                          array: any[], // toggle value
                        ) => [...array.filter((val) => val !== value), ...(array.includes(value) ? [] : [value])],
                      );
                    } else {
                      setFieldValue(value);
                      setDropdownVisible(false);
                    }
                  }
                }}
              >
                {view || label}
              </DropdownItem>
            ))}
            {items === null && <LoadingSpinner />}
            {items !== null && items.length === 0 && <PlaceholderNoItems>Keine Einträge gefunden.</PlaceholderNoItems>}
          </DropdownPopup>
        )}
      </SelectWrapper>
      <ErrorLabel>{error?.message}</ErrorLabel>
    </Wrapper>
  );
};
