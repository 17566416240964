import styled, { css } from 'styled-components';
import { FC, ReactNode, useMemo } from 'react';
import { Clickable } from '../atoms/Clickable';
import { Body4 } from 'styles/FontStyles';
import { getCurrentDayDate, getDayOfWeekDate } from 'utils/dateUtils';
import { Colors } from 'styles/Colors';

const Wrapper = styled.div<{ alignWeeks: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.5rem 0.25rem;
  z-index: 0;
  flex-wrap: wrap;

  ${Body4};

  svg {
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
  }

  ${({ alignWeeks }) =>
    alignWeeks &&
    css`
      display: grid;
      grid-template-columns: repeat(7, max-content);
      gap: 0.75rem;
      margin-inline: auto;
    `};
`;

const ItemWrapper = styled.div<{ dayOfWeek: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  grid-column: ${({ dayOfWeek }) => ((dayOfWeek + 6) % 7) + 1};
`;

const Label = styled.p<{ active: boolean; inactive: boolean; highlighted: boolean; newWeek: boolean }>`
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;

  border: 2px solid ${({ highlighted }) => (highlighted ? Colors.secondary : 'transparent')};
  border-radius: 1px;

  background-color: ${({ active, inactive }) => (active ? Colors.secondary : inactive ? Colors.grey400 : Colors.grey500)};
  color: ${({ active, inactive }) => (active ? Colors.textInverted : inactive ? Colors.grey600 : Colors.textDefault)};
  font-weight: ${({ active }) => (active ? 700 : 400)};

  transition: background-color 150ms ease-out, color 150ms ease-out;

  ${({ newWeek }) =>
    newWeek &&
    css`
      border-top: 2px solid ${Colors.signalOrange};
    `};

  ${({ inactive }) =>
    !inactive &&
    css`
      @media (hover: hover) {
        &:hover {
          background-color: ${Colors.secondary};
          color: ${Colors.textInverted};
        }
      }
    `};
`;

const DayOfWeekLabel = styled(Label)`
  background-color: transparent;
`;

interface DayOfMonthInputProps {
  className?: string;
  value: string;
  setValue: (value: string) => void;
  renderMarker?: (value: string) => ReactNode;
  alignWeeks?: boolean;
}

export const DayOfMonthInput: FC<DayOfMonthInputProps> = (props) => {
  const { className, value, setValue, renderMarker, alignWeeks = false } = props;

  const days = useMemo(() => {
    const date = getCurrentDayDate(value);
    const selectedMonth = date.getMonth();

    const days = [];
    date.setDate(1);
    let current = 1;
    while (date.getMonth() === selectedMonth) {
      days.push(getCurrentDayDate(date));
      date.setDate(++current);
    }

    return days;
  }, [value]);

  return (
    <Wrapper className={className} alignWeeks={alignWeeks}>
      {alignWeeks &&
        [...Array(7)].map((_, index) => {
          const day = getDayOfWeekDate((index + 1) % 7);
          return (
            <ItemWrapper key={index} dayOfWeek={day.getDay()}>
              <DayOfWeekLabel active={false} highlighted={false} inactive={false} newWeek={day.getDay() === 1}>
                {day.toLocaleDateString(undefined, { weekday: 'short' })}
              </DayOfWeekLabel>
            </ItemWrapper>
          );
        })}
      {days.map((day: Date, index) => (
        <ItemWrapper key={index} dayOfWeek={day.getDay()}>
          <Clickable disabled={false} onClick={() => setValue(day.toISOString())}>
            <Label
              active={value === day.toISOString()}
              inactive={[0, 6].includes(day.getDay())}
              highlighted={day.toISOString() === getCurrentDayDate().toISOString()}
              title={day.toLocaleDateString(undefined, { dateStyle: 'full' })}
              newWeek={day.getDay() === 1}
            >
              {day.toLocaleDateString(undefined, { day: 'numeric' })}
            </Label>
          </Clickable>
          {renderMarker?.(day.toISOString())}
        </ItemWrapper>
      ))}
    </Wrapper>
  );
};
