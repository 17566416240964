import { FC } from 'react';
import ReactivateTourDialog from './ReactivateTourDialog';
import { IScheduledTour, IScheduledTourException } from 'api/models/ScheduledTour';
import { useNavigate } from 'react-router-dom';
import { IReactivateTourPayload, useScheduledTourException } from 'api/controllers/scheduled-tour-exceptions/ScheduledTourExceptionsAPI';

interface IReactivateTourDialogOverlayProps {
  schedule: IScheduledTour;
  exception: IScheduledTourException;
  timestamp: string;
  showChangeDurationDialog: boolean;
  onUpdate?: () => void;
  setShowChangeDurationDialog: (show: boolean) => void;
}

const ReactivateTourDialogOverlay: FC<IReactivateTourDialogOverlayProps> = (props) => {
  const { timestamp, exception, schedule, showChangeDurationDialog, setShowChangeDurationDialog } = props;
  const navigate = useNavigate();

  const scheduledTourExceptionsAPI = useScheduledTourException();

  const onDelete = (exceptionId: string) => {
    if (window.confirm('Möchten Sie die Stornierung wirklich zurücksetzen?')) {
      scheduledTourExceptionsAPI.deleteScheduledTourException({ id: parseInt(exceptionId) }).then(() => {
        window.location.reload();
      });
    }
  };

  const onSubmit = (config: IReactivateTourPayload, setLoading: (loading: boolean) => void) => {
    setLoading(true);

    scheduledTourExceptionsAPI
      .reactivateScheduledTourException({
        id: exception.id,
        start_date: config.start_date,
        end_date: config.end_date,
      })
      .then((res) => {
        setShowChangeDurationDialog(false);
        window.location.reload();
      })
      .catch((err) => {
        console.error('could not adjust tour', err);
        alert('Fehler beim Ändern der Tour');
      })
      .finally(() => setLoading(false));
  };

  return (
    <div>
      <ReactivateTourDialog
        open={showChangeDurationDialog}
        setOpen={setShowChangeDurationDialog}
        timestamp={timestamp}
        onSubmit={onSubmit}
        onDelete={onDelete}
        scheduledTour={schedule}
        exception={exception}
      />
    </div>
  );
};

export default ReactivateTourDialogOverlay;
