// General
import { FC, memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useFieldArray, useForm } from 'react-hook-form';

// Styles
import { Body2Bold, Body3, Headline2, Headline3 } from 'styles/FontStyles';
import { BREAKPOINT_LG, BREAKPOINT_MD } from 'styles/Breakpoints';

// Components
import { ButtonPrimary } from 'components/common/inputs/Button';
import { TextInput } from 'components/common/inputs/TextInput';
import { LoadingSpinner } from 'components/common/loaders/LoadingSpinner';
import { Clickable } from 'components/common/atoms/Clickable';
import { Form } from 'components/common/inputs/Form';
import { Dropdown } from 'components/common/inputs/Dropdown';
import { Switch } from 'components/common/inputs/Switch';
import { SearchableDropdown } from 'components/common/inputs/SearchableDropdown';

// Icons
import { Trash } from 'components/icons/Trash';
import { Plus } from 'components/icons/Plus';
import { Facilities } from 'components/icons/Facilities';

// Types
import { IPassenger } from 'api/models/Passenger';
import { ICustomer } from 'api/models/Customer';

// Controllers
import { usePassengersAPI } from 'api/controllers/PassengersAPI';
import { useSearchAPI } from 'api/controllers/SearchAPI';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  ${Headline2};
  margin-bottom: 2.5rem;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2rem;
  margin-bottom: 4rem;
`;

const SectionTitle = styled.h2`
  ${Headline3};
`;

const ContactTitle = styled.h4`
  ${Body2Bold};
  grid-column: 1 / -1;

  ${BREAKPOINT_LG} {
    grid-column: 1;
    grid-area: 1 / 1 / 5 / 1;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2rem;

  ${BREAKPOINT_MD} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

const GridRow = styled(Row)`
  ${BREAKPOINT_MD} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
  }
`;

const GridRowInline = styled(Row)`
  ${BREAKPOINT_MD} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
  }

  ${BREAKPOINT_LG} {
    grid-template-columns: auto 1fr 1fr;
  }
`;

const StyledDropdown = styled(Dropdown)`
  width: 16rem;
`;

const StyledSearchableDropdown = styled(SearchableDropdown)`
  min-inline-size: 16rem;
`;

const AddButton = styled(Clickable)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;

  ${Body3};

  align-self: flex-start;
  padding-bottom: 1rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    object-fit: contain;
  }
`;

const RemoveButton = styled(AddButton)`
  align-self: flex-end;
  width: min-content;
`;

interface PassengerFormProps {
  passenger?: IPassenger;
  defaultCustomerId?: string;
  onSuccess?: (id: string) => void;
}

export const PassengerForm: FC<PassengerFormProps> = memo((props) => {
  const { passenger = null, onSuccess, defaultCustomerId } = props;

  const searchAPI = useSearchAPI();
  const [customers, setCustomers] = useState<Partial<ICustomer>[]>([]);
  const [customersSearchQuery, setCustomersSearchQuery] = useState('');

  useEffect(() => {
    searchAPI
      .searchCustomers({ query: customersSearchQuery, page: { offset: 0, limit: 100 } })
      .then((res) => {
        setCustomers(res.data.items);
      })
      .catch(() => {
        setCustomers([]);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customersSearchQuery]);

  const passengersAPI = usePassengersAPI();
  const [isSubmitting, setSubmitting] = useState(false);

  const form = useForm({
    defaultValues: {
      firstName: passenger?.firstName || '',
      lastName: passenger?.lastName || '',
      hasWheelchair: passenger?.hasWheelchair || false,
      loadingTime: passenger?.loadingTime || '3',
      comment: passenger?.comment || '',
      addresses: (passenger?.addresses || []).sort((a, b) => a.createdAt.localeCompare(b.createdAt)),
      contactPersons: (passenger?.contactPersons || [])
        .map(({ formattedPhoneNumber, ...item }) => ({
          ...item,
          phoneNumber: formattedPhoneNumber,
        }))
        .sort((a, b) => a.createdAt.localeCompare(b.createdAt)),
      customerId: passenger?.customerId || defaultCustomerId || null,
    },
  });

  const addresses = useFieldArray({ name: 'addresses' as never, control: form.control, keyName: 'form-id' });
  const contacts = useFieldArray({ name: 'contactPersons' as never, control: form.control, keyName: 'form-id' });

  return (
    <Wrapper>
      <Form
        form={form}
        onSubmit={(values) => {
          setSubmitting(true);
          (passenger?.id ? passengersAPI.updatePassenger(passenger?.id, values) : passengersAPI.createPassenger(values))
            .then((res) => {
              onSuccess?.(res.data.id);
            })
            .catch((err) => {
              // TODO add generic error message
            })
            .finally(() => setSubmitting(false));
        }}
      >
        <Title>Fahrgastprofil</Title>

        <Section>
          <SectionTitle>Allgemeine Informationen</SectionTitle>
          <GridRow>
            <TextInput id={'firstName'} placeholder={'Vorname eingeben'} label={'Vorname'} $plain />
            <TextInput id={'lastName'} placeholder={'Nachname eingeben'} label={'Nachname'} $plain />
          </GridRow>

          {addresses.fields.map((field, index) => (
            <GridRowInline key={field['form-id']}>
              <ContactTitle>{index + 1}. Wohnsitz</ContactTitle>
              <TextInput
                id={`addresses.${index}.addressLine1`}
                placeholder={'Straße und Hausnummer eingeben'}
                label={'Straße und Hausnummer'}
                $plain
              />
              <TextInput
                optional
                id={`addresses.${index}.addressLine2`}
                placeholder={'Adresszusatz eingeben'}
                label={'Adresszusatz'}
                $plain
              />
              <TextInput id={`addresses.${index}.zipCode`} placeholder={'Postleitzahl eingeben'} label={'PLZ'} $plain />
              <TextInput id={`addresses.${index}.city`} placeholder={'Stadt eingeben'} label={'Stadt'} $plain />
              <TextInput
                optional
                optionalLabel
                id={`addresses.${index}.comment`}
                placeholder={'z.B. "Haushalt Eltern"'}
                label={'Information zum Wohnsitz'}
                $plain
              />
              <RemoveButton
                onClick={() => {
                  addresses.remove(index);
                }}
              >
                <Trash />
              </RemoveButton>
            </GridRowInline>
          ))}
          <GridRow>
            <AddButton
              onClick={() => {
                addresses.append({ addressLine1: '', addressLine2: '', zipCode: '', city: '', comment: '' });
              }}
            >
              <Plus /> Weiteren Wohnsitz hinzufügen
            </AddButton>
          </GridRow>
        </Section>

        <Section>
          <SectionTitle>Kontakt</SectionTitle>
          {contacts.fields.map((field, index) => (
            <GridRowInline key={field['form-id']}>
              <ContactTitle>{index + 1}. Kontakt</ContactTitle>
              <TextInput
                optional
                id={`contactPersons.${index}.emailAddress`}
                placeholder={'beispiel@gmail.com'}
                label={'E-Mail-Adresse'}
                $plain
              />
              <TextInput
                optional
                id={`contactPersons.${index}.phoneNumber`}
                placeholder={'z.B. 01578-2983456'}
                label={'Telefonnummer'}
                $plain
              />
              <TextInput optional optionalLabel id={`contactPersons.${index}.firstName`} placeholder={'Vorname'} label={'Vorname'} $plain />
              <TextInput
                optional
                optionalLabel
                id={`contactPersons.${index}.lastName`}
                placeholder={'Nachname'}
                label={'Nachname'}
                $plain
              />
              <TextInput
                optional
                optionalLabel
                id={`contactPersons.${index}.role`}
                placeholder={'z.B. Mutter'}
                label={'Verbindung zum Fahrgast'}
                $plain
              />
              <RemoveButton
                onClick={() => {
                  contacts.remove(index);
                }}
              >
                <Trash />
              </RemoveButton>
            </GridRowInline>
          ))}
          <GridRow>
            <AddButton
              onClick={() => {
                contacts.append({ firstName: '', lastName: '', emailAddress: '', phoneNumber: '', role: '' });
              }}
            >
              <Plus /> Weitere Ansprechpartner hinzufügen
            </AddButton>
          </GridRow>
        </Section>

        <Section>
          <SectionTitle>Informationen zur Einrichtung</SectionTitle>
          <Row>
            <StyledSearchableDropdown
              id={'customerId'}
              onSearchInput={setCustomersSearchQuery}
              items={
                [
                  ...(customers || []).map((customer) => ({
                    value: customer.id,
                    label: customer.displayName,
                    view: (
                      <>
                        <Facilities />
                        <div>
                          <span>({customer.internalId})</span> {customer.displayName}
                        </div>
                      </>
                    ),
                  })),
                  // fallback if no data is loaded
                  !customers &&
                    passenger?.customer && {
                      value: passenger.customer.id,
                      label: passenger.customer.displayName,
                    },
                ] as any[]
              }
              placeholder={'Einrichtung auswählen'}
              label={'Einrichtung'}
              $plain
            />
          </Row>
        </Section>

        <Section>
          <SectionTitle>Informationen zum Transport</SectionTitle>
          <Row>
            <Switch id={'hasWheelchair'} label={'Rollstuhl'} placeholder={'Sitzt der Fahrgast im Rollstuhl?'} />
          </Row>
          <Row>
            <StyledDropdown
              optional
              id={'loadingTime'}
              items={[3, 5, 10, 15].map((time) => ({
                value: time.toString(),
                label: `${time} Min`,
              }))}
              placeholder={'Ladezeit auswählen'}
              label={'Ladezeit in Minuten'}
              $plain
            />
          </Row>
        </Section>

        <Section>
          <Row>
            <TextInput
              optional
              id={'comment'}
              type={'textarea'}
              placeholder={'Anmerkungen hinzufügen...'}
              label={'Anmerkungen'}
              style={{ flex: 1 }}
            />
          </Row>
        </Section>

        <ButtonPrimary type={'submit'}>{isSubmitting ? <LoadingSpinner /> : 'Speichern'}</ButtonPrimary>
      </Form>
    </Wrapper>
  );
});
