// General
import { FC } from 'react';
import styled, { css } from 'styled-components';

// Styling
import { Body4, Body4Bold } from 'styles/FontStyles';
import { Colors } from 'styles/Colors';

// Types
import { IInputProps } from 'components/common/inputs/TextInput';

// Icons
import { ChevronDown } from 'components/icons/ChevronDown';

// Components
import { Field, useFieldError, useFieldProps } from 'components/common/inputs/Field';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const DropdownLabel = styled.label<{ disabled?: boolean }>`
  ${Body4Bold};
  margin: 0;

  ${({ disabled = false }) =>
    disabled &&
    css`
      color: ${Colors.grey700};
    `};

  span {
    color: ${Colors.grey700};
  }
`;

const Select = styled(Field).attrs({ as: 'select' })<{ error: boolean }>`
  appearance: none;
  background: none;
  width: 100%;
  height: 2.75rem;

  padding: 0.75rem 3rem 0.75rem 1rem;

  border: 1px solid ${Colors.grey500};
  border-radius: 8px;

  ${Body4};
  color: ${Colors.textDefault};

  transition: border-color 150ms ease-out, color 150ms ease-out;

  &:focus-visible {
    border-color: ${Colors.grey800};
  }

  &:disabled {
    background: ${Colors.grey300};
    color: ${Colors.grey700};
  }

  ${({ error = false }) =>
    error &&
    css`
      border-color: ${Colors.signalRed900};
    `};
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

const ChevronDownIcon = styled(ChevronDown)`
  position: absolute;
  right: 1rem;
  width: 1rem;
  height: 1rem;
  object-fit: contain;
  pointer-events: none;
`;

export const ErrorLabel = styled.p`
  ${Body4};
  color: ${Colors.signalRed900};
`;

export const Dropdown: FC<IInputProps & { items: { value: string; label: string }[]; defaultValue?: string; selectedValue?: string }> = (
  props,
) => {
  const {
    id,
    name = id,
    label = '',
    placeholder = '',
    disabled = false,
    optional = false,
    optionalLabel = false,
    items = [],
    className,
    style,
    defaultValue,
    onValueChange,
  } = props;

  const fieldProps = useFieldProps(name, optional);
  const error = useFieldError(name);

  return (
    <Wrapper className={className} style={style}>
      {label && (
        <DropdownLabel disabled={disabled}>
          {label}
          {optionalLabel && <span> (optional)</span>}
        </DropdownLabel>
      )}

      <SelectWrapper>
        <Select
          {...fieldProps}
          onChange={(event) => {
            onValueChange && onValueChange((event.target as HTMLSelectElement).value);
          }}
          id={id}
          name={name}
          error={!!error}
          optional={optional}
          disabled={disabled}
          defaultValue={defaultValue}
        >
          {placeholder && (
            <option disabled={disabled} value={''}>
              {placeholder}
            </option>
          )}
          {items.map(({ value, label }, index) => (
            <option value={value} key={index}>
              {label}
            </option>
          ))}
        </Select>
        <ChevronDownIcon />
      </SelectWrapper>

      <ErrorLabel>{error?.message}</ErrorLabel>
    </Wrapper>
  );
};
