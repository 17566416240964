import styled from 'styled-components';
import { RadioButton } from 'components/common/atoms/RadioButton';
import { IScheduledTourException } from 'api/models/ScheduledTour';
import { ExceptionListItemInfo } from 'components/common/elements/tours/tour-details/ExceptionListItem';

export const RadioButtonWrapper = styled.div`
  display: grid;
  grid-column-start: 1;
  grid-column-end: span 2;
  grid-template-columns: subgrid;

  > label {
    cursor: pointer;
    &::before {
      content: '';
      position: absolute;
      inset: 0;
      z-index: 2;
    }
  }

  &:not(:only-child) {
    margin-bottom: 1rem;
  }

  & > * {
    display: grid;
    grid-template-columns: subgrid;
    grid-column-start: 1;
    grid-column-end: span 2;
    gap: inherit;
  }
`;

interface IScheduledTourExceptionRadioButtonProps {
  exception: IScheduledTourException;
  activeExceptionId?: string;
  selectedException?: IScheduledTourException;
  onExceptionClick: (exception: IScheduledTourException) => void;
  createdDate: Date;
}

export default function ScheduledTourExceptionRadioButton({
  exception,
  selectedException,
  onExceptionClick,
  createdDate,
}: IScheduledTourExceptionRadioButtonProps) {
  return (
    <RadioButtonWrapper>
      <RadioButton
        value={exception.id}
        checked={selectedException?.id || ''}
        name="exception"
        onChange={() => {
          onExceptionClick && exception && onExceptionClick(exception);
        }}
      >
        <ExceptionListItemInfo>
          Erstellt am:{' '}
          {[
            createdDate.toLocaleDateString('de-DE', { dateStyle: 'short' }),
            createdDate.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' }),
          ].join(' - ')}{' '}
          Uhr
        </ExceptionListItemInfo>
      </RadioButton>
    </RadioButtonWrapper>
  );
}
