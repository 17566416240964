import { css } from 'styled-components';
import { BREAKPOINT_LG } from 'styles/Breakpoints';

export const Headline1 = css`
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 700;
  letter-spacing: 0.01em;

  ${BREAKPOINT_LG} {
    font-size: 3rem;
    line-height: 3.75rem;
  }
`;

export const Headline2 = css`
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  letter-spacing: 0.01em;

  ${BREAKPOINT_LG} {
    font-size: 2rem;
    line-height: 2.5rem;
  }
`;

export const Headline3 = css`
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 700;
  letter-spacing: 0.01em;

  ${BREAKPOINT_LG} {
    font-size: 1.5rem;
    line-height: 2rem;
  }
`;

export const Headline4 = css`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
  letter-spacing: 0.01em;

  ${BREAKPOINT_LG} {
    font-size: 1rem;
    line-height: 1.5rem;
  }
`;

export const Headline5 = css`
  ${Headline4};
`;

export const Headline6 = css`
  ${Headline4};
`;

export const Body1 = css`
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 400;
`;

export const Body1Bold = css`
  ${Body1};
  font-weight: 700;
`;

export const Body2 = css`
  font-size: 1.125rem;
  line-height: 2rem;
  font-weight: 400;
`;

export const Body2Bold = css`
  ${Body2};
  font-weight: 700;
`;

export const Body3 = css`
  font-size: 1rem;
  line-height: 1.375rem;
  font-weight: 400;
`;

export const Body3Bold = css`
  ${Body3};
  font-weight: 700;
`;

export const Body4 = css`
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
`;

export const Body4Bold = css`
  ${Body4};
  font-weight: 700;
`;

export const Body5 = css`
  font-size: 0.75rem;
  line-height: 0.875rem;
  font-weight: 400;
`;

export const Body5Bold = css`
  ${Body5};
  font-weight: 700;
`;
