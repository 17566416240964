import { FC } from 'react';
import { IEmployee } from 'api/models/Employee';
import InfoProfile from './EmployeeInfoProfile';
import InfoWork from './EmployeeInfoWork';
import AnchorSectionView from '../AnchorSectionView';

export const EmployeeDetailsInformation: FC<{ employee: IEmployee }> = (props) => {
  const { employee } = props;

  return (
    <AnchorSectionView
      items={[
        {
          title: 'Persönliche Daten',
          content: <InfoProfile employee={employee} />,
        },
        {
          title: 'Daten zur Arbeit',
          content: <InfoWork employee={employee} />,
        },
      ]}
    />
  );
};
