import { FC, useCallback } from 'react';
import { getCurrentDayDate } from 'utils/dateUtils';
import { IScheduledTourException } from 'api/models/ScheduledTour';
import { getFormattedReasonForConflict } from 'utils/tourUtils';
import { IConflict } from 'api/models/Conflict';
import { Toast } from 'components/common/elements/Toast';

interface ITourDetailsConflictBannerProps {
  startDate: string;
  endDate: string;
  timestamp: string;
  daysOfWeek: string;
  conflicts: IConflict[];
  activeException: IScheduledTourException | null | undefined;
  isException: boolean;
  isOriginalTour: boolean;
  selectedException?: IScheduledTourException;
}

const ConflictBanner = (props: ITourDetailsConflictBannerProps) => {
  const { startDate, endDate, timestamp, isOriginalTour, conflicts, activeException, isException, selectedException } = props;

  const getExceptionToastMessage = (exception?: IScheduledTourException) => {
    let message;

    switch (exception?.reason) {
      case 'cancelled':
        message = 'Diese Fahrt wurde abgesagt';
        break;
      case 'tour_split':
        message = 'Diese Fahrt wurde aufgeteilt';
        break;
      case 'tour_extended':
        message = 'Es gibt eine neuere Version dieser Fahrt';
        break;
      case 'no_passenger':
        message = 'Es gibt keine Fahrgäste für diese Fahrt';
        break;
      default:
        message = 'Es besteht Handlungsbedarf';
        break;
    }

    if (exception?.comment) {
      message = `${message} <br /> Kommentar: ${exception.comment}`;
    }

    return message;
  };

  const getExceptionToastType = useCallback((exception?: IScheduledTourException) => {
    switch (exception?.reason) {
      case 'cancelled':
        return 'error';
      case 'tour_extended':
      case 'no_passenger':
        return 'alert';
      case 'tour_split':
        return 'error';
      default:
        return 'info';
    }
  }, []);

  const tourOk = isException || (isOriginalTour && !activeException);
  const tourChanged = !isOriginalTour && isException && activeException !== null && activeException !== undefined;
  const tourCancelled = selectedException ? ['cancelled', 'tour_split'].includes(selectedException?.reason) : false;
  const inDateRange =
    getCurrentDayDate(startDate) <= new Date(timestamp) && (!endDate || getCurrentDayDate(endDate) >= new Date(timestamp));

  if (isOriginalTour && activeException && !tourCancelled) {
    return <Toast type="alert">Dies ist die originale Tour</Toast>;
  }

  if (inDateRange) {
    return (
      <>
        {
          // shown if this tour is the active exception for the day
          conflicts.length < 1 && tourOk && activeException?.id === selectedException?.id && (
            <Toast type={'success'}>Dies ist die aktuelle Tour</Toast>
          )
        }
        {
          // shown if this tour is the active exception for the day
          (tourChanged && activeException.id !== selectedException?.id) ||
            (tourCancelled && (
              <Toast type={getExceptionToastType(selectedException)}>
                <span dangerouslySetInnerHTML={{ __html: getExceptionToastMessage(selectedException) }} />
              </Toast>
            ))
        }
        {conflicts.length > 0 &&
          (conflicts as IConflict[]).map((conflict, index) => (
            <Toast type={'error'} key={index}>
              {getFormattedReasonForConflict(conflict, timestamp)}
            </Toast>
          ))}
      </>
    );
  }

  return null;
};

export default ConflictBanner;
