import styled from 'styled-components';
import { FC, useEffect, useMemo, useState } from 'react';
import { ChevronLeft } from 'components/icons/ChevronLeft';
import { Calendar } from 'components/icons/Calendar';
import { Clickable } from '../atoms/Clickable';
import { Body4 } from 'styles/FontStyles';
import { addMonths, getCurrentDayDate, isDateInMonthArray } from 'utils/dateUtils';
import { ContextMenu } from '../atoms/ContextMenu';
import { Colors } from 'styles/Colors';
import { DateYearInput } from 'components/common/inputs/DateYearInput';

const Wrapper = styled.div<{ pickerOpen: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;

  position: relative;
  z-index: ${({ pickerOpen }) => (pickerOpen ? 10 : 1)};

  ${Body4};

  svg {
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
  }
`;

const Label = styled.p`
  pointer-events: none;
  user-select: none;
  inline-size: 6.5rem;
  text-align: center;
`;

const ChevronRight = styled(ChevronLeft)`
  transform: rotate(180deg);
`;

const Popup = styled(ContextMenu).attrs({ hideCloseButton: true })`
  top: 100%;
  bottom: auto;
  left: auto;
  right: 0;
  margin: 0.5rem 0 0;

  padding: 0.5rem;

  color: ${Colors.textDefault};
  cursor: auto;
`;

const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  padding-block: 0.25rem 0;
`;

const MonthGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
`;

const MonthTile = styled(Clickable)<{ active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  inline-size: 5.5rem;

  padding: 0.5rem;
  border-radius: 2px;
  background-color: ${({ active }) => (active ? Colors.secondary : Colors.grey400)};
  color: ${({ active }) => (active ? Colors.textInverted : Colors.textDefault)};
  font-weight: ${({ active }) => (active ? 700 : 400)};

  transition: background-color 150ms ease-out, color 150ms ease-out;

  @media (hover: hover) {
    &:hover {
      background-color: ${Colors.secondary};
      color: ${Colors.textInverted};
    }
  }
`;

interface DateMonthInputProps {
  setValue: (value: string) => void;
  value: string;
  className?: string;
  minDate?: Date;
  maxDate?: Date;
}

export const DateMonthInput: FC<DateMonthInputProps> = (props) => {
  const { value, setValue, className, minDate, maxDate } = props;

  const [pickerOpen, setPickerOpen] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const setMonth = (offset: number) => {
    const date = getCurrentDayDate(value);
    date.setDate(1);
    date.setMonth(date.getMonth() + offset);
    setValue(date.toISOString());
  };

  const monthsOfYear = useMemo(() => {
    const date = getCurrentDayDate(currentValue);
    const selectedYear = date.getFullYear();

    const months = [];
    date.setDate(1);
    date.setMonth(0);
    let current = 0;
    while (date.getFullYear() === selectedYear) {
      months.push(getCurrentDayDate(date));
      date.setMonth(++current);
    }

    return months;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  const allowedMonths = useMemo(() => {
    const min = minDate ? new Date(minDate) : new Date(0);
    const max = maxDate ? new Date(maxDate) : new Date(8640000000000000);
    return monthsOfYear.filter((month) => month >= min && month <= max);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minDate, maxDate]);

  const handleMinusClick = () => {
    const newDate = addMonths(new Date(value), -1);
    if (!isDateInMonthArray(newDate, allowedMonths)) return;

    setMonth(-1);
  };

  const handlePlusClick = () => {
    const newDate = addMonths(new Date(value));
    if (!isDateInMonthArray(newDate, allowedMonths)) return;

    setMonth(1);
  };

  return (
    <Wrapper className={className} pickerOpen={pickerOpen}>
      <Clickable onClick={handleMinusClick}>
        <ChevronLeft />
      </Clickable>

      <Label>{value && new Date(value).toLocaleDateString(undefined, { month: 'long', year: 'numeric' })}</Label>
      <Clickable onClick={() => setPickerOpen(true)}>
        <Calendar />
      </Clickable>
      <Clickable onClick={handlePlusClick}>
        <ChevronRight />
      </Clickable>
      <Popup open={pickerOpen} setOpen={setPickerOpen}>
        <PopupContent>
          <DateYearInput value={currentValue} setValue={setCurrentValue} />
          <MonthGrid>
            {allowedMonths.map((month: Date, index) => (
              <MonthTile
                key={index}
                onClick={() => {
                  setValue(month.toISOString());
                  setPickerOpen(false);
                }}
                active={new Date(value).getFullYear() === month.getFullYear() && new Date(value).getMonth() === month.getMonth()}
              >
                {month.toLocaleDateString(undefined, { month: 'long' })}
              </MonthTile>
            ))}
          </MonthGrid>
        </PopupContent>
      </Popup>
    </Wrapper>
  );
};
