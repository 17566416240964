// General
import styled from 'styled-components';

// Components
import { ContextMenu } from 'components/common/atoms/ContextMenu';

// Styles
import { Colors } from 'styles/Colors';

export const Tooltip = styled(ContextMenu).attrs({ hideCloseButton: true })`
  z-index: 5;
  left: auto;
  bottom: auto;
  top: 100%;
  right: -0.625rem;
  background: ${Colors.grey900};
  color: ${Colors.white50};
  box-shadow: none;
  padding: 1.25rem;
  gap: 0.5rem;
  min-width: 0;
  margin: 1rem 0 0;

  &::before {
    content: '';
    position: absolute;
    top: -0.5rem;
    right: 1rem;
    width: 0.75rem;
    height: 0.5rem;
    background: ${Colors.grey900};
    clip-path: polygon(50% 0, 100% 100%, 0 100%);
  }
`;

export const TooltipCentered = styled(Tooltip)`
  right: 50%;
  transform: translateX(50%);

  &::before {
    right: 50%;
    transform: translateX(50%);
  }
`;
