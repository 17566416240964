import styled from 'styled-components';
import { Colors } from 'styles/Colors';
import { Facilities } from 'components/icons/Facilities';
import { TourDragItemContent } from 'components/content/customers/tourplan/tiles/TourplanTourTile';
import { PropsWithChildren, ReactNode } from 'react';
import { ICustomer } from 'api/models/Customer';
import { IPassengerRefactored } from 'api/models/Passenger';
import { formatAddress } from 'utils/addressUtils';
import { TourplanPassengerViewRefactored } from 'components/content/customers/tourplan/tiles/TourplanPassengerViewRefactored';

const TourItemPassengersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.25rem;
`;

const PassengersStopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 0.75rem;

  & > *:last-child {
    flex: 1;
  }

  button {
    max-width: none;
  }
`;

const PassengersStop = styled.div<{ dotActive?: boolean; lineActive?: boolean; lineHidden?: boolean }>`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  inline-size: 1rem;
  margin-block: 0 -0.25rem;

  transform: translateY(0.625rem);

  &::before {
    content: '';
    width: 7px;
    height: 7px;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: ${({ dotActive = true }) => (dotActive ? Colors.grey600 : Colors.grey300)};
    border: ${({ dotActive = true }) => (dotActive ? `1px solid ${Colors.grey600}` : `1px dashed ${Colors.grey500}`)};
  }

  &::after {
    content: '';
    flex: 1 0 0;
    border-left: 1px dashed ${({ lineActive = true }) => (lineActive ? Colors.grey600 : Colors.grey500)};
    opacity: ${({ lineHidden = false }) => (lineHidden ? 0 : 1)};
  }
`;

const CustomerStop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  inline-size: 1rem;

  color: ${Colors.grey600};

  svg {
    width: 1rem;
    height: 1rem;
    z-index: 1;
  }

  position: relative;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background-color: ${Colors.white50};
    z-index: 0;
  }

  ${PassengersStop} {
    position: absolute;
    inset: 0;
    z-index: -1;
  }
`;

interface IPassengersTrackProps {
  direction: 'outwards' | 'return' | any;
  customer: ICustomer | undefined;
  passengers: IPassengerRefactored[];
  renderPassenger?: (passenger: IPassengerRefactored, index: number) => ReactNode;
  renderDropOverItems?: () => ReactNode;
  customerTime?: string;
}

function PassengersTrackRefactored(props: PropsWithChildren<IPassengersTrackProps>) {
  const { direction, customer, passengers = [], renderPassenger, renderDropOverItems, customerTime, children } = props;

  return (
    <TourItemPassengersWrapper>
      {direction === 'return' && (
        <PassengersStopWrapper>
          <CustomerStop>
            <Facilities />
            <PassengersStop lineActive={passengers.length > 0 || !renderDropOverItems} />
          </CustomerStop>
          <TourDragItemContent>
            <span>
              {customerTime && <em>{customerTime}</em>}
              <b>{customer?.displayName}</b> {formatAddress(customer?.address)}
            </span>
          </TourDragItemContent>
        </PassengersStopWrapper>
      )}
      {passengers.map((passenger, index, array) => (
        <PassengersStopWrapper key={index}>
          <PassengersStop
            lineActive={index < array.length - 1 || (!renderDropOverItems && !children)}
            lineHidden={index === array.length - 1 && direction === 'return' && !renderDropOverItems && !children}
          />
          {renderPassenger?.(passenger, index)}
          {!renderPassenger && <TourplanPassengerViewRefactored passenger={passenger} />}
        </PassengersStopWrapper>
      ))}
      {children && (
        <PassengersStopWrapper>
          <PassengersStop dotActive={false} lineActive={false} lineHidden={direction === 'return'} />
          {children}
        </PassengersStopWrapper>
      )}
      {renderDropOverItems && (
        <PassengersStopWrapper>
          <PassengersStop dotActive={false} lineActive={false} lineHidden={direction === 'return'} />
          {renderDropOverItems()}
        </PassengersStopWrapper>
      )}
      {direction === 'outwards' && (
        <PassengersStopWrapper>
          <CustomerStop>
            <Facilities />
          </CustomerStop>
          <TourDragItemContent>
            <span>
              {customerTime && <em>{customerTime}</em>}
              <b>{customer?.displayName}</b> {formatAddress(customer?.address)}
            </span>
          </TourDragItemContent>
        </PassengersStopWrapper>
      )}
    </TourItemPassengersWrapper>
  );
}

export default PassengersTrackRefactored;
