// General
import styled, { css } from 'styled-components';
import { FC, PropsWithChildren } from 'react';

// Styles
import { Body2Bold } from 'styles/FontStyles';
import { Colors } from 'styles/Colors';
import { BREAKPOINT_MD } from 'styles/Breakpoints';
import { formatName } from 'utils/nameUtils';

const Wrapper = styled.div<{
  size: SizeType;
  hideBorder?: boolean;
  empty?: boolean;
  error?: boolean;
  variation?: VariationType;
  alwaysShowActiveState?: boolean;
}>`
  --status-color: ${({ variation }) => (variation === 'active' ? Colors.signalGreen900 : Colors.signalRed900)};
  --size: ${({ size }) => {
    switch (size) {
      case 'large':
      case 'medium':
        return '3.375rem';
      case 'small':
        return '2.75rem';
      default:
      case 'mini':
        return '2rem';
    }
  }};
  --dot-size: ${({ size }) => {
    switch (size) {
      case 'large':
      case 'medium':
        return '1rem';
      case 'small':
        return '.75rem';
      default:
      case 'mini':
        return '.5rem';
    }
  }};

  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: var(--size);
  height: var(--size);
  border: 1px solid currentColor;
  border-radius: 50%;

  ${Body2Bold};
  color: ${Colors.grey700};
  letter-spacing: 0.01em;
  text-decoration: none;

  ${({ hideBorder = false }) =>
    hideBorder &&
    css`
      border-width: 0;
    `};

  ${({ empty = false }) =>
    empty &&
    css`
      color: ${Colors.grey500};
      border: 1px dashed currentColor;
    `};

  ${({ error = false }) =>
    error &&
    css`
      color: ${Colors.signalRed900};
      border: 1px dashed currentColor;
    `};

  ${({ variation }) =>
    variation !== 'none' &&
    css`
      :after {
        content: '';
        position: absolute;
        right: var(--dot-offset, -0.125rem);
        bottom: var(--dot-offset, -0.125rem);
        background: var(--status-color);
        width: var(--dot-size, 1rem);
        height: var(--dot-size, 1rem);
        border: 2px solid ${Colors.white50};
        border-radius: 100%;

        ${BREAKPOINT_MD} {
          content: unset;
        }
      }
    `};

  ${({ alwaysShowActiveState, variation }) =>
    variation !== 'none' &&
    alwaysShowActiveState &&
    css`
      :after {
        ${BREAKPOINT_MD} {
          content: '';
        }
      }
    `};

  // TODO check responsive styling for mini, small and large
  ${BREAKPOINT_MD} {
    --size: ${({ size }) => {
      switch (size) {
        case 'large':
          return '6.25rem';
        case 'medium':
          return '5rem';
        case 'small':
          return '2.75rem';
        default:
        case 'mini':
          return '2rem';
      }
    }};
    --dot-size: ${({ size }) => {
      switch (size) {
        case 'large':
        case 'medium':
          return '1rem';
        case 'small':
          return '.75rem';
        default:
        case 'mini':
          return '.5rem';
      }
    }};
  }
`;

const Letters = styled.span`
  font-size: calc(var(--size) / 2);
  cursor: default;
`;

const AvatarImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  user-select: none;
  border-radius: 50%;
`;

type SizeType = 'mini' | 'small' | 'medium' | 'large';

type VariationType = 'active' | 'inactive' | 'none';

interface IAvatarProps {
  className?: string;
  src?: string;
  firstName?: string;
  lastName?: string;
  size?: SizeType;
  variation?: VariationType;
  alwaysShowActiveState?: boolean;
  empty?: boolean;
  error?: boolean;
}

export const Avatar: FC<PropsWithChildren<IAvatarProps>> = (props) => {
  const { src, firstName, lastName, size, variation, alwaysShowActiveState, empty, error, className, children } = props;

  const fullName = formatName({ firstName, lastName });

  return (
    <Wrapper
      title={fullName}
      size={size || 'small'}
      className={className}
      hideBorder={!!src}
      empty={empty}
      error={error}
      variation={variation || 'none'}
      alwaysShowActiveState={alwaysShowActiveState}
    >
      <Letters>
        {firstName?.substr(0, 1).toUpperCase()}
        {lastName?.substr(0, 1).toUpperCase()}
      </Letters>

      {children}

      {src && <AvatarImg src={src} alt={fullName} />}
    </Wrapper>
  );
};
