import styled, { keyframes } from 'styled-components';
import { FC, PropsWithChildren } from 'react';
import { Card } from '../atoms/Card';
import { Colors } from 'styles/Colors';
import { ChipTile } from '../atoms/ChipTile';

const AnimSpin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  width: var(--spinner-size, 1.5rem);
  height: var(--spinner-size, 1.5rem);
  flex-shrink: 0;
  margin: auto;
  border: var(--spinner-width, 1px) solid transparent;
  border-radius: 50%;
  border-right-color: currentColor;

  animation: ${AnimSpin} 1.5s linear infinite;
`;

const Overlay = styled.div`
  position: fixed;
  inset: 0;
  z-index: 12;
  pointer-events: all;

  background: rgba(0, 0, 0, 0.4);

  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProgressUICard = styled(Card)`
  flex: 0;
  gap: 1rem;

  h2 {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  ${ChipTile} {
    min-inline-size: 6.25rem;
    justify-content: center;
    text-align: center;
    align-self: center;
    margin-block-end: 0.75rem;
  }
`;

const ProgressUISpinner = styled(Spinner)`
  --spinner-size: 3rem;
  --spinner-width: 0.25rem;
  color: ${Colors.primary};
`;

const SmallSpinner = styled(Spinner)`
  --spinner-size: 1rem;
`;

export const LoadingSpinner: FC<{ small?: boolean }> = ({ small = false }) => {
  if (small) {
    return <SmallSpinner />;
  }

  return <Spinner />;
};

export const LoadingOverlay = (props: PropsWithChildren) => {
  const { children } = props;

  return (
    <Overlay
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {children || <LoadingSpinner />}
    </Overlay>
  );
};

interface ILoadingProgressUIProps {
  title?: string;
  progress?: { progress: number; count: number } | null;
}
export function LoadingProgressUI(props: ILoadingProgressUIProps) {
  const { title, progress } = props;

  return (
    <LoadingOverlay>
      <ProgressUICard>
        <h2>{title}</h2>
        {progress && (
          <ChipTile progress={progress.progress / progress.count}>
            {progress.progress} / {progress.count}
          </ChipTile>
        )}
        <ProgressUISpinner />
      </ProgressUICard>
    </LoadingOverlay>
  );
}
