import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { TPageType } from 'components/common/elements/ContentPageFilterRow';

const { persistAtom } = recoilPersist({
  key: 'user-filters',
  storage: localStorage,
});

interface IUserFiltersState {
  pages: {
    [page in TPageType]?: {
      q?: string;
      viewAsList: boolean;
      propertyFilters: {
        [key: string]: string | string[];
      };
    };
  };
}

export const UserFiltersState = atom<IUserFiltersState>({
  key: 'UserFiltersState',
  default: {
    pages: {},
  },
  effects_UNSTABLE: [persistAtom],
});
