import { FC } from 'react';
import { IAddress } from 'api/models/Address';
import styled from 'styled-components';
import { Location } from 'components/icons/Location';
import { Body4 } from 'styles/FontStyles';

const TagWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  ${Body4};
`;

const LocationIcon = styled(Location)`
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
`;

export const LocationTag: FC<{ address?: string | IAddress | null }> = (props) => {
  const { address } = props;

  return (
    <TagWrapper>
      <LocationIcon />
      {(typeof address === 'string' ? address : address?.city) || '-'}
    </TagWrapper>
  );
};
