import { Bridge, Empty } from '../bridge'

import type { CustomerOrganizationCollectionResponse } from 'json-schemas/CustomerOrganization.CollectionResponse';
import type { CustomerOrganizationItemResponse } from 'json-schemas/CustomerOrganization.ItemResponse';
import type { CustomerOrganizationCastParams } from 'json-schemas/CustomerOrganization.CastParams';

export class CustomerOrganizationsBridge extends Bridge {
  async getAll(): Promise<CustomerOrganizationCollectionResponse> {
    this.checkAuth();

    const result = await this.get<Empty, CustomerOrganizationCollectionResponse>(`/api/customer-organizations`, {});

    return result;
  }

  async getById(id: string | number) {
    this.checkAuth();

    const result = await this.get<Empty, CustomerOrganizationItemResponse>(`/api/customer-organizations/${id}`, {});

    return result;
  }

  async create(attrs: CustomerOrganizationCastParams) {
    this.checkAuth();

    const result = await this.post<typeof attrs, CustomerOrganizationItemResponse>(`/api/customer-organizations`, attrs);

    return result;
  }

  async updateWithId(id: string | number, attrs: CustomerOrganizationCastParams) {
    this.checkAuth();

    const payload = {customer_organization: attrs};
    const result = await this.patch<typeof payload, CustomerOrganizationItemResponse>(`/api/customer-organizations/${id}`, payload);

    return result;
  }

  async deleteOne(id: string | number) {
    this.checkAuth();

    return this.delete(`/api/customer-organizations/${id}`);
  }
}
