import { ChangeEvent, FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Colors } from 'styles/Colors';

const Radio = styled.input`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  border: 3px solid #fff;
  box-shadow: 0 0 0 3px ${Colors.grey500};

  &:checked {
    background-color: black;
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

interface RadioButtonProps {
  value?: string;
  checked?: string;
  name: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const RadioButton: FC<PropsWithChildren<RadioButtonProps>> = (props) => {
  const { value, onChange, checked, name, children } = props;

  return (
    <Label>
      <Radio
        type="radio"
        id={`radio-${name}-${value}`}
        value={value}
        onChange={(e) => {
          onChange && onChange(e);
        }}
        checked={checked === value}
        name={name}
      />
      {children || value}
    </Label>
  );
};
