import styled from 'styled-components';
import { TextInput } from 'components/common/inputs/TextInput';
import { Button } from 'components/common/inputs/Button';
import { LoadingSpinner } from 'components/common/loaders/LoadingSpinner';
import { HighlightedLink } from 'components/common/atoms/Link';
import { useAuthAPI } from 'api/controllers/AuthAPI';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'components/common/inputs/Form';
import { PasswordLogin } from 'components/icons/Password';
import { IconWrapper, LoginScreenWrapper } from 'components/pages/auth/Login';
import { Headline2 } from 'styles/FontStyles';
import { ArrowleftIcon } from 'components/icons/ArrowLeft';
import { BREAKPOINT_MD, BREAKPOINT_XL } from 'styles/Breakpoints';

const Content = styled.div`
  grid-column: span 12;
  margin-block-start: 5rem;

  ${BREAKPOINT_MD} {
    margin-block-start: unset;
    grid-column: 3 / span 8;
    min-block-size: 100vh;
  }

  ${BREAKPOINT_XL} {
    grid-column: 5 / span 4;
  }
`;

const ContentInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-block-size: 100%;
  text-align: center;

  form {
    inline-size: 100%;
    align-items: center;
    margin-block-start: 1.5rem;
    gap: 1.5rem;

    > div {
      inline-size: 100%;
    }
  }
`;

const Title = styled.h1`
  ${Headline2};
  margin-block-end: 1rem;
`;

const BackLink = styled(HighlightedLink)`
  margin-block: 1.5rem;
`;

export const ForgotPassword = () => {
  const authAPI = useAuthAPI();

  const form = useForm({ defaultValues: { email: '' } });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  return (
    <LoginScreenWrapper>
      <Content>
        <ContentInner>
          <IconWrapper>
            <PasswordLogin />
          </IconWrapper>
          <Title>{success ? 'Sie haben eine E-Mail erhalten.' : 'Passwort vergessen?'}</Title>
          <p>
            {success
              ? 'Wir haben eine E-Mail an die von Ihnen angegebene Adresse geschickt. Klicken Sie auf den Link in der E-Mail um Ihr Passwort zurückzusetzen.'
              : 'Bitte geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen. Möglicherweise müssen Sie Ihren Spam-Ordner prüfen.'}
          </p>
          {!success && (
            <Form
              form={form}
              onSubmit={(values) => {
                setLoading(true);
                authAPI
                  .forgotPassword(values)
                  .then((res) => {
                    console.log('forgot password', res.data);
                    setSuccess(true);
                  })
                  .catch((e) => {
                    form.setError('email', { message: 'Hier hat etwas nicht geklappt. Bitte überprüfe deine Email Adresse.' });
                    console.error('forgot password', e);
                  })
                  .finally(() => setLoading(false));
              }}
            >
              <TextInput id={'email'} autoComplete={'current-user'} label={'Email'} placeholder={'beispiel@sonnenschein.com'} />
              <Button type={'submit'}>{loading ? <LoadingSpinner /> : 'Email anfordern'}</Button>
            </Form>
          )}
          <BackLink href={'/login'}>
            <ArrowleftIcon />
            Zum Login
          </BackLink>
        </ContentInner>
      </Content>
    </LoginScreenWrapper>
  );
};
