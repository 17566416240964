import { useState, useMemo, Fragment } from 'react';
import { useAuthAPI } from 'api/controllers/AuthAPI';
import { IUser, TUserPermission, USER_PERMISSION_TITLES } from 'api/models/User';
import { useMatchBreakpoint } from 'hooks/useMatchBreakpoint';
import { useShowDialog } from 'state/DialogState';
import { PlainCheckbox } from 'components/common/atoms/Checkbox';
import { ButtonTertiary, ButtonPrimary } from 'components/common/inputs/Button';
import { LoadingSpinner } from 'components/common/loaders/LoadingSpinner';
import styled, { css } from 'styled-components';
import { CollapsibleRow } from 'components/common/atoms/CollapsibleRow';
import { BREAKPOINT_XL } from 'styles/Breakpoints';
import { Colors } from 'styles/Colors';
import { Body4, Body5 } from 'styles/FontStyles';
import { Info } from 'components/common/atoms/List';

const PermissionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
  padding-block: 0.75rem;

  ${BREAKPOINT_XL} {
    flex-direction: row;
    align-items: flex-end;
  }

  p {
    ${Body4};
    text-align: start;
  }
`;

const InnerContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.5rem;
`;

const PermissionChip = styled.p<{ active?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-inline: 0.625rem;
  padding-block: 0.375rem;

  ${Body5};
  background-color: ${Colors.grey500};
  border-radius: 1rem;

  ${({ active = false }) =>
    active &&
    css`
      background-color: ${Colors.primary};
    `};
`;

const TrashButton = styled(ButtonTertiary)`
  color: ${Colors.signalRed900};
  border-color: ${Colors.signalRed900};

  transition: border-color 150ms ease-out, background-color 150ms ease-out, color 150ms ease-out;

  @media (hover: hover) {
    :hover {
      color: ${Colors.textInverted};
      border-color: ${Colors.signalRed900};
      background-color: ${Colors.signalRed900};
    }
  }
`;

interface IUserRow {
  userAccount: IUser;
  onUpdate: (userAccount: IUser | null) => void;
}
export default function UserRow({ userAccount, onUpdate }: IUserRow) {
  const authAPI = useAuthAPI();
  const showDialog = useShowDialog();

  const isDesktop = useMatchBreakpoint();

  const [permissions, setPermissions] = useState(userAccount.permissions.split(';') as TUserPermission[]);
  const hasChanged = useMemo(() => {
    return userAccount.permissions.split(';').sort().join(';') !== permissions.slice().sort().join(';');
  }, [userAccount, permissions]);
  const [loading, setLoading] = useState(false);

  return (
    <CollapsibleRow
      content={
        <ContentWrapper>
          <InnerContentWrapper>
            <p>Berechtigungen:</p>
            <PermissionsWrapper>
              {Object.entries(USER_PERMISSION_TITLES).map(([permission, title]) => {
                // const disabled = permission !== 'admin' && permissions.includes('admin');
                // const checked = permissions.includes(permission as TUserPermission) || permissions.includes('admin');
                const disabled = false;
                const checked = permissions.includes(permission as TUserPermission);
                return (
                  <PermissionChip key={permission} active={!disabled && checked} onClick={(e) => e.stopPropagation()}>
                    <PlainCheckbox
                      disabled={disabled}
                      checked={checked}
                      onCheckedChange={() =>
                        setPermissions((state) => [
                          ...state.filter((i) => i !== permission),
                          ...(permissions.includes(permission as TUserPermission) ? [] : [permission as TUserPermission]),
                        ])
                      }
                      label={title}
                    />
                  </PermissionChip>
                );
              })}
            </PermissionsWrapper>
          </InnerContentWrapper>
          {hasChanged && (
            <>
              <ButtonTertiary
                disabled={loading}
                onClick={(e: any) => {
                  e.stopPropagation();
                  setPermissions(userAccount.permissions.split(';') as TUserPermission[]);
                }}
              >
                Verwerfen
              </ButtonTertiary>
              <ButtonPrimary
                disabled={loading}
                onClick={(e: any) => {
                  e.stopPropagation();

                  setLoading(true);
                  authAPI
                    .updateUser(userAccount.id, { permissions })
                    .then((res) => {
                      onUpdate(res.data);
                    })
                    .catch(() => {
                      showDialog({
                        headline: 'Nutzer Speichern',
                        body: 'Der Nutzer konnte nicht gespeichert werden. Bitte probieren Sie es später erneut.',
                      });
                    })
                    .finally(() => setLoading(false));
                }}
              >
                Speichern
              </ButtonPrimary>
            </>
          )}
          {!hasChanged && (
            <>
              <ButtonTertiary
                disabled={loading}
                onClick={(e: any) => {
                  e.stopPropagation();

                  setLoading(true);
                  authAPI
                    .forgotPassword({ email: userAccount.email })
                    .then(() => {
                      showDialog({
                        headline: 'Mail Angefordert',
                        body: 'Der Nutzer bekommt in Kürze eine Email mit einem Link, über den ein neues Passwort angefordert werden kann.',
                      });
                    })
                    .catch(() => {
                      showDialog({
                        headline: 'Anfordern Fehlgeschlagen',
                        body: 'Die Passwort-Zurücksetzen-Email konnte nicht angefordert werden. Bitte versuchen Sie es später erneut.',
                      });
                    })
                    .finally(() => setLoading(false));
                }}
              >
                Passwort Mail anfordern
              </ButtonTertiary>
              <TrashButton
                disabled={loading}
                onClick={(e: any) => {
                  e.stopPropagation();

                  showDialog({
                    headline: 'Nutzer löschen',
                    body: 'Möchten Sie diesen Nutzer wirklich löschen?',
                    buttons: ({ loading = false }) => (
                      <>
                        <ButtonTertiary disabled={loading} onClick={() => showDialog(null)}>
                          Abbrechen
                        </ButtonTertiary>
                        <TrashButton
                          disabled={loading}
                          onClick={() => {
                            showDialog({ loading: true }, true);
                            authAPI
                              .deleteUser(userAccount.id)
                              .then(() => {
                                showDialog(null);
                                onUpdate(null);
                              })
                              .catch(() => {
                                showDialog({
                                  headline: 'Nutzer löschen',
                                  body: 'Der Nutzer konnte nicht gelöscht werden. Bitte probieren Sie es später erneut.',
                                });
                              });
                          }}
                        >
                          {loading ? <LoadingSpinner /> : 'Löschen'}
                        </TrashButton>
                      </>
                    ),
                  });
                }}
              >
                Nutzer löschen
              </TrashButton>
            </>
          )}
        </ContentWrapper>
      }
    >
      <Info>
        <span>{userAccount.email}</span>
      </Info>
      {isDesktop && (
        <Info>
          <PermissionsWrapper>
            {Object.keys(USER_PERMISSION_TITLES)
              .filter((key) => userAccount.permissions?.split(';').includes(key))
              .map((permission, index, array) => {
                if (array.length > 2 && index > 0) {
                  if (index > 1) {
                    return <Fragment key={index}></Fragment>;
                  }
                  return <PermissionChip key={permission}>+{array.length - 1} weitere</PermissionChip>;
                }
                return <PermissionChip key={permission}>{USER_PERMISSION_TITLES[permission as TUserPermission]}</PermissionChip>;
              })}
          </PermissionsWrapper>
        </Info>
      )}
      <Info>{userAccount.createdAt && new Date(userAccount.createdAt).toLocaleDateString(undefined, { dateStyle: 'medium' })}</Info>
    </CollapsibleRow>
  );
}
