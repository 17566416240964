import { useEffect, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { usePassengerSearchAPI } from 'api/controllers/InfiniteSearchAPI';
import { usePassengersAPI } from 'api/controllers/PassengersAPI';
import { IPassenger } from 'api/models/Passenger';
import { PassengerFilterState } from 'state/PassengerFilterState';
import { List, ListHead } from 'components/common/atoms/List';
import { ContentPageHead } from 'components/common/elements/ContentPageHead';
import { PassengerListRow } from 'components/common/elements/passenger/PassengerListRow';
import { PassengerTile } from 'components/common/elements/passenger/PassengerTile';
import { ButtonSecondary } from 'components/common/inputs/Button';
import { LoadingSpinner } from 'components/common/loaders/LoadingSpinner';
import { Plus } from 'components/icons/Plus';
import { PassengerFilter } from 'components/content/passengers/PassengerFilter';
import { Link } from 'react-router-dom';
import InfinityScrollingWrapper from 'components/common/elements/InfinityScrollingWrapper';
import { UiState } from 'state/UiState';

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
`;

const StyledList = styled(List)`
  --list-template: 2.75rem 1.2fr 5rem 9rem 1fr 8.75rem;

  .infinite-scroll-component {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
  }
`;

const StyledListHead = styled(ListHead)`
  top: 10rem;
  margin-block-start: -2.5rem;
  padding-block-start: 2.5rem;
`;

export const PassengersList = () => {
  const uiState = useRecoilValue(UiState);

  const searchParams = useRecoilValue(PassengerFilterState);

  const { status, data, hasNextPage, fetchNextPage, error, isFetching } = usePassengerSearchAPI(
    uiState.passengerList.activeTab,
    searchParams,
  );
  const passengerAPI = usePassengersAPI();

  const passengers = useMemo(() => {
    return (data?.pages.map((page) => page.items).flat() as IPassenger[]) || [];
  }, [data]);

  const removeItem = (id: string) => {
    passengerAPI
      .deletePassenger(id)
      .then((res) => window.location.reload())
      .catch(() => console.error('error'));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [uiState.passengerList.activeTab]);

  return (
    <Wrapper>
      <ContentPageHead title={`Fahrgäste`} listName="passengerList">
        <FlexWrapper>
          <Link to={'/passengers/add'}>
            <ButtonSecondary>
              <Plus /> Fahrgast anlegen
            </ButtonSecondary>
          </Link>

          <PassengerFilter selectedRegions={searchParams.regions || []} />
        </FlexWrapper>
      </ContentPageHead>

      {passengers ? (
        uiState.passengerList.activeTab === 'tile' ? (
          <InfiniteScroll
            dataLength={passengers.length}
            next={fetchNextPage}
            style={{ overflow: 'visible' }}
            hasMore={hasNextPage}
            loader={<LoadingSpinner />}
            endMessage={<p>Keine weiteren Fahrgäste</p>}
          >
            <InfinityScrollingWrapper>
              {passengers.map((passenger) => (
                <PassengerTile key={passenger.id} passenger={passenger} onUpdate={() => removeItem(passenger.id)} />
              ))}
            </InfinityScrollingWrapper>
          </InfiniteScroll>
        ) : (
          <StyledList>
            <StyledListHead>
              <div />
              <div>Name</div>
              <div>Klassenstufe</div>
              <div>Barrierefreiheit</div>
              <div>Einrichtung</div>
            </StyledListHead>
            <InfiniteScroll
              dataLength={passengers.length}
              next={fetchNextPage}
              style={{ overflow: 'visible' }}
              hasMore={hasNextPage}
              loader={<LoadingSpinner />}
              endMessage={<p>Keine weiteren Fahrgäste</p>}
            >
              {passengers.map((passenger) => (
                <PassengerListRow key={passenger.id} passenger={passenger} onUpdate={() => removeItem(passenger.id)} />
              ))}
            </InfiniteScroll>
          </StyledList>
        )
      ) : null}

      {(isFetching || status === 'pending') && <LoadingSpinner />}
      {error && <div>Es ist ein Fehler aufgetreten</div>}
    </Wrapper>
  );
};
