import styled from 'styled-components';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useBookingCompletionsAPI } from 'api/controllers/BookingCompletionsAPI';
import { LoadingSpinner } from 'components/common/loaders/LoadingSpinner';
import { Info, List, ListHead } from 'components/common/atoms/List';
import { Colors } from 'styles/Colors';
import { SectionTitle } from 'components/common/atoms/Form';
import { ButtonPrimary, ButtonSecondary, ButtonTertiary } from 'components/common/inputs/Button';
import { Plus } from 'components/icons/Plus';
import CreateBookingCompletionDialog from 'components/common/elements/accounting/CreateBookingCompletionDialog';
import { Trash } from 'components/icons/Trash';
import { Body4, Body5Bold } from 'styles/FontStyles';
import { useShowDialog } from 'state/DialogState';
import { IBookingCompletion } from 'api/models/BookingCompletion';
import { Alert } from 'components/icons/Alert';
import { Excel } from 'components/icons/Excel';
import { ExcelButton } from 'components/common/elements/ExcelButton';
import { downloadBlob } from 'utils/downloadUtils';
import { BREAKPOINT_XL } from 'styles/Breakpoints';
import { useMatchBreakpoint } from 'hooks/useMatchBreakpoint';
import { formatName } from 'utils/nameUtils';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { LatestBookingCompletionState } from 'state/LatestBookingCompletionState';
import { getBookingMonthEndDate } from 'utils/dateUtils';
import { CollapsibleRow } from 'components/common/atoms/CollapsibleRow';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  --list-template: 5rem 1fr 3.5rem 4rem 5rem 10rem;
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
`;

const StyledList = styled(List)`
  --list-template: 1fr 1.5fr 1.5rem;

  ${BREAKPOINT_XL} {
    --list-template: 1fr 1.5fr 2fr 1.5rem;
  }

  ${Info} {
    em {
      font-style: normal;
      color: ${Colors.signalRed900};
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
  padding-block: 0.75rem;

  ${BREAKPOINT_XL} {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }

  p {
    ${Body4};
    color: ${Colors.grey700};
    text-align: start;
    max-inline-size: 750px;
  }
`;

const TrashButton = styled(ButtonTertiary)`
  color: ${Colors.signalRed900};
  border-color: ${Colors.signalRed900};

  transition: border-color 150ms ease-out, background-color 150ms ease-out, color 150ms ease-out;

  @media (hover: hover) {
    :hover {
      color: ${Colors.textInverted};
      border-color: ${Colors.signalRed900};
      background-color: ${Colors.signalRed900};
    }
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  align-self: flex-start;

  ${Body5Bold};
  color: ${Colors.primary};
  text-align: start;
`;

const AlertWrapper = styled(LoadingWrapper)`
  color: ${Colors.signalRed900};
`;

const BookingCompletionItem = memo((props: { bookingCompletion: IBookingCompletion; onDelete: () => void }) => {
  const { bookingCompletion, onDelete } = props;

  const isDesktop = useMatchBreakpoint();
  const showDialog = useShowDialog();

  const bookingCompletionsAPI = useBookingCompletionsAPI();

  const latestBookingCompletion = useRecoilValue(LatestBookingCompletionState);
  const isLatest = useMemo(() => {
    return bookingCompletion && latestBookingCompletion && bookingCompletion.id === latestBookingCompletion.id;
  }, [bookingCompletion, latestBookingCompletion]);

  const expandedContent = useMemo(
    () => (
      <>
        <ContentWrapper>
          <p>
            Der Buchungsabschluss verhindert, dass Daten vor dem Buchungstag geändert werden können. Um Korrekturen vorzunehmen, muss der
            Buchungsabschluss gelöscht werden.
          </p>
          <TrashButton
            disabled={!isLatest}
            onClick={(e: MouseEvent) => {
              e.stopPropagation();

              showDialog({
                headline: 'Buchungsabschluss löschen',
                body: 'Möchten Sie den Buchungsabschluss wirklich löschen? Alle seine erstellten Abrechnungen werden dadurch gelöscht.',
                buttons: (
                  <>
                    <ButtonSecondary onClick={() => showDialog(null)}>Abbrechen</ButtonSecondary>
                    <TrashButton
                      onClick={() => {
                        showDialog({ loading: true }, true);
                        bookingCompletionsAPI
                          .deleteBookingCompletion(bookingCompletion.id)
                          .then(() => onDelete())
                          .catch(() => {})
                          .finally(() => showDialog(null));
                      }}
                    >
                      <Trash /> Löschen
                    </TrashButton>
                  </>
                ),
              });
            }}
          >
            <Trash /> Löschen
          </TrashButton>
        </ContentWrapper>
        {bookingCompletion.state === 'completed' && (
          <ContentWrapper>
            <ExcelButton
              onClick={(e: any) => {
                e.stopPropagation();

                bookingCompletionsAPI
                  .downloadEmployeeInvoices(bookingCompletion.id)
                  .then((res) => {
                    const filename = (res.headers['content-disposition'] || '').replace(/.*filename="(.+)"/g, '$1');
                    downloadBlob(res.data, filename);
                  })
                  .catch(() => {});
              }}
            >
              <Excel />
              Lohnabrechnungen{' '}
              {getBookingMonthEndDate(bookingCompletion.timestamp)
                .toLocaleDateString(undefined, { year: '2-digit', month: 'short' })
                .replace(/\./g, '')}{' '}
              exportieren (.xlsx)
            </ExcelButton>
            <ExcelButton
              onClick={(e: any) => {
                e.stopPropagation();

                bookingCompletionsAPI
                  .downloadCustomerInvoices(bookingCompletion.id)
                  .then((res) => {
                    const filename = (res.headers['content-disposition'] || '').replace(/.*filename="(.+)"/g, '$1');
                    downloadBlob(res.data, filename);
                  })
                  .catch(() => {});
              }}
            >
              <Excel />
              Umsatzliste{' '}
              {getBookingMonthEndDate(bookingCompletion.timestamp)
                .toLocaleDateString(undefined, { year: '2-digit', month: 'short' })
                .replace(/\./g, '')}{' '}
              exportieren (.xlsx)
            </ExcelButton>
          </ContentWrapper>
        )}
      </>
    ),
    [isLatest, bookingCompletionsAPI, bookingCompletion.id, bookingCompletion.state, bookingCompletion.timestamp, onDelete, showDialog],
  );

  const listItemContent = useMemo(
    () => (
      <>
        <Info>
          <span>
            {bookingCompletion.timestamp && new Date(bookingCompletion.timestamp).toLocaleDateString(undefined, { dateStyle: 'medium' })}
          </span>
          {!isDesktop && bookingCompletion.state === 'in_progress' && (
            <LoadingWrapper>
              <LoadingSpinner />
            </LoadingWrapper>
          )}
          {!isDesktop && bookingCompletion.state === 'completed_with_error' && (
            <AlertWrapper>
              <Alert />
            </AlertWrapper>
          )}
        </Info>
        <Info>
          <span>
            {(bookingCompletion.createdBy?.employee && formatName(bookingCompletion.createdBy.employee)) ||
              bookingCompletion.createdBy?.email}
          </span>
        </Info>
        {isDesktop && (
          <InfoWrapper>
            {bookingCompletion.state === 'in_progress' && (
              <LoadingWrapper>
                <span>Abrechnungen werden erstellt</span>
                <LoadingSpinner />
              </LoadingWrapper>
            )}
            {bookingCompletion.state === 'completed_with_error' && (
              <AlertWrapper>
                <Alert />
                <span>Fehler beim Erstellen der Abrechnungen</span>
              </AlertWrapper>
            )}

            {bookingCompletion.comment && <Info>{bookingCompletion.comment}</Info>}
          </InfoWrapper>
        )}
      </>
    ),
    [isDesktop, bookingCompletion.state, bookingCompletion.timestamp, bookingCompletion.comment, bookingCompletion.createdBy],
  );

  return <CollapsibleRow content={expandedContent}>{listItemContent}</CollapsibleRow>;
});

export default function AccountingHome() {
  const [loading, setLoading] = useState(false);
  const [bookingCompletions, setBookingCompletions] = useState<IBookingCompletion[] | null>(null);
  const setLatestBookingCompletion = useSetRecoilState(LatestBookingCompletionState);
  const bookingCompletionsAPI = useBookingCompletionsAPI();

  const [showCreateBookingCompletionDialog, setShowCreateBookingCompletionDialog] = useState(false);

  const isDesktop = useMatchBreakpoint();

  const updateTimerRef = useRef<any>(null);

  const fetchBookingCompletions = useCallback(() => {
    setLoading(true);
    setBookingCompletions(null);
    if (updateTimerRef.current) {
      clearTimeout(updateTimerRef.current);
      updateTimerRef.current = null;
    }
    bookingCompletionsAPI
      .getBookingCompletions({ offset: 0, limit: -1 })
      .then((res) => {
        const completions = res.data.rows || [];
        setBookingCompletions(completions);
        setLatestBookingCompletion(completions[0] || null);

        // poll first completion if in progress
        if (completions[0]?.state === 'in_progress') {
          const firstCompletionId = completions[0]?.id;
          const pollCompletion = () => {
            bookingCompletionsAPI
              .getBookingCompletionById({ id: firstCompletionId })
              .then((res) => {
                if (res.data.state !== 'in_progress') {
                  setBookingCompletions((state) => [res.data, ...(state || []).filter(({ id }) => id !== firstCompletionId)]);
                  updateTimerRef.current = null;
                } else {
                  updateTimerRef.current = setTimeout(pollCompletion, 5000);
                }
              })
              .catch(() => (updateTimerRef.current = null));
          };
          updateTimerRef.current = setTimeout(pollCompletion, 5000);
        }
      })
      .catch(() => setBookingCompletions(null))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchBookingCompletions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bookingCompletionsList = useMemo(
    () => (
      <StyledList>
        <ListHead>
          <div>Buchungstag</div>
          <div>Erstellt von</div>
          {isDesktop && <div>Anmerkungen</div>}
        </ListHead>

        {loading && <LoadingSpinner />}

        {!loading &&
          bookingCompletions?.map((bookingCompletion) => (
            <BookingCompletionItem key={bookingCompletion.id} bookingCompletion={bookingCompletion} onDelete={fetchBookingCompletions} />
          ))}
        {!loading && !(bookingCompletions || []).length && <p style={{ padding: '.5rem' }}>Kein Buchungsabschluss gesetzt</p>}
      </StyledList>
    ),
    [isDesktop, loading, bookingCompletions, fetchBookingCompletions],
  );

  const createBookingCompletionDialog = useMemo(() => {
    const lastTimestamp = bookingCompletions?.[0]?.timestamp;
    return (
      <CreateBookingCompletionDialog
        open={showCreateBookingCompletionDialog}
        setOpen={setShowCreateBookingCompletionDialog}
        lastTimestamp={bookingCompletions?.[0]?.timestamp}
        onSubmit={(data, setLoading) => {
          setLoading(true);
          bookingCompletionsAPI
            .createBookingCompletion(data)
            .then(() => fetchBookingCompletions())
            .catch(() => {})
            .finally(() => {
              setLoading(false);
              setShowCreateBookingCompletionDialog(false);
            });
        }}
      />
    );
  }, [
    showCreateBookingCompletionDialog,
    setShowCreateBookingCompletionDialog,
    bookingCompletions,
    bookingCompletionsAPI,
    fetchBookingCompletions,
  ]);

  return (
    <Wrapper>
      <HeaderRow>
        <SectionTitle>Abrechnungen</SectionTitle>

        <ButtonPrimary onClick={() => setShowCreateBookingCompletionDialog(true)}>
          <Plus /> Buchungsabschluss erstellen
        </ButtonPrimary>
      </HeaderRow>

      {bookingCompletionsList}

      {createBookingCompletionDialog}
    </Wrapper>
  );
}
