import { FC } from 'react';
import styled from 'styled-components';
import { ICustomerCategory } from 'api/models/CustomerCategory';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2rem;
`;

interface ICustomerCategoryDetailsProps {
  customerCategory: ICustomerCategory;
}

export const CustomerCategoryDetails: FC<ICustomerCategoryDetailsProps> = (props) => {
  const { customerCategory } = props;

  return <Wrapper>{customerCategory.name}</Wrapper>;
};
