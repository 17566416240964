import { FC } from 'react';
import styled from 'styled-components';
import { Colors } from 'styles/Colors';
import { Checkmark } from 'components/icons/Checkmark';
import { Field, useFieldValue } from '../inputs/Field';

const Input = styled(Field)<{ large?: boolean }>`
  width: ${({ large }) => (large ? '2rem' : '1.5rem')};
  height: ${({ large }) => (large ? '2rem' : '1.5rem')};
  border-radius: ${({ large }) => (large ? '8px' : '6px')};
  border: 1.5px solid ${Colors.grey700};
  background-color: ${Colors.grey300};
  grid-area: 1 / 1;

  transition: background-color 150ms ease-out, border-color 150ms ease-out;

  :checked {
    background-color: ${Colors.grey900};
    border-color: ${Colors.grey900};
  }

  :disabled {
    background-color: ${Colors.grey700};
    border-color: ${Colors.grey700};
  }
`;

const Label = styled.label<{ large?: boolean; disabled?: boolean }>`
  position: relative;
  display: grid;
  grid-template-columns: ${({ large }) => (large ? '2rem' : '1.5rem')} max-content;
  place-items: center;
  gap: 0.5rem;
  cursor: ${({ disabled = false }) => (disabled ? 'auto' : 'pointer')};
  opacity: ${({ disabled = false }) => (disabled ? 0.5 : 1)};
`;

const StyledCheckmark = styled(Checkmark)<{ large?: boolean }>`
  grid-area: 1 / 1;
  width: ${({ large }) => (large ? '1.5rem' : '1.25rem')};
  height: ${({ large }) => (large ? '1.5rem' : '1.25rem')};
  color: ${Colors.textInverted};
`;

interface CheckboxProps {
  id: string;
  name?: string;
  large?: boolean;
  disabled?: boolean;
  label: string;
  value?: string;
}

export const Checkbox: FC<CheckboxProps> = (props) => {
  const { id, name = id, large = false, disabled = false, label, value } = props;

  const checked = useFieldValue(name);

  return (
    <Label large={large} disabled={disabled}>
      <Input type={'checkbox'} id={id} name={name} value={value} large={large} disabled={disabled} />
      {checked && <StyledCheckmark />}
      {label}
    </Label>
  );
};

interface IPlainCheckbox {
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
  className?: string;
}

export const PlainCheckbox: FC<Partial<CheckboxProps> & IPlainCheckbox> = (props) => {
  const { id, name = id, large = false, disabled = false, label, checked, onCheckedChange, className } = props;

  return (
    <Label large={large} disabled={disabled} className={className} title={label}>
      <Input
        type={'checkbox'}
        id={id}
        name={name}
        large={large}
        disabled={disabled}
        as={'input'}
        checked={checked}
        onChange={({ target }: any) => onCheckedChange(target.checked)}
      />
      {checked && <StyledCheckmark />}
      <span>{label}</span>
    </Label>
  );
};
