import styled from 'styled-components';
import { BREAKPOINT_MD, BREAKPOINT_XL } from 'styles/Breakpoints';

export const GridMain = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 0.875rem;
  margin-inline-start: 1rem;
  margin-inline-end: 1rem;

  ${BREAKPOINT_MD} {
    grid-gap: 1.5rem;
    margin-inline-start: 2rem;
    margin-inline-end: 2rem;
  }

  ${BREAKPOINT_XL} {
    grid-gap: 2rem;
    margin: 0;
  }
`;
