import styled, { css } from 'styled-components';
import { Body2 } from 'styles/FontStyles';
import { Colors } from 'styles/Colors';
import { Clickable } from '../atoms/Clickable';
import { Plus } from 'components/icons/Plus';
import { Minus } from 'components/icons/Minus';
import { useState, useRef, useEffect } from 'react';
import { Checkmark } from 'components/icons/Checkmark';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.25rem;

  inline-size: 100%;
  max-inline-size: 16rem;

  ${Body2};
  color: ${Colors.secondary};
`;

const Label = styled.span<{ inactive?: boolean }>`
  width: 6rem;
  text-align: center;
  ${({ inactive }) =>
    inactive &&
    css`
      color: ${Colors.grey600};
    `};
`;

const Button = styled(Clickable)<{ fixWidth?: boolean }>`
  width: ${({ fixWidth }) => (fixWidth ? '3.5rem' : 'min-content')};
  border-radius: 50%;
  border: 1px solid currentColor;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  :disabled {
    color: ${Colors.grey600};
  }
`;

const EditableInput = styled.input`
  ${Body2};
  color: ${Colors.secondary};
  border-bottom: 1px solid currentColor;
  width: 6rem;
  text-align: center;
`;

const PlaceholderButton = styled.div`
  width: 3.5rem;
`;

const EditableInputWrapper = styled.div`
  position: relative;

  svg {
    position: absolute;
    height: 16px;
    width: 16px;
    top: 0.425rem;
    right: 0;
    cursor: pointer;
  }
`;

interface ITimeSupplementInputProps {
  className?: string;
  value: number;
  setValue: (value: number) => void;
  steps?: { value: number; label: string }[];
  formatValue?: (value: number) => string;
  withLabel?: boolean;
  type?: 'minutes' | 'days';
}

export default function TimeSupplementInput(props: ITimeSupplementInputProps) {
  const {
    type,
    withLabel,
    className,
    value = 0,
    setValue,
    steps = [{ value: 1, label: '' }],
    formatValue = (value) => `${value} Min.`,
  } = props;

  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
        setIsEditing(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // fix the value to the nearest day if it's a days input and we're done editing
    if (type === 'days' && !isEditing) {
      setValue(Math.round(value / 1440) * 1440);
    }
    // eslint-disable-next-line
  }, [isEditing]);

  // Ensure we always have 2 steps,
  const filledSteps = [...steps];
  while (filledSteps.length < 2) {
    filledSteps.push({ value: 0, label: '' });
  }

  return (
    <Wrapper className={className} ref={inputRef}>
      {filledSteps.toReversed().map((step, index) =>
        step.value === 0 ? (
          <PlaceholderButton key={`placeholder-minus-${index}`} />
        ) : (
          <Button
            key={`minus-${step.value}`}
            disabled={value <= 0}
            fixWidth={withLabel}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setValue(Math.max(0, value - step.value));
            }}
          >
            <Minus /> {withLabel ? step.label : ''}
          </Button>
        ),
      )}

      {isEditing ? (
        <EditableInputWrapper>
          <EditableInput
            value={value.toString()}
            onChange={(e) => {
              setValue(Number(e.target.value));
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setIsEditing(false);
              }
            }}
          />
          <Checkmark onClick={() => setIsEditing(false)} />
        </EditableInputWrapper>
      ) : (
        <Label inactive={value <= 0} onClick={() => setIsEditing(true)}>
          {formatValue(value)}
        </Label>
      )}

      {filledSteps.map((step, index) =>
        step.value === 0 ? (
          <PlaceholderButton key={`placeholder-plus-${index}`} />
        ) : (
          <Button
            key={`plus-${step.value}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setValue(value + step.value);
            }}
          >
            <Plus /> {withLabel ? step.label : ''}
          </Button>
        ),
      )}
    </Wrapper>
  );
}
