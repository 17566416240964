import { useEffect, useState } from 'react';
import { EmployeeForm } from 'components/content/employees/EmployeeForm';
import { Breadcrumb } from 'components/common/elements/Breadcrumb';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingSpinner } from 'components/common/loaders/LoadingSpinner';
import { IEmployee } from 'api/models/Employee';
import { useEmployeesAPI } from 'api/controllers/EmployeesAPI';
import { ContentPageLayout } from 'components/common/atoms/Layout';

export const EmployeeEdit = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const employeesAPI = useEmployeesAPI();
  const [employee, setEmployee] = useState<IEmployee | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      employeesAPI
        .getEmployeeById({ id })
        .then((res) => setEmployee(res.data))
        .catch(() => navigate('/404'))
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <ContentPageLayout>
      <Breadcrumb
        items={[
          {
            title: 'Mitarbeiter Übersicht',
            href: '/employees',
          },
        ]}
      />
      {loading ? <LoadingSpinner /> : <EmployeeForm employee={employee || undefined} />}
    </ContentPageLayout>
  );
};
