import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { TourListSettingState } from 'state/TourListSettingState';
import { Colors } from 'styles/Colors';
import { Body4 } from 'styles/FontStyles';

const FilterDescription = styled.p`
  ${Body4};
  color: ${Colors.grey700};
  margin-block-end: 1rem;
`;

const getDirectionText = (directions: string[] = []): string =>
  directions.length === 1 ? (directions[0] === 'return' ? 'Rückfahrten' : 'Hinfahrten') : 'Fahrten';

const getConflictText = (conflictsOnly?: boolean): string => (conflictsOnly ? ' mit Konflikten' : '');

const getRegionText = (regionsTitles: (string | undefined)[]): string =>
  regionsTitles.length > 0 ? `${regionsTitles.length === 1 ? 'der Region' : 'den Regionen'} ${regionsTitles.join(', ')}` : 'allen Regionen';

const getCustomerFilterText = (customerIds: (string | number)[] = []): string =>
  customerIds.length > 0
    ? `Es wird nach ${customerIds.length === 1 ? 'einer Einrichtung' : `${customerIds.length} Einrichtungen`} gefiltert. `
    : '';

const getSearchQueryText = (searchQuery: string = ''): string => (searchQuery.length > 0 ? `Es wird nach "${searchQuery}" gesucht.` : '');

const FilterDescriptionComponent = () => {
  const {
    filters: { regions, directions, conflictsOnly, customerIds },
    query,
  } = useRecoilValue(TourListSettingState);

  return (
    <FilterDescription>
      <span>
        Es werden {getDirectionText(directions)}
        {getConflictText(conflictsOnly)} aus {getRegionText(regions)} angezeigt.
      </span>
      {getCustomerFilterText(customerIds)}
      {getSearchQueryText(query)}
    </FilterDescription>
  );
};

export default FilterDescriptionComponent;
