import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Section } from 'components/common/atoms/Form';
import styled, { css } from 'styled-components';
import { Body2 } from 'styles/FontStyles';
import { LoadingProgressUI, LoadingSpinner } from 'components/common/loaders/LoadingSpinner';
import { IPassenger } from 'api/models/Passenger';
import { ICustomer } from 'api/models/Customer';
import { useAvailableDragDropItems } from '../tourplan/DragDropContext';
import { useSchedulesAPI } from 'api/controllers/SchedulesAPI';
import { TTourplanScheduledTour } from 'components/content/customers/tourplan/tiles/TourplanScheduledTourTile';
import { useForm } from 'react-hook-form';
import { Form } from 'components/common/inputs/Form';
import { useNavigate } from 'react-router-dom';
import { getCurrentDayDate, getISODate } from 'utils/dateUtils';
import { IScheduledTourException } from 'api/models/ScheduledTour';
import { IOrder } from 'api/models/Order';
import { useCustomersAPI } from 'api/controllers/CustomersAPI';
import { AxiosResponse } from 'axios';
import { IChangeTourSubmitPayload } from 'components/common/elements/tours/tour-details/ChangeTourDialog';
import { IEmployee } from 'api/models/Employee';
import { usePassengersAPI } from 'api/controllers/PassengersAPI';
import TourplanDesktop from './TourplanDesktop';
import { useMatchBreakpoint } from 'hooks/useMatchBreakpoint';
import TourplanMobile from './TourplanMobile';
import { BREAKPOINT_XL } from 'styles/Breakpoints';
import useValueChange from 'hooks/useValueChange';
import { useShowDialog } from 'state/DialogState';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
  flex: 1;

  position: relative;

  ${Body2};
`;

const FormWrapper = styled.div<{ overlayVisible?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;

  margin: -2.5rem;
  padding: 2.5rem;

  & > *:only-child {
    flex-grow: 1;
  }

  transition: margin-inline-end 150ms ease-out;

  ${({ overlayVisible = false }) =>
    overlayVisible &&
    css`
      ${BREAKPOINT_XL} {
        margin-inline-end: 20rem;
      }
    `};
`;

interface ICustomerTourplanViewProps {
  customer: ICustomer | null;
  direction?: 'outwards' | 'return';
}

export const CustomerTourplanView: FC<ICustomerTourplanViewProps> = (props) => {
  const { customer, direction = 'outwards' } = props;

  const showDialog = useShowDialog();
  const navigate = useNavigate();
  const isDesktop = useMatchBreakpoint() || true;

  const splitTourId = useMemo(() => new URLSearchParams(window.location.search).get('split-tour') || undefined, []);

  const customersAPI = useCustomersAPI();
  const passengersAPI = usePassengersAPI();
  const schedulesAPI = useSchedulesAPI();

  const [timestamp, setTimestamp] = useState(
    getCurrentDayDate(new URLSearchParams(window.location.search).get('date') || undefined).toISOString(),
  );

  const form = useForm({
    defaultValues: {
      schedules: [] as TTourplanScheduledTour[],
      schedulesToDelete: [] as TTourplanScheduledTour[],
    },
  });

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submittingState, setSubmittingState] = useState<{ progress: number; count: number } | null>(null);

  const [, setAvailablePassengers] = useAvailableDragDropItems<IPassenger>();
  const [orders, setOrders] = useState<IOrder[] | null>(null);
  const [splitScheduledTour, setSplitScheduledTour] = useState<TTourplanScheduledTour | null>(null);

  const isExceptionInRange = useCallback(
    (exception: IScheduledTourException) =>
      getCurrentDayDate(exception.startDate) <= getCurrentDayDate(timestamp) &&
      (!exception.endDate || getCurrentDayDate(exception.endDate) >= getCurrentDayDate(timestamp)),
    [timestamp],
  );

  // adjust timestamp query param on timestamp change
  useValueChange(() => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('date', getISODate(getCurrentDayDate(timestamp || undefined)));
    navigate({ search: searchParams.toString() }, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timestamp]);

  const initForm = useCallback(() => {
    if (customer?.id && timestamp) {
      setLoading(true);
      Promise.all(
        [
          customersAPI.getCustomerScheduledTours({ id: customer.id, limit: -1, timestamp }),
          customersAPI.getCustomerOrders({ id: customer.id, limit: -1, direction, timestamp, includePassengers: true }),
        ].filter(Boolean) as unknown as AxiosResponse[],
      )
        .then(async (res) => {
          const scheduledToursPassengerIds = ((res[0]?.data?.rows || []) as TTourplanScheduledTour[]).flatMap(({ passengers = [] }) =>
            passengers.map(({ id }) => id.toString()),
          );
          const ordersPassengerIds = ((res[1]?.data?.rows || []) as IOrder[]).flatMap(({ tours = [] }) =>
            tours.flatMap(({ passengers = [] }) => passengers.map(({ id }) => id.toString())),
          );

          const passengers = await passengersAPI.getPassengers({
            ids: [...scheduledToursPassengerIds, ...ordersPassengerIds].filter((item, index, array) => array.indexOf(item) === index),
            offset: 0,
            limit: -1,
          });

          return [...res, passengers];
        })
        .then((res) => {
          const passengers = (res[2].data.rows || []) as IPassenger[];
          const schedules = (res[0].data.rows as TTourplanScheduledTour[])
            .filter(
              (tour) =>
                tour.direction === direction && (!splitTourId ? !tour.isException : !(tour.exceptions || []).some(isExceptionInRange)),
            )
            .map((schedule) => ({
              ...schedule,
              passengers: (schedule.passengers || []).map((passenger) => ({
                ...(passengers.find(({ id }) => id?.toString() === passenger.id?.toString()) || {}),
                ...passenger,
              })),
            }));

          const orders: IOrder[] = res[1].data.rows || [];

          let filteredSchedules = null;
          if (!splitTourId) {
            filteredSchedules = schedules;
            // set non-scheduled passengers as available passengers
            setAvailablePassengers(
              passengers.filter((passenger) => {
                return !schedules
                  .map(({ passengers = [] }) => passengers.find(({ id }) => id.toString() === passenger.id.toString()))
                  .filter(Boolean)[0];
              }),
            );
            // add passenger data to orders payload
            setOrders(
              orders.map((order) => ({
                ...order,
                tours: (order.tours || []).map((tour) => ({
                  ...tour,
                  passengers: (tour.passengers || []).map((passenger) => ({
                    ...(passengers.find(({ id }) => id?.toString() === passenger.id?.toString()) || {}),
                    ...passenger,
                  })),
                })),
              })),
            );
            setSplitScheduledTour(null);
          } else {
            const splitTour = schedules.find(({ id }) => id?.toString() === splitTourId) || null;
            filteredSchedules = schedules.filter(
              (schedule) =>
                schedule.id?.toString() !== splitTourId && // exclude splitTour
                schedule.daysOfWeek?.split(',').includes(getCurrentDayDate(timestamp).getDay().toString()), // only show tours for current timestamp
            );
            setSplitScheduledTour(splitTour);
            setAvailablePassengers(
              (splitTour?.passengers || []).map((passenger) => ({
                ...(passengers.find(({ id }) => id?.toString() === passenger.id?.toString()) || {}),
                ...passenger,
              })),
            );
            setOrders(orders);
          }

          // update form
          const formSchedules = filteredSchedules.map((schedule) => ({
            ...schedule,
            passengers: (schedule.passengers || [])
              .sort((a, b) => (a.ScheduledTourPassenger?.position || 0) - (b.ScheduledTourPassenger?.position || 0))
              .map((passenger, index) => ({
                ...passenger,
                position: index,
              })),
            selectedDayOfWeek: timestamp,
          }));
          form.setValue('schedules', formSchedules);
          form.setValue('schedulesToDelete', [] as TTourplanScheduledTour[]);
        })
        .catch(() => {})
        .finally(() => setLoading(false));
    }
  }, [customer, direction, timestamp, splitTourId, customersAPI, form, isExceptionInRange, passengersAPI, setAvailablePassengers]);

  useEffect(() => {
    initForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer, direction, timestamp, splitTourId]);

  const handleSplitTour = useCallback(
    async (values: {
      schedules: TTourplanScheduledTour[];
      schedulesToDelete: TTourplanScheduledTour[];
      config?: IChangeTourSubmitPayload;
      splitTourUpdate?: { driver: IEmployee | null; companion: IEmployee | null };
    }) => {
      if (splitTourId && splitScheduledTour) {
        const direction = splitScheduledTour.direction;
        const splitTourPassengerIds = (splitScheduledTour.passengers || []).map(({ id }) => id);
        const schedulesToUpdate = values.schedules.filter(
          (schedule) => schedule.hasChanged && (schedule.passengers || []).some(({ id }) => splitTourPassengerIds.includes(id?.toString())),
        );

        const passengersInSplitTour = (splitScheduledTour.passengers || []).filter(
          ({ id }) =>
            !schedulesToUpdate.some(({ passengers = [] }) => passengers.some((passenger) => passenger.id?.toString() === id?.toString())),
        );

        if (passengersInSplitTour.length > 0) {
          schedulesToUpdate.push({
            ...splitScheduledTour,
            passengers: passengersInSplitTour,
            // add driver and companion info
            ...(values.splitTourUpdate
              ? {
                  driverId: values.splitTourUpdate.driver?.id,
                  companionId: values.splitTourUpdate.companion?.id,
                }
              : {}),
          });
        }

        // handle split tour
        setSubmitting(true);
        await schedulesAPI
          .splitSchedule({
            splitTourId,
            schedulesToUpdate: schedulesToUpdate.map((schedule) => ({
              ...schedule,
              daysOfWeek: Object.keys((direction === 'return' ? schedule.departureDate : schedule.arrivalDate) || {}).join(','),
              passengers: (schedule.passengers || []).map(
                (item, index) =>
                  ({
                    id: item.id, // submit id only (better performance)
                    ScheduledTourPassenger: {
                      ...item.ScheduledTourPassenger,
                      position: index,
                    },
                  } as IPassenger),
              ),
              // reset populated data for transfer
              driver: undefined,
              companion: undefined,
              customer: undefined,
              destinations: undefined,
              exceptions: undefined,
            })),
            splitTourStartDate: getCurrentDayDate(values.config?.startDate || undefined).toISOString(),
            splitTourEndDate:
              values.config?.endDate || splitScheduledTour.endDate
                ? getCurrentDayDate((values.config?.endDate || splitScheduledTour.endDate)!).toISOString()
                : undefined,
          })
          .then((res) => {
            navigate(
              `/tours/${
                (passengersInSplitTour.length === 0 && schedulesToUpdate[0]?.id) || res.data.originalScheduleId || splitTourId
              }?date=${getCurrentDayDate(values.config?.startDate || undefined).toISOString()}`,
            );
          })
          .catch((err) => {
            console.error('could not split tour', err);
            // show error message
            showDialog({
              headline: 'Fahrt aufteilen',
              body: 'Beim Aufteilen der Fahrt ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
            });
          })
          .finally(() => setSubmitting(false));
      }
    },
    [setSubmitting, schedulesAPI, navigate, splitTourId, splitScheduledTour, showDialog],
  );

  const handleSaveScheduledTours = useCallback(
    async (values: {
      schedules: TTourplanScheduledTour[];
      schedulesToDelete: TTourplanScheduledTour[];
      config?: IChangeTourSubmitPayload;
      splitTourUpdate?: { driver: IEmployee | null; companion: IEmployee | null };
    }) => {
      const { schedules = [], schedulesToDelete = [] } = values;
      const schedulesToUpdate = schedules.filter(({ hasChanged = false }) => hasChanged);

      // update schedules one by one with loading progress UI
      setSubmitting(true);
      setSubmittingState({ progress: 0, count: schedulesToUpdate.length + schedulesToDelete.length });

      const errors: string[] = [];
      let hasErrorForbidden = false;
      await Promise.all([
        ...schedulesToUpdate.map((schedule) => {
          return schedulesAPI
            .updateSchedule({
              timestamp,
              schedule: {
                ...schedule,
                daysOfWeek: Object.keys((direction === 'return' ? schedule.departureDate : schedule.arrivalDate) || {}).join(','),
                passengers: (schedule.passengers || []).map(
                  (item, index) =>
                    ({
                      id: item.id, // submit id only (better performance)
                      ScheduledTourPassenger: {
                        ...item.ScheduledTourPassenger,
                        position: index,
                      },
                    } as IPassenger),
                ),
                // reset populated data for transfer
                driver: undefined,
                companion: undefined,
                customer: undefined,
                destinations: undefined,
                exceptions: undefined,
              },
            })
            .then((res) => {
              console.log(`${schedule.id !== undefined ? 'updated' : 'created'} scheduled tour`, res.data);
            })
            .catch((err) => {
              console.error(`error ${schedule.id !== undefined ? 'updating' : 'creating'} scheduled tour`, err);
              errors.push(`${schedule.name} (${schedule.direction === 'return' ? 'Rück' : 'Hin'})`);
              if (err?.response?.status === 403) {
                hasErrorForbidden = true;
              }
            })
            .finally(() =>
              setSubmittingState((state) => ({
                progress: (state?.progress || 0) + 1,
                count: state?.count || schedulesToUpdate.length + schedulesToDelete.length,
              })),
            );
        }),
        ...(schedulesToDelete.map(({ id }) => id).filter(Boolean) as string[]).map((scheduleToDelete) => {
          return schedulesAPI
            .removeSchedule({
              timestamp,
              scheduleToDelete,
            })
            .then((res) => {
              console.log(`removed scheduled tour`, res.data);
            })
            .catch((err) => {
              console.error(`error removing scheduled tour`, err);
              errors.push(`Entfernte Fahrt ${scheduleToDelete}`);
              if (err?.response?.status === 403) {
                hasErrorForbidden = true;
              }
            })
            .finally(() =>
              setSubmittingState((state) => ({
                progress: (state?.progress || 0) + 1,
                count: state?.count || schedulesToUpdate.length + schedulesToDelete.length,
              })),
            );
        }),
      ]).catch(() => {});

      // dismiss progress UI
      setSubmittingState(null);
      setSubmitting(false);

      if (errors.length > 0) {
        console.log('schedules with errors:', errors);

        // show error message
        showDialog({
          headline: 'Fahrten speichern',
          body: `Bei ${errors.length} von ${
            schedulesToUpdate.length + schedulesToDelete.length
          } Fahrten ist ein Fehler aufgetreten. Folgende Fahrten konnten nicht gespeichert werden: ${errors.sort().join(', ')}.${
            hasErrorForbidden ? `\nÜberprüfen Sie bitte auch den gesetzten Buchungsabschluss!` : ''
          }`,
        });
      } else {
        // successful -> reload form
        initForm();
      }
    },
    [setSubmitting, schedulesAPI, direction, timestamp, showDialog, initForm],
  );

  const onSubmit = useCallback(
    (values: {
      schedules: TTourplanScheduledTour[];
      schedulesToDelete: TTourplanScheduledTour[];
      config?: IChangeTourSubmitPayload;
      splitTourUpdate?: { driver: IEmployee | null; companion: IEmployee | null };
    }) => {
      if (splitTourId !== undefined) {
        return handleSplitTour(values);
      } else {
        return handleSaveScheduledTours(values);
      }
    },
    [splitTourId, handleSplitTour, handleSaveScheduledTours],
  );

  if (loading) {
    return (
      <Section>
        <Wrapper>
          <h2>Fahrtenplanung</h2>
          <LoadingSpinner />
        </Wrapper>
      </Section>
    );
  }

  return (
    <FormWrapper overlayVisible>
      <Form form={form} onSubmit={onSubmit}>
        {isDesktop ? (
          <TourplanDesktop
            customer={customer}
            orders={orders}
            direction={direction}
            timestamp={timestamp}
            setTimestamp={setTimestamp}
            onSubmit={onSubmit}
            submitting={submitting}
            splitTour={splitScheduledTour}
          />
        ) : (
          <TourplanMobile
            customer={customer}
            orders={orders}
            direction={direction}
            timestamp={timestamp}
            setTimestamp={setTimestamp}
            onSubmit={onSubmit}
            submitting={submitting}
            splitTour={splitScheduledTour}
          />
        )}
      </Form>
      {submittingState && <LoadingProgressUI title={`Fahrten speichern`} progress={submittingState} />}
    </FormWrapper>
  );
};
