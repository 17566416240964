import { TItemsInAdditionState, useDragDropItemsMoveState, useDropOverItems, useMoveItems } from '../DragDropContext';
import { ButtonPrimary } from 'components/common/inputs/Button';
import { Plus } from 'components/icons/Plus';
import { TourDragItem, TourDragItemContent } from 'components/content/customers/tourplan/tiles/TourplanTourTile';
import styled from 'styled-components';
import { memo } from 'react';

const DropOverItemContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
`;

export const DropOverItemsBaseView = memo((props: { tour?: Partial<any> }) => {
  const { tour } = props;
  const dropOverItems = useDropOverItems(tour || 'revert');

  return (
    <TourDragItem dragging={dropOverItems.length > 0} empty>
      <TourDragItemContent>
        <DropOverItemContent>
          <Plus /> {dropOverItems.length > 0 ? `${dropOverItems.length} hinzufügen` : 'hier reinziehen'}
        </DropOverItemContent>
      </TourDragItemContent>
    </TourDragItem>
  );
});

export const DropOverItems = memo((props: { tour?: Partial<any>; onDropListener?: (props: TItemsInAdditionState<any, any>) => void }) => {
  const { tour, onDropListener } = props;

  const [moveItemsState] = useDragDropItemsMoveState();
  const moveItems = useMoveItems({ onDropListener });

  if (moveItemsState !== null && moveItemsState.srcContainer !== tour) {
    return (
      <ButtonPrimary
        onClick={(e: Event) => {
          e.stopPropagation();
          moveItems(tour || 'revert');
        }}
      >
        {typeof tour === 'object' ? `In ${tour.name}` : 'Hierher'} verschieben
      </ButtonPrimary>
    );
  }

  return <DropOverItemsBaseView tour={tour} />;
});
