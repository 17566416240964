import { ButtonTertiary } from 'components/common/inputs/Button';
import { useState } from 'react';
import styled from 'styled-components';
import { Body1Bold, Body2 } from 'styles/FontStyles';
import { Colors } from 'styles/Colors';
import TimeSupplementInput from 'components/common/inputs/TimeSupplementInput';
import { IPassenger } from 'api/models/Passenger';
import { formatName } from 'utils/nameUtils';

const Wrapper = styled.div`
  position: absolute;
  inset: 0;
  z-index: 5;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  padding: 2rem;

  ${Body2};
  text-align: center;
  color: ${Colors.secondary};

  b {
    ${Body1Bold};
  }

  background-color: ${Colors.white50};
  border: 1px solid ${Colors.secondary};
  border-radius: 0.5rem;

  ${ButtonTertiary} {
    border-color: ${Colors.secondary};
    color: ${Colors.secondary};
    flex-shrink: 0;
  }
`;

const PassengerAdditionTimeSupplementInput = styled(TimeSupplementInput)`
  margin-block: 0.5rem 1rem;
`;

const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

interface IPassengerAdditionOverlayProps {
  passengersInAdditionState: Partial<IPassenger>[];
  initialTimeSupplementAmount?: number;
  onSubmit: (amount: number) => void;
  onCancel?: () => void;
}
export default function PassengerAdditionOverlay(props: IPassengerAdditionOverlayProps) {
  const { passengersInAdditionState, initialTimeSupplementAmount = 10, onSubmit, onCancel } = props;

  const [passengerInAdditionStateTimeSupplement, setPassengerInAdditionStateTimeSupplement] = useState(initialTimeSupplementAmount);

  return (
    <Wrapper>
      <ContentBlock>
        <span>Mehraufwand für den Transport von</span>
        <b>{passengersInAdditionState.map(formatName).join('; ')}</b>
        <span>angeben</span>
      </ContentBlock>

      <PassengerAdditionTimeSupplementInput
        value={passengerInAdditionStateTimeSupplement}
        setValue={setPassengerInAdditionStateTimeSupplement}
      />

      <ContentBlock>
        <ButtonTertiary onClick={() => onSubmit(passengerInAdditionStateTimeSupplement)}>Bestätigen</ButtonTertiary>
        {onCancel && <ButtonTertiary onClick={onCancel}>Abbrechen</ButtonTertiary>}
      </ContentBlock>
    </Wrapper>
  );
}
