// General
import { FC } from 'react';

// Components
import { CardColumn, CardRow, CardRowHeadline, CardsWrapper, CardTable, Card } from 'components/common/atoms/Card';

// Types
import { IPassenger } from 'api/models/Passenger';
import { formatName } from 'utils/nameUtils';

export const PassengerDetailsInformation: FC<{ passenger: IPassenger }> = (props) => {
  const { passenger } = props;

  const { hasWheelchair, customer, loadingTime } = passenger;

  return (
    <CardsWrapper>
      <Card>
        <CardTable>
          <CardRow>
            <CardRowHeadline>Name</CardRowHeadline>
            <CardColumn>{formatName(passenger) || '-'}</CardColumn>
          </CardRow>
          <CardRow>
            <CardRowHeadline>Barrierefreiheit</CardRowHeadline>
            <CardColumn>{hasWheelchair ? 'Rollstuhlfahrer' : 'Läufer'}</CardColumn>
          </CardRow>
          <CardRow>
            <CardRowHeadline>Einrichtung</CardRowHeadline>
            <CardColumn>{customer?.displayName || '-'}</CardColumn>
          </CardRow>
          <CardRow>
            <CardRowHeadline>Ladezeiten</CardRowHeadline>
            <CardColumn>{(loadingTime && `${loadingTime} Min.`) || '-'}</CardColumn>
          </CardRow>
        </CardTable>
      </Card>

      <Card>
        <CardTable>
          <CardRow>
            <CardRowHeadline>Anmerkungen</CardRowHeadline>
            <CardColumn>{passenger?.comment || '-'}</CardColumn>
          </CardRow>
        </CardTable>
      </Card>
    </CardsWrapper>
  );
};
