import { Breadcrumb } from 'components/common/elements/Breadcrumb';
import { ContentPageLayout } from 'components/common/atoms/Layout';
import { CustomerOrganizationForm } from 'components/content/customer_organizations/CustomerOrganizationForm';

export const CustomerOrganizationAdd = () => {
  return (
    <ContentPageLayout>
      <Breadcrumb
        items={[
          {
            title: 'Einrichtungen Übersicht',
            href: '/customers',
          },
        ]}
      />
      <CustomerOrganizationForm />
    </ContentPageLayout>
  );
};
