import { memo } from 'react';
import styled, { css } from 'styled-components';
import { FC, PropsWithChildren } from 'react';

const AccordionWrapper = styled.div<{ open: boolean }>`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(0, 1fr);

  //overflow: hidden;

  transition: grid-template-rows 300ms ease-out, overflow 300ms ease-out;

  & > :only-child {
    transition: opacity 300ms ease-out, visibility 300ms ease-out;
  }

  ${({ open = false }) =>
    !open &&
    css`
      grid-template-rows: minmax(0, 0fr);
      overflow: hidden;

      & > :only-child {
        opacity: 0;
        visibility: hidden;
      }
    `};
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

interface ICollapsableProps {
  open: boolean;
  className?: string;
}

export const Collapsible: FC<PropsWithChildren<ICollapsableProps>> = memo((props) => {
  const { open = false, className, children } = props;

  return (
    <AccordionWrapper open={open} className={className}>
      <Body>{children}</Body>
    </AccordionWrapper>
  );
});
