import { FC, useState } from 'react';
import styled from 'styled-components';
import { Headline2, Headline3 } from 'styles/FontStyles';
import { ButtonPrimary } from 'components/common/inputs/Button';
import { BREAKPOINT_MD } from 'styles/Breakpoints';
import { TextInput } from 'components/common/inputs/TextInput';
import { LoadingSpinner } from 'components/common/loaders/LoadingSpinner';
import { Switch } from 'components/common/inputs/Switch';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Form } from 'components/common/inputs/Form';
import { useVehiclesAPI } from 'api/controllers/VehiclesAPI';
import { IVehicle } from 'api/models/Vehicle';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  ${Headline2};
  margin-bottom: 2.5rem;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2rem;

  margin-bottom: 4rem;
`;

const SectionTitle = styled.h2`
  ${Headline3};
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2rem;

  ${BREAKPOINT_MD} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

const GridRow = styled(Row)`
  ${BREAKPOINT_MD} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
  }
`;

export const VehicleForm: FC<{ vehicle?: IVehicle }> = (props) => {
  const { vehicle = null } = props;

  const navigate = useNavigate();

  const vehiclesAPI = useVehiclesAPI();
  const [isSubmitting, setSubmitting] = useState(false);

  const form = useForm({
    defaultValues: {
      licensePlate: vehicle?.licensePlate || '',
      hasRoomForWheelchairs: vehicle?.hasRoomForWheelchairs || false,
      brandName: vehicle?.brandName || '',
      modelName: vehicle?.modelName || '',
      color: vehicle?.color || '',
      comment: vehicle?.comment || '',
    },
  });

  return (
    <Wrapper>
      <Form
        form={form}
        onSubmit={(values) => {
          console.log('submit', values);

          setSubmitting(true);
          (vehicle?.id ? vehiclesAPI.updateVehicle(vehicle?.id, values) : vehiclesAPI.createVehicle(values))
            .then((res) => {
              console.log(vehicle?.id ? 'update' : 'create', 'vehicle', res.data);
              navigate(`/vehicles/${res.data.id}`, { replace: true });
            })
            .catch((err) => {
              // TODO add generic error message
            })
            .finally(() => setSubmitting(false));
        }}
      >
        <Title>Fahrzeugprofil</Title>

        <Section>
          <SectionTitle>Fahrzeugdaten</SectionTitle>
          <GridRow>
            <Switch id={'hasRoomForWheelchairs'} label={'Rollstuhl-KFZ'} placeholder={'Kann das Fahrzeug Rollstühle transportieren?'} />
          </GridRow>
          <GridRow>
            <TextInput
              id={'licensePlate'}
              placeholder={'Kennzeichen eingeben'}
              label={'Kennzeichen'}
              $plain
              {...form.register('licensePlate', { required: 'Bitte geben Sie ein Kennzeichen an.' })}
            />
          </GridRow>
          <GridRow>
            <TextInput
              id={'brandName'}
              placeholder={'z.B. Mercedes'}
              label={'Marke'}
              $plain
              {...form.register('brandName', { required: 'Bitte geben Sie eine Marke an.' })}
            />
            <TextInput
              id={'modelName'}
              placeholder={'Modell eingeben'}
              label={'Modell'}
              $plain
              {...form.register('modelName', { required: 'Bitte geben Sie ein Modell an.' })}
            />
          </GridRow>
          <GridRow>
            <TextInput optional id={'color'} placeholder={'z.B. Weiß'} label={'Farbe'} $plain />
          </GridRow>

          <Row>
            <TextInput
              optional
              id={'comment'}
              type={'textarea'}
              placeholder={'z.B. Hinweis zu Reparaturmaßnahmen...'}
              label={'Anmerkungen'}
              style={{ flex: 1 }}
            />
          </Row>
        </Section>

        <ButtonPrimary type={'submit'}>{isSubmitting ? <LoadingSpinner /> : 'Speichern'}</ButtonPrimary>
      </Form>
    </Wrapper>
  );
};
