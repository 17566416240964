import { FC } from 'react';
import { ICustomerCategory } from 'api/models/CustomerCategory';
import { ContextOptionsFixed } from '../ContextOptionsFixed';
import { Info, InfoBold, ListRowWrapper, OptionsWrapper, Row, ListRowControlsWrapper } from 'components/common/atoms/List';
import { useHasPermission } from 'state/UserMeState';
import styled from 'styled-components';
import { Trash } from 'components/icons/Trash';
import { Edit } from 'components/icons/Edit';
import { useNavigate } from 'react-router-dom';
import { Link } from 'components/common/atoms/Link';

const TrashIcon = styled(Trash)`
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
`;

const EditIcon = styled(Edit)`
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
`;

interface ICustomerCategoryListRowProps {
  customerCategory: ICustomerCategory;
  onUpdate?: () => void;
}

export const CustomerCategoryListRow: FC<ICustomerCategoryListRowProps> = (props) => {
  const { customerCategory } = props;
  const { id, name } = props.customerCategory;
  const isAdmin = useHasPermission('admin');
  const navigate = useNavigate();

  return (
    <ListRowWrapper>
      <Row>
        <InfoBold>{id || '-'}</InfoBold>
        <Info>
          <Link href={`/customer-categories/${id}`}>{name}</Link>
        </Info>
        <ListRowControlsWrapper>
          <OptionsWrapper>
            <ContextOptionsFixed
              items={
                [
                  {
                    content: (
                      <>
                        <EditIcon /> Bearbeiten
                      </>
                    ),
                    onClick: () => {
                      navigate(`/customer-categories/${customerCategory.id}/edit`);
                    },
                  },
                  isAdmin && {
                    content: (
                      <>
                        <TrashIcon /> Entfernen
                      </>
                    ),
                    onClick: () => {}, //useShowDeleteCustomerCategoryDialog(id),
                  },
                ].filter(Boolean) as []
              }
            />
          </OptionsWrapper>
        </ListRowControlsWrapper>
      </Row>
    </ListRowWrapper>
  );
};
