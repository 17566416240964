import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingSpinner } from 'components/common/loaders/LoadingSpinner';
import { CustomerOrderForm } from 'components/content/customers/orders/CustomerOrderForm';
import { useOrderAPI } from 'api/controllers/OrderAPI';
import { useCustomersAPI } from 'api/controllers/CustomersAPI';
import { ICustomer } from 'api/models/Customer';
import { IOrder } from 'api/models/Order';
import { ContentPageLayout } from 'components/common/atoms/Layout';

export const CustomerOrderEdit = () => {
  const { customerId, id } = useParams();

  const navigate = useNavigate();

  const customersAPI = useCustomersAPI();
  const orderAPI = useOrderAPI();
  const [customer, setCustomer] = useState<ICustomer | null>(null);
  const [order, setOrder] = useState<IOrder | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (customerId && id) {
      setLoading(true);

      Promise.all([customersAPI.getCustomerById({ id: customerId }), orderAPI.getOrderById(id)])
        .then((res) => {
          setCustomer(res[0].data.data);
          setOrder(res[1].data);
        })
        .catch(() => navigate('/404'))
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, id]);

  return (
    <ContentPageLayout>
      {loading ? <LoadingSpinner /> : <CustomerOrderForm customer={customer} order={order || undefined} />}
    </ContentPageLayout>
  );
};
