import { FC } from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import { SearchInput } from 'components/common/inputs/SearchInput';
import useSyncStateWithURL from 'hooks/useSyncStateWithURL';
import { TourListSettingState } from 'state/TourListSettingState';
import { BREAKPOINT_LG, BREAKPOINT_MD, BREAKPOINT_XL } from 'styles/Breakpoints';
import { Colors } from 'styles/Colors';
import { Body3, Body4 } from 'styles/FontStyles';
import { SimpleSwitchSmall } from 'components/common/inputs/Switch';
import { ToursListFilterButton } from 'components/content/tours/ToursListFilterButton';

const FilterRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;

  ${BREAKPOINT_XL} {
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
  }
`;

const FilterRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;

  ${BREAKPOINT_MD} {
    gap: 1rem;
  }
  ${BREAKPOINT_XL} {
    justify-content: space-between;
  }
`;

const StyledSearchInput = styled(SearchInput)`
  ${BREAKPOINT_MD} {
    width: auto;
    flex: 1;
    min-width: 20rem;
  }

  ${BREAKPOINT_LG} {
    width: 100%;
    max-width: 29rem;
  }
`;

const StyledSwitchWrapper = styled.div`
  display: flex;
  gap: 1.25rem;

  ${BREAKPOINT_MD} {
    margin-right: 1rem;
  }
`;

const StyledSwitch = styled(SimpleSwitchSmall)`
  align-self: center;
  flex-direction: row-reverse;
  gap: 0.5rem;

  input:checked {
    background-color: ${Colors.signalRed800};
  }

  ${Body4};
  color: ${Colors.textDefault};

  b {
    ${Body3};
  }
`;

export const ToursListFilter: FC = (props) => {
  const [settings, setSettings] = useRecoilState(TourListSettingState);
  useSyncStateWithURL(TourListSettingState);

  return (
    <FilterRowWrapper>
      <FilterRow>
        <StyledSearchInput
          placeholder={'nach Fahrten suchen'}
          value={settings.query}
          setValue={(value) => {
            setSettings({ ...settings, query: value });
          }}
          onReset={() => {
            setSettings({ ...settings, query: '' });
          }}
        />
        <StyledSwitchWrapper>
          <StyledSwitch
            checked={!settings.showConflicts.generic}
            onValueChange={() =>
              setSettings({ ...settings, showConflicts: { ...settings.showConflicts, generic: !settings.showConflicts.generic } })
            }
            label={'Tour Konflikte'}
          />
          <StyledSwitch
            checked={!settings.showConflicts.driverInfo}
            onValueChange={() =>
              setSettings({ ...settings, showConflicts: { ...settings.showConflicts, driverInfo: !settings.showConflicts.driverInfo } })
            }
            label={'Fahrer Infos'}
          />
        </StyledSwitchWrapper>

        <ToursListFilterButton />
      </FilterRow>
    </FilterRowWrapper>
  );
};
