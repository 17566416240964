import { IPickupDate } from 'api/models/PickupDate';
import { IEmployeeAbsence } from 'api/models/EmployeeAbsence';
import { getCurrentDayDate } from 'utils/dateUtils';
import { IEmployee } from 'api/models/Employee';
import { IConflict } from 'api/models/Conflict';
import { formatName } from 'utils/nameUtils';

export const getTourDaysOfWeek = (arrivals: IPickupDate | undefined | null, departures: IPickupDate | undefined | null) => {
  const daysOfWeek = [];

  for (let key of Object.keys(arrivals || {})) {
    if (key.match(/^[0-6]$/)) {
      daysOfWeek.push(key);
    }
  }
  for (let key of Object.keys(departures || {})) {
    if (key.match(/^[0-6]$/)) {
      daysOfWeek.push(key);
    }
  }

  return daysOfWeek
    .filter((item, index, array) => array.indexOf(item) === index)
    .sort((a, b) => {
      return ((+a + 1) % 7) - ((+b + 1) % 7);
    });
};

export const getDriverAbsence = (driver: IEmployee | undefined, date: string) => {
  return (driver?.absences || []).find(
    ({ startDate, endDate }) =>
      getCurrentDayDate(startDate) <= getCurrentDayDate(date) && (!endDate || getCurrentDayDate(endDate) >= getCurrentDayDate(date)),
  );
};

export const isDriverUnavailable = (driver: IEmployee | undefined, date: string) => {
  return !driver || !!getDriverAbsence(driver, date);
};

export const getFormattedReasonForConflict = (conflict: IConflict | undefined, date?: string) => {
  if (conflict) {
    const { reason, tour } = conflict;

    const getAbsenceDescription = (absence: IEmployeeAbsence | undefined) => {
      if (absence) {
        return absence.endDate
          ? ` bis ${getCurrentDayDate(absence.endDate).toLocaleDateString(undefined, { dateStyle: 'medium' })}`
          : ` seit ${getCurrentDayDate(absence.startDate).toLocaleDateString(undefined, { dateStyle: 'medium' })}`;
      }
      return '';
    };

    switch (reason) {
      case 'driver_not_set':
        return 'Kein Fahrer geplant';
      case 'unset_pickupdate':
        return 'Es fehlt min. eine Fahrgast Zeit';
      case 'driver_unavailable':
        return `${tour.driver ? tour.driver.fullNameWithComma : 'Fahrer'} fällt aus${
          date ? getAbsenceDescription(getDriverAbsence(tour.driver, date)) : ''
        }`;
      case 'companion_unavailable':
        return `${tour.companion ? formatName(tour.companion) : 'Begleitung'} fällt aus${
          date ? getAbsenceDescription(getDriverAbsence(tour.companion, date)) : ''
        }`;
      case 'driver_duplicated':
        return `${tour.driver ? tour.driver.fullNameWithComma : 'Fahrer'} mehrfach geplant`;
      case 'companion_duplicated':
        return `${tour.companion ? formatName(tour.companion) : 'Begleitung'} mehrfach geplant`;
      case 'no_passenger':
        return 'Diese Fahrt hat keine Fahrgäste.';
      default:
        break;
    }
  }

  return 'Bei dieser Fahrt besteht ein Konflikt.';
};
