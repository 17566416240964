import styled from 'styled-components';

import { CalendarExceptionList } from 'components/content/calendar-exceptions/CalendarExceptionList';
import { TabStatusBarLayout } from 'components/common/elements/TabStatusBarLayout';
import { BulkCancellationList } from 'components/content/bulk_cancellations/BulkCancellationList';
import { ButtonSecondary } from 'components/common/inputs/Button';
import { Plus } from 'components/icons/Plus';

const Wrapper = styled.section`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 2.25rem var(--content-indent) 4.75rem;
  margin-bottom: 2.25rem;
`;

export const CalendarExceptions = () => {
  return (
    <Wrapper>
      <TabStatusBarLayout
        breadcrumb={{
          title: 'Kalender-Ausnahmen',
          href: '/calendar-exceptions',
        }}
        subPageBreadcrumb={{
          // Add the missing subPageBreadcrumb property
          title: 'Kalender-Ausnahmen',
          href: '/calendar-exceptions',
        }}
        topRightContent={
          <ButtonSecondary href={'/calendar-exceptions/add'}>
            <Plus /> Schließzeit anlegen
          </ButtonSecondary>
        }
        header={'Kalender-Ausnahmen'}
        items={[
          {
            title: 'Geplante Schließzeiten',
            content: <CalendarExceptionList />,
            hash: '#calendar',
          },
          {
            title: 'Ausgeführte Schließzeiten',
            content: <BulkCancellationList />,
            hash: '#bulk',
          },
        ]}
      />
    </Wrapper>
  );
};
