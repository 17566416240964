// General
import { FC, useMemo } from 'react';
import styled, { css } from 'styled-components';

// Styles
import { Colors } from 'styles/Colors';
import { Body1Bold, Body3, Body4 } from 'styles/FontStyles';
import { BREAKPOINT_MD, BREAKPOINT_SM } from 'styles/Breakpoints';

// Components
import { Avatar } from 'components/common/atoms/Avatar';
import { EmployeeActiveLabel } from 'components/common/labels/ActiveLabel';
import { EmployeeAddressPreview } from 'components/common/elements/employee/EmployeeAddressPreview';
import { EmployeeContextOptions } from 'components/common/elements/employee/EmployeeContextOptions';

// Types
import { IEmployee } from 'api/models/Employee';

// Icons
import { Bus } from 'components/icons/Bus';
import { useNavigate } from 'hooks/useNavigate';

const TileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  cursor: pointer;

  background: ${Colors.white50};
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
  border-radius: 8px;

  padding: 1rem;

  transition: box-shadow 150ms ease-out, border-radius 150ms ease-out;

  &:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    border-radius: 8px;
  }
`;

const Flexbox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

const ProfileWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: max-content max-content 1fr;
  margin-bottom: 1.25rem;
  grid-column-gap: 0.5rem;

  ${BREAKPOINT_SM} {
    grid-column-gap: 1rem;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const EmployeeAvatar = styled(Avatar)`
  ${Body1Bold};
  grid-area: 2 / 1 / 4 / 1;

  ${BREAKPOINT_MD} {
    grid-area: 1 / 1 / 4 / 1;
  }
`;

const ProfileInfoNameWrapper = styled(Flexbox)`
  gap: 1rem;
`;

const ContextInfoWrapper = styled(Flexbox)`
  ${Body4};
  color: ${Colors.grey600};
  justify-content: flex-end;
  gap: 0.25rem;
  grid-area: 1 / 1 / 1 / 3;
`;

const OptionsWrapper = styled.div`
  display: none;

  ${BREAKPOINT_MD} {
    display: initial;
  }
`;

const ResponsiveTextToggleStyles = css`
  > :first-child {
    display: none;
  }

  ${BREAKPOINT_SM} {
    > :first-child {
      display: initial;
    }

    > :last-child {
      display: none;
    }
  }
`;

const SmallText = styled.div`
  ${Body3};
  ${ResponsiveTextToggleStyles};
  text-align: left;
`;

const LargeText = styled.div`
  ${Body1Bold};
  ${ResponsiveTextToggleStyles};
`;

const ActivityTag = styled(EmployeeActiveLabel)`
  display: none;

  ${BREAKPOINT_MD} {
    display: flex;
  }
`;

const BusIcon = styled(Bus)`
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
`;

interface IEmployeeTileProps {
  employee: IEmployee;
  onUpdate?: () => void;
}

export const EmployeeTile: FC<IEmployeeTileProps> = (props) => {
  const { employee, onUpdate } = props;

  const { firstName, lastName, image, jobTitle, drivingLicense = '-', internalId, id } = employee;

  const navigate = useNavigate();

  const isAbsent = useMemo(() => {
    return (employee.absences || []).length > 0;
  }, [employee]);

  return (
    <TileWrapper
      onClick={(e) => {
        if (!e.isDefaultPrevented()) {
          navigate(`/employees/${id}`);
        }
      }}
    >
      <ProfileWrapper>
        <EmployeeAvatar
          src={image?.url}
          firstName={firstName}
          lastName={lastName}
          size={'medium'}
          variation={isAbsent ? 'inactive' : 'active'}
        />
        <ContextInfoWrapper>
          <span>{jobTitle}</span>
          <BusIcon />
          <span>{drivingLicense}</span>
          <OptionsWrapper>
            <EmployeeContextOptions employee={employee} onUpdate={onUpdate} />
          </OptionsWrapper>
        </ContextInfoWrapper>

        <SmallText>
          <span>{firstName}</span>
          <span>{internalId}</span>
        </SmallText>

        <ProfileInfoNameWrapper>
          <LargeText>
            <span>{lastName}</span>
            <span>{`${firstName} ${lastName}`}</span>
          </LargeText>

          <ActivityTag employee={employee} />
        </ProfileInfoNameWrapper>
      </ProfileWrapper>

      <ContentWrapper>
        <AddressWrapper>
          <EmployeeAddressPreview employee={employee} />
        </AddressWrapper>
      </ContentWrapper>
    </TileWrapper>
  );
};
