import { IAPIModelBase } from 'api/models/index';

export interface ICustomerCategory extends IAPIModelBase {
  name: string;
}

export const emptyCustomerCategory: ICustomerCategory = {
  id: '',
  name: '',
  createdAt: '',
  updatedAt: ''
}

export const DROPDOWN_ITEMS_CUSTOMER_CATEGORIES = [
  { value: 'Alle', label: 'Alle' },
  { value: 'Schule', label: 'Schule' },
  { value: 'Hochschule', label: 'Hochschule' },
  { value: 'Behindertenwerkstatt', label: 'Behindertenwerkstatt' },
  { value: 'Kindergarten', label: 'Kindergarten' },
];
