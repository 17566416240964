import axios from 'axios';
import { useAuthHeader } from 'state/AuthState';
import { FetchCustomersParams, FetchEmployeesParams, FetchPassengersParams, FetchVehiclesParams } from 'api/controllers/InfiniteSearchAPI';

interface ISearchAPI {
  query: string;
  indices?: string[];
  customerIds?: string[];
  ids?: string[];
  regions?: string[];
  directions?: string[];
  startDate?: string;
  endDate?: string;
  includeDays?: boolean;
  from?: number;
  size?: number;
  sortBy?: string;
}

export const useSearchAPI = () => {
  const authHeader = useAuthHeader();

  const checkAuth = async () => {
    if (!authHeader) {
      throw new Error('401');
    }
  };

  const search = (props: ISearchAPI) => {
    return checkAuth().then(() => {
      return axios.post(`/api/search`, props, { ...authHeader });
    });
  };

  const searchCustomers = (props: FetchCustomersParams) => {
    return checkAuth().then(() => {
      return axios.post(`/api/search/customers`, props, { ...authHeader });
    });
  };

  const searchEmployees = (props: FetchEmployeesParams) => {
    return checkAuth().then(() => {
      return axios.post(`/api/search/employees`, props, { ...authHeader });
    });
  };

  const searchPassengers = (props: FetchPassengersParams) => {
    return checkAuth().then(() => {
      return axios.post(`/api/search/passengers`, props, { ...authHeader });
    });
  };

  const searchVehicles = (props: FetchVehiclesParams) => {
    return checkAuth().then(() => {
      return axios.post(`/api/search/vehicles`, props, { ...authHeader });
    });
  };

  return {
    search,
    searchCustomers,
    searchEmployees,
    searchPassengers,
    searchVehicles,
  };
};
