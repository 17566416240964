import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { FetchPassengersParams } from 'api/controllers/InfiniteSearchAPI';

const { persistAtom } = recoilPersist({
  key: 'passenger-filters',
  storage: localStorage,
});

export const PassengerFilterState = atom<FetchPassengersParams>({
  key: 'PassengerFilterState',
  default: {
    query: '',
    page: {
      offset: 0,
      limit: 24,
    },
    regions: [],
    customerIds: [],
    travelTypes: [],
  },
  effects_UNSTABLE: [persistAtom],
});
