import React from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { DropdownWithRef } from '../common/inputs/DropdownWithRef';
import { Form } from '../common/inputs/Form';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  padding: 2.25rem var(--content-indent) 4.75rem;
`;

export const UseFormTest: React.FC = () => {
  const form = useForm({
    defaultValues: {
      dropdown: '2',
    },
  });

  const items = [
    {
      value: '1',
      label: 'Item 1',
    },
    {
      value: '2',
      label: 'Item 2',
    },
  ];

  const formRegister = form.register('dropdown', { required: 'Bitte wählen etwas aus.' });

  return (
    <Wrapper>
      <Form form={form} onSubmit={(values) => console.log(values)}>
        <DropdownWithRef
          id="dropdown"
          placeholder="Dropdown-Test"
          items={items}
          onValueChange={(value) => form.setValue('dropdown', value)}
          {...formRegister} // Pass the ref and other props from react-hook-form
        />
        <div>{form.watch('dropdown')}</div>
      </Form>
    </Wrapper>
  );
};
