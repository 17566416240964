import { FC, useCallback, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { ButtonPrimary, ButtonSecondary, ButtonTertiary } from 'components/common/inputs/Button';
import { LoadingProgressUI, LoadingSpinner } from 'components/common/loaders/LoadingSpinner';
import { useNavigate } from 'react-router-dom';
import { useFieldArray, useForm } from 'react-hook-form';
import { Form } from 'components/common/inputs/Form';
import { useOrderAPI } from 'api/controllers/OrderAPI';
import { Body2, Body2Bold, Headline2, Headline3 } from 'styles/FontStyles';
import { ICustomer } from 'api/models/Customer';
import { IOrder } from 'api/models/Order';
import { CustomerOrderFormPageOrderData } from 'components/content/customers/orders/CustomerOrderFormPageOrderData';
import { CustomerOrderFormPageImportSelection } from 'components/content/customers/orders/CustomerOrderFormPageImportSelection';
import { BREAKPOINT_MD } from 'styles/Breakpoints';
import { CustomerOrderFormPageTours } from 'components/content/customers/orders/CustomerOrderFormPageTours';
import { CustomerOrderFormPagePassengers } from 'components/content/customers/orders/CustomerOrderFormPagePassengers';
import { IPassenger } from 'api/models/Passenger';
import { CustomerOrderFormPageDateRange } from 'components/content/customers/orders/CustomerOrderFormPageDateRange';
import { Clock } from 'components/icons/Clock';
import { Breadcrumb } from 'components/common/elements/Breadcrumb';
import { Facilities } from 'components/icons/Facilities';
import { Colors } from 'styles/Colors';
import { formatAddress } from 'utils/addressUtils';
import { ChipTile } from 'components/common/atoms/ChipTile';
import BackButton from 'components/common/atoms/BackButton';
import { UseFormReturn } from 'react-hook-form/dist/types';
import { useMatchBreakpoint } from 'hooks/useMatchBreakpoint';
import { useShowDialog } from 'state/DialogState';
import { useRecoilValue } from 'recoil';
import { NavigationCollapsedState } from 'state/NavigationState';
import { ITour } from 'api/models/Tour';
import { getCurrentDayDate, getFirstOfNextMonthDate } from 'utils/dateUtils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
  flex-grow: 1;

  position: relative;
`;

const FormWrapper = styled.div<{ overlayVisible?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;

  margin: -2.5rem;
  padding: 2.5rem;
  overflow: auto;

  & > *:only-child {
    flex-grow: 1;
  }

  transition: margin-inline-end 150ms ease-out;

  ${({ overlayVisible = false }) =>
    overlayVisible &&
    css`
      margin-inline-end: 20rem;
      margin-block-end: 0;
    `};
`;

const FormHeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
  z-index: 80;
  background-color: rgb(250, 250, 250);
  max-width: 80%;
  flex-shrink: 1;
`;

const FormHeadWrapperStep4 = styled(FormHeadWrapper)`
  position: sticky;
  top: 64px;
  margin-block-end: 2.5rem;
  margin-inline-start: -2.5rem;
  padding-left: 2.5rem;
  padding-bottom: 2rem;

  padding-bottom: 1rem;
  padding-top: 0;
  margin-top: -1.5rem;
  margin-bottom: 0;
`;

const FormHeadContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
  flex-wrap: wrap;
`;

const FormHeadContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const Title = styled.h2`
  ${Body2};

  span {
    ${Body2Bold};
  }
`;

const ButtonsWrapper = styled.div<{ navCollapsed: boolean; overlayVisible: boolean }>`
  position: fixed;
  z-index: 2;
  inset-inline-start: ${({ navCollapsed }) => (navCollapsed ? '5.5rem' : '20rem')};
  inset-inline-end: ${({ overlayVisible }) => (overlayVisible ? '20rem' : '0')};
  inset-block-end: 0;

  transition: inset-inline-start 300ms ease-out, inset-inline-end 300ms ease-out, padding-inline-end 300ms ease-out;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;

  padding-block: 0.75rem;
  padding-inline-start: 4.75rem;
  padding-inline-end: ${({ overlayVisible }) => (overlayVisible ? '1rem' : '4.75rem')};
  background-color: ${Colors.white50};
`;

const ButtonsRightGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;

  ${BREAKPOINT_MD} {
    margin-inline-start: auto;
  }
`;

const PlaceholderDesktopOnly = styled.h2`
  ${Headline2};
  text-align: center;

  padding: 2rem;
  margin: auto;
`;

const Headline = styled.h2`
  ${Headline3};
`;

type TPage = 'date-range' | 'order-data' | 'import-selection' | 'passengers' | 'tours-outwards' | 'tours-return';

interface ICustomerOrderFormProps {
  customer: ICustomer | null;
  order?: IOrder;
}

export const CustomerOrderForm: FC<ICustomerOrderFormProps> = ({ customer, order }) => {
  const { id: customerId = '' } = customer || {};

  const navigate = useNavigate();
  const navCollapsed = useRecoilValue(NavigationCollapsedState);
  const isDesktop = useMatchBreakpoint();

  const checkAllPassengersRef = useRef<{ handleCheckAllChanges: () => void }>(null);

  const showDialog = useShowDialog();

  const [selectedPassengers, setSelectedPassengers] = useState<string[]>(
    order?.tours
      ?.flatMap(({ passengers = [] }) => passengers.map(({ id }) => id))
      ?.filter((item, index, array) => array.indexOf(item) === index) || [],
  );
  const [importedPassengers, setImportedPassengers] = useState<Partial<IPassenger>[]>([]);

  const orderAPI = useOrderAPI();
  const [isSubmitting, setSubmitting] = useState(false);
  const [submittingState, setSubmittingState] = useState<{ progress: number; count: number } | null>(null);

  const [page, setPage] = useState<TPage>(order?.id ? 'passengers' : 'date-range');

  const startDate =
    ((order?.tours || [])[0]?.startDate && new Date((order?.tours || [])[0]?.startDate).toISOString()) ||
    getFirstOfNextMonthDate(getCurrentDayDate()).toISOString();
  const endDate = ((order?.tours || [])[0]?.endDate && new Date((order?.tours || [])[0]?.endDate!).toISOString()) || null;

  const formDateRange = useForm({
    defaultValues: {
      startDate,
      endDate,
      isExistingOrder: !!order,
      timestamp: !!order
        ? // use current date, but min startDate and max endDate
          (endDate && getCurrentDayDate() > getCurrentDayDate(endDate)
            ? getCurrentDayDate(endDate)
            : getCurrentDayDate() < getCurrentDayDate(startDate)
            ? getCurrentDayDate(startDate)
            : getCurrentDayDate()
          ).toISOString()
        : null,
    },
  });

  const formOrderData = useForm({
    defaultValues: {
      displayName: order?.displayName || '',
      comment: order?.comment || '',
    },
  });

  const formTours = useForm({
    defaultValues: {
      customerId,
      tours: (order?.tours || []) as ITour[],
      toursToDelete: [] as string[],
    },
  });

  const tours = useFieldArray({ name: 'tours', control: formTours.control, keyName: 'form-id' });

  const orderName = formOrderData.watch('displayName');
  const orderStartDate = formDateRange.watch('startDate');
  const orderEndDate = formDateRange.watch('endDate');

  const orderTours = formTours.watch('tours');
  const { toursOutwardsCount, toursOutwardsPassengersCount, toursReturnCount, toursReturnPassengersCount } = useMemo(() => {
    const toursOutwards = orderTours.filter(({ direction }) => direction === 'outwards');
    const toursOutwardsCount = toursOutwards.length;
    const toursOutwardsPassengersCount = toursOutwards.flatMap(({ passengers = [] }) => passengers).length;
    const toursReturn = orderTours.filter(({ direction }) => direction === 'return');
    const toursReturnCount = toursReturn.length;
    const toursReturnPassengersCount = toursReturn.flatMap(({ passengers = [] }) => passengers).length;

    return {
      toursOutwards,
      toursOutwardsCount,
      toursOutwardsPassengersCount,
      toursReturn,
      toursReturnCount,
      toursReturnPassengersCount,
    };
  }, [orderTours]);

  const form: any = useMemo(() => {
    switch (page) {
      case 'tours-return':
      case 'tours-outwards':
      case 'passengers':
      case 'import-selection':
        return formTours;
      case 'order-data':
        return formOrderData;
      case 'date-range':
      default:
        return formDateRange;
    }
  }, [page, formDateRange, formOrderData, formTours]);

  const onImportTours = useCallback(
    (data: ITour[]) => {
      if (data && Array.isArray(data) && data.length > 0) {
        const importedTours = data.map((tour) => ({
          ...tour,
          passengers: tour.passengers?.map((passenger) => ({
            ...passenger,
            customerId,
          })),
        }));
        tours.replace(importedTours);

        // save imported passengers in state
        setImportedPassengers(
          importedTours
            .flatMap(({ passengers = [] }) => passengers)
            .reduce((res, item) => {
              if (
                !res.some(
                  ({ id, addresses = [] }) => item.id === id || formatAddress((item.addresses || [])[0]) === formatAddress(addresses[0]),
                )
              ) {
                // filter for distinct passengers
                res.push(item);
              }
              return res;
            }, [] as IPassenger[]),
        );
      }

      setPage('tours-outwards');
    },
    [tours, customerId],
  );

  const onSubmitForm = useCallback(() => {
    const values = {
      ...formDateRange.getValues(),
      ...formOrderData.getValues(),
      ...formTours.getValues(),
    };

    if (page === 'tours-return') {
      (async () => {
        const { isExistingOrder, timestamp, tours, toursToDelete, ...payload } = {
          ...values,
          tours: (values.tours || []).map((tour) => ({
            ...tour,
            customerOrderId: order?.id,
            startDate: values.startDate,
            endDate: values.endDate,
            passengers: (tour.passengers || []).map(({ id, isDraft, customerId: passengerCustomerId }, index) => ({
              // get passenger frame only
              id,
              ...(isDraft !== false ? { isDraft: false } : {}), // enable imported passengers
              ...(passengerCustomerId === undefined ? { customerId } : {}), // link with current customer (imported passengers do not have a customer yet)
              TourPassenger: {
                position: index,
              },
            })),
            // reset populated data for transfer
            driver: undefined,
            companion: undefined,
            order: undefined,
            destinations: undefined,
          })),
        };

        // create/update order
        setSubmitting(true);
        const customerOrderId = await (order?.id
          ? orderAPI.updateOrder(order!.id, { ...payload, timestamp })
          : orderAPI.createOrder(payload)
        )
          .then((res) => {
            console.log(order?.id ? 'update' : 'create', 'order', res.data);
            return res.data.id;
          })
          .catch((err) => {
            console.error(`error ${order?.id ? 'updating' : 'creating'} order:`, err);
            return null;
          });

        if (customerOrderId === null) {
          showDialog({
            headline: 'Auftrag speichern',
            body: 'Beim Speichern des Auftrags ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
          });
          setSubmitting(false);
          return;
        }

        // update tour one by one with loading progress UI
        const errors: string[] = [];
        const count = tours.length + toursToDelete.filter(Boolean).length;
        setSubmittingState({ progress: 0, count });
        await Promise.all([
          ...tours.map((tour: any) => {
            return (
              tour.id !== undefined
                ? orderAPI.updateTour(tour.id, customerOrderId, { ...tour, timestamp })
                : orderAPI.createTour(customerOrderId, tour)
            )
              .then((res) => {
                console.log(`${tour.id !== undefined ? 'updated' : 'created'} tour`, res.data);
              })
              .catch((err) => {
                console.error(`error ${tour.id !== undefined ? 'updating' : 'creating'} tour`, err);
                errors.push(`${tour.name} (${tour.direction === 'return' ? 'Rück' : 'Hin'})`);
              })
              .finally(() => setSubmittingState((state) => ({ progress: (state?.progress || 0) + 1, count: state?.count || count })));
          }),
          ...toursToDelete.filter(Boolean).map((id) => {
            return orderAPI
              .deleteTour(id, getCurrentDayDate(timestamp || '').toISOString())
              .then((res) => {
                console.log(`deleted tour`, res.data);
              })
              .catch((err) => {
                console.error(`error deleting tour`, err);
                errors.push(`Entfernte Fahrt ${id}`);
              })
              .finally(() => setSubmittingState((state) => ({ progress: (state?.progress || 0) + 1, count: state?.count || count })));
          }),
        ]).catch(() => {});

        // dismiss progress UI
        setSubmittingState(null);
        setSubmitting(false);

        if (errors.length > 0) {
          console.log('tours with errors:', errors);

          // show error message
          showDialog({
            headline: 'Vertragstouren speichern',
            body: `Bei ${errors.length} von ${
              tours.length
            } Vertragstouren ist ein Fehler aufgetreten. Folgende Touren konnten nicht gespeichert werden: ${errors.sort().join(', ')}.`,
          });
        } else {
          // redirect to orders
          navigate(`/customers/${customerId}#orders`, { replace: true });
        }
      })();
    } else {
      // generate order name if not set
      if (['date-range'].includes(page) && !formOrderData.getValues('displayName') && customer) {
        formOrderData.setValue(
          'displayName',
          [
            customer.internalId,
            [formDateRange.getValues('startDate'), formDateRange.getValues('endDate')]
              .map(
                (date) =>
                  date &&
                  `${new Date(date).toLocaleDateString(undefined, { month: '2-digit' })}${new Date(date).toLocaleDateString(undefined, {
                    year: '2-digit',
                  })}`,
              )
              .filter(Boolean)
              .join('/'),
          ]
            .filter(Boolean)
            .join('-'),
        );
      }

      if (['order-data'].includes(page) && importedPassengers.length > 0) {
        // user has chosen import
        setPage('tours-outwards');
      } else if (['order-data'].includes(page) && selectedPassengers.length > 0) {
        // user has chosen manual order or is editing existing order
        setPage('passengers');
      } else {
        switch (page) {
          case 'date-range':
            setPage('order-data');
            break;
          case 'order-data':
            setPage('import-selection');
            break;
          case 'passengers':
            setPage('tours-outwards');
            break;
          case 'tours-outwards':
            // copy tours for return
            (values.tours || [])
              .filter(({ direction, copyForReturnTour = false }: any) => copyForReturnTour && direction === 'outwards')
              .forEach((tour) => {
                const returnTourIndex = (values.tours || []).findIndex(
                  ({ name, direction }) => name === tour.name && direction === 'return',
                );
                if (returnTourIndex >= 0) {
                  const returnTour = values.tours?.[returnTourIndex];
                  formTours.setValue(
                    'tours',
                    (values.tours || []).map((item, index) => {
                      if (index === returnTourIndex) {
                        return {
                          ...returnTour,
                          passengers: (tour.passengers || []).map((passenger) => ({
                            ...passenger,
                            ...((returnTour?.passengers || []).find(({ id }) => id === passenger.id) || {}),
                          })),
                        };
                      }
                      return item;
                    }) as any,
                  );
                } else {
                  formTours.setValue('tours', [
                    ...(values.tours || []),
                    {
                      ...tour,
                      direction: 'return',
                      arrivalDate: null,
                      departureDate: null,
                      passengers: [...(tour.passengers || [])],
                    },
                  ] as any);
                }
              });

            setPage('tours-return');
            break;
        }
      }
    }
  }, [
    formDateRange,
    formOrderData,
    formTours,
    page,
    customer,
    customerId,
    importedPassengers.length,
    selectedPassengers.length,
    navigate,
    order,
    orderAPI,
    showDialog,
  ]);

  const handleContinueAllButtonClick = useCallback(
    (e: any) => {
      e.preventDefault();
      if (checkAllPassengersRef.current) {
        checkAllPassengersRef.current.handleCheckAllChanges();
      }
      onSubmitForm();
    },
    [onSubmitForm],
  );

  const breadcrumb = useMemo(
    () => (
      <Breadcrumb
        hideBackButton
        items={[
          {
            title: (() => {
              switch (page) {
                case 'date-range':
                case 'order-data':
                  return 'Schritt 1: Zeitraum festlegen';
                case 'import-selection':
                  return 'Schritt 2: Datenimport oder manuelle Eingabe';
                case 'passengers':
                  return 'Schritt 3: Auswahl der Fahrgäste';
                case 'tours-outwards':
                case 'tours-return':
                  return 'Schritt 4: Aufteilung Vertragstouren';
                default:
                  return '';
              }
            })(),
            href: '#',
            onClick: () => {},
          },
        ]}
      >
        Auftrag erfassen
      </Breadcrumb>
    ),
    [page],
  );

  const formHead = useMemo(() => {
    const HeadComponent = page === 'tours-outwards' ? FormHeadWrapperStep4 : FormHeadWrapper;

    return (
      <HeadComponent>
        {orderName && (
          <FormHeadContent>
            <Title>
              Auftrag <span>{orderName}</span>
            </Title>
          </FormHeadContent>
        )}
        <FormHeadContentWrapper>
          {orderStartDate && (
            <FormHeadContent>
              <Clock />{' '}
              {[orderStartDate, orderEndDate]
                .map((date) => date && new Date(date).toLocaleDateString(undefined, { dateStyle: 'medium' }))
                .filter(Boolean)
                .join(' - ')}
            </FormHeadContent>
          )}
          <FormHeadContent>
            <Facilities />
            <Title>
              {customer?.internalId} <span>{customer?.displayName}</span>
            </Title>
          </FormHeadContent>
        </FormHeadContentWrapper>
        <Headline>
          {page === 'tours-return' ? 'Erstellen Sie alle Rückfahrten des Auftrags' : 'Erstellen Sie alle Hinfahrten des Auftrags'}
        </Headline>
      </HeadComponent>
    );
  }, [orderName, orderStartDate, orderEndDate, customer?.internalId, customer?.displayName, page]);

  const buttonsView = useMemo(
    () => (
      <ButtonsWrapper navCollapsed={navCollapsed} overlayVisible={['tours-outwards', 'tours-return'].includes(page)}>
        {['tours-outwards', 'tours-return'].includes(page) && (
          <>
            <ChipTile
              title={`${toursOutwardsPassengersCount}/${selectedPassengers.length + importedPassengers.length} Fahrgäste verteilt`}
              progress={toursOutwardsPassengersCount / (selectedPassengers.length + importedPassengers.length)}
            >
              {toursOutwardsCount} Vertragstouren - Hinfahrten
            </ChipTile>
            {['tours-return'].includes(page) && (
              <ChipTile
                title={`${toursReturnPassengersCount}/${selectedPassengers.length + importedPassengers.length} Fahrgäste verteilt`}
                progress={toursReturnPassengersCount / (selectedPassengers.length + importedPassengers.length)}
              >
                {toursReturnCount} Vertragstouren - Rückfahrten
              </ChipTile>
            )}
          </>
        )}
        {!['tours-outwards', 'tours-return'].includes(page) && (
          <ButtonTertiary disabled={isSubmitting} onClick={() => navigate(-1)}>
            Abbrechen
          </ButtonTertiary>
        )}
        <ButtonsRightGroup>
          {!['date-range'].includes(page) && (
            <BackButton
              onClick={() => {
                if (['tours-outwards'].includes(page) && importedPassengers.length > 0) {
                  // user has chosen import
                  setPage('order-data');
                } else if (['passengers'].includes(page)) {
                  // user has chosen manual order or is editing existing order
                  setPage('order-data');
                } else {
                  switch (page) {
                    case 'tours-return':
                      setPage('tours-outwards');
                      break;
                    case 'tours-outwards':
                      setPage('passengers');
                      break;
                    case 'import-selection':
                      setPage('order-data');
                      break;
                    case 'order-data':
                      setPage('date-range');
                      break;
                  }
                }
              }}
            />
          )}
          {page === 'passengers' && (
            <ButtonPrimary ref={checkAllPassengersRef} onClick={handleContinueAllButtonClick} type={'submit'}>
              {isSubmitting ? <LoadingSpinner /> : 'Weiter mit allen Passagieren'}
            </ButtonPrimary>
          )}
          {!['tours-return'].includes(page) && (
            <ButtonSecondary disabled={['import-selection', 'import'].includes(page)} type={'submit'}>
              {isSubmitting ? <LoadingSpinner /> : 'Weiter'}
            </ButtonSecondary>
          )}
          {page === 'tours-return' && <ButtonPrimary type={'submit'}>{isSubmitting ? <LoadingSpinner /> : 'Speichern'}</ButtonPrimary>}
        </ButtonsRightGroup>
      </ButtonsWrapper>
    ),
    [
      navCollapsed,
      page,
      toursOutwardsPassengersCount,
      selectedPassengers.length,
      importedPassengers.length,
      toursOutwardsCount,
      toursReturnPassengersCount,
      toursReturnCount,
      isSubmitting,
      handleContinueAllButtonClick,
      navigate,
    ],
  );

  const progressUI = useMemo(
    () => <>{submittingState && <LoadingProgressUI title={`Vertragstouren speichern`} progress={submittingState} />}</>,
    [submittingState],
  );

  // placeholder desktop only
  if (!isDesktop) {
    return (
      <Wrapper>
        <Breadcrumb
          items={[
            {
              title: 'Auftrag erfassen',
              href: null,
            },
          ]}
        />
        <PlaceholderDesktopOnly>Die Auftragserfassung muss am PC mit einem großen Display durchgeführt werden.</PlaceholderDesktopOnly>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {breadcrumb}
      {formHead}
      <FormWrapper
        overlayVisible={['tours-outwards', 'tours-return'].includes(page)}
        style={{ paddingTop: !['tours-outwards', 'tours-return'].includes(page) ? '6.5rem' : undefined }}
      >
        <Form form={form as UseFormReturn<any>} onSubmit={onSubmitForm}>
          <>
            {page === 'date-range' && <CustomerOrderFormPageDateRange />}
            {page === 'order-data' && <CustomerOrderFormPageOrderData />}
            {page === 'import-selection' && (
              <CustomerOrderFormPageImportSelection onContinueClick={() => setPage('passengers')} onImport={onImportTours} />
            )}
            {page === 'passengers' && (
              <CustomerOrderFormPagePassengers
                customerId={customerId}
                selectedPassengers={selectedPassengers}
                setSelectedPassengers={setSelectedPassengers}
                ref={checkAllPassengersRef}
              />
            )}
            {['tours-outwards', 'tours-return'].includes(page) && (
              <CustomerOrderFormPageTours
                customer={customer}
                selectedPassengers={selectedPassengers}
                importedPassengers={importedPassengers}
                direction={page === 'tours-return' ? 'return' : 'outwards'}
              />
            )}
          </>
          {buttonsView}
        </Form>
      </FormWrapper>

      {progressUI}
    </Wrapper>
  );
};
