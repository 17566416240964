import { useEffect, useState } from 'react';
import { Breadcrumb } from 'components/common/elements/Breadcrumb';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingSpinner } from 'components/common/loaders/LoadingSpinner';
import { useCustomersAPI } from 'api/controllers/CustomersAPI';
import { ICustomer } from 'api/models/Customer';
import { ContentPageLayout } from 'components/common/atoms/Layout';
import { CustomerTourplanView } from 'components/content/customers/tourplan/CustomerTourplanView';

export const CustomerTourplan = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const direction = new URLSearchParams(window.location.search).get('direction') || 'outwards';

  const customersAPI = useCustomersAPI();
  const [customer, setCustomer] = useState<ICustomer | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      customersAPI
        .getCustomerById({ id })
        .then((res) => setCustomer(res.data.data))
        .catch(() => navigate('/404'))
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <ContentPageLayout>
      <Breadcrumb
        items={[
          {
            title: 'Fahrtenplanung',
            href: null,
          },
        ]}
      />
      {customer && !loading ? (
        <CustomerTourplanView customer={customer} direction={direction === 'return' ? 'return' : 'outwards'} />
      ) : (
        <LoadingSpinner />
      )}
    </ContentPageLayout>
  );
};
