// General
import styled from 'styled-components';

// Components
import CustomerDetailsAccountingInvoice from 'components/common/elements/customer/CustomerDetailsAccountingInvoice';
import { useParams } from 'react-router-dom';
import { Breadcrumb } from 'components/common/elements/Breadcrumb';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;

  padding: 2.25rem var(--content-indent) 4.75rem;
`;

export const CustomerDetailInvoiceDetails = () => {
  const { customerId, invoiceId } = useParams();

  return (
    <Wrapper>
      <Breadcrumb
        items={[
          {
            title: 'Einrichtung Details',
            href: `/customers/${customerId}`,
          },
        ]}
      />

      <CustomerDetailsAccountingInvoice customerId={customerId!} invoiceId={invoiceId!} />
    </Wrapper>
  );
};
