import axios from 'axios';
import { useAuthHeader } from 'state/AuthState';

interface IGetCustomerCategoriesProps {}

interface ICustomerCategoryProps {
  customer_category: {
    name: string;
  };
}

export interface IGetResourceByIdProps {
  id: string;
}

export const useCustomerCategoryAPI = () => {
  const authHeader = useAuthHeader();

  const checkAuth = async () => {
    if (!authHeader) {
      throw new Error('401');
    }
  };

  const createCustomerCategory = (props: ICustomerCategoryProps) => {
    return checkAuth().then(() => {
      return axios.post(`/api/customer-categories`, props, { ...authHeader });
    });
  };

  const getCustomerCategories = (_props: IGetCustomerCategoriesProps) => {
    return checkAuth().then(() => {
      return axios.get(`/api/customer-categories`, { ...authHeader });
    });
  };

  const getCustomerCategoryById = (props: IGetResourceByIdProps) => {
    return checkAuth().then(() => {
      return axios.get(`/api/customer-categories/${props.id}`, { ...authHeader });
    });
  };

  const updateCustomerCategory = (id: string, props: ICustomerCategoryProps) => {
    return checkAuth().then(() => {
      return axios.patch(`/api/customer-categories/${id}`, props, { ...authHeader });
    });
  };

  const deleteCustomerCategory = (id: string) => {
    return checkAuth().then(() => {
      return axios.delete(`/api/customer-categories/${id}`, { ...authHeader });
    });
  };

  return {
    createCustomerCategory,
    deleteCustomerCategory,
    updateCustomerCategory,
    getCustomerCategories,
    getCustomerCategoryById,
  };
};
