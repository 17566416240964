import { CSSProperties, memo } from 'react';
import { useLatestBookingCompletionDate } from 'state/LatestBookingCompletionState';
import { getCurrentDayDate } from 'utils/dateUtils';
import styled from 'styled-components';
import { Colors } from 'styles/Colors';

const Wrapper = styled.div`
  position: absolute;
  inset: 0;
  inline-size: 100%;
  block-size: 100%;
  z-index: 2;

  pointer-events: all;
  cursor: default;
  background-color: ${Colors.grey700};
  opacity: 0.33;
`;

interface IBlockedByBookingCompletionOverlayProps {
  timestamp: string | null | undefined;
  style?: CSSProperties;
  className?: string;
}
function BlockedByBookingCompletionOverlay(props: IBlockedByBookingCompletionOverlayProps) {
  const { timestamp, className, style } = props;

  const bookingCompletionDate = useLatestBookingCompletionDate(false);

  return (
    <>
      {timestamp && bookingCompletionDate && bookingCompletionDate >= getCurrentDayDate(timestamp) && (
        <Wrapper
          className={className}
          style={style}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {/* no content */}
        </Wrapper>
      )}
    </>
  );
}

export default memo(BlockedByBookingCompletionOverlay);
