import { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { useVehicleSearchAPI } from 'api/controllers/InfiniteSearchAPI';
import { useVehiclesAPI } from 'api/controllers/VehiclesAPI';
import { IVehicle } from 'api/models/Vehicle';
import { UiState } from 'state/UiState';
import { VehicleFilterState } from 'state/VehicleFilterState';
import { Clickable } from 'components/common/atoms/Clickable';
import { HighlightedLink } from 'components/common/atoms/Link';
import { List, ListHead } from 'components/common/atoms/List';
import { ContentPageHead } from 'components/common/elements/ContentPageHead';
import InfinityScrollingWrapper from 'components/common/elements/InfinityScrollingWrapper';
import { VehicleListRow } from 'components/common/elements/vehicle/VehicleListRow';
import { VehicleTile } from 'components/common/elements/vehicle/VehicleTile';
import { ButtonSecondary } from 'components/common/inputs/Button';
import { LoadingSpinner } from 'components/common/loaders/LoadingSpinner';
import { Plus } from 'components/icons/Plus';
import { VehicleFilter } from 'components/content/vehicles/VehicleFilter';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

const StyledList = styled(List)`
  --list-template: 2.5rem 5rem 1fr 6.5rem 5.5rem 7rem 20rem;
`;

const LoadMoreButton = styled(HighlightedLink).attrs({ as: Clickable })<{ onClick: () => void } & any>`
  display: flex;
  pointer-events: auto;
  user-select: auto;
  padding-block: 0.25rem;
  padding-inline: 0.5rem;
`;

export const VehiclesList = () => {
  const uiState = useRecoilValue(UiState);
  const [searchParams] = useRecoilState(VehicleFilterState);

  const { status, data, hasNextPage, fetchNextPage, error, isFetching } = useVehicleSearchAPI(searchParams);
  const vehicleAPI = useVehiclesAPI();

  const vehicles = (data?.pages.map((page) => page.items).flat() as IVehicle[]) || [];

  const removeItem = (id: string) => {
    vehicleAPI
      .deleteVehicle(id)
      .then((res) => window.location.reload())
      .catch(() => console.error('error'));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [uiState.vehicleList.activeTab]);

  return (
    <Wrapper>
      <ContentPageHead title={`Fuhrpark Übersicht`} listName="vehicleList">
        <FlexWrapper>
          <Link to={'/vehicles/add'}>
            <ButtonSecondary>
              <Plus /> Fahrzeug anlegen
            </ButtonSecondary>
          </Link>

          <VehicleFilter />
        </FlexWrapper>
      </ContentPageHead>

      {data &&
        data.pages &&
        (uiState.vehicleList.activeTab === 'tile' ? (
          <InfiniteScroll
            dataLength={vehicles.length}
            next={fetchNextPage}
            hasMore={hasNextPage}
            loader={<LoadingSpinner />}
            style={{ overflow: 'visible' }}
            endMessage={<p>Keine weiteren Fahrzeuge</p>}
          >
            <InfinityScrollingWrapper>
              {(!isFetching || vehicles.length > 0) &&
                vehicles.map((vehicle) => <VehicleTile key={vehicle.id} vehicle={vehicle} onUpdate={() => removeItem(vehicle.id)} />)}
            </InfinityScrollingWrapper>
          </InfiniteScroll>
        ) : (
          <StyledList>
            <ListHead>
              <div>Kennzeichen</div>
              <div>Fahrer</div>
              <div>Fahrzeug</div>
              <div>Verfügbarkeit</div>
            </ListHead>
            {(!isFetching || vehicles.length > 0) &&
              vehicles.map((vehicle, index) => (
                <VehicleListRow key={vehicle.id} vehicle={vehicle} onUpdate={() => removeItem(vehicle.id)} />
              ))}
          </StyledList>
        ))}
      {isFetching || (status === 'pending' && <LoadingSpinner />)}
      {error && <div>Es ist ein Fehler aufgetreten</div>}
      {hasNextPage && <LoadMoreButton onClick={fetchNextPage}>Mehr anzeigen</LoadMoreButton>}
    </Wrapper>
  );
};
