import { PropsWithChildren, ReactNode, useState } from 'react';
import { Collapsible } from 'components/common/atoms/Collapsible';
import { ListRowWrapper, Row } from 'components/common/atoms/List';
import { ChevronDown } from 'components/icons/ChevronDown';
import styled from 'styled-components';

const StyledListRowWrapper = styled(ListRowWrapper)`
  padding-block: 0.75rem;
`;

export const CollapsibleRow = (props: PropsWithChildren<{ content: ReactNode }>) => {
  const { content, children } = props;

  const [open, setOpen] = useState(false);

  return (
    <StyledListRowWrapper
      as="div"
      onClick={(e) => {
        if (!e.isDefaultPrevented()) {
          setOpen(!open);
        }
      }}
    >
      <Row>
        {children}
        <ChevronDown invert={open} />
      </Row>
      <Collapsible open={open}>{content}</Collapsible>
    </StyledListRowWrapper>
  );
};
