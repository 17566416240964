import { useCallback } from 'react';
import { ButtonPrimary, ButtonSecondary } from 'components/common/inputs/Button';
import { LoadingSpinner } from 'components/common/loaders/LoadingSpinner';
import { useShowDialog } from 'state/DialogState';
import { usePassengersAPI } from 'api/controllers/PassengersAPI';

export const useShowDeletePassengerDialog = (id: string) => {
  const showDialog = useShowDialog();

  const passengersAPI = usePassengersAPI();

  return useCallback(
    (onSuccess?: () => void) => {
      showDialog({
        headline: 'Fahrgast entfernen',
        body: <>Möchten Sie diesen Fahrgast wirklich löschen?</>,
        buttons: ({ loading = false }) => (
          <>
            <ButtonPrimary
              disabled={loading}
              onClick={() => {
                showDialog({ loading: true }, true);

                passengersAPI
                  .deletePassenger(id)
                  .then(() => {
                    showDialog(null);
                    onSuccess?.();
                  })
                  .catch((e) => {
                    console.error('delete passenger', e);

                    // show error message
                    showDialog({
                      headline: 'Fahrgast entfernen',
                      body: <>Der Fahrgast konnte nicht entfernt werden. Bitte versuchen Sie es später erneut.</>,
                    });
                  });
              }}
            >
              {loading ? <LoadingSpinner /> : 'Entfernen'}
            </ButtonPrimary>
            <ButtonSecondary disabled={loading} onClick={() => showDialog(null)}>
              Abbrechen
            </ButtonSecondary>
          </>
        ),
      });
    },
    [id, showDialog, passengersAPI],
  );
};
