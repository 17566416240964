import { css } from 'styled-components';
import { Colors } from 'styles/Colors';

// const SCROLLBAR_WIDTH = '.375rem';
const SCROLLBAR_WIDTH = '.625rem';

export const ScrollbarStyle = css`
  /* width */
  ::-webkit-scrollbar {
    inline-size: ${SCROLLBAR_WIDTH};

    &:horizontal {
      block-size: ${SCROLLBAR_WIDTH};
    }
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background-color: transparent;
    transition: background-color 150ms ease-out;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 0.25rem;

    border-inline-end: ${SCROLLBAR_WIDTH} solid ${Colors.grey500};
    transition: border-color 150ms ease-out;

    &:horizontal {
      border-inline-end: none;
      border-block-end: ${SCROLLBAR_WIDTH} solid ${Colors.grey500};
    }
  }

  /* Handle on hover */
  &:hover {
    /* Track */
    ::-webkit-scrollbar-track {
      background-color: ${Colors.grey400};
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      border-color: ${Colors.grey600};
    }
  }
`;
