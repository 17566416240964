import { ICustomerCategory } from 'api/models/CustomerCategory';
import { IAPIModelBase } from 'api/models/index';

export interface ICalendarException extends IAPIModelBase {
  name: string;
  beginDate: Date;
  endDate: Date;
  publicHoliday: boolean;
  customerCategory?: ICustomerCategory;
  customerCategoryId?: string;
}


export const emptyCalendarException: ICalendarException = {
  id: '',
  name: '',
  beginDate: new Date(),
  endDate: new Date(),
  publicHoliday: false,
  customerCategoryId: '',
  createdAt: '',
  updatedAt: ''
}
