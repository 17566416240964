import { FC } from 'react';
import { TourLabel } from 'components/common/labels/TourLabel';
import styled from 'styled-components';
import { Colors } from 'styles/Colors';
import { Body4, Headline3 } from 'styles/FontStyles';
import { Link } from 'components/common/atoms/Link';
import { Facilities } from 'components/icons/Facilities';
import { Wheelchair } from 'components/icons/Wheelchair';
import { Walker } from 'components/icons/Walker';
import { IConflict } from 'api/models/Conflict';
import { IPassenger } from 'api/models/Passenger';

const TourItemHead = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.5rem;

  ${Body4};

  & > * {
    min-block-size: 1.75rem;
  }
`;

const TourItemHeadContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;

  flex: 1;
`;

const DirectionHint = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  margin-inline-end: 1rem;
`;

const CustomerName = styled.h3`
  ${Headline3};
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  svg {
    flex-shrink: 0;
    margin-block: -1rem;
    opacity: 0;
    margin-inline-end: -1.5rem;
    transition: opacity 150ms ease-out, margin-inline-end 150ms ease-out;
  }

  &:hover {
    svg {
      opacity: 1;
      margin-inline-end: 0;
    }
  }
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  color: ${Colors.grey600};

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

interface TourInfoViewProps {
  name: string;
  direction: string | undefined;
  conflicts: IConflict[];
  timestamp: string;
  customer: any;
  passengersWheelchair: Partial<IPassenger>[];
  passengersWalking: Partial<IPassenger>[];
}

const TourInfoView: FC<TourInfoViewProps> = ({
  name,
  direction,
  conflicts,
  timestamp,
  customer,
  passengersWheelchair,
  passengersWalking,
}) => {
  return (
    <TourItemHead>
      <TourItemHeadContent>
        <TourLabel>{name}</TourLabel>
        <DirectionHint>{direction === 'return' ? 'Rück' : 'Hin'}</DirectionHint>
        <Link href={`/customers/${customer?.id}`} target={'_blank'} $displayContents={true}>
          <CustomerName>
            <span>{customer?.displayName}</span>
            <Facilities />
          </CustomerName>
        </Link>
        {passengersWheelchair.length > 0 && (
          <IconWrapper>
            {passengersWheelchair.length} <Wheelchair />
          </IconWrapper>
        )}
        {passengersWalking.length > 0 && (
          <IconWrapper>
            {passengersWalking.length} <Walker />
          </IconWrapper>
        )}
      </TourItemHeadContent>
    </TourItemHead>
  );
};

export default TourInfoView;
