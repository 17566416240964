// General
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { ICalendarException, emptyCalendarException } from 'api/models/CalendarException';
import { useNavigate, useParams } from 'react-router-dom';
import { useCalendarExceptionAPI } from 'api/controllers/CalendarExceptionAPI';
import { LoadingSpinner } from 'components/common/loaders/LoadingSpinner';
import { TabStatusBarLayout } from 'components/common/elements/TabStatusBarLayout';
import { CalendarExceptionDetails } from 'components/content/calendar-exceptions/CalendarExceptionDetails';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;

  padding: 2.25rem var(--content-indent) 4.75rem;
`;

export const CalendarExceptionDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const calendarExceptionAPI = useCalendarExceptionAPI();
  const [calendarException, setCalendarException] = useState<ICalendarException>(emptyCalendarException);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      calendarExceptionAPI
        .getCalendarExceptionById({ id })
        .then((res) => {
          setCalendarException(res.data);
        })
        .catch(() => navigate('/404'))
        .finally(() => setLoading(false));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Wrapper>
      {loading && <LoadingSpinner />}

      {!loading && calendarException && (
        <TabStatusBarLayout
          breadcrumb={{
            title: 'Kalender-Ausnahmen',
            href: '/calendar-exceptions',
          }}
          subPageBreadcrumb={{
            title: calendarException.name,
            href: `/calendar-exceptions/${calendarException.id}`,
          }}
          items={[
            {
              title: 'Informationen',
              content: <CalendarExceptionDetails calendarException={calendarException} />,
              hash: '',
            },
          ]}
        />
      )}
    </Wrapper>
  );
};
