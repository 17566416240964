import styled from 'styled-components';
import { Body1Bold, Body4, Body4Bold } from 'styles/FontStyles';
import { Colors } from 'styles/Colors';
import { Card } from 'components/common/atoms/Card';
import { ActiveLabel } from 'components/common/labels/ActiveLabel';
import { Link } from 'components/common/atoms/Link';
import { Bus } from 'components/icons/Bus';
import { FC, memo } from 'react';
import { Edit } from 'components/icons/Edit';
import { Trash } from 'components/icons/Trash';
import { IVehicle } from 'api/models/Vehicle';
import { Clickable } from 'components/common/atoms/Clickable';
import { useNavigate } from 'react-router-dom';
import { useShowDeleteVehicleDialog } from 'hooks/vehicles/useShowDeleteVehicleDialog';
import { Wheelchair } from 'components/icons/Wheelchair';
import { Employee } from 'components/icons/Employee';
import { LocationTag } from '../LocationTag';
import { BREAKPOINT_MD } from 'styles/Breakpoints';
import { formatName } from 'utils/nameUtils';
import { useHasPermission } from 'state/UserMeState';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const ContextOptionsWrapper = styled.div`
  display: none;

  ${BREAKPOINT_MD} {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
`;

const ContextOption = styled(Clickable).attrs(({ onClick }: any) => (!onClick ? { as: Link } : {}))<{ href?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;

  ${Body4};
  padding: 0 1rem 0.5rem;

  &:hover {
    text-decoration: underline;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    object-fit: contain;
  }
`;

const StatusCard = styled(Card)`
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
`;

const StatusContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
`;

const StatusTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

const StatusTitle = styled.h2`
  ${Body1Bold};
`;

const StatusRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem 2rem;
  flex-wrap: wrap;
`;

const InfoTag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  ${Body4};

  svg {
    width: 1.5rem;
    height: 1.5rem;
    object-fit: contain;
  }

  a {
    ${Body4Bold};
    color: ${Colors.secondary};
  }
`;

const ContextMenu = memo((props: { vehicle: IVehicle }) => {
  const { vehicle } = props;

  const navigate = useNavigate();
  const showDeleteVehiclesDialog = useShowDeleteVehicleDialog(vehicle.id);
  const isAdmin = useHasPermission('admin');

  return (
    <ContextOptionsWrapper>
      <ContextOption href={`/vehicles/${vehicle.id}/edit`}>
        <Edit /> Bearbeiten
      </ContextOption>
      {isAdmin && (
        <ContextOption href={''} onClick={() => showDeleteVehiclesDialog(() => navigate('/vehicles', { replace: true }))}>
          <Trash /> Entfernen
        </ContextOption>
      )}
    </ContextOptionsWrapper>
  );
});

export const VehiclesDetailsStatusCard: FC<{ vehicle: IVehicle }> = (props) => {
  const { vehicle } = props;

  const { drivers = [] } = vehicle;

  return (
    <Wrapper>
      <ContextMenu vehicle={vehicle} />

      <StatusCard>
        <StatusContentWrapper>
          <StatusTitleWrapper>
            <StatusTitle>{vehicle.licensePlate}</StatusTitle>
            <ActiveLabel active state={'Einsatzbereit'} />
          </StatusTitleWrapper>
          <StatusRow>
            {vehicle.hasRoomForWheelchairs && (
              <InfoTag>
                <Wheelchair />
                für Rollstuhltransport
              </InfoTag>
            )}
            <InfoTag>
              <Bus />
              {[vehicle.brandName, vehicle.modelName, vehicle.color].filter(Boolean).join(' / ')}
            </InfoTag>
            {drivers.length > 0 && (
              <>
                <InfoTag>
                  <Employee /> {formatName(drivers[0])}
                </InfoTag>
                <LocationTag address={drivers[0].region} />
              </>
            )}
          </StatusRow>
        </StatusContentWrapper>
      </StatusCard>
    </Wrapper>
  );
};
