import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { Colors } from 'styles/Colors';
import { Close } from 'components/icons/Close';
import { Clickable } from '../atoms/Clickable';
import { Body1Bold } from 'styles/FontStyles';
import { BREAKPOINT_LG, BREAKPOINT_SM } from 'styles/Breakpoints';
import { ButtonSecondary } from '../inputs/Button';
import { UseFormReturn } from 'react-hook-form/dist/types';
import { Form } from '../inputs/Form';

const Backdrop = styled.div<{ open?: boolean }>`
  display: flex;
  flex-direction: column;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: 1500;

  transition: opacity 300ms ease-out, visibility 300ms ease-out;

  opacity: ${({ open }) => (open ? 1 : 0)};
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  background: rgba(0, 0, 0, 0.8);

  pointer-events: ${({ open }) => (open ? 'all' : 'none')};
`;

const DialogWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;

  width: 100%;
  max-width: 44rem;
  min-height: 15rem;

  margin: auto;
  padding: 2rem 1rem;
  overflow-y: auto;

  background-color: ${Colors.white50};
  border-radius: 8px;

  text-align: center;

  ${BREAKPOINT_LG} {
    padding: 2rem;
    gap: 2rem;
  }
`;

const Headline = styled.h2`
  ${Body1Bold};
  padding-inline: 2rem;
`;

const CloseButton = styled(Clickable)`
  position: absolute;
  z-index: 1;
  display: flex;
  top: 2.25rem;
  right: 2rem;
  background-color: ${Colors.white50};
  padding: 0.25rem;
  margin: -0.25rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const ButtonWrapper = styled.div`
  margin-block-start: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  ${BREAKPOINT_SM} {
    flex-direction: row;
  }

  ${BREAKPOINT_LG} {
    flex-direction: row;

    & > *:first-child:not(:last-child) {
      margin-inline-end: auto;
    }

    & > *:last-child:not(:first-child) {
      margin-inline-start: auto;
    }
  }
`;

interface IDialogProps {
  open: boolean;
  onClose?: () => void;
  onClosed?: () => void;
  headline?: ReactNode;
  text?: ReactNode;
  buttons?: ReactNode | null;
  className?: string;
  form?: UseFormReturn<any>;
  onSubmit?: (values: any) => void;
}

export const Dialog: FC<IDialogProps> = (props) => {
  const { open, onClose, headline, text, buttons, onClosed, className, form, onSubmit } = props;

  return (
    <Backdrop open={open} tabIndex={open ? 0 : -1} onTransitionEnd={() => !open && onClosed?.()}>
      <DialogWrapper
        className={className}
        {...((form
          ? {
              as: Form,
              form,
              onSubmit,
            }
          : {}) as any)}
      >
        {onClose && (
          <CloseButton onClick={onClose}>
            <Close />
          </CloseButton>
        )}

        {headline && <Headline>{headline}</Headline>}

        <TextWrapper>{text}</TextWrapper>

        {buttons !== null && <ButtonWrapper>{buttons || <ButtonSecondary onClick={onClose}>Okay</ButtonSecondary>}</ButtonWrapper>}
      </DialogWrapper>
    </Backdrop>
  );
};
