import { FieldErrors, FieldValues, UseFormReturn } from 'react-hook-form/dist/types';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { FormProvider } from 'react-hook-form';

const FormTag = styled.form`
  appearance: none;
  border: 0;

  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

interface IFormProps<T extends FieldValues> {
  className?: string;
  form: UseFormReturn<T>;
  onSubmit: (values: T) => void;
  onInvalid?: (values: FieldErrors<T>) => void;
}

export const Form = <T extends FieldValues>(props: PropsWithChildren<IFormProps<T>>) => {
  const { className, form, onSubmit, onInvalid, children } = props;

  return (
    <FormProvider {...form}>
      <FormTag
        className={className}
        onSubmit={(e) => {
          e.stopPropagation();
          form.handleSubmit(onSubmit, onInvalid)(e);
        }}
      >
        {children}
      </FormTag>
    </FormProvider>
  );
};
