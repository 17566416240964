import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IScheduledTour, IScheduledTourException } from 'api/models/ScheduledTour';
import { BREAKPOINT_MD } from 'styles/Breakpoints';
import { Colors } from 'styles/Colors';
import { getCurrentDayDate } from 'utils/dateUtils';
import { Button, ButtonPrimary, ButtonSecondary } from 'components/common/inputs/Button';
import { Calendar } from 'components/common/inputs/Calendar';
import { LoadingSpinner } from 'components/common/loaders/LoadingSpinner';
import { Dialog } from 'components/common/elements/Dialog';
import { IReactivateTourPayload } from 'api/controllers/scheduled-tour-exceptions/ScheduledTourExceptionsAPI';

const ButtonRed = styled(ButtonPrimary)`
  background-color: ${Colors.signalRed900};
  color: ${Colors.textInverted};
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;

  text-align: start;
`;

const GridRow = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;

  ${BREAKPOINT_MD} {
    grid-template-columns: 1fr 1fr;
  }
`;

const SeparatorRow = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  text-align: center;
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.25rem;

  color: ${Colors.grey700};
`;

interface IReactivateTourDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  scheduledTour: IScheduledTour;
  exception: IScheduledTourException;
  timestamp?: string;
  onSubmit: (config: IReactivateTourPayload, setLoading: (loading: boolean) => void) => void;
  onDelete: (exceptionId: string) => void;
}
export default function ReactivateTourDialog(props: IReactivateTourDialogProps) {
  const { open, setOpen, scheduledTour, timestamp, exception, onSubmit, onDelete } = props;
  const { name, direction } = scheduledTour;

  const [loading, setLoading] = useState(false);

  const minDate = getCurrentDayDate(exception.startDate);
  const maxDate = exception.endDate ? getCurrentDayDate(exception.endDate) : null;

  const [startDate, setStartDate] = useState<Date>(getCurrentDayDate(minDate));
  const [endDate, setEndDate] = useState<Date | null>(maxDate);

  useEffect(() => {
    if (open) {
      setStartDate(getCurrentDayDate(timestamp));
      setEndDate(getCurrentDayDate(timestamp));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      headline={`Fahrt ${name} (${direction === 'return' ? 'Rück' : 'Hin'}) reaktivieren`}
      text={
        <Body>
          <p>
            Soll die Fahrt{' '}
            <b>
              {name} ({direction === 'return' ? 'Rück' : 'Hin'})
            </b>{' '}
            im folgenden Zeitraum wieder aktiviert werden?
          </p>
          <GridRow>
            <Label>
              <b>Von</b>
              <Calendar
                title={'TT/MM/JJJJ'}
                value={startDate}
                setValue={setStartDate as any}
                minDate={minDate}
                maxDate={maxDate}
                disabled={true}
                dropdownStyling
              />
            </Label>
            <Label>
              <b>Bis</b>
              <Calendar
                title={'TT/MM/JJJJ'}
                value={endDate}
                setValue={setEndDate as any}
                minDate={startDate}
                maxDate={maxDate}
                dropdownStyling
              />
            </Label>
          </GridRow>
          <SeparatorRow>ODER</SeparatorRow>
          <SeparatorRow>
            <ButtonRed onClick={() => onDelete(exception.id)}>Ausnahme entfernen</ButtonRed>
          </SeparatorRow>
        </Body>
      }
      buttons={
        <>
          <ButtonSecondary onClick={() => setOpen(false)}>Abbrechen</ButtonSecondary>

          <Button onClick={() => onSubmit({ id: exception.id, start_date: startDate, end_date: endDate as Date }, setLoading)}>
            {loading ? <LoadingSpinner /> : 'Zeitraum anpassen'}
          </Button>
        </>
      }
    />
  );
}
