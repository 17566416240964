// General
import { FC } from 'react';

// Components
import { CardsWrapper, CardRow, CardColumn, Card, CardRowHeadline, SectionHeadline } from 'components/common/atoms/Card';

// Types
import { IVehicle } from 'api/models/Vehicle';
import { formatName } from 'utils/nameUtils';

export const VehicleDetailsInformation: FC<{ vehicle: IVehicle }> = (props) => {
  const { vehicle } = props;

  const { drivers = [] } = vehicle;

  return (
    <CardsWrapper>
      <SectionHeadline>Informationen</SectionHeadline>

      <Card>
        <CardRow>
          <CardRowHeadline>Kennzeichen</CardRowHeadline>
          <CardColumn>{vehicle.licensePlate || '-'}</CardColumn>
        </CardRow>
        <CardRow>
          <CardRowHeadline>Barrierefreiheit</CardRowHeadline>
          <CardColumn>{vehicle.hasRoomForWheelchairs ? 'für Rollstuhltransport' : '-'}</CardColumn>
        </CardRow>
        <CardRow>
          <CardRowHeadline>Marke</CardRowHeadline>
          <CardColumn>{vehicle.brandName || '-'}</CardColumn>
        </CardRow>
        <CardRow>
          <CardRowHeadline>Modell</CardRowHeadline>
          <CardColumn>{vehicle.modelName || '-'}</CardColumn>
        </CardRow>
        <CardRow>
          <CardRowHeadline>Farbe</CardRowHeadline>
          <CardColumn>{vehicle.color || '-'}</CardColumn>
        </CardRow>
        <CardRow>
          <CardRowHeadline>Fahrer</CardRowHeadline>
          <CardColumn>{formatName(drivers[0]) || '-'}</CardColumn>
        </CardRow>
      </Card>
    </CardsWrapper>
  );
};
