import styled from 'styled-components';
import { IEmployeeAbsence } from 'api/models/EmployeeAbsence';
import { FC, useCallback, useMemo } from 'react';
import { getCurrentDayDate } from 'utils/dateUtils';
import { Colors } from 'styles/Colors';

const AbsenceRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 0.5rem;
  padding-block: 0.625rem;
  border-radius: 0.25rem;

  @media (hover: hover) {
    :hover {
      cursor: pointer;
      box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
    }
  }
`;

const CurrentIndicator = styled.span`
  margin-inline-start: 0.5rem;
  color: ${Colors.secondary};
`;

const DateSum = styled.p<{ current?: boolean }>`
  padding-inline: 1rem;
  padding-block: 0.25rem;
  background: ${({ current = false }) => (current ? Colors.secondaryLight : Colors.grey400)};
  border-radius: 0.125rem;
`;

interface EmployeeAbsenceAccordionItemProps {
  id: string;
  item: IEmployeeAbsence;
  onClick: (value?: IEmployeeAbsence) => void;
}

export const EmployeeAbsenceAccordionItem: FC<EmployeeAbsenceAccordionItemProps> = (props) => {
  const { item, onClick } = props;
  const date = useMemo(() => getCurrentDayDate(), []);

  const isCurrentAbsence = useCallback(
    (startDate: string, endDate: string) => {
      const start = getCurrentDayDate(startDate);
      const end = getCurrentDayDate(endDate);
      return start <= date && end >= date;
    },
    [date],
  );

  const formatTimeSpan = (start: string, end?: string) => {
    const startDate = getCurrentDayDate(start);

    if (end) {
      const endDate = getCurrentDayDate(end);

      if (startDate.getFullYear() === endDate.getFullYear()) {
        if (startDate.getMonth() === endDate.getMonth()) {
          if (startDate.getDay() === endDate.getDay()) {
            return endDate.toLocaleDateString(undefined, { dateStyle: 'medium' });
          } else {
            return `${startDate.toLocaleDateString(undefined, { day: '2-digit' })}. - ${endDate.toLocaleDateString(undefined, {
              dateStyle: 'medium',
            })}`;
          }
        } else {
          return `${startDate.toLocaleDateString(undefined, { day: '2-digit', month: '2-digit' })} - ${endDate.toLocaleDateString(
            undefined,
            { dateStyle: 'medium' },
          )}`;
        }
      } else {
        return `${startDate.toLocaleDateString(undefined, { dateStyle: 'medium' })} - ${endDate.toLocaleDateString(undefined, {
          dateStyle: 'medium',
        })}`;
      }
    } else {
      return `seit ${startDate.toLocaleDateString(undefined, { dateStyle: 'medium' })}`;
    }
  };

  return (
    <AbsenceRow onClick={() => onClick(item)}>
      <p>
        {formatTimeSpan(item.startDate, item.endDate)}
        {isCurrentAbsence(item.startDate, item.endDate) && <CurrentIndicator>(aktuell)</CurrentIndicator>}
      </p>
      <DateSum current={isCurrentAbsence(item.startDate, item.endDate)}>
        {item.workDays} {item.workDays === 1 ? 'Tag' : 'Tage'}
      </DateSum>
    </AbsenceRow>
  );
};
