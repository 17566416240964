import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { usePassengersAPI } from 'api/controllers/PassengersAPI';
import { ICustomer } from 'api/models/Customer';
import { IPassenger } from 'api/models/Passenger';
import { ITour } from 'api/models/Tour';
import { Body2 } from 'styles/FontStyles';
import { Section } from 'components/common/atoms/Form';
import { LoadingSpinner } from 'components/common/loaders/LoadingSpinner';
import { DragDropContext, useAvailableDragDropItems } from '../tourplan/DragDropContext';
import { TourplanAvailablePassengersOverlay } from '../tourplan/overlays/TourplanAvailablePassengersOverlay';
import { TourplanNewTourTile } from '../tourplan/tiles/TourplanNewTourTile';
import { TourplanTourTile, TourplanToursWrapper } from '../tourplan/tiles/TourplanTourTile';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1.5rem;

  flex: 1;

  position: relative;

  ${Body2};
`;

interface ICustomerOrderFormPageToursProps {
  customer: ICustomer | null;
  selectedPassengers: string[];
  importedPassengers: Partial<IPassenger>[];
  direction?: 'outwards' | 'return';
}

export const CustomerOrderFormPageTours: FC<ICustomerOrderFormPageToursProps> = memo((props) => {
  const { customer, selectedPassengers = [], importedPassengers = [], direction } = props;

  const form = useFormContext();
  const tours = useFieldArray({ name: 'tours', control: form.control, keyName: 'formId' });
  const toursToDelete = useFieldArray({ name: 'toursToDelete', control: form.control, keyName: 'formId' });

  const [availablePassengers, setAvailablePassengers] = useAvailableDragDropItems<IPassenger>();
  const [loading, setLoading] = useState(false);

  const passengersAPI = usePassengersAPI();

  useEffect(() => {
    if (selectedPassengers.length > 0) {
      setLoading(true);
      passengersAPI
        .getPassengers({
          offset: 0,
          limit: -1,
          ids: selectedPassengers,
          includeDrafts: true,
        })
        .then((res) => {
          const passengersInTours = (tours.fields as unknown as ITour[])
            .filter((tour) => !direction || tour.direction === direction)
            .flatMap(({ passengers = [] }) => passengers.map(({ id }) => id));
          const [available, planned]: [IPassenger[], IPassenger[]] = (res.data?.rows || []).reduce(
            (res: [IPassenger[], IPassenger[]], item: IPassenger) => {
              res[passengersInTours.includes(item.id) ? 1 : 0].push(item);
              return res;
            },
            [[], []],
          );

          // set available
          setAvailablePassengers(available.sort((a, b) => a.lastName?.localeCompare(b.lastName)));
          // enrich passenger data in tours (if necessary)
          for (let i = 0; i < tours.fields.length; i++) {
            const tour = tours.fields[i] as Partial<ITour>;
            if (
              (tour.passengers || []).some(
                (passenger) => !passenger.hasOwnProperty('customerId') || !selectedPassengers.includes(passenger.id?.toString()),
              )
            ) {
              // check if passengers are in other tours
              const hasPassengersInOtherTours =
                direction === 'outwards' &&
                (tour.passengers || []).some(({ id }: IPassenger) =>
                  (tours.fields as any[]).some(
                    ({ direction, passengers = [] }) =>
                      direction === 'return' && passengers.some((passenger: IPassenger) => passenger.id === id),
                  ),
                );
              const getPassengersString = (passengers: IPassenger[]) =>
                passengers
                  .map(({ id }) => id)
                  .sort((a, b) => a.localeCompare(b))
                  .join(',');
              const hasSameReturnTour = (tours.fields as any[]).some(({ direction, name, passengers = [] }) => {
                return (
                  direction === 'return' &&
                  name === tour.name &&
                  getPassengersString(passengers) === getPassengersString(tour.passengers || [])
                );
              });

              // update tour data
              tours.update(i, {
                ...tour,
                passengers: (tour.passengers || [])
                  .filter(({ id }) => selectedPassengers.includes(id?.toString()))
                  .map((passenger) => ({
                    ...passenger,
                    ...(planned.find(({ id }: IPassenger) => id === passenger.id) || {}),
                  })),
                copyForReturnTourDisabled: !hasSameReturnTour && hasPassengersInOtherTours,
                copyForReturnTour: hasSameReturnTour,
              });
            }
          }
        })
        .catch((e) => {
          console.error('error', e);
          setAvailablePassengers([]);
        })
        .finally(() => setLoading(false));
    }

    if (importedPassengers.length > 0) {
      const passengersInTours = (tours.fields as unknown as ITour[])
        .filter((tour) => !direction || tour.direction === direction)
        .flatMap(({ passengers = [] }) => passengers.map(({ id }) => id));
      setAvailablePassengers(importedPassengers.filter(({ id }) => !passengersInTours.includes(id || '')));
    }

    if (selectedPassengers.length < 1 && importedPassengers.length < 1) {
      setAvailablePassengers([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPassengers, importedPassengers, direction]);

  const visibleFields = useMemo(() => {
    return tours.fields.filter((tour: Partial<ITour> & any) => !direction || tour.direction === direction);
  }, [tours.fields, direction]);

  const onSetCopyForReturnTour = useCallback(
    (formId: string, copy: boolean) => {
      const index = tours.fields.findIndex((tour) => formId === tour.formId);
      if (index >= 0) {
        tours.update(index, { ...tours.fields[index], copyForReturnTour: copy });
      }
    },
    [tours],
  );

  const onSetIgnoreCalenderExceptionTour = useCallback(
    (formId: string, copy: boolean) => {
      const index = tours.fields.findIndex((tour) => formId === tour.formId);
      if (index >= 0) {
        tours.update(index, { ...tours.fields[index], ignoreCalendarException: copy });
      }
    },
    [tours],
  );

  const tourplanView = useMemo(
    () => (
      <TourplanToursWrapper>
        {visibleFields.map((tour: Partial<ITour> & any) => {
          return (
            <TourplanTourTile
              key={tour.id}
              tour={tour}
              customer={customer || undefined}
              {...(direction === 'outwards'
                ? {
                    copyForReturnTourDisabled: tour.copyForReturnTourDisabled || false,
                    copyForReturnTour: tour.copyForReturnTour || false,
                    onSetCopyForReturnTour: (copy) => onSetCopyForReturnTour(tour.formId, copy),
                  }
                : {})}
              onSetIgnoreCalenderExceptionTour={(copy: boolean) => onSetIgnoreCalenderExceptionTour(tour.formId, copy)}
              onRemove={() => {
                toursToDelete.append(tour.id);
              }}
            />
          );
        })}
        <TourplanNewTourTile />
      </TourplanToursWrapper>
    ),
    [visibleFields, direction, customer, onSetIgnoreCalenderExceptionTour, onSetCopyForReturnTour, toursToDelete],
  );

  const availablePassengersOverlay = useMemo(
    () => (
      <TourplanAvailablePassengersOverlay
        availablePassengers={availablePassengers}
        totalPassengers={(tours.fields as any[])
          .filter((tour) => !direction || tour.direction === direction)
          .flatMap(({ passengers = [] }) => passengers)
          .sort((a, b) => a.lastName?.localeCompare(b.lastName))}
        count={selectedPassengers.length + importedPassengers.length}
      />
    ),
    [availablePassengers, tours.fields, direction, importedPassengers.length, selectedPassengers.length],
  );

  return (
    <DragDropContext
      dataSource={tours as any}
      itemsKey={'passengers'}
      tourPrefix={customer?.internalId}
      direction={direction || 'outwards'}
    >
      <Wrapper>
        <Section>
          {loading && <LoadingSpinner />}
          {!loading && (
            <>
              {tourplanView}
              {availablePassengersOverlay}
            </>
          )}
        </Section>
      </Wrapper>
    </DragDropContext>
  );
});
