import styled from 'styled-components';
import { FC, MouseEventHandler } from 'react';
import { Colors } from 'styles/Colors';
import { ContextOptions } from '../ContextOptions';
import { Trash } from 'components/icons/Trash';
import { Edit } from 'components/icons/Edit';
import { Body1Bold, Body3, Body4, Body4Bold } from 'styles/FontStyles';
import { Phone } from 'components/icons/Phone';
import { Link } from 'components/common/atoms/Link';
import { getTelLink } from 'utils/phoneUtils';
import { Mail } from 'components/icons/Mail';
import { IPassenger } from 'api/models/Passenger';
import { Avatar } from 'components/common/atoms/Avatar';
import { Facilities } from 'components/icons/Facilities';
import { Wheelchair } from 'components/icons/Wheelchair';
import { Walker } from 'components/icons/Walker';
import { useShowDeletePassengerDialog } from 'hooks/passengers/useShowDeletePassengerDialog';
import { formatName } from 'utils/nameUtils';
import { useHasPermission } from 'state/UserMeState';
import { useNavigate } from 'hooks/useNavigate';

const TileWrapper = styled.div.attrs(({ onClick = null }) => (onClick ? { as: 'button', type: 'button' } : {}))<{
  onClick?: MouseEventHandler;
}>`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  background: ${Colors.white50};
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
  border-radius: 8px;

  padding: 1rem;

  transition: box-shadow 150ms ease-out, border-radius 150ms ease-out;

  &:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    border-radius: 8px;
  }
`;

const TileHeadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 1.75rem;
`;

const StyledAvatar = styled(Avatar)`
  ${Body1Bold};
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.125rem;

  flex: 1;
  align-self: center;

  ${Body3};
  text-align: left;
`;

const Title = styled.div`
  ${Body1Bold};
`;

const ContextMenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
`;

const LabelIconWrapper = styled.div`
  display: contents;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    color: ${Colors.grey600};
    pointer-events: none;
  }
`;

const TagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.5rem 2rem;
  flex-wrap: wrap;
  min-block-size: 1.5rem;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const Tag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  overflow: hidden;

  ${Body4};

  svg {
    width: 1.5rem;
    height: 1.5rem;
    object-fit: contain;
  }

  a {
    ${Body4Bold};
    color: ${Colors.secondary};
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const PassengerTile: FC<{ passenger: IPassenger; onUpdate?: () => void }> = (props) => {
  const { id, firstName, lastName, hasWheelchair, customer, contactPersons = [] } = props.passenger;

  const navigate = useNavigate();
  const showDeletePassengerDialog = useShowDeletePassengerDialog(id);
  const isAdmin = useHasPermission('admin');

  const contactPhone = contactPersons.find(({ phoneNumber }) => !!phoneNumber);
  const contactEmail = contactPersons.find(({ emailAddress }) => !!emailAddress);

  const contactPhoneLabel = [formatName(contactPhone), contactPhone?.role].filter(Boolean).join('; ');
  const contactEmailLabel = [formatName(contactEmail), contactEmail?.role].filter(Boolean).join('; ');

  return (
    <TileWrapper
      onClick={(e) => {
        if (!e.isDefaultPrevented()) {
          navigate(`/passengers/${id}`);
        }
      }}
    >
      <TileHeadWrapper>
        <StyledAvatar src={''} firstName={firstName} lastName={lastName} size={'medium'} />
        <TitleWrapper>
          {firstName}
          <Title>{lastName}</Title>
        </TitleWrapper>
        <ContextMenuWrapper>
          <LabelIconWrapper>{hasWheelchair ? <Wheelchair /> : <Walker />}</LabelIconWrapper>
          <ContextOptions
            as="div"
            items={
              [
                isAdmin && {
                  content: (
                    <>
                      <Trash /> Entfernen
                    </>
                  ),
                  onClick: () => showDeletePassengerDialog(props.onUpdate),
                },
                {
                  content: (
                    <>
                      <Edit /> Bearbeiten
                    </>
                  ),
                  onClick: () => navigate(`/passengers/${id}/edit`),
                },
              ].filter(Boolean) as []
            }
          />
        </ContextMenuWrapper>
      </TileHeadWrapper>
      <TagsWrapper>
        {contactPhone && (
          <Tag>
            <Phone />
            <Link href={getTelLink(contactPhone?.phoneNumber) || ''}>{contactPhone.formattedPhoneNumber}</Link>
            {contactPhoneLabel && <span>({contactPhoneLabel})</span>}
          </Tag>
        )}
        {contactEmail && (
          <Tag>
            <Mail />
            <Link href={`mailto:${contactEmail.emailAddress}`}>{contactEmail.emailAddress}</Link>
            {contactEmailLabel && <span>({contactEmailLabel})</span>}
          </Tag>
        )}
      </TagsWrapper>
      {customer && (
        <TagsWrapper>
          <Tag>
            <Facilities /> {customer.displayName}
          </Tag>
        </TagsWrapper>
      )}
    </TileWrapper>
  );
};
