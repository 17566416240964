// General
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useCustomerCategoryAPI } from 'api/controllers/CustomerCategoryAPI';
import { ICustomerCategory, emptyCustomerCategory } from 'api/models/CustomerCategory';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingSpinner } from 'components/common/loaders/LoadingSpinner';
import { TabStatusBarLayout } from 'components/common/elements/TabStatusBarLayout';
import { CustomerCategoryDetails } from 'components/content/customer-categories/CustomerCategoryDetails';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;

  padding: 2.25rem var(--content-indent) 4.75rem;
`;

export const CustomerCategoryDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const customerCategoryAPI = useCustomerCategoryAPI();
  const [customerCategory, setCustomerCategory] = useState<ICustomerCategory>(emptyCustomerCategory);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      customerCategoryAPI
        .getCustomerCategoryById({ id })
        .then((res) => {
          setCustomerCategory(res.data.data);
        })
        .catch(() => navigate('/404'))
        .finally(() => setLoading(false));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Wrapper>
      {loading && <LoadingSpinner />}

      {!loading && customerCategory && (
        <TabStatusBarLayout
          breadcrumb={{
            title: 'Kundenkategorien',
            href: '/customer-categories',
          }}
          subPageBreadcrumb={{
            title: customerCategory.name,
            href: `/customer-categories/${customerCategory.id}`,
          }}
          items={[
            {
              title: 'Informationen',
              content: <CustomerCategoryDetails customerCategory={customerCategory} />,
              hash: '',
            },
          ]}
        />
      )}
    </Wrapper>
  );
};
