import styled from 'styled-components';
import { Body4, Headline2 } from 'styles/FontStyles';
import { getCurrentDayDate } from 'utils/dateUtils';
import { PropsWithChildren, ReactNode } from 'react';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  ${Body4};

  em {
    ${Headline2};
    font-style: normal;
    margin-block: -1rem -0.5rem;
  }
`;

interface IDateTitleProps {
  timestamp: string;
  before?: ReactNode;
}

export default function DateTitle(props: PropsWithChildren<IDateTitleProps>) {
  const { timestamp, before, children } = props;

  return (
    <Wrapper>
      {before}
      <em>{getCurrentDayDate(timestamp).toLocaleDateString(undefined, { day: '2-digit' })}</em>
      <span>{getCurrentDayDate(timestamp).toLocaleDateString(undefined, { month: 'long', year: 'numeric' })}</span>
      <b>{getCurrentDayDate(timestamp).toLocaleDateString(undefined, { weekday: 'short' })}</b>
      {children}
    </Wrapper>
  );
}
