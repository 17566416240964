/* eslint-disable react-hooks/exhaustive-deps */
import styled, { css } from 'styled-components';
import { BREAKPOINT_MD, BREAKPOINT_SM } from 'styles/Breakpoints';
import { Filter } from 'components/icons/Filter';
import { FC, useEffect, useMemo, useState } from 'react';
import { Colors } from 'styles/Colors';
import { ContextMenu } from 'components/common/atoms/ContextMenu';
import { ButtonPrimary } from 'components/common/inputs/Button';
import { Body2Bold, Body3, Body4Bold } from 'styles/FontStyles';
import { Clickable } from 'components/common/atoms/Clickable';
import { ChevronDown } from 'components/icons/ChevronDown';
import { Collapsible } from 'components/common/atoms/Collapsible';
import { PlainCheckbox } from 'components/common/atoms/Checkbox';
import { SimpleSwitchSmall } from 'components/common/inputs/Switch';
import { Close } from 'components/icons/Close';
import { VehicleFilterState } from 'state/VehicleFilterState';
import { FetchPassengersParams } from 'api/controllers/InfiniteSearchAPI';
import { useRecoilState } from 'recoil';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
`;

const FilterButtonWrapper = styled.button.attrs({ type: 'button' })`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 3rem;
  width: 3rem;
  border-radius: 1.5rem;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
  background: ${Colors.white50};

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  @media (hover: hover) {
    transition: stroke-width 100ms ease-out;

    :hover {
      stroke-width: 1.5;
    }
  }

  ${BREAKPOINT_MD} {
    padding-inline: 1rem;
    padding-block: 0.75rem;
    width: auto;
    gap: 1rem;
  }
`;

const FilterLabel = styled.p`
  display: none;

  ${BREAKPOINT_MD} {
    display: initial;
    font-size: 1rem;
    color: var(--color-grey-600);
  }
`;

const Popup = styled(ContextMenu)`
  position: fixed;
  left: 0.5rem;
  right: 0.5rem;
  top: 0;
  bottom: 0;
  overflow: auto;

  ${BREAKPOINT_SM} {
    position: absolute;
    left: auto;
    right: 0;
    top: 100%;
    bottom: auto;
    width: 22.5rem;
  }

  margin-block: 0.75rem;
  padding-inline: 1rem;
  padding-block: 0.25rem;
  gap: 0;
  align-items: stretch;
`;

const CloseButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  padding-block: 1rem 0.5rem;

  ${BREAKPOINT_SM} {
    display: none;
  }
`;

const PopupRow = styled.div`
  ${Body3};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem;
  padding-block: 0.5rem;

  & > span {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
  }

  em {
    font-style: normal;
    color: ${Colors.secondary};
  }

  h4 {
    ${Body2Bold};
    margin-block: -0.5rem;
  }

  & > ${ButtonPrimary} {
    margin: auto;
  }
`;

const StyledCheckbox = styled(PlainCheckbox)`
  && {
    grid-template-columns: 1.5rem minmax(0, 1fr);

    p {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      justify-self: stretch;
    }
  }
`;

const Badge = styled.div`
  margin-block: -0.5rem;

  inline-size: 1.5rem;
  block-size: 1.5rem;
  border-radius: 50%;

  background-color: ${Colors.primary};
  overflow: hidden;

  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PopupSection = styled.div<{ hasBorderTop?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  ${({ hasBorderTop = false }) =>
    hasBorderTop &&
    css`
      border-block-start: 1px solid ${Colors.grey500};
      margin-block-start: 0.5rem;
      padding-block-start: 0.5rem;
    `};
`;

const StyledSwitch = styled(SimpleSwitchSmall)`
  flex: 1;
  flex-direction: row-reverse;
  justify-content: space-between;

  ${Body4Bold};
  color: ${Colors.textDefault};
`;

const FlyingBadge = styled(Badge)`
  position: absolute;
  inset-inline-end: 0;
  inset-block-start: 0.25rem;
  transform: scale(0.85);
`;

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 12.5rem;
  overflow-y: auto;
  padding-inline-end: 0.5rem;
`;

const StyledClickable = styled(Clickable)`
  &:disabled {
    color: ${Colors.grey700};
  }
`;

interface IFilterButtonProps {
  filterOptions: any;
  handleReset: () => void;
}

export interface IFilterOption {
  key: string;
  title: string;
  region?: string;
}

export interface IFilter {
  [key: string]: string | string[];
}
export const FilterButton: FC<IFilterButtonProps> = (props) => {
  const { filterOptions, handleReset } = props;

  const [recoilFilterState, setRecoilFilterState] = useRecoilState<any>(VehicleFilterState);

  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [filterRowsOpen, setFilterRowsOpen] = useState<string[]>([]);

  const activeFiltersCount = useMemo(() => {
    return Object.entries(recoilFilterState).reduce((res, [key, item]) => {
      if (['page', 'query'].includes(key)) return res;

      if (Array.isArray(item)) {
        return res + item.length;
      } else if ((item as any).toString() !== '0') {
        return res + 1;
      }
      return res;
    }, 0);
  }, [recoilFilterState]);

  useEffect(() => {
    if (filterMenuOpen) {
      // setFilterRowsOpen(
      // Object.entries(recoilFilterState)
      //   .map(([key, value]: (key: string: value:)) => (value.length > 0 ? key : undefined))
      //   .filter(Boolean) as string[],
      // );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterMenuOpen]);

  function handleToggleClick(filterKey: string) {
    setFilterRowsOpen((state) => {
      if (state.includes(filterKey)) {
        return state.filter((key) => key !== filterKey);
      }
      return [...state, filterKey];
    });
  }

  function handleCheckedChange(checked: boolean, filterKey: string, option: IFilterOption) {
    const oldFilterState = (recoilFilterState[filterKey] as string[]) || [];
    const value = option.key;

    const filterConfig = filterOptions.find((filter: IFilter) => filter.key === filterKey);

    let newValue = undefined;
    if (filterConfig.type === 'boolean') {
      newValue = checked ? '1' : '0';
    } else {
      newValue = checked ? [...oldFilterState, value] : oldFilterState.filter((item) => item !== value);
    }

    const newRecoilFilterState = {
      ...recoilFilterState,
      [filterKey]: newValue,
    };

    setRecoilFilterState(newRecoilFilterState);
  }

  return (
    <Wrapper>
      <FilterButtonWrapper onClick={() => setFilterMenuOpen(true)}>
        <FilterLabel>Filter</FilterLabel>
        <Filter />
        {!!activeFiltersCount && <FlyingBadge>{activeFiltersCount}</FlyingBadge>}
      </FilterButtonWrapper>

      <Popup open={filterMenuOpen} setOpen={setFilterMenuOpen} hideCloseButton>
        <CloseButtonWrapper>
          <Clickable onClick={() => setFilterMenuOpen(false)}>
            <Close />
          </Clickable>
        </CloseButtonWrapper>

        <PopupRow>
          <h4>Filter</h4>
          <Clickable
            onClick={() => {
              handleReset();
            }}
          >
            <em>Zurücksetzen</em>
          </Clickable>
        </PopupRow>

        {filterOptions.map((filter: any, index: number) => {
          const disabled = filter.dependency;
          let filterValue: string | string[] = '';
          if (filter.type === 'boolean') {
            filterValue = recoilFilterState[filter.key as keyof FetchPassengersParams] as string;
          } else if (filter.type === 'multi') {
            filterValue = (recoilFilterState[filter.key as keyof FetchPassengersParams] as string[]) || ([] as string[]);
          }

          return (
            <PopupSection key={filter.key} hasBorderTop={index > 0}>
              {filter.type === 'multi' && (
                <>
                  <StyledClickable onClick={() => handleToggleClick(filter.key)} disabled={disabled}>
                    <PopupRow>
                      <span>
                        <b>{filter.title}</b>

                        {!!filterValue.length && <Badge>{filterValue.length}</Badge>}
                      </span>
                      <ChevronDown invert={filterRowsOpen.includes(filter.key)} />
                    </PopupRow>
                  </StyledClickable>
                  <Collapsible open={filterRowsOpen.includes(filter.key) && !disabled}>
                    <OptionsWrapper>
                      {filter.options
                        .filter(({ hideFilter = false }) => !hideFilter)
                        .map((option: any) => {
                          const key = filter.key as keyof FetchPassengersParams;
                          const value = recoilFilterState[key] as string[] | undefined;

                          const checked = value?.includes(option.key) || false;
                          return (
                            <PopupRow key={option.key}>
                              <StyledCheckbox
                                label={option.title}
                                checked={checked}
                                onCheckedChange={(checked) => handleCheckedChange(checked, filter.key, option)}
                              />
                            </PopupRow>
                          );
                        })}
                    </OptionsWrapper>
                  </Collapsible>
                </>
              )}
              {filter.type === 'boolean' && (
                <PopupRow>
                  <StyledSwitch
                    checked={filterValue === '1'}
                    onValueChange={(value) => {
                      if (recoilFilterState) handleCheckedChange(value, filter.key, { key: '1', title: filter.title });
                    }}
                    label={filter.title}
                  />
                </PopupRow>
              )}
            </PopupSection>
          );
        })}
      </Popup>
    </Wrapper>
  );
};
