import styled from 'styled-components';
import { FC } from 'react';
import { TourDetails } from 'components/content/tours/TourDetails';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
  padding: 2.25rem var(--content-indent) 4.75rem;
`;

export const TourDetail: FC = () => {
  return (
    <Wrapper>
      <TourDetails />
    </Wrapper>
  );
};
