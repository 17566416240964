import styled, { css } from 'styled-components';
import { Colors } from 'styles/Colors';
import { Body2Bold, Body3, Body3Bold, Body4Bold, Headline2 } from 'styles/FontStyles';
import { Link } from 'components/common/atoms/Link';
import { BREAKPOINT_LG, BREAKPOINT_MD, BREAKPOINT_XL } from 'styles/Breakpoints';

export const Card = styled.section`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-inline: 1rem;
  padding-block: 1.5rem;
  background: ${Colors.white50};
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  flex: 1;

  ${BREAKPOINT_MD} {
    padding-inline: 2rem;
    padding-block: 2rem;
  }
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1.5rem;
  flex: 1;
`;

export const CardTable = styled.div`
  //
`;

export const CardColumn = styled.div<{ disabled?: boolean }>`
  ${Body3Bold};

  &:not(:last-child) {
    padding-right: 2.5rem;
    white-space: nowrap;
  }

  &:last-child {
    ${Body3};
    width: 100%;
  }

  ${({ disabled = false }) =>
    disabled &&
    css`
      color: ${Colors.grey700};
    `};

  svg {
    width: 1.5rem;
    height: 1.5rem;
    object-fit: contain;
    margin: -0.25rem;
  }
`;

export const HighlightLink = styled(Link)`
  color: ${Colors.secondary};
`;

export const SectionHeadline = styled.h2`
  ${Headline2};

  ${BREAKPOINT_MD} {
    display: none;
  }
`;

export const CardHeadline = styled.h5`
  ${Body2Bold};
  padding-block-start: 1.25rem;

  ${BREAKPOINT_MD} {
    display: none;
  }
`;

export const CardRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding-block: 1.5rem 1rem;
  border-block-end: 1px solid ${Colors.grey500};

  ${BREAKPOINT_MD} {
    flex-direction: row;
    align-items: center;
  }

  :first-of-type {
    padding-block-start: 0;
  }
`;

export const CardRowHeadline = styled.p`
  ${Body4Bold};
  color: ${Colors.grey600};

  ${BREAKPOINT_MD} {
    ${Body3Bold};
    color: ${Colors.textDefault};
    width: 10rem;
    flex-shrink: 0;
  }
`;

export const CardRowContent = styled.p`
  ${Body3};
`;

export const IconCardRow = styled.div<{ disabled?: boolean }>`
  display: flex;
  gap: 0.25rem 1.375rem;
  padding-block: 1.5rem 1rem;
  border-block-end: 1px solid ${Colors.grey500};

  ${BREAKPOINT_XL} {
    gap: 2.875rem;
  }

  ${({ disabled = false }) =>
    disabled &&
    css`
      color: ${Colors.grey700};
    `};

  svg {
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
    margin-inline: -0.25rem;
  }

  :first-of-type {
    padding-block-start: 0;
  }
`;

export const IconCardRowInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  ${BREAKPOINT_LG} {
    flex-direction: row;
    gap: 1rem;
  }

  ${BREAKPOINT_XL} {
    gap: 2rem;
  }
`;

export const IconCardRowHeadline = styled.p`
  ${Body3Bold};
  width: 7.5rem;
  flex-shrink: 0;
`;
