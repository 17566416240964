import { FC, useState } from 'react';
import { useEmployeesAPI } from 'api/controllers/EmployeesAPI';
import styled from 'styled-components';
import { Body3, Headline2, Headline3 } from 'styles/FontStyles';
import { ButtonPrimary } from 'components/common/inputs/Button';
import { BREAKPOINT_MD } from 'styles/Breakpoints';
import { TextInput } from 'components/common/inputs/TextInput';
import { LoadingSpinner } from 'components/common/loaders/LoadingSpinner';
import { Switch } from 'components/common/inputs/Switch';
import { Dropdown } from 'components/common/inputs/Dropdown';
import { IEmployee } from 'api/models/Employee';
import { useNavigate } from 'react-router-dom';
import { Clickable } from 'components/common/atoms/Clickable';
import { Plus } from 'components/icons/Plus';
import { Trash } from 'components/icons/Trash';
import { useFieldArray, useForm } from 'react-hook-form';
import { Form } from 'components/common/inputs/Form';
import { DROPDOWN_ITEMS_REGIONS_EMPLOYEE } from 'api/models/Region';
import FileUploader from 'components/common/elements/FileUploader';
import { useShowDialog } from 'state/DialogState';
import { IVehicle } from 'api/models/Vehicle';
import { Wheelchair } from 'components/icons/Wheelchair';
import { Walker } from 'components/icons/Walker';
import VehiclePicker from 'components/common/elements/VehiclePicker';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  ${Headline2};
  margin-bottom: 2.5rem;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2rem;

  margin-bottom: 4rem;
`;

const SectionTitle = styled.h2`
  ${Headline3};
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2rem;

  ${BREAKPOINT_MD} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

const GridRow = styled(Row)`
  ${BREAKPOINT_MD} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
  }
`;

const GridRowInline = styled(Row)`
  ${BREAKPOINT_MD} {
    display: grid;
    grid-template-columns: auto 1fr 1fr;
    align-items: flex-end;
  }
`;

const AddButton = styled(Clickable)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;

  ${Body3};

  align-self: flex-start;
  padding-bottom: 1rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    object-fit: contain;
  }
`;

const RemoveButton = styled(AddButton)`
  align-self: flex-end;
  width: min-content;
`;

const VehicleItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  padding-block: 0.25rem;

  overflow: hidden;

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const EmployeeForm: FC<{ employee?: IEmployee }> = (props) => {
  const { employee = null } = props;

  const showDialog = useShowDialog();
  const navigate = useNavigate();

  const employeesAPI = useEmployeesAPI();
  const [isSubmitting, setSubmitting] = useState(false);

  const [file, setFile] = useState<File | null>(null);

  const form = useForm({
    defaultValues: {
      internalId: employee?.internalId || '',
      firstName: employee?.firstName || '',
      lastName: employee?.lastName || '',
      emailAddress: employee?.emailAddress || '',
      phoneNumbers: (employee?.phoneNumbers || [])
        .map(({ formattedPhoneNumber, ...item }) => ({ ...item, phoneNumber: formattedPhoneNumber }))
        .sort((a, b) => a.createdAt.localeCompare(b.createdAt)),
      address: {
        id: employee?.address?.id || undefined,
        addressLine1: employee?.address?.addressLine1 || '',
        addressLine2: employee?.address?.addressLine2 || '',
        zipCode: employee?.address?.zipCode || '',
        city: employee?.address?.city || '',
        country: employee?.address?.country || 'de',
      },
      jobTitle: employee?.jobTitle || '',
      drivingLicense: employee?.drivingLicense || 'B',
      region: employee?.region || '',
      comment: employee?.comment || '',
      hasSkillScalamobil: employee?.hasSkillScalamobil || false,
      hasSkillSupport: employee?.hasSkillSupport || false,
      isDrivingTauga: employee?.isDrivingTauga || false,
      vehicle: employee?.vehicles?.[0] || null,
    },
  });

  const phoneNumbers = useFieldArray({ name: 'phoneNumbers' as never, control: form.control, keyName: 'form-id' });

  const vehicle = form.watch('vehicle');

  return (
    <Wrapper>
      <Form
        form={form}
        onSubmit={(values) => {
          const saveEmployeeData = async ({ vehicle, ...values }: any) => {
            if (employee?.id && vehicle?.id !== employee?.vehicles?.[0]?.id) {
              await employeesAPI.setEmployeeVehicle(employee.id, { vehicleId: vehicle?.id || null });
            }
            (employee?.id ? employeesAPI.updateEmployee(employee?.id, values) : employeesAPI.createEmployee(values))
              .then((res) => {
                navigate(`/employees/${res.data.id}`, { replace: true });
              })
              .catch((err) => {
                console.error(`error ${employee?.id ? 'updating' : 'creating'} employee`, err);

                // show generic error message
                showDialog({
                  headline: 'Personenprofil speichern',
                  body: 'Beim Speichern der Daten ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
                });
              })
              .finally(() => setSubmitting(false));
          };

          setSubmitting(true);
          if (file) {
            employeesAPI
              .uploadFile(file)
              .then((res) => {
                saveEmployeeData({ ...values, imageId: res.data.id }); // save data with imageId
              })
              .catch((err) => {
                setSubmitting(false);
                // show generic error message
                showDialog({
                  headline: 'Personenprofil speichern',
                  body: 'Beim Speichern der Daten ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
                });
              });
          } else {
            saveEmployeeData(values);
          }
        }}
      >
        <Title>Personenprofil</Title>

        <Section>
          <SectionTitle>Persönliche Daten</SectionTitle>
          <GridRow>
            <TextInput
              id={'firstName'}
              placeholder={'Vorname eingeben'}
              label={'Vorname'}
              $plain
              {...form.register('firstName', { required: 'Bitte geben Sie einen Vornamen an.' })}
            />
            <TextInput
              id={'lastName'}
              placeholder={'Nachname eingeben'}
              label={'Nachname'}
              $plain
              {...form.register('lastName', { required: 'Bitte geben Sie einen Vornamen an.' })}
            />
          </GridRow>

          <GridRow>
            <TextInput optional id={'emailAddress'} placeholder={'beispiel@gmail.com'} label={'E-Mail-Adresse'} $plain />
          </GridRow>
          {phoneNumbers.fields.map((field, index) => (
            <GridRowInline key={field['form-id']}>
              <Dropdown
                id={`phoneNumbers.${index}.type`}
                // placeholder={'z.B. Arbeit'}
                label={'Telefonnummer'}
                items={[
                  { value: 'Arbeit', label: 'Arbeit' },
                  { value: 'privat', label: 'privat' },
                ]}
                {...form.register(`phoneNumbers.${index}.type`, { required: 'Bitte geben Sie einen Typ an.' })}
              />
              <TextInput
                id={`phoneNumbers.${index}.phoneNumber`}
                placeholder={'z.B. 01578-2983456'}
                // label={'Telefonnummer'}
                {...form.register(`phoneNumbers.${index}.phoneNumber`, { required: 'Bitte geben Sie eine Nummer an.' })}
                $plain
              />
              <RemoveButton
                onClick={() => {
                  phoneNumbers.remove(index);
                }}
              >
                <Trash />
              </RemoveButton>
            </GridRowInline>
          ))}
          <GridRow>
            <AddButton
              onClick={() => {
                phoneNumbers.append({ phoneNumber: '', type: 'Arbeit' });
              }}
            >
              <Plus /> Telefonnummer hinzufügen
            </AddButton>
          </GridRow>
          <GridRow>
            <TextInput
              id={'address.addressLine1'}
              placeholder={'Straße und Hausnummer eingeben'}
              label={'Straße und Hausnummer'}
              $plain
              {...form.register('address.addressLine1', { required: 'Bitte geben Sie eine Straße und Hausnummer an.' })}
            />
            <TextInput optional id={'address.addressLine2'} placeholder={'Adresszusatz eingeben'} label={'Adresszusatz'} $plain />
          </GridRow>
          <GridRow>
            <TextInput
              id={'address.zipCode'}
              placeholder={'Postleitzahl eingeben'}
              label={'Postleitzahl'}
              $plain
              {...form.register('address.zipCode', { required: 'Bitte geben Sie eine PLZ an.' })}
            />
            <TextInput
              id={'address.city'}
              placeholder={'Stadt eingeben'}
              label={'Stadt'}
              $plain
              {...form.register('address.city', { required: 'Bitte geben Sie eine Stadt an.' })}
            />
          </GridRow>
        </Section>

        <Section>
          <SectionTitle>Profilfoto</SectionTitle>
          <FileUploader
            title={'Laden Sie hier ein Profilfoto hoch. Für Personen ohne Foto werden stattdessen die Initialen ihres Namens angezeigt. '}
            file={file}
            setFile={setFile}
            hint={['zulässige Formate: .png, .jpeg', 'Dateigröße: max. 1 MB']}
          />
        </Section>

        <Section>
          <SectionTitle>Informationen zur Arbeit</SectionTitle>
          <GridRow>
            <Dropdown
              id={'jobTitle'}
              items={[
                { value: 'Fahrer', label: 'Fahrer' },
                { value: 'Fahrdienstleiter', label: 'Fahrdienstleiter' },
                { value: 'Fuhrpark-Administration', label: 'Fuhrpark-Administration' },
                { value: 'FDL D', label: 'FDL D' },
                { value: 'Geschäftsführer', label: 'Geschäftsführer' },
                { value: 'Assistentin FDL', label: 'Assistentin FDL' },
                { value: 'Assistentin Fuhrpark', label: 'Assistentin Fuhrpark' },
                { value: 'FDL W', label: 'FDL W' },
                { value: 'Personal + Buchhaltung', label: 'Personal + Buchhaltung' },
                { value: 'Assistent FDL', label: 'Assistent FDL' },
                { value: 'FDL R', label: 'FDL R' },
                { value: 'Assistentin FDL D', label: 'Assistentin FDL D' },
                { value: 'Projektleiterin', label: 'Projektleiterin' },
                { value: 'FDL/Assistent', label: 'FDL/Assistent' },
              ]}
              label={'Rolle'}
              placeholder={'Rolle der Person auswählen'}
              {...form.register('jobTitle', { required: 'Bitte wählen Sie einen Rolle aus.' })}
            />
            <Dropdown
              optional
              id={'region'}
              items={DROPDOWN_ITEMS_REGIONS_EMPLOYEE}
              label={'Einsatzgebiet'}
              placeholder={'zuständige Region'}
            />
          </GridRow>
          <GridRow>
            <Row>
              <Dropdown
                id={'drivingLicense'}
                items={[
                  { value: 'D1', label: 'D1' },
                  { value: 'B', label: 'B' },
                  { value: 'F', label: 'F' },
                  { value: 'G/B', label: 'G/B' },
                  { value: 'F/B', label: 'F/B' },
                ]}
                label={'Führerscheinklasse'}
                placeholder={'Klasse'}
              />
            </Row>
            <VehiclePicker
              label={'zugehöriges KFZ'}
              title={
                vehicle
                  ? `${vehicle.licensePlate} (${[
                      [vehicle.brandName, vehicle.modelName].filter(Boolean).join(' '),
                      vehicle.color,
                      vehicle.hasRoomForWheelchairs ? 'rollstuhlgeeignet' : undefined,
                    ]
                      .filter(Boolean)
                      .join(', ')})`
                  : 'Fahrzeug auswählen'
              }
              searchInputPlaceholder={'nach Fahrzeug suchen'}
              selectedItem={vehicle}
              onSelect={(vehicle) => form.setValue('vehicle', vehicle || null)}
              renderItemContent={(vehicle: IVehicle) => (
                <VehicleItem>
                  <b>{vehicle.licensePlate}</b>
                  {[[vehicle.brandName, vehicle.modelName].filter(Boolean).join(' '), vehicle.color].filter(Boolean).join(', ')}
                  {vehicle.hasRoomForWheelchairs ? <Wheelchair /> : <Walker />}
                </VehicleItem>
              )}
              indices={['vehicles']}
            />
          </GridRow>
          <GridRow>
            <TextInput
              id={'internalId'}
              placeholder={'Personalnummer eingeben'}
              label={'Personalnummer'}
              $plain
              {...form.register('internalId', { required: 'Bitte geben Sie einen Personalnummber an.' })}
            />

            {/* TODO add employment type */}
          </GridRow>
          <Row>
            <TextInput
              optional
              id={'comment'}
              type={'textarea'}
              placeholder={'Anmerkungen hinzufügen...'}
              label={'Anmerkungen'}
              style={{ flex: 1 }}
            />
          </Row>
          <Row>
            <Switch
              id={'hasSkillScalamobil'}
              label={'Scalamobil'}
              placeholder={'Ist der Fahrer für die Nutzung eines Treppenrollstuhls geschult? (Scalamobil)'}
            />
          </Row>
          <Row>
            <Switch
              id={'hasSkillSupport'}
              label={'Unterstützung'}
              placeholder={'Ist der Fahrer in der Lage, Gäste zu tragen oder beim Laufen zu unterstützen?'}
            />
          </Row>
          <Row>
            <Switch id={'isDrivingTauga'} label={'Tauga Fahrten'} placeholder={'Fährt der Fahrer für Tauga? (Spezialfahrten Wuppertal)'} />
          </Row>
        </Section>

        <ButtonPrimary type={'submit'}>{isSubmitting ? <LoadingSpinner /> : 'Speichern'}</ButtonPrimary>
      </Form>
    </Wrapper>
  );
};
