// General
import styled, { css } from 'styled-components';
import { Link as ReactRouterLink } from 'react-router-dom';

// Styles
import { Colors } from 'styles/Colors';
import { Body4Bold } from 'styles/FontStyles';

interface ILinkProps {
  href: string;
  target?: string;
  $displayContents?: boolean;
}

export const Link = styled.a.attrs((props: ILinkProps) => ({
  as: props.href?.startsWith('/') ? ReactRouterLink : undefined,
  to: props.href?.startsWith('/') ? props.href : undefined,
  rel: 'noopener noreferrer',
  target: props.target !== undefined ? props.target : props.href?.startsWith('/') ? undefined : '_blank',
  tabIndex: !props.href ? -1 : 0,
}))<ILinkProps>`
  cursor: pointer;
  //@media (hover: hover) {
  //  &:hover {
  //    text-decoration: underline;
  //  }
  //}

  ${({ href }) =>
    !href &&
    css`
      pointer-events: none;
    `};

  ${({ $displayContents }) =>
    $displayContents &&
    css`
      display: contents;
    `};
`;

export const HighlightedLink = styled(Link)`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;

  ${Body4Bold};
  color: ${Colors.secondary};

  margin: -0.125rem -0.25rem;
  padding: 0.125rem 0.25rem;
  border-radius: 0.25rem;

  transition: background-color 150ms ease-out;

  @media (hover: hover) {
    &:hover {
      text-decoration: none;
      background-color: ${Colors.grey400};
    }
  }

  &:disabled {
    color: ${Colors.grey600};
    background-color: ${Colors.white50};
  }
`;
