import { FC, ReactNode, isValidElement } from 'react';
import styled from 'styled-components';
import { TabSwitchItem } from '../atoms/TabSwitchItem';
import { UiStateListNames, UiState } from 'state/UiState';
import { useRecoilState } from 'recoil';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

interface ITabSwitchProps {
  items: ReactNode[];
  highlighted?: boolean;
  small?: boolean;
  onItemClick: (index: number) => void;
  className?: string;
  listName: UiStateListNames;
}

export const TabSwitch: FC<ITabSwitchProps> = (props) => {
  const {
    items,
    highlighted = false,
    // eslint-disable-next-line
    small = false,
    onItemClick,
    className,
    listName,
  } = props;

  const [state, setState] = useRecoilState(UiState);

  return (
    <Wrapper className={className}>
      {items.map((item, index) => (
        <TabSwitchItem
          key={index}
          index={index}
          content={item}
          onClick={() => {
            if (listName && isValidElement(item)) {
              setState({ ...state, [listName]: { activeTab: item.props.value } });
            } else if (onItemClick) {
              onItemClick(index);
            }
          }}
          active={isValidElement(item) && state[listName]?.activeTab === item.props.value}
          highlighted={highlighted}
          small={small}
        />
      ))}
    </Wrapper>
  );
};
