import {
  forwardRef,
  useImperativeHandle,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  FC,
  Ref,
} from 'react';
import styled from 'styled-components';
import { Body1Bold, Body4Bold } from 'styles/FontStyles';
import { LoadingSpinner } from 'components/common/loaders/LoadingSpinner';
import { List, ListHead } from 'components/common/atoms/List';
import { PassengerListRow } from 'components/common/elements/passenger/PassengerListRow';
import { useSearchAPI } from 'api/controllers/SearchAPI';
import { Card } from 'components/common/atoms/Card';
import { Colors } from 'styles/Colors';
import { SearchInput } from 'components/common/inputs/SearchInput';
import { IPassenger } from 'api/models/Passenger';
import { ButtonSecondary } from 'components/common/inputs/Button';
import { Plus } from 'components/icons/Plus';
import CreatePassengerDialogPortal from 'components/content/passengers/CreatePassengerDialogPortal';
import { useContentPageFilter } from 'components/common/elements/ContentPageFilterRow';
import { usePassengersAPI } from 'api/controllers/PassengersAPI';
import { CheckboxWithRef } from 'components/common/atoms/CheckboxWithRef';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;

  flex: 1;

  margin-block-start: -3rem;
`;

const CardWrapper = styled(Card)`
  gap: 1rem;
  overflow: hidden;
  flex: 1 0 0;
`;

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;

  & > * {
    max-inline-size: 450px;
  }
`;

const AddNewButton = styled(ButtonSecondary)`
  margin-inline-end: auto;
`;

const HitsCount = styled.p`
  align-self: flex-end;
  ${Body4Bold};
`;

const Title = styled.h2`
  ${Body1Bold};
  text-align: center;
`;

const StyledList = styled(List)`
  --list-template: 2.5rem 2.75rem 1.2fr 6.25rem 12.5rem 1.75fr;
  margin: 0;

  flex: 1 0 0;
`;

const StyledListHead = styled(ListHead)`
  padding-inline: 0.5rem;
  padding-block: 0.5rem 1rem;
  border-bottom: 1px solid ${Colors.grey500};

  margin-block-end: 0;

  &&:before {
    content: none;
  }
`;

const ListContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-inline: -2rem;
  padding-inline: 2rem;
  padding-block-end: 2rem;
  margin-block-end: -2rem;

  flex: 1 0 0;
  overflow-y: auto;

  & > *:not(:last-child) {
    border-bottom: 1px solid ${Colors.grey500};
  }
`;

const Placeholder = styled.p`
  padding: 2rem;
  color: ${Colors.grey700};
  text-align: center;
`;

interface ICustomerOrderFormPagePassengersProps {
  customerId: string;
  selectedPassengers: string[];
  setSelectedPassengers: Dispatch<SetStateAction<string[]>>;
  ref: Ref<{ handleCheckAllChanges: () => void }>;
}

export const CustomerOrderFormPagePassengers: FC<ICustomerOrderFormPagePassengersProps> = forwardRef<
  { handleCheckAllChanges: () => void },
  ICustomerOrderFormPagePassengersProps
>((props, ref) => {
  const { customerId, selectedPassengers, setSelectedPassengers } = props;

  const searchAPI = useSearchAPI();
  const [customerPassengers, setCustomerPassengers] = useState<IPassenger[]>([]);
  const [hitsCount, setHitsCount] = useState<number | null>(null);
  const contentPageFilter = useContentPageFilter('passengers');
  const { searchQuery, setSearchQuery } = contentPageFilter;

  const passengersAPI = usePassengersAPI();
  const [customerPassengersSelection, setCustomerPassengersSelection] = useState<IPassenger[] | null>(null);

  const lastSearchQueryRef = useRef(searchQuery);
  const [openCreateNewPassengerDialog, setOpenCreateNewPassengerDialog] = useState(false);

  useEffect(() => {
    document.querySelector('body')?.classList.toggle('no-scroll', openCreateNewPassengerDialog);

    return () => {
      document.querySelector('body')?.classList.toggle('no-scroll', false);
    };
  }, [openCreateNewPassengerDialog]);

  const requestCode = useRef(0);
  const requestCodeSelection = useRef(0);

  const search = useCallback(
    (searchQuery: string) => {
      const currentRequestCode = ++requestCode.current;
      setCustomerPassengers([]);
      setHitsCount(null);

      searchAPI
        .searchPassengers({
          query: searchQuery,
          customerIds: [customerId],
          page: {
            offset: 0,
            limit: 200,
          },
        })
        .then((res) => {
          if (currentRequestCode === requestCode.current) {
            setCustomerPassengers(res.data?.items || []);
            setHitsCount(res.data?.total || 0);
          }
        })
        .catch(() => {
          if (currentRequestCode === requestCode.current) {
            setCustomerPassengers([]);
          }
        });
    },
    [setCustomerPassengers, setHitsCount, searchAPI, customerId],
  );

  const fetchSelectedPassengers = useCallback(
    (selectedIds: string[]) => {
      const currentRequestCode = ++requestCodeSelection.current;
      setCustomerPassengersSelection(null);

      passengersAPI
        .getPassengers({
          ids: selectedIds,
          includeDrafts: true,
          offset: 0,
          limit: -1,
        })
        .then((res) => {
          if (currentRequestCode === requestCodeSelection.current) {
            setCustomerPassengersSelection(res.data?.rows || []);
          }
        })
        .catch(() => {
          if (currentRequestCode === requestCodeSelection.current) {
            setCustomerPassengersSelection([]);
          }
        });
    },
    [setCustomerPassengersSelection, passengersAPI],
  );

  useEffect(() => {
    lastSearchQueryRef.current = searchQuery;
    search(searchQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    fetchSelectedPassengers(selectedPassengers); // initial fetch only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const listener = () => {
      search(lastSearchQueryRef.current);
    };
    document.addEventListener('passengers-update', listener);
    return () => document.removeEventListener('passengers-update', listener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchInput = useMemo(
    () => (
      <SearchInput placeholder="nach Fahrgästen suchen" value={searchQuery} setValue={setSearchQuery} onReset={() => setSearchQuery('')} />
    ),
    [searchQuery, setSearchQuery],
  );

  const visiblePassengers = useMemo(() => {
    if (customerPassengers !== null && customerPassengersSelection !== null) {
      return [
        ...((searchQuery.length === 0 && customerPassengersSelection) || []),
        ...(customerPassengers || [])
          .filter((data) => searchQuery.length > 0 || !customerPassengersSelection?.some((item) => item.id === data.id))
          .map((data) => data),
      ].sort((a, b) => {
        if (!selectedPassengers.includes(a.id) && selectedPassengers.includes(b.id)) {
          return -1;
        } else if (selectedPassengers.includes(a.id) && !selectedPassengers.includes(b.id)) {
          return 1;
        } else {
          return a.lastName.localeCompare(b.lastName);
        }
      });
    }
    return null;
  }, [customerPassengers, customerPassengersSelection, searchQuery.length, selectedPassengers]);

  useImperativeHandle(
    ref,
    () => {
      return {
        handleCheckAllChanges: () => {
          setAllChecked((_state) => true);
          setSelectedPassengers((_state) => visiblePassengers?.map((item) => item.id) || []);
        },
      };
    },
    [setSelectedPassengers, visiblePassengers],
  );
  const passengersList = useMemo(
    () => (
      <ListContent>
        {visiblePassengers === null && <LoadingSpinner />}
        {visiblePassengers !== null && visiblePassengers.length === 0 && <Placeholder>Keine Fahrgäste gefunden.</Placeholder>}
        {visiblePassengers?.map((passenger) => (
          <PassengerListRow
            key={passenger.id}
            passenger={passenger}
            selectable
            showCustomer={false}
            showAddress
            selected={selectedPassengers.includes(passenger.id)}
            onSelect={() => {
              setSelectedPassengers(
                (state) =>
                  [...state.filter((id) => id !== passenger.id), !selectedPassengers.includes(passenger.id) && passenger.id].filter(
                    Boolean,
                  ) as string[],
              );
            }}
            onUpdate={() => setCustomerPassengers((state) => (state || []).filter((item) => item.id !== passenger.id))}
          />
        ))}
      </ListContent>
    ),
    [visiblePassengers, selectedPassengers, setSelectedPassengers],
  );

  const createPassengerDialog = useMemo(
    () => (
      <>
        {openCreateNewPassengerDialog && (
          <CreatePassengerDialogPortal
            customerId={customerId}
            onClose={() => {
              setOpenCreateNewPassengerDialog(false);
            }}
          />
        )}
      </>
    ),
    [openCreateNewPassengerDialog, setOpenCreateNewPassengerDialog, customerId],
  );

  const [allChecked, setAllChecked] = useState(false);
  const handleCheckAllChanges = (newValue: boolean) => {
    if (newValue) {
      setSelectedPassengers(visiblePassengers?.map((item) => item.id) || []);
      setAllChecked(true);
    } else {
      setSelectedPassengers([]);
      setAllChecked(false);
    }
  };

  return (
    <Wrapper>
      <CardWrapper>
        <Title>Wählen Sie die Fahrgäste die in diesem Auftrag transportiert werden müssen</Title>

        <FilterWrapper>
          <AddNewButton onClick={() => setOpenCreateNewPassengerDialog(true)}>
            <Plus /> Fahrgast hinzufügen
          </AddNewButton>
          {searchInput}
        </FilterWrapper>

        {hitsCount !== null && (
          <HitsCount>
            {hitsCount} {hitsCount === 1 ? 'Ergebnis' : 'Ergebnisse'}
          </HitsCount>
        )}

        <StyledList>
          <StyledListHead>
            <div>
              <CheckboxWithRef checked={allChecked} onCheckedChange={handleCheckAllChanges} />
            </div>
            <div />
            <div>Name</div>
            <div>Klassenstufe</div>
            <div>Barrierefreiheit</div>
            <div>Adresse</div>
          </StyledListHead>
          {passengersList}
        </StyledList>
      </CardWrapper>

      <HitsCount>
        {selectedPassengers.length} {selectedPassengers.length === 1 ? 'Fahrgast' : 'Fahrgäste'} ausgewählt
      </HitsCount>

      {createPassengerDialog}
    </Wrapper>
  );
});
