import styled from 'styled-components';
import { TabStatusBarLayout } from 'components/common/elements/TabStatusBarLayout';
import { CustomerCategories } from 'components/pages/customer-categories/CustomerCategories';
import { UserManagement } from 'components/pages/user-management/UserManagement';
import { useRecoilState } from 'recoil';
import { UserMeState } from 'state/UserMeState';
import { Finance } from '../finance/Finance';

const Wrapper = styled.section`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 2.25rem var(--content-indent) 4.75rem;
`;

export const BaseData = () => {
  const [userMe] = useRecoilState(UserMeState);

  return (
    <Wrapper>
      <TabStatusBarLayout
        breadcrumb={{
          title: 'Stammdaten',
          href: '/stammdaten',
        }}
        subPageBreadcrumb={{
          // Add the missing subPageBreadcrumb property
          title: 'Stammdaten',
          href: '/stammdaten',
        }}
        header={'Stammdaten'}
        items={[
          {
            title: 'Finanzen',
            hash: '#finance',
            content: <Finance />,
            permissions: ['admin'],
          },
          {
            title: 'Nutzerverwaltung',
            hash: '#user-management',
            content: <UserManagement />,
            permissions: ['user-management'],
          },
          {
            title: 'Kundenkategorien',
            hash: '#customer-categories',
            content: <CustomerCategories />,
            permissions: ['admin'],
          },
        ].filter(
          ({ permissions }) =>
            permissions.length === 0 ||
            permissions.every(
              (permission) => userMe?.permissions?.split(';').includes('admin') || userMe?.permissions?.split(';').includes(permission),
            ),
        )}
      />
    </Wrapper>
  );
};
