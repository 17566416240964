import { FC } from 'react';
import styled from 'styled-components';
import { Colors } from 'styles/Colors';
import { Body1Bold, Body3, Body4, Body4Bold } from 'styles/FontStyles';
import { ContextOptions } from '../ContextOptions';
import { LocationTag } from '../LocationTag';
import { Link } from 'components/common/atoms/Link';
import { useShowDeleteCustomerDialog } from 'hooks/customers/useShowDeleteCustomerDialog';
import { getTelLink } from 'utils/phoneUtils';
import { ICustomer } from 'api/models/Customer';
import { Trash } from 'components/icons/Trash';
import { Edit } from 'components/icons/Edit';
import { Phone } from 'components/icons/Phone';
import { Mail } from 'components/icons/Mail';
import { Tours } from 'components/icons/Tours';
import { useHasPermission } from 'state/UserMeState';
import { useNavigate } from 'hooks/useNavigate';

const Title = styled(Link)`
  ${Body1Bold};
  align-self: flex-start;
  margin-bottom: 1.5rem;

  overflow: hidden;
  text-overflow: ellipsis;
  max-inline-size: 100%;
`;

const Tag = styled.div`
  ${Body4};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    object-fit: contain;
  }

  a {
    ${Body4Bold};
    color: ${Colors.secondary};
  }
`;

const TileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: ${Colors.white50};
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 1rem;
  transition: box-shadow 150ms ease-out, border-radius 150ms ease-out;
  text-decoration: none;
  max-inline-size: 100%;

  @media (hover: hover) {
    :hover {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
      border-radius: 8px;
      cursor: pointer;
    }
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
`;

const InternalId = styled.div`
  ${Body3};
`;

const TagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.5rem 2rem;
  flex-wrap: wrap;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const CustomerTile: FC<{ customer: ICustomer; onUpdate?: () => void }> = (props) => {
  const { id, internalId, displayName, region, contactPersons = [], scheduledTours = [] } = props.customer;

  const navigate = useNavigate();
  const showDeleteCustomerDialog = useShowDeleteCustomerDialog(id);
  const isAdmin = useHasPermission('admin');

  const contactPhone = contactPersons.find(({ phoneNumber }) => !!phoneNumber);
  const contactEmail = contactPersons.find(({ emailAddress }) => !!emailAddress);

  return (
    <TileWrapper onClick={() => navigate(`/customers/${id}`)}>
      <TitleWrapper>
        <InternalId>{internalId}</InternalId>

        <ContextOptions
          items={
            [
              isAdmin && {
                content: (
                  <>
                    <Trash /> Entfernen
                  </>
                ),
                onClick: () => showDeleteCustomerDialog(props.onUpdate),
              },
              {
                content: (
                  <>
                    <Edit /> Bearbeiten
                  </>
                ),
                onClick: () => navigate(`/customers/${id}/edit`),
              },
            ].filter(Boolean) as []
          }
        />
      </TitleWrapper>

      <Title href={`/customers/${id}`}>{displayName}</Title>

      <TagsWrapper>
        {contactPhone && (
          <Tag>
            <Phone />
            <Link href={getTelLink(contactPhone?.phoneNumber) || ''} onClick={(e) => e.stopPropagation()}>
              {contactPhone.formattedPhoneNumber}
            </Link>
          </Tag>
        )}
        {contactEmail && (
          <Tag>
            <Mail />
            <Link href={`mailto:${contactEmail.emailAddress}`} onClick={(e) => e.stopPropagation()}>
              {contactEmail.emailAddress}
            </Link>
          </Tag>
        )}
      </TagsWrapper>

      <TagsWrapper>
        <LocationTag address={region} />
        {scheduledTours.length > 0 && (
          <Tag>
            <Tours /> {scheduledTours.length} Fahrten
          </Tag>
        )}
      </TagsWrapper>
    </TileWrapper>
  );
};
