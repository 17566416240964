import axios from 'axios';
import { useAuthHeader, useAuthState } from 'state/AuthState';
import { useSetRecoilState } from 'recoil';
import { UserFiltersState } from 'state/UserFiltersState';
import { TUserPermission } from 'api/models/User';
import { ICreateUserSubmitPayload } from 'components/common/elements/user-management/CreateUserDialog';

interface ILoginProps {
  email: string;
  password: string;
}

interface IForgotPasswordProps {
  email: string;
}

interface IResetPasswordProps {
  token: string;
  password: string;
}

interface IUpdateUserProps {
  permissions: string[];
}

export const useAuthAPI = () => {
  const [, setAuthState] = useAuthState();
  const authHeader = useAuthHeader();
  const setUserFilters = useSetRecoilState(UserFiltersState);

  const login = (props: ILoginProps) => {
    return axios.post('/api/auth/login', props).then(async (res) => {
      if (res.data.authToken) {
        // fetch user permissions sync
        await axios
          .get('/api/auth/me', { headers: { authorization: `Bearer ${res.data.authToken}` } })
          .then((res) => {
            const permissions = (res.data.permissions || '').split(';') as TUserPermission[];
            const regions = permissions.reduce((res, permission) => {
              switch (permission) {
                case 'region:d':
                  res.push('Düsseldorf');
                  break;
                case 'region:n':
                  res.push('Niederrhein');
                  break;
                case 'region:w':
                  res.push('Wuppertal');
                  break;
                case 'region:r':
                  res.push('Ruhrgebiet');
                  break;
              }
              return res;
            }, [] as string[]);

            // set region filters
            setUserFilters({
              pages: {
                tours: {
                  viewAsList: false,
                  propertyFilters: {
                    regions,
                  },
                },
                employees: {
                  viewAsList: false,
                  propertyFilters: {
                    regions,
                  },
                },
                vehicles: {
                  viewAsList: false,
                  propertyFilters: {
                    regions,
                  },
                },
                passengers: {
                  viewAsList: false,
                  propertyFilters: {
                    regions,
                  },
                },
                customers: {
                  viewAsList: false,
                  propertyFilters: {
                    regions,
                  },
                },
              },
            });
          })
          .catch(() => {});
      }

      // set auth token
      setAuthState(res.data);

      return res;
    });
  };

  const logout = () => {
    setAuthState(null);
    setUserFilters({ pages: {} }); // reset user filters state
  };

  const forgotPassword = (props: IForgotPasswordProps) => {
    return axios.post('/api/auth/forgot-password', props);
  };

  const resetPassword = (props: IResetPasswordProps) => {
    return axios.post('/api/auth/reset-password', props);
  };

  const getUserMe = () => {
    return axios.get('/api/auth/me', { ...authHeader });
  };

  const getUsersList = () => {
    return axios.get('/api/auth/users-list', { ...authHeader });
  };

  const createUser = (props: ICreateUserSubmitPayload) => {
    const mappedPermissions = Object.keys(props.permissions).reduce((res, key) => {
      if (props.permissions[key as TUserPermission]) {
        res.push(key);
      }
      return res;
    }, [] as string[]);

    return axios.post('/api/auth/create-user', { ...props, permissions: mappedPermissions }, { ...authHeader });
  };

  const updateUser = (id: string, props: IUpdateUserProps) => {
    return axios.patch(`/api/auth/users/${id}`, props, { ...authHeader });
  };

  const deleteUser = (id: string) => {
    return axios.delete(`/api/auth/users/${id}`, { ...authHeader });
  };

  return {
    login,
    logout,
    forgotPassword,
    resetPassword,
    getUserMe,
    getUsersList,
    createUser,
    updateUser,
    deleteUser,
  };
};
