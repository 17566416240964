import { FC, memo, useState } from 'react';
import styled from 'styled-components';
import { Body1Bold } from 'styles/FontStyles';
import { Card } from 'components/common/atoms/Card';
import { ButtonPrimary } from 'components/common/inputs/Button';
import { BREAKPOINT_MD } from 'styles/Breakpoints';
import { ITour } from 'api/models/Tour';
import { CustomerOrderFormPageImportDialog } from 'components/content/customers/orders/CustomerOrderFormPageImportDialog';
import { Excel } from 'components/icons/Excel';
import { ExcelButton } from 'components/common/elements/ExcelButton';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.5rem;
`;

const CardWrapper = styled(Card)`
  gap: 1.5rem;
  overflow: auto;
`;

const Title = styled.h2`
  ${Body1Bold};
  text-align: center;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  gap: 1.5rem;

  ${BREAKPOINT_MD} {
    flex-direction: row;
    align-items: center;
  }
`;

interface ICustomerOrderFormPageImportProps {
  onContinueClick: () => void;
  onImport: (data: ITour[]) => void;
}

export const CustomerOrderFormPageImportSelection: FC<ICustomerOrderFormPageImportProps> = memo((props) => {
  const { onContinueClick, onImport } = props;

  const [showImportDialog, setShowImportDialog] = useState(false);

  return (
    <Wrapper>
      <CardWrapper>
        <Title>Möchten Sie den Auftrag aus Excel importieren oder manuell eigeben?</Title>
        <ButtonsWrapper>
          <ExcelButton onClick={() => setShowImportDialog(true)}>
            <Excel />
            Aus Excel importieren
          </ExcelButton>
          <ButtonPrimary onClick={onContinueClick}>Auftrag manuell anlegen</ButtonPrimary>
        </ButtonsWrapper>
      </CardWrapper>
      {showImportDialog && <CustomerOrderFormPageImportDialog onImport={onImport} onClose={() => setShowImportDialog(false)} />}
    </Wrapper>
  );
});
