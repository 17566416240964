import { useQuery } from '@tanstack/react-query';
import { Region } from 'api/models/Region';
import { useCustomersAPI } from 'api/controllers/CustomersAPI';

export const useCustomers = (region?: Region) => {
  const customersApi = useCustomersAPI();

  return useQuery({
    queryKey: ['customers', region],
    enabled: !!region,
    staleTime: 24 * 60 * 60 * 1000, // 1 day
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    queryFn: async () => {
      if (!region) {
        // This should not be reached because `enabled` is set to false when region is undefined.
        throw new Error('Region is required');
      }

      const { data } = await customersApi.getCustomersForSelect({ region }).then((res) => {
        return res;
      });

      return data as { label: string; value: string }[];
    },
  });
};
