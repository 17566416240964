// General
import { FC } from 'react';

// Components
import { CardColumn, CardRow, CardRowHeadline, CardsWrapper, Card } from 'components/common/atoms/Card';

// Types
import { ICustomer } from 'api/models/Customer';

const BILLING_TYPE_TEXT = {
  single: 'Einzelrechnung',
  contract: 'Vertragsrechnung',
};

export const CustomerDetailsInformation: FC<{ customer: ICustomer }> = (props) => {
  const { customer } = props;

  const getBillingTypeText = (billingType: string | undefined) => {
    const hasTextKey = Object.keys(BILLING_TYPE_TEXT).includes(billingType as string);

    if (billingType === undefined || !hasTextKey) return '-';

    return BILLING_TYPE_TEXT[billingType as keyof typeof BILLING_TYPE_TEXT];;
  };

  return (
    <CardsWrapper>
      <Card>
        <CardRow>
          <CardRowHeadline>Einrichtung</CardRowHeadline>
          <CardColumn>{customer?.displayName || '-'}</CardColumn>
        </CardRow>
        <CardRow>
          <CardRowHeadline>Straße</CardRowHeadline>
          <CardColumn>{customer?.address?.addressLine1 || '-'}</CardColumn>
        </CardRow>
        <CardRow>
          <CardRowHeadline>Adresszusatz</CardRowHeadline>
          <CardColumn>{customer?.address?.addressLine2 || '-'}</CardColumn>
        </CardRow>
        <CardRow>
          <CardRowHeadline>Postleitzahl</CardRowHeadline>
          <CardColumn>{customer?.address?.zipCode || '-'}</CardColumn>
        </CardRow>
        <CardRow>
          <CardRowHeadline>Stadt</CardRowHeadline>
          <CardColumn>{customer?.address?.city || '-'}</CardColumn>
        </CardRow>
      </Card>

      <Card>
        <CardRow>
          <CardRowHeadline>Abrechnungsart</CardRowHeadline>
          <CardColumn>{getBillingTypeText(customer?.billingType)}</CardColumn>
        </CardRow>
        <CardRow>
          <CardRowHeadline>Anmerkungen</CardRowHeadline>
          <CardColumn>{customer?.comment || '-'}</CardColumn>
        </CardRow>
      </Card>
    </CardsWrapper>
  );
};
