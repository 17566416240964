import styled from 'styled-components';
import { ButtonTertiary } from '../inputs/Button';
import { Colors } from 'styles/Colors';

export const ExcelButton = styled(ButtonTertiary)`
  border-color: ${Colors.signalGreen900};
  color: ${Colors.signalGreen900};

  gap: 0.25rem;

  svg {
    width: 1.75rem;
    height: 1.75rem;
  }
`;
