import { AuthHelp } from 'state/AuthState';
import axios from 'axios';

export type Empty = Record<string, never>;

export class Bridge {
  authHelp: AuthHelp;

  constructor(authHelp: AuthHelp) {
    this.authHelp = authHelp;
  }

  protected checkAuth() {
    this.authHelp.check();
  }

  protected async get<ReqPayload, RespPayload>(path: string, reqPayload: ReqPayload): Promise<RespPayload> {
    const resp = await axios.get(path, { params: reqPayload, ...this.authHelp.header });
    return resp.data as RespPayload;
  }

  protected async post<ReqPayload, RespPayload>(path: string, reqPayload: ReqPayload): Promise<RespPayload> {
    const resp = await axios.post(path, reqPayload, { ...this.authHelp.header });
    return resp.data as RespPayload;
  }

  protected async patch<ReqPayload, RespPayload>(path: string, reqPayload: ReqPayload): Promise<RespPayload> {
    const resp = await axios.patch(path, reqPayload, { ...this.authHelp.header });
    return resp.data as RespPayload;
  }

  protected async delete<RespPayload = Empty>(path: string): Promise<RespPayload> {
    const resp = await axios.delete(path, { ...this.authHelp.header });
    return resp.data as RespPayload;
  }
};
