import styled from 'styled-components';
import { useCallback, useEffect, useState } from 'react';
import { LoadingSpinner } from 'components/common/loaders/LoadingSpinner';
import { Info, List, ListHead } from 'components/common/atoms/List';
import { Colors } from 'styles/Colors';
import { SectionTitle } from 'components/common/atoms/Form';
import { ButtonPrimary } from 'components/common/inputs/Button';
import { Plus } from 'components/icons/Plus';
import { useAuthAPI } from 'api/controllers/AuthAPI';
import { IUser } from 'api/models/User';
import CreateUserDialog from 'components/common/elements/user-management/CreateUserDialog';
import { useMatchBreakpoint } from 'hooks/useMatchBreakpoint';
import { BREAKPOINT_XL } from 'styles/Breakpoints';
import UserRow from './UserRow';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  --list-template: 5rem 1fr 3.5rem 4rem 5rem 10rem;
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
`;

const StyledList = styled(List)`
  --list-template: 1.5fr 1fr 1.5rem;

  ${BREAKPOINT_XL} {
    --list-template: 1fr 1.5fr 2fr 1.5rem;
  }

  ${Info} {
    em {
      font-style: normal;
      color: ${Colors.signalRed900};
    }
  }
`;

export default function UserManagementHome() {
  const [loading, setLoading] = useState(false);
  const [usersList, setUsersList] = useState<IUser[] | null>(null);
  const authAPI = useAuthAPI();

  const [showCreateUserLoginDialog, setShowCreateUserLoginDialog] = useState(false);

  const isDesktop = useMatchBreakpoint();

  const fetchUsersList = useCallback(() => {
    setLoading(true);
    setUsersList(null);
    authAPI
      .getUsersList()
      .then((res) => {
        setUsersList(res.data.rows || []);
      })
      .catch(() => setUsersList(null))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchUsersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <HeaderRow>
        <SectionTitle>Nutzerverwaltung</SectionTitle>

        <ButtonPrimary onClick={() => setShowCreateUserLoginDialog(true)}>
          <Plus /> Nutzeraccount erstellen
        </ButtonPrimary>
      </HeaderRow>

      <StyledList>
        <ListHead>
          <div>Email</div>
          {isDesktop && <div>Berechtigungen</div>}
          <div>Erstellt am</div>
        </ListHead>

        {loading && <LoadingSpinner />}

        {!loading &&
          usersList?.map((userAccount, index) => (
            <UserRow
              key={index}
              userAccount={userAccount}
              onUpdate={(user) => {
                if (user?.id) {
                  setUsersList((state) =>
                    (state || []).map((item) => {
                      if (item.id === user.id) {
                        return user;
                      }
                      return item;
                    }),
                  );
                } else {
                  fetchUsersList();
                }
              }}
            />
          ))}
        {!loading && !(usersList || []).length && <p style={{ padding: '.5rem' }}>Keine Nutzeraccounts gefunden</p>}
      </StyledList>

      {showCreateUserLoginDialog && (
        <CreateUserDialog
          open={showCreateUserLoginDialog}
          setOpen={setShowCreateUserLoginDialog}
          onSubmit={(data, setLoading) => {
            setLoading(true);
            authAPI
              .createUser(data)
              .then(() => fetchUsersList())
              .catch(() => {})
              .finally(() => {
                setLoading(false);
                setShowCreateUserLoginDialog(false);
              });
          }}
        />
      )}
    </Wrapper>
  );
}
