import styled from 'styled-components';
import { Body4, Body4Bold } from 'styles/FontStyles';
import { Colors } from 'styles/Colors';
import { FC } from 'react';
import { IEmployee } from 'api/models/Employee';
import { getAbsenceTypeLabel } from 'api/models/EmployeeAbsence';

export const ActiveLabel = styled.div<{ active?: boolean; state: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;

  height: 2rem;
  padding: 0.25rem 1rem;

  background: ${({ active = true }) => (active ? Colors.signalGreen800 : Colors.signalRed300)};
  border: 1px solid ${({ active = true }) => (active ? Colors.signalGreen900 : Colors.signalRed900)};
  border-radius: 22px;

  ${Body4};
  color: ${({ active = true }) => (active ? Colors.signalGreen900 : Colors.signalRed900)};

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &::before {
    content: '${({ state = '' }) => state}';
    display: ${({ state = '' }) => (!!state ? 'flex' : 'none')};
    ${Body4Bold};
  }
`;

export const EmployeeActiveLabel: FC<{ employee?: IEmployee; className?: string }> = (props) => {
  const { className } = props;
  const absences = (props.employee?.absences || []).sort((a, b) => {
    return ((b.startDate || '') as unknown as string).localeCompare((a.startDate || '') as unknown as string);
  });

  const activeAbsences = absences[0] && absences[0].endDate && new Date(absences[0].endDate) > new Date();

  return (
    <ActiveLabel
      active={absences.length === 0 || !activeAbsences}
      state={absences.length === 0 || !activeAbsences ? 'Aktiv' : getAbsenceTypeLabel(absences[0])}
      className={className}
    >
      {absences.length !== 0 &&
        activeAbsences &&
        `${!!absences[0].endDate ? 'bis' : 'seit'} ${new Date(absences[0].endDate || absences[0].startDate || '').toLocaleDateString('de', {
          dateStyle: 'medium',
        })}`}
    </ActiveLabel>
  );
};
