import styled from 'styled-components';
import { FC } from 'react';
import { ChevronLeft } from 'components/icons/ChevronLeft';
import { Clickable } from '../atoms/Clickable';
import { Body4 } from 'styles/FontStyles';
import { getCurrentDayDate } from 'utils/dateUtils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;

  position: relative;
  z-index: 1;

  ${Body4};

  svg {
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
  }
`;

const Label = styled.p`
  pointer-events: none;
  user-select: none;
  inline-size: 6.5rem;
  text-align: center;
`;

const ChevronRight = styled(ChevronLeft)`
  transform: rotate(180deg);
`;

interface DateYearInputProps {
  className?: string;
  value: string;
  setValue: (value: string) => void;
  minDate?: Date;
  maxDate?: Date;
}

export const DateYearInput: FC<DateYearInputProps> = (props) => {
  const { className, value, setValue } = props;

  const setYear = (offset: number) => {
    const date = getCurrentDayDate(value);
    date.setFullYear(date.getFullYear() + offset);
    if (props.minDate && date < props.minDate) {
      date.setFullYear(props.minDate.getFullYear());
      date.setMonth(props.minDate.getMonth());
    }
    if (props.maxDate && date > props.maxDate) {
      date.setFullYear(props.maxDate.getFullYear());
      date.setMonth(props.maxDate.getMonth());
    }
    setValue(date.toISOString());
  };

  return (
    <Wrapper className={className}>
      <Clickable onClick={() => setYear(-1)}>
        <ChevronLeft />
      </Clickable>

      <Label>{value && new Date(value).toLocaleDateString(undefined, { year: 'numeric' })}</Label>

      <Clickable onClick={() => setYear(1)}>
        <ChevronRight />
      </Clickable>
    </Wrapper>
  );
};
