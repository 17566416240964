import { useState } from 'react';
import { ICustomerCategory } from 'api/models/CustomerCategory';
import { ContentPageLayout } from 'components/common/atoms/Layout';
import { Breadcrumb } from 'components/common/elements/Breadcrumb';
import { LoadingSpinner } from 'components/common/loaders/LoadingSpinner';
import { CustomerCategoryForm } from 'components/content/customer-categories/CustomerCategoryForm';

export const CustomerCategoryAdd = () => {
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [customerCategory, setCustomerCategory] = useState<ICustomerCategory>({
    id: '',
    name: '',
    createdAt: new Date().toDateString(),
    updatedAt: new Date().toDateString(),
  });

  return (
    <ContentPageLayout>
      <Breadcrumb
        items={[
          {
            title: 'Einrichtungen Übersicht',
            href: '/customers',
          },
        ]}
      />
      {loading ? <LoadingSpinner /> : <CustomerCategoryForm customerCategory={customerCategory || undefined} />}
    </ContentPageLayout>
  );
};
