import styled, { css, keyframes } from 'styled-components';
import { IPickupDate } from 'api/models/PickupDate';
import { getCurrentDayDate, getDayOfWeekDate } from 'utils/dateUtils';
import { Colors } from 'styles/Colors';
import { Body4 } from 'styles/FontStyles';
import { useState } from 'react';
import { Edit } from 'components/icons/Edit';
import { Clickable } from 'components/common/atoms/Clickable';
import WrappedTimeInput from 'components/common/elements/WrappedTimeInput';

const Wrapper = styled.div<{ activeTile?: number; tilesCount?: number }>`
  display: grid;
  grid-template-columns: ${({ activeTile = -1, tilesCount = 5 }) => {
    return [...Array(tilesCount)].reduce((res, _, index) => {
      let col = '1fr';
      if (activeTile >= 0 && activeTile !== index) {
        col = '.001fr';
      }
      return [res, `minmax(.5rem, ${col})`].filter(Boolean).join(' ');
    }, '');
  }};
  grid-template-rows: 3.5rem;
  gap: 0.25rem;

  min-inline-size: 16rem;

  transition: grid-template-columns 150ms ease-out;
`;

const TilesColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.25rem;
`;

const Tile = styled.div<{ hide?: boolean; active?: boolean; disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.125rem;

  flex-grow: 1;

  position: relative;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  ${Body4};
  color: ${Colors.textInverted};
  background-color: ${Colors.secondary};
  border: 1px solid ${Colors.secondary};
  border-radius: 2px;

  transition: background-color 150ms ease-out, color 150ms ease-out;

  ${({ disabled = false }) =>
    disabled &&
    css`
      background-color: ${Colors.grey500};
      border-color: ${Colors.grey500};
      color: ${Colors.grey700};
    `};

  ${({ hide = false }) =>
    hide &&
    css`
      pointer-events: none;
      user-select: none;

      & > * {
        opacity: 0;
      }
    `};

  ${({ active = false }) =>
    active &&
    css`
      background-color: ${Colors.white50};
      color: ${Colors.secondary};
    `};
`;

const TileHover = styled(Clickable)`
  position: absolute;
  inset: 0;
  background-color: ${Colors.white50};
  color: ${Colors.secondary};

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  cursor: pointer;

  transition: opacity 150ms ease-out;
  opacity: 0;

  :hover {
    opacity: 1;
  }
`;

const InputContentAppear = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const InputContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.125rem;

  animation: ${InputContentAppear} 300ms ease-out;
`;

const InlineTimeInput = styled(WrappedTimeInput)`
  padding-inline: 0.75rem 0.5rem;
`;

interface ITourPickupTimesProps {
  pickupTimes: IPickupDate;
  editable?: boolean;
  onChange?: (pickupTimes: IPickupDate) => void;
  direction?: string;
  timestamp?: string;
  onTimestampChange?: (timestamp: string) => void;
}

export default function TourPickupTimes(props: ITourPickupTimesProps) {
  const { onChange, editable = !!onChange, pickupTimes, direction = 'outwards', timestamp, onTimestampChange } = props;

  const [editTileIndex, setEditTileIndex] = useState(-1);

  return (
    <Wrapper activeTile={editTileIndex} tilesCount={7}>
      {[...Array(7)].map((_, index) => (
        <TilesColumn key={(index + 1) % 7}>
          {
            // not in edit mode
            editTileIndex < 0 && (
              <Clickable
                onClick={
                  editable && onTimestampChange ? () => onTimestampChange?.(getDayOfWeekDate((index + 1) % 7).toISOString()) : undefined
                }
              >
                <Tile
                  hide={editTileIndex >= 0 && editTileIndex !== index}
                  active={
                    timestamp !== undefined
                      ? getCurrentDayDate(timestamp).getDay() !== (index + 1) % 7 && !!pickupTimes[`${(index + 1) % 7}`]
                      : editTileIndex === index
                  }
                  disabled={
                    timestamp !== undefined ? getCurrentDayDate(timestamp).getDay() !== (index + 1) % 7 : !pickupTimes[`${(index + 1) % 7}`]
                  }
                >
                  <InputContent>
                    <span>
                      <b>{getDayOfWeekDate((index + 1) % 7).toLocaleDateString(undefined, { weekday: 'short' })}</b>
                    </span>
                  </InputContent>
                </Tile>
              </Clickable>
            )
          }
          <Tile
            hide={editTileIndex >= 0 && editTileIndex !== index}
            active={
              editTileIndex === index || (!!pickupTimes[`${(index + 1) % 7}`] && getCurrentDayDate(timestamp).getDay() !== (index + 1) % 7)
            }
            disabled={getCurrentDayDate(timestamp).getDay() !== (index + 1) % 7 || !pickupTimes[`${(index + 1) % 7}`]}
          >
            {
              // not in edit mode
              editTileIndex < 0 && (
                <InputContent>
                  <span>
                    {(pickupTimes[`${(index + 1) % 7}`] &&
                      new Date(pickupTimes[`${(index + 1) % 7}`]).toLocaleTimeString(undefined, { timeStyle: 'short' })) ||
                      '-'}
                  </span>
                </InputContent>
              )
            }
            {
              // not in edit mode
              editable && editTileIndex < 0 && (
                <>
                  <TileHover
                    onClick={() => {
                      setEditTileIndex(editTileIndex < 0 ? index : -1);
                    }}
                  >
                    <Edit />
                  </TileHover>
                </>
              )
            }
            {
              // tile is in edit mode
              editTileIndex === index && (
                <InputContent>
                  <span>{getDayOfWeekDate((index + 1) % 7).toLocaleDateString(undefined, { weekday: 'long' })}</span>
                  <InlineTimeInput
                    autoFocus
                    direction={direction}
                    defaultValue={(() => {
                      const getPickupTimeForDayOfWeek = (dayOfWeek: number) =>
                        pickupTimes[`${dayOfWeek % 7}`] &&
                        new Date(pickupTimes[`${dayOfWeek % 7}`]).toLocaleTimeString(undefined, { timeStyle: 'short' });

                      let day = index + 1;
                      if (!pickupTimes[`${day % 7}`]) {
                        do {
                          day = day < 1 ? 6 : day - 1;
                        } while (!pickupTimes[`${day % 7}`] && day !== index + 1); // search for valid pickup time
                      }

                      return getPickupTimeForDayOfWeek(day);
                    })()}
                    onChange={(value) => {
                      const times = { ...pickupTimes };
                      delete times[`${(index + 1) % 7}`];
                      onChange?.({
                        ...times,
                        ...(!!value?.length
                          ? {
                              [`${(index + 1) % 7}`]: new Date(`2023-01-01 ${value}:00`).toISOString(),
                            }
                          : {}),
                      });
                      setEditTileIndex(-1);
                    }}
                  />
                </InputContent>
              )
            }
          </Tile>

          {/*/ original tile
              <Tile
                key={(index + 1) % 7}
                hide={editTileIndex >= 0 && editTileIndex !== index}
                active={editTileIndex === index}
                disabled={!pickupTimes[`${(index + 1) % 7}`]}
              >
                {
                  // not in edit mode
                  editTileIndex < 0 &&
                  <InputContent>
                    <span>
                      <b>{ getDayOfWeekDate((index + 1) % 7).toLocaleDateString(undefined, { weekday: 'short' }) }</b>
                    </span>
                    <span>
                      { (pickupTimes[`${(index + 1) % 7}`] && new Date(pickupTimes[`${(index + 1) % 7}`]).toLocaleTimeString(undefined, { timeStyle: 'short' })) || '-' }
                    </span>
                  </InputContent>
                }
                {
                  // not in edit mode
                  editable && editTileIndex < 0 &&
                  <>
                    <TileHover
                      onClick={() => {
                        setEditTileIndex(editTileIndex < 0 ? index : -1);
                      }}
                    >
                      <Edit />
                    </TileHover>
                  </>
                }
                {
                  // tile is in edit mode
                  editTileIndex === index &&
                  <InputContent>
                    <span>
                      { getDayOfWeekDate((index + 1) % 7).toLocaleDateString(undefined, { weekday: 'long' }) }
                    </span>
                    <InlineTimeInput
                      direction={direction}
                      defaultValue={pickupTimes[`${(index + 1) % 7}`] && new Date(pickupTimes[`${(index + 1) % 7}`]).toLocaleTimeString(undefined, { timeStyle: 'short' })}
                      onChange={(value) => {
                        const times = { ...pickupTimes };
                        delete times[`${(index + 1) % 7}`];
                        onChange?.({
                          ...times,
                          ...(!!value ? {
                            [`${(index + 1) % 7}`]: new Date(`2023-01-01 ${value}:00`).toISOString(),
                          } : {}),
                        });
                        setEditTileIndex(-1);
                      }}
                    />
                  </InputContent>
                }
              </Tile>
              // */}
        </TilesColumn>
      ))}
    </Wrapper>
  );
}
