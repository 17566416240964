import { useCallback } from 'react';
import { useAuthHelp } from 'state/AuthState';
import { CustomerOrganizationsBridge } from '../../bridges/CustomerOrganizationsBridge';
import { useCustomersAPI } from 'api/controllers/CustomersAPI';
import { ButtonPrimary, ButtonSecondary } from 'components/common/inputs/Button';
import { LoadingSpinner } from 'components/common/loaders/LoadingSpinner';
import { useShowDialog } from 'state/DialogState';

// TODO list does not remove deleted item
export const useShowDeleteCustomerOrganizationDialog = (id: number) => {
  const showDialog = useShowDialog();

  const authHelp = useAuthHelp();
  const customerOrganizationsBridge = new CustomerOrganizationsBridge(authHelp);

  return useCallback(
    (onSuccess?: () => void) => {
      showDialog({
        headline: 'Organisation entfernen',
        body: <>Möchten Sie diese Organisation wirklich löschen?</>,
        buttons: ({ loading = false }) => (
          <>
            <ButtonPrimary
              disabled={loading}
              onClick={() => {
                showDialog({ loading: true }, true);

                customerOrganizationsBridge.deleteOne(id)
                  .then(() => {
                    showDialog(null);
                    onSuccess?.();
                  })
                  .catch((e) => {
                    console.error('delete customer', e);

                    // show error message
                    showDialog({
                      headline: 'Organisation entfernen',
                      body: <>Die Organisation konnte nicht entfernt werden. Bitte versuchen Sie es später erneut.</>,
                    });
                  });
              }}
            >
              {loading ? <LoadingSpinner /> : 'Entfernen'}
            </ButtonPrimary>
            <ButtonSecondary disabled={loading} onClick={() => showDialog(null)}>
              Abbrechen
            </ButtonSecondary>
          </>
        ),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, showDialog, authHelp],
  );
};
