import { ButtonPrimary, ButtonSecondary } from 'components/common/inputs/Button';
import { LoadingSpinner } from 'components/common/loaders/LoadingSpinner';
import { Dialog } from 'components/common/elements/Dialog';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Colors } from 'styles/Colors';
import { getCurrentDayDate } from 'utils/dateUtils';
import { Calendar } from 'components/common/inputs/Calendar';
import { TextInput } from 'components/common/inputs/TextInput';
import { BREAKPOINT_MD } from 'styles/Breakpoints';

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;

  text-align: start;
`;

const GridRow = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;

  ${BREAKPOINT_MD} {
    grid-template-columns: 1fr 1fr;
  }
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.25rem;

  color: ${Colors.grey700};
`;

const CommentInput = styled(TextInput)`
  label {
    margin: 0;
  }

  textarea {
    max-width: none;
  }
`;

export interface IChangeTourSubmitPayload {
  startDate: Date | null;
  endDate: Date | null;
  comment: string | null;
}
interface IChangeTourDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  timestamp?: string;
  onSubmit: (config: IChangeTourSubmitPayload, setLoading: (loading: boolean) => void) => void;
  minDate?: Date | null;
  maxDate?: Date | null;
}
export default function ChangeTourDialog(props: IChangeTourDialogProps) {
  const { open, setOpen, timestamp, onSubmit, minDate } = props;

  const [loading, setLoading] = useState(false);

  const [startDate, setStartDate] = useState<Date | null>(getCurrentDayDate(timestamp));
  const [endDate, setEndDate] = useState<Date | null>(getCurrentDayDate(timestamp));

  const [comment, setComment] = useState('');

  useEffect(() => {
    if (open) {
      setStartDate(getCurrentDayDate(timestamp));
      setEndDate(getCurrentDayDate(timestamp));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      headline={'Für welchen Zeitraum gilt diese Änderung?'}
      text={
        <Body>
          <GridRow>
            <Label>
              <b>Von</b>
              <Calendar
                title={'TT/MM/JJJJ'}
                value={startDate}
                setValue={setStartDate as any}
                minDate={minDate ? new Date(minDate.toString()) : null}
                maxDate={endDate}
                dropdownStyling
              />
            </Label>
            <Label>
              <b>Bis</b>
              <Calendar
                title={'TT/MM/JJJJ'}
                value={endDate}
                setValue={setEndDate as any}
                minDate={startDate}
                maxDate={props.maxDate ? new Date(props.maxDate?.toString()) : null} // even if maxDate is a Date object it is in the wrong
                dropdownStyling
              />
            </Label>
          </GridRow>
          <CommentInput
            id={'change-tour-dialog:comment'}
            type={'textarea'}
            independent
            value={comment}
            onValueChange={setComment}
            label={'Kommentar für den/die Fahrer'}
            placeholder={'Kommentar hinzufügen...'}
          />
        </Body>
      }
      buttons={
        <>
          <ButtonSecondary disabled={loading} onClick={() => setOpen(false)}>
            Abbrechen
          </ButtonSecondary>
          <ButtonPrimary disabled={loading || !startDate} onClick={() => onSubmit({ startDate, endDate, comment }, setLoading)}>
            {loading ? <LoadingSpinner /> : 'Speichern'}
          </ButtonPrimary>
        </>
      }
    />
  );
}
