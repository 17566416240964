// General
import styled from 'styled-components';

// Components
import { CustomerDetails } from 'components/content/customers/CustomerDetails';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;

  padding: 2.25rem var(--content-indent) 4.75rem;
`;

export const CustomerDetail = () => {
  return (
    <Wrapper>
      <CustomerDetails />
    </Wrapper>
  );
};
