import styled from 'styled-components';
import { Body1, Body1Bold, Body4, Body4Bold } from 'styles/FontStyles';
import { Colors } from 'styles/Colors';
import { Card } from 'components/common/atoms/Card';
import { Link } from 'components/common/atoms/Link';
import { FC } from 'react';
import { Edit } from 'components/icons/Edit';
import { Trash } from 'components/icons/Trash';
import { ICustomer } from 'api/models/Customer';
import { LocationTag } from '../LocationTag';
import { Phone } from 'components/icons/Phone';
import { getTelLink } from 'utils/phoneUtils';
import { Mail } from 'components/icons/Mail';
import { Clickable } from 'components/common/atoms/Clickable';
import { useNavigate } from 'react-router-dom';
import { useShowDeleteCustomerDialog } from 'hooks/customers/useShowDeleteCustomerDialog';
import { Tours } from 'components/icons/Tours';
import { PassengersMany } from 'components/icons/PassengersMany';
import { useHasPermission } from 'state/UserMeState';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const ContextOptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const ContextOption = styled(Clickable).attrs(({ onClick }: any) => (!onClick ? { as: Link } : {}))<{ href?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;

  ${Body4};
  padding: 0 1rem 0.5rem;

  &:hover {
    text-decoration: underline;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    object-fit: contain;
  }
`;

const StatusCard = styled(Card)`
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
`;

const StatusContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
`;

const StatusTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
`;

const StatusInternalId = styled.h2`
  ${Body1};
`;

const StatusTitle = styled.h2`
  ${Body1Bold};
`;

const StatusRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
`;

const InfoTag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  ${Body4};

  svg {
    width: 1.5rem;
    height: 1.5rem;
    object-fit: contain;
  }

  a {
    ${Body4Bold};
    color: ${Colors.secondary};
  }
`;

export const CustomerDetailsStatusCard: FC<{ customer: ICustomer }> = (props) => {
  const { customer } = props;

  const { scheduledTours = [], passengers = [] } = customer;

  const navigate = useNavigate();
  const showDeleteCustomerDialog = useShowDeleteCustomerDialog(customer.id);
  const isAdmin = useHasPermission('admin');

  const contactPhone = customer?.contactPersons?.find(({ phoneNumber }) => !!phoneNumber);
  const contactEmail = customer?.contactPersons?.find(({ emailAddress }) => !!emailAddress);

  return (
    <Wrapper>
      <ContextOptionsWrapper>
        <ContextOption href={`/customers/${customer.id}/edit`}>
          <Edit /> Bearbeiten
        </ContextOption>
        {isAdmin && (
          <ContextOption href={''} onClick={() => showDeleteCustomerDialog(() => navigate('/customers', { replace: true }))}>
            <Trash /> Entfernen
          </ContextOption>
        )}
      </ContextOptionsWrapper>

      <StatusCard>
        <StatusContentWrapper>
          <StatusTitleWrapper>
            <StatusInternalId>{customer.internalId}</StatusInternalId>
            <StatusTitle>{customer.displayName}</StatusTitle>
          </StatusTitleWrapper>
          <StatusRow>
            {contactPhone && (
              <InfoTag>
                <Phone />
                <Link href={getTelLink(contactPhone?.phoneNumber) || ''}>{contactPhone.formattedPhoneNumber}</Link>
              </InfoTag>
            )}
            {contactEmail && (
              <InfoTag>
                <Mail />
                <Link href={`mailto:${contactEmail.emailAddress}`}>{contactEmail.emailAddress}</Link>
              </InfoTag>
            )}
          </StatusRow>
          <StatusRow>
            <LocationTag address={customer.region} />
            {scheduledTours.length > 0 && (
              <InfoTag>
                <Tours /> {scheduledTours.length} Fahrten
              </InfoTag>
            )}
            {passengers.length > 0 && (
              <InfoTag>
                <PassengersMany /> {passengers.length} Fahrgäste
              </InfoTag>
            )}
          </StatusRow>
        </StatusContentWrapper>
      </StatusCard>
    </Wrapper>
  );
};
