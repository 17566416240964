// General
import { FC, ReactNode, useState } from 'react';
import styled from 'styled-components';

// Components
import { Tooltip } from '../atoms/Tooltip';
import { ContextMenuItem } from '../atoms/ContextMenu';
import { Clickable } from '../atoms/Clickable';

// Icons
import { Details } from 'components/icons/Details';

const Container = styled.div`
  position: relative;
`;

const ClickableWrapper = styled(Clickable)<{ detailsMenuOpen: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;

  z-index: ${({ detailsMenuOpen }) => (detailsMenuOpen ? 5 : 0)};

  svg {
    width: 1.5rem;
    height: 1.5rem;
    object-fit: contain;
  }
`;

interface IContextOptionsProps {
  items: {
    onClick?: () => void;
    href?: string;
    content: ReactNode;
  }[];
}

export const ContextOptionsFixed: FC<IContextOptionsProps> = (props) => {
  const { items } = props;
  const [detailsMenuOpen, setDetailsMenuOpen] = useState(false);

  return (
    <Container>
      <ClickableWrapper
        detailsMenuOpen={detailsMenuOpen}
        onClick={(e) => {
          e.stopPropagation();
          setDetailsMenuOpen(!detailsMenuOpen);
        }}
      >
        <Details />
      </ClickableWrapper>
      <Tooltip open={detailsMenuOpen} setOpen={setDetailsMenuOpen}>
        {items.map(({ content, onClick, href }, index) => (
          <object key={index}>
            <ContextMenuItem
              href={href}
              onClick={(e: any) => {
                e.stopPropagation();
                setDetailsMenuOpen(false);
                onClick?.();
              }}
            >
              {content}
            </ContextMenuItem>
          </object>
        ))}
      </Tooltip>
    </Container>
  );
};
