import { ICustomerOrganization } from 'api/models/CustomerOrganization';
import { useShowDeleteCustomerOrganizationDialog } from 'hooks/customer_organizations/useShowDeleteCustomerOrganizationDialog';
import { useNavigate } from 'hooks/useNavigate';
import { useHasPermission } from 'state/UserMeState';
import { Edit } from 'components/icons/Edit';
import { Trash } from 'components/icons/Trash';
import { Info, InfoBold, ListRowControlsWrapper, ListRowWrapper, OptionsWrapper, Row } from 'components/common/atoms/List';
import { ContextOptions } from '../ContextOptions';
import { FC } from 'react';

import { IAPIModelBaseV3, ReplaceIn, PickFromModel } from 'api/models';

// ICustomer does not have `name`:
interface OurCustomer extends IAPIModelBaseV3 {
  name: string;
}

type OurCustomerOrganization =
  ReplaceIn<
    PickFromModel<ICustomerOrganization, "name" | "description" | "customers">,
    {id: number, customers?: OurCustomer[]}
  >

export const CustomerOrganizationListRow: FC<{ customerOrganization: OurCustomerOrganization; onUpdate?: () => void }> = (props) => {
  const { id, name, description, customers } = props.customerOrganization;

  const showDeleteCustomerOrganizationDialog = useShowDeleteCustomerOrganizationDialog(id);

  const navigate = useNavigate();
  const isAdmin = useHasPermission('admin');

  return (
    <ListRowWrapper>
      <Row>
        <InfoBold>{name || '-'}</InfoBold>
        <Info>{description || '-'}</Info>
        <Info>{customers?.length || '-'}</Info>

        <ListRowControlsWrapper>
          <OptionsWrapper>
            <ContextOptions
              items={
                [
                  isAdmin && {
                    content: (
                      <>
                        <Trash /> Entfernen
                      </>
                    ),
                    onClick: () => showDeleteCustomerOrganizationDialog(props.onUpdate),
                  },
                  {
                    content: (
                      <>
                        <Edit /> Bearbeiten
                      </>
                    ),
                    onClick: () => navigate(`/customer-organizations/${id}/edit`),
                  },
                ].filter(Boolean) as []
              }
            />
          </OptionsWrapper>
        </ListRowControlsWrapper>
      </Row>
    </ListRowWrapper>
  );
};
