import { PropsWithChildren, memo } from 'react';
import { getCurrentDayDate } from 'utils/dateUtils';
import styled, { css } from 'styled-components';
import { BREAKPOINT_MAX_XL, BREAKPOINT_XL } from 'styles/Breakpoints';
import { Body3, Body3Bold, Headline2 } from 'styles/FontStyles';
import { Colors } from 'styles/Colors';

const DayWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-block-start: 6.25rem;
  gap: 1.5rem;

  ${BREAKPOINT_MAX_XL} {
    && {
      border-bottom: none;
    }
  }

  ${BREAKPOINT_XL} {
    padding-block-start: 5.5rem;
    gap: 0;
  }
`;

const DateLabel = styled.div<{ active: boolean; bygone: boolean }>`
  position: absolute;
  top: 2rem;
  right: 100%;
  padding-inline-end: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  margin-inline-end: -2.75rem;

  ${BREAKPOINT_XL} {
    margin-inline-end: 0;
  }

  ${Body3Bold};

  span {
    ${Headline2};
    position: relative;

    em {
      ${Body3};
      font-style: normal;
      white-space: nowrap;

      display: flex;
      flex-direction: row;
      align-items: flex-end;
      gap: 0.75rem;

      position: absolute;
      bottom: 0;
      left: 100%;
      padding: 0.25rem;

      &::after {
        display: ${({ active }) => (active ? 'flex' : 'none')};
        content: 'heute';
        background: ${Colors.grey500};
        padding-inline: 1rem;
        padding-block: 0.25rem;
        border-radius: 20px;
      }
    }
  }

  ${({ active }) =>
    active &&
    css`
      color: ${Colors.secondary};

      &::before {
        content: '';
        position: absolute;
        top: 1rem;
        left: -1rem;
        width: 0.5rem;
        height: 0.5rem;
        flex-shrink: 0;
        border-radius: 50%;
        background: ${Colors.secondary};
      }
    `};

  ${({ bygone }) =>
    bygone &&
    css`
      color: ${Colors.grey600};
    `};
`;

const DayContainer = memo((props: PropsWithChildren<{ day: string }>) => {
  const { day, children } = props;

  return (
    <DayWrapper data-day={getCurrentDayDate(day).toISOString()}>
      <DateLabel
        active={getCurrentDayDate(day).toISOString() === getCurrentDayDate().toISOString()}
        bygone={getCurrentDayDate(day) < getCurrentDayDate()}
      >
        <span>
          {new Date(day).toLocaleDateString(undefined, {
            day: '2-digit',
            month: undefined,
            year: undefined,
          })}
          <em>
            {new Date(day).toLocaleDateString(undefined, {
              month: 'long',
              year: 'numeric',
            })}
          </em>
        </span>
        {new Date(day).toLocaleDateString(undefined, {
          weekday: 'short',
          day: undefined,
          month: undefined,
          year: undefined,
        })}
      </DateLabel>
      {children}
    </DayWrapper>
  );
});

export default DayContainer;
