import styled from 'styled-components';
import { Body4 } from 'styles/FontStyles';
import { Colors } from 'styles/Colors';

import { getFormattedValue } from 'utils/dateUtils';
import { ChipTile } from 'components/common/atoms/ChipTile';
import { IScheduledTour } from 'api/models/ScheduledTour';

const DateRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;

  z-index: 2;

  & > span {
    ${Body4};
    color: ${Colors.grey600};
  }
`;

interface ITourRuntimeProps {
  scheduledTour: IScheduledTour;
  className?: string;
}
export default function TourRuntime(props: ITourRuntimeProps) {
  const { scheduledTour, className } = props;

  return (
    <DateRow className={className}>
      <span>Laufzeit:</span>
      <ChipTile>
        {getFormattedValue(
          [scheduledTour.startDate, scheduledTour.endDate].map((date) => (date ? new Date(date) : null)) as [Date | null, Date | null],
        )}
      </ChipTile>
    </DateRow>
  );
}
