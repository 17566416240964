import styled from 'styled-components';
import { Body1Bold, Body4, Body4Bold } from 'styles/FontStyles';
import { Colors } from 'styles/Colors';
import { Card } from 'components/common/atoms/Card';
import { Link } from 'components/common/atoms/Link';
import { FC } from 'react';
import { Edit } from 'components/icons/Edit';
import { Trash } from 'components/icons/Trash';
import { Phone } from 'components/icons/Phone';
import { getTelLink } from 'utils/phoneUtils';
import { Mail } from 'components/icons/Mail';
import { IPassenger } from 'api/models/Passenger';
import { Avatar } from 'components/common/atoms/Avatar';
import { Facilities } from 'components/icons/Facilities';
import { Wheelchair } from 'components/icons/Wheelchair';
import { useShowDeletePassengerDialog } from 'hooks/passengers/useShowDeletePassengerDialog';
import { Clickable } from 'components/common/atoms/Clickable';
import { useNavigate } from 'react-router-dom';
import { formatName } from 'utils/nameUtils';
import { useHasPermission } from 'state/UserMeState';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const ContextOptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const ContextOption = styled(Clickable).attrs(({ onClick }: any) => (!onClick ? { as: Link } : {}))<{ href?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;

  ${Body4};
  padding: 0 1rem 0.5rem;

  &:hover {
    text-decoration: underline;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    object-fit: contain;
  }
`;

const StatusCard = styled(Card)`
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
`;

const StyledAvatar = styled(Avatar)`
  ${Body1Bold};
  font-size: 2.25rem;
  letter-spacing: 2px;
`;

const StatusContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;

  flex: 1;
  overflow: hidden;
`;

const StatusTitle = styled.h2`
  ${Body1Bold};
`;

const StatusRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem 2rem;
  flex-wrap: wrap;
`;

const InfoTag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  overflow: hidden;

  ${Body4};

  svg {
    width: 1.5rem;
    height: 1.5rem;
    object-fit: contain;
    flex-shrink: 0;
  }

  a {
    ${Body4Bold};
    color: ${Colors.secondary};
    white-space: nowrap;
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const PassengerDetailsStatusCard: FC<{ passenger: IPassenger }> = (props) => {
  const { passenger } = props;

  const navigate = useNavigate();
  const showDeletePassengerDialog = useShowDeletePassengerDialog(passenger.id);
  const isAdmin = useHasPermission('admin');

  const contactPhone = passenger?.contactPersons?.find(({ phoneNumber }) => !!phoneNumber);
  const contactEmail = passenger?.contactPersons?.find(({ emailAddress }) => !!emailAddress);

  const contactPhoneLabel = [formatName(contactPhone), contactPhone?.role].filter(Boolean).join('; ');
  const contactEmailLabel = [formatName(contactEmail), contactEmail?.role].filter(Boolean).join('; ');

  return (
    <Wrapper>
      <ContextOptionsWrapper>
        <ContextOption href={`/passengers/${passenger.id}/edit`}>
          <Edit /> Bearbeiten
        </ContextOption>
        {isAdmin && (
          <ContextOption onClick={() => showDeletePassengerDialog(() => navigate(`/passengers`))}>
            <Trash /> Entfernen
          </ContextOption>
        )}
      </ContextOptionsWrapper>

      <StatusCard data-test-id='passenger-detail-status-card'>
        <StyledAvatar src={''} firstName={passenger.firstName} lastName={passenger.lastName} size={'large'} />
        <StatusContentWrapper>
          <StatusTitle>{formatName(passenger)}</StatusTitle>
          <StatusRow>
            {contactPhone && (
              <InfoTag>
                <Phone />
                <Link href={getTelLink(contactPhone?.phoneNumber) || ''}>{contactPhone.formattedPhoneNumber}</Link>
                {contactPhoneLabel && <span>({contactPhoneLabel})</span>}
              </InfoTag>
            )}
            {contactEmail && (
              <InfoTag>
                <Mail />
                <Link href={`mailto:${contactEmail.emailAddress}`}>{contactEmail.emailAddress}</Link>
                {contactEmailLabel && <span>({contactEmailLabel})</span>}
              </InfoTag>
            )}
            {passenger.hasWheelchair && (
              <InfoTag>
                <Wheelchair /> <span>Rollstuhlfahrer</span>
              </InfoTag>
            )}
            {passenger.customer && (
              <InfoTag>
                <Facilities /> <span>{passenger.customer.displayName}</span>
              </InfoTag>
            )}
          </StatusRow>
        </StatusContentWrapper>
      </StatusCard>
    </Wrapper>
  );
};
