import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCustomerCategoryAPI } from 'api/controllers/CustomerCategoryAPI';
import { ICustomerCategory } from 'api/models/CustomerCategory';
import { ContentPageLayout } from 'components/common/atoms/Layout';
import { Breadcrumb } from 'components/common/elements/Breadcrumb';
import { LoadingSpinner } from 'components/common/loaders/LoadingSpinner';
import { CustomerCategoryForm } from 'components/content/customer-categories/CustomerCategoryForm';

export const CustomerCategoryEdit = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);

  const [customerCategory, setCustomerCategory] = useState<ICustomerCategory>();

  const customerCategoryAPI = useCustomerCategoryAPI();
  useEffect(() => {
    if (id) {
      setLoading(true);
      customerCategoryAPI
        .getCustomerCategoryById({ id })
        .then((res) => {
          setCustomerCategory(res.data.data);
        })
        .catch(() => {})
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <ContentPageLayout>
      <Breadcrumb
        items={[
          {
            title: 'Einrichtungen Übersicht',
            href: '/customers',
          },
        ]}
      />
      {loading ? <LoadingSpinner /> : <CustomerCategoryForm customerCategory={customerCategory || undefined} />}
    </ContentPageLayout>
  );
};
