import styled from 'styled-components';
import UserManagementHome from 'components/content/user-management/UserManagementHome';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  padding: 2.25rem 0 0 0;
`;

export function UserManagement() {
  return (
    <Wrapper>
      <UserManagementHome />
    </Wrapper>
  );
}
