import { FC, memo, useMemo, useState } from 'react';
import { IPassenger } from 'api/models/Passenger';
import { TourplanAvailablePassengerView, TourplanPassengerView } from '../tiles/TourplanPassengerView';
import { Checkmark } from 'components/icons/Checkmark';
import { DropOverItemsBaseView } from '../tiles/DropOverItems';
import { AvailablePassengersList, ButtonsWrapper, ListTitle, TaskCompletedHint, TourplanOverlay } from 'components/content/customers/tourplan/overlays/TourplanOverlay';
import { useDragDropItemsMoveState, useMoveItems, useSelectedDragDropItems } from '../DragDropContext';
import { ITour } from 'api/models/Tour';
import { ButtonPrimary, ButtonTertiary } from 'components/common/inputs/Button';
import { NoSearchResult } from 'components/content/customers/tourplan/overlays/TourplanAvailableOrdersOverlay';

/**
 * for create/edit order
 */
export const TourplanAvailablePassengersOverlay: FC<{
  availablePassengers: Partial<IPassenger>[];
  count: number;
  totalPassengers?: Partial<IPassenger>[];
}> = memo((props) => {
  const { availablePassengers = [], totalPassengers, count } = props;

  const [searchString, setSearchString] = useState<string>('');
  const [filteredUnplannedPassengers, setFilteredUnplannedPassengers] = useState<IPassenger[]>(availablePassengers as IPassenger[]);
  const [filteredTotalPassengers, setFilteredTotalPassengers] = useState<IPassenger[]>(totalPassengers as IPassenger[]);

  const availablePassengersList = useMemo(
    () => (
      <>
        <ListTitle style={{ zIndex: 1 }}>
          nicht verteilte Fahrgäste ({availablePassengers.length}/{count})
        </ListTitle>
        <AvailablePassengersList>
          {filteredUnplannedPassengers.map((passenger, index) => (
            <TourplanAvailablePassengerView key={index} passenger={passenger} />
          ))}
          {availablePassengers.length === 0 && (
            <TaskCompletedHint>
              <Checkmark /> alle Fahrgäste wurden verteilt
            </TaskCompletedHint>
          )}
          <DropOverItemsBaseView />
          {filteredUnplannedPassengers.length === 0 && searchString && <NoSearchResult>Keine passenden Fahrten gefunden.</NoSearchResult>}
        </AvailablePassengersList>
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [count, availablePassengers, filteredUnplannedPassengers],
  );

  const plannedPassengersList = useMemo(
    () => (
      <>
        {totalPassengers && (
          <>
            <ListTitle style={{ zIndex: 2 }}>
              verteilte Fahrgäste ({count - availablePassengers.length}/{count})
            </ListTitle>
            <AvailablePassengersList inactive>
              {filteredTotalPassengers.map((passenger, index) => (
                <TourplanPassengerView key={index} passenger={passenger} />
              ))}
              {filteredTotalPassengers.length === 0 && searchString && <NoSearchResult>Keine passenden Fahrten gefunden.</NoSearchResult>}
            </AvailablePassengersList>
          </>
        )}
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [availablePassengers.length, count, totalPassengers, filteredTotalPassengers],
  );

  const [selectedItems, resetSelectedItems] = useSelectedDragDropItems<Partial<ITour>, IPassenger>('revert');
  const [moveItemsState, setMoveItemsState] = useDragDropItemsMoveState();

  const moveItems = useMoveItems({});

  const revertButtonView = useMemo(
    () => (
      <>
        {moveItemsState !== null && moveItemsState.srcContainer !== 'revert' && (
          <ButtonsWrapper>
            <ButtonPrimary
              onClick={(e: Event) => {
                e.stopPropagation();
                moveItems('revert');
              }}
            >
              In Planung verschieben
            </ButtonPrimary>
          </ButtonsWrapper>
        )}
      </>
    ),
    [moveItemsState, moveItems],
  );

  const moveSelectionButtonsView = useMemo(
    () => (
      <>
        {selectedItems.length > 0 && (
          <ButtonsWrapper>
            <ButtonTertiary
              disabled={moveItemsState !== null}
              onClick={(e: Event) => {
                e.stopPropagation();
                resetSelectedItems();
              }}
            >
              Auswahl verwerfen
            </ButtonTertiary>
            <ButtonPrimary
              onClick={(e: Event) => {
                e.stopPropagation();
                if (moveItemsState !== null) {
                  setMoveItemsState(null);
                } else {
                  setMoveItemsState({ srcContainer: 'revert', items: selectedItems });
                }
              }}
            >
              {moveItemsState !== null
                ? 'Verschieben abbrechen'
                : `${selectedItems.length} ${selectedItems.length === 1 ? 'Fahrgast' : 'Fahrgäste'} verschieben`}
            </ButtonPrimary>
          </ButtonsWrapper>
        )}
      </>
    ),
    [selectedItems, moveItemsState, resetSelectedItems, setMoveItemsState],
  );

  const handleSearchChange = (searchString: string) => {
    const filteredAvailablePassengers = availablePassengers.filter((passenger) => {
      return (
        passenger !== undefined &&
        (passenger.firstName?.toLowerCase().includes(searchString.toLowerCase()) ||
          passenger.lastName?.toLowerCase().includes(searchString.toLowerCase()))
      );
    });

    const filteredPlannedPassengers = totalPassengers?.filter((passenger) => {
      return (
        passenger !== undefined &&
        (passenger.firstName?.toLowerCase().includes(searchString.toLowerCase()) ||
          passenger.lastName?.toLowerCase().includes(searchString.toLowerCase()))
      );
    });
    setFilteredUnplannedPassengers(filteredAvailablePassengers as IPassenger[]);
    setFilteredTotalPassengers(filteredPlannedPassengers as IPassenger[]);

    setSearchString(searchString);
  };

  return (
    <TourplanOverlay title={'Fahrgäste'} count={count} searchString={searchString} handleSearchChange={handleSearchChange}>
      {moveSelectionButtonsView}

      {availablePassengersList}
      {plannedPassengersList}

      {revertButtonView}
      {moveSelectionButtonsView}
    </TourplanOverlay>
  );
});
