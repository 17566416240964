import { SearchInput } from '../inputs/SearchInput';
import { Avatar } from '../atoms/Avatar';
import { Trash } from 'components/icons/Trash';
import { LoadingSpinner } from '../loaders/LoadingSpinner';
import { useEffect, useState } from 'react';
import { IPassenger } from 'api/models/Passenger';
import styled, { css } from 'styled-components';
import { ContextMenu } from '../atoms/ContextMenu';
import { Colors } from 'styles/Colors';
import { Body4, Body4Bold } from 'styles/FontStyles';
import { Plus } from 'components/icons/Plus';
import { BREAKPOINT_LG } from 'styles/Breakpoints';
import { Clickable } from '../atoms/Clickable';
import { Close } from 'components/icons/Close';
import { formatName } from 'utils/nameUtils';
import { usePassengers } from 'state/PassengersState';
import { SimpleSwitch } from '../inputs/Switch';

const Wrapper = styled.div<{ popupOpen: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  position: relative;
  z-index: ${({ popupOpen }) => (popupOpen ? 5 : 0)};
`;

const AddPassengerRow = styled.div`
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  ${Body4Bold};
  color: ${Colors.grey600};
  padding-block: 0.5rem;

  transition: color 150ms ease-out;

  @media (hover: hover) {
    &:hover {
      color: ${Colors.textDefault};
      cursor: pointer;
    }
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
  }
`;

const Popup = styled(ContextMenu).attrs({ hideCloseButton: true })`
  position: fixed;
  top: 0.5rem;
  bottom: 0.5rem;
  left: 0.5rem;
  right: 0.5rem;
  margin: 0 auto;
  max-inline-size: 24rem;

  ${BREAKPOINT_LG} {
    position: absolute;
    top: auto;
    bottom: 100%;
    left: 0;
    right: auto;
    margin: 0.5rem 0 0;
    max-inline-size: none;
  }

  align-items: stretch;
  padding: 0.5rem;

  color: ${Colors.textDefault};
  cursor: auto;
`;

const CloseButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;

  & > :first-child {
    flex: 1;
  }

  ${BREAKPOINT_LG} {
    & > :not(:first-child) {
      display: none;
    }
  }
`;

const SwitchRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
`;

const PassengersList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.75rem;

  margin: -0.5rem;
  padding: 0.5rem;

  overflow: auto;
  overflow-x: hidden;

  ${BREAKPOINT_LG} {
    max-height: 20rem;
  }
`;

const PassengerItem = styled.div<{ active: boolean; showDivider?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  margin: -0.5rem;
  padding: 0.5rem;

  ${Body4};

  transition: background-color 150ms ease-out;

  @media (hover: hover) {
    :hover {
      cursor: pointer;
      background-color: ${Colors.grey400};
    }
  }

  ${({ active }) =>
    active &&
    css`
      & > :first-child {
        box-shadow: 0 0 0 2px ${Colors.secondary};
      }

      color: ${Colors.grey700};
      opacity: 0.85;
    `};

  ${({ showDivider = false }) =>
    showDivider &&
    css`
      position: relative;

      &::after {
        content: '';
        position: absolute;
        inset-inline: 0.5rem;
        inset-block: 100% 0;
        block-size: 1px;
        background-color: ${Colors.grey500};
      }
    `};
`;

const TrashButton = styled.div.attrs({ role: 'button', tabIndex: 0 })`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;

  ${Body4};
  color: ${Colors.secondary};

  margin: -0.75rem;
  padding: 0.75rem;
  margin-inline-start: auto;

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

interface IPassengerPickerProps {
  customerId: string;
  organizationId?: string;
  unavailablePassengers?: string[];
  selectedPassenger?: IPassenger;
  onSelect?: (passenger: IPassenger | null) => void;
}
export default function PassengerPicker(props: IPassengerPickerProps) {
  const { customerId, organizationId, unavailablePassengers = [], selectedPassenger, onSelect } = props;

  const [searchOrganization, setSearchOrganization] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [searchString, setSearchString] = useState('');

  const [searchParams, setSearchParams] = useState({
    customerId,
    organizationId: searchOrganization && !!organizationId ? organizationId : undefined,
    search: searchString,
    setSearchString,
  });
  const { data: passengers, refetch } = usePassengers(searchParams);

  const setSearchQuery = (searchQuery: string) => {
    setSearchString(searchQuery);
    setSearchParams({
      ...searchParams,
      organizationId: searchOrganization && !!organizationId ? organizationId : undefined,
      search: searchQuery,
    });
  };

  useEffect(() => {
    if (popupOpen) {
      refetch();
    } else if (searchParams.search !== '') {
      setSearchParams({
        ...searchParams,
        organizationId: searchOrganization && !!organizationId ? organizationId : undefined,
        search: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, popupOpen]);

  const onOrganisationChange = (value: boolean) => {
    setSearchOrganization(value);
    value ? setSearchParams({ ...searchParams, organizationId }) : setSearchParams({ ...searchParams, organizationId: undefined });
  };

  return (
    <Wrapper popupOpen={popupOpen}>
      <AddPassengerRow tabIndex={0} role={'button'} onClick={() => setPopupOpen(true)}>
        <Plus /> Fahrgast hinzufügen
      </AddPassengerRow>
      <Popup open={popupOpen} setOpen={setPopupOpen}>
        <CloseButtonRow>
          <SearchInput
            placeholder={'nach Fahrgästen suchen'}
            value={searchString}
            setValue={setSearchQuery}
            onReset={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setSearchQuery('');
            }}
          />
          <Clickable onClick={() => setPopupOpen(false)}>
            <Close />
          </Clickable>
        </CloseButtonRow>
        {organizationId && (
          <SwitchRow>
            <SimpleSwitch label="In Organisation suchen" checked={searchOrganization} onValueChange={onOrganisationChange} />
          </SwitchRow>
        )}
        {selectedPassenger && (
          <PassengerItem
            tabIndex={0}
            role="button"
            active
            showDivider
            onClick={(e) => {
              e.stopPropagation();
              onSelect?.(selectedPassenger);
              setPopupOpen(false);
            }}
          >
            <Avatar firstName={selectedPassenger.firstName} lastName={selectedPassenger.lastName} size={'mini'} />
            <span>{formatName(selectedPassenger)}</span>
            <TrashButton
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onSelect?.(null);
              }}
            >
              entfernen <Trash />
            </TrashButton>
          </PassengerItem>
        )}
        <PassengersList>
          {passengers === null && <LoadingSpinner />}
          {passengers
            ?.filter(
              (passenger) => selectedPassenger?.id !== passenger.id.toString() && !unavailablePassengers.includes(passenger.id.toString()),
            )
            .map((passenger, index) => (
              <PassengerItem
                key={passenger.id}
                tabIndex={0}
                role="button"
                active={selectedPassenger?.id === passenger.id.toString()}
                onClick={(e) => {
                  e.stopPropagation();
                  onSelect?.(passenger.apiOldData);
                  setPopupOpen(false);
                }}
              >
                <Avatar firstName={passenger.firstName} lastName={passenger.lastName} size={'mini'} />
                <span>{passenger.fullNameWithComma}</span>
              </PassengerItem>
            ))}
        </PassengersList>
      </Popup>
    </Wrapper>
  );
}
