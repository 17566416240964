import { useAuthHeader } from 'state/AuthState';
import axios from 'axios';

export const useScheduledTourPassengers = () => {
  const authHeader = useAuthHeader();

  const checkAuth = async () => {
    if (!authHeader) {
      throw new Error('401');
    }
  };

  const getPassengers = (params: { id: number; forDay: string }) => {
    return checkAuth().then(() => {
      return axios.post(`/api/tours/${params.id}/scheduled-tour-passengers`, params, { ...authHeader });
    });
  };

  return {
    getPassengers,
  };
};
