import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAuthHelp } from 'state/AuthState';
import { CustomerOrganizationsBridge } from '../../../bridges/CustomerOrganizationsBridge';
import { ICustomerOrganization } from 'api/models/CustomerOrganization';
import { BREAKPOINT_MD } from 'styles/Breakpoints';
import { Headline2, Headline3 } from 'styles/FontStyles';
import { ButtonPrimary } from 'components/common/inputs/Button';
import { Form } from 'components/common/inputs/Form';
import { TextInput } from 'components/common/inputs/TextInput';
import { LoadingSpinner } from 'components/common/loaders/LoadingSpinner';

import { ReplaceIn, PickFromModel } from 'api/models';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  ${Headline2};
  margin-bottom: 2.5rem;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2rem;

  margin-bottom: 4rem;
`;

const SectionTitle = styled.h2`
  ${Headline3};
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2rem;

  ${BREAKPOINT_MD} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

const GridRow = styled(Row)`
  ${BREAKPOINT_MD} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
  }
`;

type OurCustomerOrganization =
  ReplaceIn<
    PickFromModel<ICustomerOrganization, "name" | "description">,
    {id: number}
  >

export const CustomerOrganizationForm: FC<{ customerOrganization?: OurCustomerOrganization }> = (props) => {
  const { customerOrganization = null } = props;

  const navigate = useNavigate();

  const authHelp = useAuthHelp();
  const customerOrganizationsBridge = new CustomerOrganizationsBridge(authHelp);

  const [isSubmitting, setSubmitting] = useState(false);

  const form = useForm({
    shouldUseNativeValidation: true,
    defaultValues: {
      name: customerOrganization?.name || '',
      description: customerOrganization?.description || '',
    },
  });

  return (
    <Wrapper>
      <Form
        form={form}
        onSubmit={(values) => {
          setSubmitting(true);
          (customerOrganization?.id
            ? customerOrganizationsBridge.updateWithId(customerOrganization.id, values)
            : customerOrganizationsBridge.create(values)
          )
            .then((res) => {
              navigate('/customers#organizations', { replace: true });
            })
            .catch((err) => {
              // TODO add generic error message
            })
            .finally(() => setSubmitting(false));
        }}
      >
        <Title>Kunden-Organisationen</Title>

        <Section>
          <SectionTitle>Allgemeine Informationen</SectionTitle>
          <GridRow>
            <TextInput
              id={'name'}
              placeholder={'z.B. Graf-Recke-Stiftung'}
              label={'Name der Organisation'}
              $plain
              {...form.register('name', {
                required: 'Bitte geben Sie eine Namen ein.',
              })}
            />
          </GridRow>
          <GridRow>
            <TextInput
              optional
              id={'description'}
              type={'textarea'}
              placeholder={'Anmerkungen hinzufügen...'}
              label={'Anmerkungen'}
              style={{ flex: 1 }}
              {...form.register('description', {
                required: 'Bitte geben Sie eine Beschreibung ein.',
              })}
            />
          </GridRow>
        </Section>

        <ButtonPrimary type={'submit'}>{isSubmitting ? <LoadingSpinner /> : 'Speichern'}</ButtonPrimary>
      </Form>
    </Wrapper>
  );
};
