import { FC } from 'react';
import { IEmployee } from 'api/models/Employee';
import { getTelLink } from 'utils/phoneUtils';
import styled from 'styled-components';
import { Body4Bold } from 'styles/FontStyles';
import { Link } from 'components/common/atoms/Link';
import { Colors } from 'styles/Colors';
import { Employee } from 'components/icons/Employee';
import { Phone } from 'components/icons/Phone';
import { LocationTag } from '../LocationTag';
import { BREAKPOINT_SM } from 'styles/Breakpoints';

const Flexbox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
`;

const AddressRowWrapper = styled(Flexbox)`
  gap: 0.25rem 0.75rem;
  min-height: 2rem;

  ${BREAKPOINT_SM} {
    gap: 0.25rem 1.5rem;
  }
`;

const InternalIdTag = styled(Flexbox)`
  ${Body4Bold};
  display: none;

  ${BREAKPOINT_SM} {
    display: flex;
  }
`;

const PhoneNumberTag = styled(Flexbox).attrs({ as: Link })<{ href: string }>`
  ${Body4Bold};
  color: ${Colors.secondary};
`;

const EmployeeIcon = styled(Employee)`
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
`;

const PhoneIcon = styled(Phone)`
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
  color: ${Colors.textDefault};
`;

export const EmployeeAddressPreview: FC<{ employee: IEmployee }> = (props) => {
  const { internalId, region, phoneNumbers = [] } = props.employee;

  return (
    <AddressRowWrapper>
      <InternalIdTag>
        <EmployeeIcon /> {internalId || '-'}
      </InternalIdTag>

      <PhoneNumberTag href={getTelLink(phoneNumbers[0]?.phoneNumber) || ''} onClick={(e) => e.stopPropagation()}>
        <PhoneIcon /> {phoneNumbers[0]?.formattedPhoneNumber || '-'}
      </PhoneNumberTag>

      <LocationTag address={region} />
    </AddressRowWrapper>
  );
};
