import { useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { useCustomersAPI } from 'api/controllers/CustomersAPI';
import { useCustomerSearchAPI } from 'api/controllers/InfiniteSearchAPI';
import { ICustomer } from 'api/models/Customer';
import { CustomerFilterState } from 'state/CustomerFilterState';
import { List, ListHead } from 'components/common/atoms/List';
import { ContentPageHead } from 'components/common/elements/ContentPageHead';
import { CustomerListRow } from 'components/common/elements/customer/CustomerListRow';
import { CustomerTile } from 'components/common/elements/customer/CustomerTile';
import { ButtonSecondary } from 'components/common/inputs/Button';
import { LoadingSpinner } from 'components/common/loaders/LoadingSpinner';
import { Plus } from 'components/icons/Plus';
import { CustomerFilter } from 'components/content/customers/CustomerFilter';
import { Link } from 'react-router-dom';
import InfinityScrollingWrapper from 'components/common/elements/InfinityScrollingWrapper';
import { useAuthHelp } from 'state/AuthState';
import { CustomerOrganizationsBridge } from '../../../bridges/CustomerOrganizationsBridge';
import { ICustomerOrganization } from 'api/models/CustomerOrganization';
import { UiState } from 'state/UiState';
import { IAPIModelBaseV3, ReplaceIn, PickFromModel } from 'api/models';

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 0.75rem;
  --list-template: 5rem 24rem 7rem 10rem 8rem 1fr;
`;

const StyledListHead = styled(ListHead)`
  top: 16rem;
  margin-block-start: -2.5rem;
  padding-block-start: 2.5rem;
`;

type OurCustomerOrganization =
  ReplaceIn<
    PickFromModel<ICustomerOrganization, "name" | "description">,
    {id: number}
  >

export const CustomersList = () => {
  const uiState = useRecoilValue(UiState);

  const [searchParams] = useRecoilState(CustomerFilterState);
  const { status, data, hasNextPage, fetchNextPage, error, isFetching } = useCustomerSearchAPI(searchParams);

  const authHelp = useAuthHelp();
  const customerOrganizationsBridge = new CustomerOrganizationsBridge(authHelp);

  const customerAPI = useCustomersAPI();

  const [customerOrganizations, setCustomerOrganizations] = useState<OurCustomerOrganization[]>([]);

  const fetchCustomerOrganizations = async () => {
    try {
      const { data } = await customerOrganizationsBridge.getAll();
      setCustomerOrganizations(data);
    } catch (error) {
      console.error('Error fetching customer organizations:', error);
    }
  };

  useEffect(() => {
    fetchCustomerOrganizations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customers = useMemo(() => {
    return (data?.pages.map((page) => page.items).flat() as ICustomer[]) || [];
  }, [data]);

  const removeItem = (id: string) => {
    customerAPI
      .deleteCustomer(id)
      .then((res) => window.location.reload())
      .catch(() => console.error('error'));
  };

  return (
    <Wrapper>
      <ContentPageHead title={`Einrichtungen`} listName="customerList" hasTabbar={true}>
        <FlexWrapper>
          <Link to={'/customers/add'}>
            <ButtonSecondary>
              <Plus /> Einrichtung anlegen
            </ButtonSecondary>
          </Link>

          <CustomerFilter customerOrganizations={customerOrganizations} />
        </FlexWrapper>
      </ContentPageHead>

      {customers ? (
        uiState.customerList.activeTab === 'tile' ? (
          <InfiniteScroll
            dataLength={customers.length}
            next={() => {
              fetchNextPage();
            }}
            style={{ overflow: 'visible' }}
            hasMore={hasNextPage}
            loader={<LoadingSpinner />}
            endMessage={<p>Keine weiteren Einrichtungen</p>}
          >
            <InfinityScrollingWrapper>
              {customers.length > 0 &&
                customers.map((customer) => (
                  <CustomerTile key={customer.id} customer={customer} onUpdate={() => removeItem(customer.id)} />
                ))}
            </InfinityScrollingWrapper>
          </InfiniteScroll>
        ) : (
          <List>
            <StyledListHead>
              <div>Kürzel</div>
              <div>Einrichtung</div>
              <div>Anzahl Fahrten</div>
              <div>aktive Fahrgäste</div>
              <div>Region</div>
            </StyledListHead>
            {customers.length > 0 &&
              customers.map((customer) => (
                <CustomerListRow key={customer.id} customer={customer} onUpdate={() => removeItem(customer.id)} />
              ))}
          </List>
        )
      ) : null}
      {isFetching || (status === 'pending' && <LoadingSpinner />)}
      {error && <div>Es ist ein Fehler aufgetreten</div>}
    </Wrapper>
  );
};
