import { FC } from 'react';
import { TextInput } from 'components/common/inputs/TextInput';
import styled from 'styled-components';
import { Card } from 'components/common/atoms/Card';
import { Body1Bold } from 'styles/FontStyles';
import { useFormContext } from 'react-hook-form';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.5rem;
`;

const CardWrapper = styled(Card)`
  gap: 1.5rem;
  overflow: auto;
`;

const Title = styled.h2`
  ${Body1Bold};
  text-align: center;
`;

const TextInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 22rem;
`;

export const CustomerOrderFormPageOrderData: FC = () => {
  const form = useFormContext();

  return (
    <Wrapper>
      <CardWrapper>
        <Title>Gibt es weitere Informationen zu diesem Auftrag?</Title>
        <TextInputWrapper>
          <TextInput
            id={'displayName'}
            placeholder={'Anzeigename eingeben'}
            label={'Anzeigename'}
            $plain
            {...form.register('displayName', { required: 'Bitte einen Anzeigenamen angeben' })}
          />
        </TextInputWrapper>
        <TextInput
          optional
          id={'comment'}
          type={'textarea'}
          placeholder={'Anmerkungen hinzufügen...'}
          label={'Anmerkungen'}
          style={{ flex: 1 }}
        />
      </CardWrapper>
    </Wrapper>
  );
};
