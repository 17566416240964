import { useDragDropContainer } from '../DragDropContext';
import { ITour } from 'api/models/Tour';
import { IPassenger } from 'api/models/Passenger';
import { Add } from 'components/icons/Add';

import styled from 'styled-components';
import { Clickable } from 'components/common/atoms/Clickable';
import { Colors } from 'styles/Colors';
import { TileItem } from 'components/content/customers/tourplan/tiles/TourplanTourTile';
import BlockedByBookingCompletionOverlay from 'components/common/elements/BlockedByBookingCompletionOverlay';
import { memo } from 'react';

const AddTourItem = styled(TileItem).attrs({ as: Clickable })`
  padding: 2rem;
  align-items: center;
  justify-items: center;

  position: relative;

  color: ${Colors.secondary};

  svg {
    width: 3.5rem;
    height: 3.5rem;
    margin: 1.5rem;
  }
`;

export const TourplanNewTourTile = memo((props: { getCreationAttributes?: any; timestamp?: string }) => {
  const { timestamp, getCreationAttributes } = props;

  return (
    <AddTourItem {...useDragDropContainer<ITour, IPassenger>('new', getCreationAttributes)}>
      <Add />
      <span>neue Fahrt erstellen</span>
      <BlockedByBookingCompletionOverlay timestamp={timestamp} style={{ borderRadius: 8 }} />
    </AddTourItem>
  );
});
