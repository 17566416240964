import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { FetchEmployeesParams } from 'api/controllers/InfiniteSearchAPI';

const { persistAtom } = recoilPersist({
  key: 'employee-filters',
  storage: localStorage,
});

export const EmployeeFilterState = atom<FetchEmployeesParams>({
  key: 'EmployeeFilterState',
  default: {
    query: '',
    page: {
      offset: 0,
      limit: 24,
    },
    regions: [],
    skills: [],
    availableOnly: 0,
  },
  effects_UNSTABLE: [persistAtom],
});
