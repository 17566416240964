import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Body4 } from 'styles/FontStyles';
import { Colors } from 'styles/Colors';
import { ChevronDown } from 'components/icons/ChevronDown';
import { Link } from '../atoms/Link';
import { useNavigate } from 'react-router-dom';

const BreadcrumbWrapper = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  margin-block-start: -2.25rem;
  padding-block-start: 2.25rem;
  margin-inline: calc(-1 * var(--content-indent));
  padding-inline: var(--content-indent);

  padding-block-end: 0.5rem;
  margin-block-end: 0.5rem;

  background-color: ${Colors.background};
  position: sticky;
  top: 0;
  z-index: 5;

  ${Body4};

  a,
  span {
    color: ${Colors.grey600};
  }
`;

const BackIcon = styled(ChevronDown)`
  transform: rotate(90deg);
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;

  path {
    stroke-width: 2px;
  }
`;

const BackButton = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  ${Body4};

  && {
    color: ${Colors.textDefault};
  }
`;

interface IBreadcrumbProps {
  items: {
    title: string;
    href: string | null;
    onClick?: () => void;
  }[];
  className?: string;
  onBackButtonClick?: () => void;
  hideBackButton?: boolean;
}

export const Breadcrumb: FC<PropsWithChildren<IBreadcrumbProps>> = (props) => {
  const { items = [], className, onBackButtonClick, hideBackButton = false, children } = props;

  const navigate = useNavigate();

  return (
    <BreadcrumbWrapper className={className}>
      {!hideBackButton && (
        <BackButton
          href={'#'}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (onBackButtonClick) {
              onBackButtonClick();
            } else {
              navigate(-1);
            }
          }}
        >
          <BackIcon />
          Zurück
        </BackButton>
      )}

      {children}

      {items.map(({ title, href, onClick }, index) => (
        <Link
          key={index}
          href={href || ''}
          onClick={(e) => {
            if (onClick) {
              e.preventDefault();
              e.stopPropagation();
              onClick();
            }
          }}
        >
          / {title}
        </Link>
      ))}
    </BreadcrumbWrapper>
  );
};
