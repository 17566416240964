import { DragDropContext } from 'components/content/customers/tourplan/DragDropContext';
import TourDetailsTile from './TourDetailsTile';
import { IScheduledTour, IScheduledTourException } from 'api/models/ScheduledTour';
import { IConflict } from 'api/models/Conflict';
import { useNavigate } from 'react-router-dom';
import { FC } from 'react';

interface ITourTileProps {
  timestamp: string;
  setChanges: (changes: Partial<IScheduledTour> | null) => void;
  exceptions: IScheduledTourException[];
  conflicts: IConflict[] | null;
  editable: boolean;
  proceedWithoutSaving: (onProceed: () => void) => void;
  setShowChangeDurationDialog: (show: boolean) => void;
  isBlockedByBookingCompletion: boolean;
  tour: IScheduledTour;
  isTourDriven: boolean;
  hasChanged: boolean;
  selectedException?: IScheduledTourException;
  activeExceptionId?: string;
  activeDay: boolean;
  onDiscard?: () => void;
}

const TourTile: FC<ITourTileProps> = (props) => {
  const {
    activeExceptionId,
    activeDay,
    conflicts,
    editable,
    hasChanged,
    timestamp,
    tour,
    proceedWithoutSaving,
    setChanges,
    setShowChangeDurationDialog,
    onDiscard,
  } = props;

  const direction = tour.direction; // add your direction state here
  const navigate = useNavigate();

  return (
    <>
      <DragDropContext
        dataSource={{
          fields: [tour] as any,
          append: () => {},
          remove: () => {},
          update: (index, item) => {
            setChanges(item);
          },
        }}
        itemsKey={'passengers'}
        direction={(direction as any) || 'outwards'}
      >
        <TourDetailsTile
          activeDay={activeDay}
          schedule={tour}
          activeExceptionId={activeExceptionId}
          selectedException={props.selectedException}
          timestamp={timestamp}
          editable={editable}
          hasChanged={hasChanged}
          exceptions={props.exceptions}
          conflicts={conflicts || undefined}
          onSave={() => {
            setShowChangeDurationDialog(true);
          }}
          onEdit={(passenger) => {
            proceedWithoutSaving(() => {
              navigate(`/passengers/${passenger.id}/edit`);
            });
          }}
          onDiscard={onDiscard}
        />
      </DragDropContext>
    </>
  );
};

export default TourTile;
