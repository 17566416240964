import { FC } from 'react';
import { ICalendarException } from 'api/models/CalendarException';
import { Info, InfoBold, ListRowWrapper, OptionsWrapper, Row } from 'components/common/atoms/List';
import { ContextOptionsFixed } from '../ContextOptionsFixed';
import styled from 'styled-components';
import { Trash } from 'components/icons/Trash';
import { Edit } from 'components/icons/Edit';
import { useNavigate } from 'react-router-dom';
import { useHasPermission } from 'state/UserMeState';
import { Link } from 'components/common/atoms/Link';
import { Calendar } from 'components/icons/Calendar';
import { useCalendarExceptionAPI } from 'api/controllers/CalendarExceptionAPI';
import { useSchedulesAPI } from 'api/controllers/SchedulesAPI';

const TrashIcon = styled(Trash)`
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
`;

const EditIcon = styled(Edit)`
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
`;

const CalendarIcon = styled(Calendar)`
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
`;

interface ICalendarExceptionListRowProps {
  calendarException: ICalendarException;
  onRemove: () => void;
}

export const CalendarExceptionListRow: FC<ICalendarExceptionListRowProps> = ({ calendarException, onRemove }) => {
  const { id, name, beginDate, endDate, customerCategory } = calendarException;

  const isAdmin = useHasPermission('admin');
  const calendarExceptionAPI = useCalendarExceptionAPI();
  const navigate = useNavigate();

  const scheduleAPI = useSchedulesAPI();

  const handleCancelTours = async () => {
    if (calendarException !== undefined) {
      const cancellationParams = {
        startDate: calendarException.beginDate.toString(),
        endDate: calendarException.endDate.toString(),
        includeTourOnInvoice: false,
        bulkCancellationReason: calendarException.name,
        customerCategoryId: calendarException?.customerCategory?.id,
        calendarExceptionId: calendarException.id,
        reason: calendarException.name,
        comment: calendarException.name,
      };
      scheduleAPI.bulkCancellation(cancellationParams).then((res) => {
        navigate('/calendar-exceptions#bulk');
      });
    }
  };

  return (
    <ListRowWrapper>
      <Row>
        <InfoBold>{id || '-'}</InfoBold>
        <Info>
          <Link href={`/calendar-exceptions/${id}`}>{name}</Link>
        </Info>
        <Info>{new Date(beginDate).toLocaleDateString('de-DE')}</Info>
        <Info>{new Date(endDate).toLocaleDateString('de-DE')}</Info>
        <Info>{customerCategory?.name || '-'}</Info>
        <OptionsWrapper>
          <ContextOptionsFixed
            items={
              [
                {
                  content: (
                    <>
                      <EditIcon /> Bearbeiten
                    </>
                  ),
                  onClick: () => {
                    navigate(`/calendar-exceptions/${id}/edit`);
                  },
                },
                {
                  content: (
                    <>
                      <CalendarIcon /> Absagen
                    </>
                  ),
                  onClick: () => {
                    window.confirm('Wirklich alle Touren in diesem Zeitraum absagen') && handleCancelTours();
                  },
                },
                isAdmin && {
                  content: (
                    <>
                      <TrashIcon /> Entfernen
                    </>
                  ),
                  onClick: () => {
                    if (window.confirm('Wirklich löschen?')) {
                      calendarExceptionAPI.deleteCalendarException(id).then(() => {
                        onRemove();
                      });
                    }
                  },
                },
              ].filter(Boolean) as []
            }
          />
        </OptionsWrapper>
      </Row>
    </ListRowWrapper>
  );
};
