import { PropsWithChildren } from 'react';
import { useMatchBreakpoint } from 'hooks/useMatchBreakpoint';
import { IScheduledTour } from 'api/models/ScheduledTour';
import DayContainer from './DayContainer';
import TourItem from './TourItem';
import styled from 'styled-components';
import { Body4 } from 'styles/FontStyles';
import { IDayConflicts } from 'state/TourConflictsState';

const PlaceholderLabel = styled.p`
  ${Body4};
  padding-block-end: 1.5rem;
  padding-inline: 1rem;
  text-align: center;
`;

const ToursForDay = (props: PropsWithChildren<{ day: string; schedules: IScheduledTour[] | null; results?: IDayConflicts }>) => {
  const { day, schedules, children, results } = props;

  const isDesktop = useMatchBreakpoint();

  if (isDesktop !== null) {
    return (
      <DayContainer day={day}>
        {schedules?.map((schedule) => (
          <TourItem key={schedule.id} schedule={schedule} isDesktop={isDesktop} day={day} conflicts={results?.conflicts} />
        ))}
        {schedules !== null && schedules.length === 0 && <PlaceholderLabel>Keine Fahrten geplant.</PlaceholderLabel>}
        {children}
      </DayContainer>
    );
  }
  return <></>;
};

export default ToursForDay;
