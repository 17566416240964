// General
import styled from 'styled-components';
import { FC } from 'react';

// Styles
import { BREAKPOINT_MD } from 'styles/Breakpoints';

// Components
import BurgerMenuButton from '../common/elements/BugerMenuButton';

const PageHeader = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-inline: var(--content-indent);
  padding-block-start: 1rem;
  block-size: 4rem;
  margin-block-end: -4.5rem;
  z-index: 6;
  pointer-events: none;

  position: sticky;
  top: 0;

  & > * {
    pointer-events: auto;
  }

  ${BREAKPOINT_MD} {
    display: none;
  }
`;

interface HeaderProps {
  navCollapsed: boolean;
  setNavCollapsed: (value: boolean) => void;
}

export const Header: FC<HeaderProps> = (props) => {
  const { navCollapsed, setNavCollapsed } = props;

  return (
    <PageHeader>
      <BurgerMenuButton collapsed={navCollapsed} setCollapsed={setNavCollapsed} />
    </PageHeader>
  );
};
