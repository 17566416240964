import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Info, InfoBold, List, ListHead, ListRowControlsWrapper, ListRowWrapper, OptionsWrapper, Row } from 'components/common/atoms/List';
import styled from 'styled-components';
import { ContextOptions } from '../ContextOptions';
import { Trash } from 'components/icons/Trash';
import { Edit } from 'components/icons/Edit';
import { useNavigate } from 'react-router-dom';
import { ButtonPrimary, ButtonSecondary } from 'components/common/inputs/Button';
import { Plus } from 'components/icons/Plus';
import { ChevronDown } from 'components/icons/ChevronDown';
import { IOrder } from 'api/models/Order';
import { Collapsible } from 'components/common/atoms/Collapsible';
import { useShowDeleteCustomerOrderDialog } from 'hooks/customers/useShowDeleteCustomerOrderDialog';
import { Tours } from 'components/icons/Tours';
import { TourLabel } from 'components/common/labels/TourLabel';
import { Colors } from 'styles/Colors';
import { LoadingSpinner } from 'components/common/loaders/LoadingSpinner';
import { useCustomersAPI } from 'api/controllers/CustomersAPI';
import { BREAKPOINT_XL } from 'styles/Breakpoints';
import { useMatchBreakpoint } from 'hooks/useMatchBreakpoint';
import { useHasPermission } from 'state/UserMeState';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  & > :first-child {
    align-self: flex-end;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  flex-wrap: wrap;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  padding-block-start: 2.5rem;
`;

const OrderRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const OrderListRowWrapper = styled(ListRowWrapper)`
  //
`;

const StyledListOrders = styled(List)`
  --list-template: 3fr 1fr 4rem;

  ${BREAKPOINT_XL} {
    --list-template: 1.2fr 1fr 1fr 1.2fr 8.75rem;
  }
`;

const StyledListOrderTours = styled(StyledListOrders)`
  margin-bottom: 0;
  padding-block: 1rem 0;
  gap: 1rem;

  --list-template: 0 3fr 1fr 4rem;

  ${BREAKPOINT_XL} {
    --list-template: 1.2fr 1fr 1fr 1.2fr 8.75rem;
  }
`;

const InfoGrey = styled(Info)`
  color: ${Colors.grey600};
`;

export const CustomerDetailsOrders: FC<{ customerId: string; updateHook: () => void }> = (props) => {
  const { customerId, updateHook } = props;

  const customersAPI = useCustomersAPI();

  const [orders, setOrders] = useState<IOrder[] | null>(null);

  const isDesktop = useMatchBreakpoint();

  const fetchOrders = useCallback(() => {
    setOrders(null);
    customersAPI
      .getCustomerOrders({ id: customerId, limit: -1 })
      .then(async (res) => {
        const orders: IOrder[] = res.data?.rows || [];

        // TODO: Add passengers to orders in customers-api endpoint
        return orders.map((order) => ({
          ...order,
          tours: (order.tours || []).map((tour) => ({
            ...tour,
            // passengers: allPassengers
            //   .filter(({ tourId }) => tourId === tour.id.toString())
            //   .map(({ passenger, ...TourPassenger }) => ({
            //     ...passenger,
            //     TourPassenger,
            //   })),
          })),
        })) as IOrder[];
      })
      .then((orders: any) => {
        setOrders(orders);
      })
      .catch(() => setOrders([]));
  }, [customersAPI, setOrders, customerId]);

  useEffect(() => {
    fetchOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  return (
    <Wrapper>
      <ButtonWrapper>
        <ButtonPrimary href={`/customers/${customerId}/tourplan`}>
          <Tours /> Fahrtenplanung
        </ButtonPrimary>
        <ButtonSecondary href={`/customers/${customerId}/orders/add`}>
          <Plus /> Auftrag erfassen
        </ButtonSecondary>
      </ButtonWrapper>

      <ContentWrapper>
        <StyledListOrders>
          <ListHead>
            <div>Auftragsnummer</div>
            <div>Vertragstouren</div>
            {isDesktop && (
              <>
                <div>Fahrgäste</div>
                <div>Auftragszeitraum</div>
              </>
            )}
          </ListHead>
          {orders === null && <LoadingSpinner />}
          {orders?.map((order) => (
            <OrderListRow
              key={order.id}
              order={order as IMyOrder}
              updateHook={() => {
                fetchOrders();
                updateHook();
              }}
            />
          ))}
        </StyledListOrders>
      </ContentWrapper>
    </Wrapper>
  );
};

interface IMyOrder extends IOrder {
  passengers: { id: string }[];
}

const OrderListRow: FC<{ order: IMyOrder; updateHook: () => void }> = (props) => {
  const { order, updateHook } = props;

  const { customerId, tours = [] } = order;

  const navigate = useNavigate();
  const showDeleteCustomerOrderDialog = useShowDeleteCustomerOrderDialog(order.id);
  const isAdmin = useHasPermission('admin');

  const isDesktop = useMatchBreakpoint();

  const [toursOpen, setToursOpen] = useState(false);

  const totalPassengersCount = order.tours?.reduce((acc, tour) => acc + (tour.passengers || []).length, 0) || 0;

  const rowContent = useMemo(
    () => (
      <>
        <InfoBold>{order.displayName}</InfoBold>
        <Info>
          {(order.tours || []).length} {isDesktop && ((order.tours || []).length === 1 ? 'Vertragstour' : 'Vertragstouren')}
        </Info>
        {isDesktop && (
          <>
            <Info>
              {totalPassengersCount} {totalPassengersCount === 1 ? 'Fahrgast' : 'Fahrgäste'}
            </Info>
            <Info>
              {[(order.tours || [])[0]?.startDate, (order.tours || [])[0]?.endDate]
                .filter(Boolean)
                .map((date: any) => new Date(date).toLocaleDateString(undefined, { dateStyle: 'medium' }))
                .join(' - ') || '-'}
            </Info>
          </>
        )}
      </>
    ),
    [order, isDesktop, totalPassengersCount],
  );

  const contextMenu = useMemo(
    () => (
      <ContextOptions
        as="div"
        items={
          [
            isAdmin && {
              content: (
                <>
                  <Trash /> Entfernen
                </>
              ),
              onClick: () => {
                showDeleteCustomerOrderDialog(() => {
                  updateHook();
                });
              },
            },
            {
              content: (
                <>
                  <Edit /> Bearbeiten
                </>
              ),
              onClick: () => navigate(`/customers/${customerId}/orders/${order.id}/edit`),
            },
          ].filter(Boolean) as []
        }
      />
    ),
    [isAdmin, showDeleteCustomerOrderDialog, updateHook, navigate, customerId, order],
  );

  const toursList = useMemo(
    () => (
      <StyledListOrderTours noBorder>
        {tours
          .slice()
          .sort((a, b) => {
            if (a.name === b.name && a.direction !== b.direction) {
              if (a.direction === 'outwards') {
                return -1;
              }

              if (b.direction === 'outwards') {
                return 1;
              }
            }

            return (a.name || '').localeCompare(b.name || '', undefined, { numeric: true });
          })
          .map((tour) => {
            return (
              <Row key={tour.id}>
                <div />
                <InfoGrey>
                  <TourLabel>{tour.name || '-'}</TourLabel>
                  {tour.direction === 'return' ? 'Rück' : 'Hin'}
                </InfoGrey>
                <InfoGrey>
                  {(tour.passengers || []).length} {(tour.passengers || []).length === 1 ? 'Fahrgast' : 'Fahrgäste'}
                </InfoGrey>
                {/*<Info>{[tour.startDate, tour.endDate].filter(Boolean).map((date: any) => new Date(date).toLocaleDateString(undefined, { dateStyle: 'medium' })).join(' - ') || '-'}</Info>*/}
              </Row>
            );
          })}
      </StyledListOrderTours>
    ),
    [tours],
  );

  return (
    <OrderRowWrapper>
      <OrderListRowWrapper
        onClick={(e) => {
          if (!e.isDefaultPrevented()) {
            setToursOpen(!toursOpen);
          }
        }}
      >
        <Row>
          {rowContent}
          <ListRowControlsWrapper>
            <OptionsWrapper>
              <ChevronDown invert={toursOpen} />
              {contextMenu}
            </OptionsWrapper>
          </ListRowControlsWrapper>
        </Row>
        <Collapsible open={toursOpen}>{toursList}</Collapsible>
      </OrderListRowWrapper>
    </OrderRowWrapper>
  );
};
