import { memo, PropsWithChildren, useMemo, useState } from 'react';
import { ITour } from 'api/models/Tour';
import { IPassenger } from 'api/models/Passenger';
import {
  useAvailableDragDropItems,
  useDragDropItemsMoveState,
  useMoveItems,
  useMoveItemsToContainer,
  useSelectedDragDropItems,
} from '../DragDropContext';
import { TourplanOrderTourView } from '../tiles/TourplanOrderTourView';
import { ButtonPrimary, ButtonTertiary } from 'components/common/inputs/Button';
import {
  AvailablePassengersList,
  ButtonsWrapper,
  ClickableListTitle,
  ListTitle,
  ListTitleOverlay,
  TourplanOverlay,
} from './TourplanOverlay';
import { ICustomer } from 'api/models/Customer';
import { Tours } from 'components/icons/Tours';
import { Plus } from 'components/icons/Plus';
import { getCurrentDayDate } from 'utils/dateUtils';
import { TourplanAvailablePassengerView } from '../tiles/TourplanPassengerView';
import styled from 'styled-components';
import { Clickable } from 'components/common/atoms/Clickable';
import { Trash } from 'components/icons/Trash';
import { Colors } from 'styles/Colors';
import BlockedByBookingCompletionOverlay from 'components/common/elements/BlockedByBookingCompletionOverlay';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  > div {
    width: 100%;
  }
`;

const IconButton = styled(Clickable)`
  display: flex;
  flex-shrink: 0;
  padding: 0.75rem;

  @media (hover: hover) {
    :hover {
      color: ${Colors.secondary};
    }
  }
`;

export const NoSearchResult = styled.p`
  opacity: 0.5;
  margin-block: 1rem;
`;

const MoveAllHeadline = memo(
  (
    props: PropsWithChildren<{
      customer: ICustomer | null;
      unplannedOrderTours: Partial<ITour>[];
      availablePassengers: Partial<IPassenger>[];
    }>,
  ) => {
    const { customer, unplannedOrderTours, availablePassengers, children } = props;

    const moveItemsToContainer = useMoveItemsToContainer({
      getCreationAttributes: (draggingItems, creationProps) => {
        const { tour } = creationProps;
        const {
          name = tour?.name || (customer?.internalId && `${customer.internalId} ${(1).toString().padStart(2, '0')}`),
          startDate = getCurrentDayDate(),
          endDate = getCurrentDayDate(),
          arrivalDate,
          departureDate,
        } = tour || {};

        return {
          hasChanged: true, // set changed flag
          customerId: customer?.id,
          customer,
          isException: false,
          startDate,
          endDate,
          arrivalDate,
          departureDate,
          name: `F ${name}`,
        };
      },
    });

    return (
      <ClickableListTitle
        onClick={(e) => {
          e.stopPropagation();

          unplannedOrderTours.forEach((tour) => {
            const availablePassengersInTour = availablePassengers.filter(
              (passenger) => !!(tour.passengers || []).find(({ id }) => id === passenger.id),
            );

            // move order tour to scheduled tours as it is
            moveItemsToContainer({
              srcContainer: 'revert',
              targetContainer: 'new',
              itemsToMove: availablePassengersInTour,
              creationProps: {
                tour, // pass tour
              },
            });
          });
        }}
      >
        {children}
        <ListTitleOverlay>
          <Tours />
          <Plus />
          <b>alle übernehmen</b>
        </ListTitleOverlay>
      </ClickableListTitle>
    );
  },
);

interface ITourplanAvailableOrdersOverlay {
  customer: ICustomer | null;
  orderTours: ITour[];
  timestamp: string;
  overplannedPassengers: IPassenger[];
  onRemoveOverplannedPassengerClick: (passenger: IPassenger) => void;
}

/**
 * for tourplan view
 */
export const TourplanAvailableOrdersOverlay = memo((props: ITourplanAvailableOrdersOverlay) => {
  const { customer, orderTours = [], timestamp, overplannedPassengers = [], onRemoveOverplannedPassengerClick } = props;

  const [availablePassengers] = useAvailableDragDropItems<IPassenger>();

  const [selectedItems, resetSelectedItems] = useSelectedDragDropItems<Partial<ITour>, IPassenger>('revert');
  const [moveItemsState, setMoveItemsState] = useDragDropItemsMoveState();
  const moveItems = useMoveItems({});

  const [searchString, setSearchString] = useState<string>('');
  const [filteredPassengers, setFilteredPassengers] = useState<IPassenger[]>(overplannedPassengers);

  const handleSearchChange = (searchString: string) => {
    const passengers = overplannedPassengers
      .filter((passenger) => {
        return (
          passenger.firstName.toLowerCase().includes(searchString.toLowerCase()) ||
          passenger.lastName.toLowerCase().includes(searchString.toLowerCase())
        );
      })
      .sort((a, b) => a.lastName.localeCompare(b.lastName));
    setFilteredPassengers(passengers);

    const tours = plannedOrderTours.filter((tour) => {
      const passengers = tour.passengers || [];
      return passengers.some((passenger) => {
        return (
          passenger.firstName?.toLowerCase().includes(searchString.toLowerCase()) ||
          passenger.lastName?.toLowerCase().includes(searchString.toLowerCase())
        );
      });
    });
    const unplannedTours = unplannedOrderTours.filter((tour) => {
      const passengers = tour.passengers || [];
      return passengers.some((passenger) => {
        return (
          passenger.firstName?.toLowerCase().includes(searchString.toLowerCase()) ||
          passenger.lastName?.toLowerCase().includes(searchString.toLowerCase())
        );
      });
    });
    setFilteredPassengers(passengers);
    setFilteredPlannedOrderTours(tours as ITour[]);
    setFilteredUnplannedOrderTours(unplannedTours as ITour[]);

    setSearchString(searchString);
  };

  const [unplannedOrderTours, plannedOrderTours] = useMemo(() => {
    const unplanned: Partial<ITour>[] = [];
    const planned: Partial<ITour>[] = [];

    orderTours.forEach((tour) => {
      const isUnplanned = (tour.passengers || []).some(({ id }) => availablePassengers.find((passenger) => id === passenger.id));

      if (isUnplanned) {
        unplanned.push(tour);
      } else {
        planned.push(tour);
      }
    });

    return [unplanned, planned] as const;
  }, [orderTours, availablePassengers]);

  const [filteredUnplannedOrderTours, setFilteredUnplannedOrderTours] = useState<ITour[]>(unplannedOrderTours as ITour[]);
  const [filteredPlannedOrderTours, setFilteredPlannedOrderTours] = useState<ITour[]>(plannedOrderTours as ITour[]);

  const overplannedPassengersList = useMemo(() => {
    return (
      <>
        {overplannedPassengers.length > 0 && (
          <>
            <ListTitle style={{ zIndex: 1 }}>außervertragliche Fahrgäste ({overplannedPassengers.length})</ListTitle>
            <AvailablePassengersList>
              {filteredPassengers.map((passenger) => (
                <Row key={passenger.id}>
                  <IconButton onClick={() => onRemoveOverplannedPassengerClick(passenger)}>
                    <Trash />
                  </IconButton>
                  <TourplanAvailablePassengerView passenger={passenger} inSidebar={true} />
                </Row>
              ))}
            </AvailablePassengersList>
          </>
        )}
      </>
    );
  }, [overplannedPassengers, filteredPassengers, onRemoveOverplannedPassengerClick]);

  const unplannedToursList = useMemo(
    () => (
      <>
        <ListTitle style={{ zIndex: 2 }}>
          <MoveAllHeadline customer={customer} unplannedOrderTours={unplannedOrderTours} availablePassengers={availablePassengers}>
            ungeplante Vertragstouren ({unplannedOrderTours.length}/{orderTours.length})
          </MoveAllHeadline>
        </ListTitle>
        <AvailablePassengersList>
          {filteredUnplannedOrderTours.map((tour) => (
            <TourplanOrderTourView
              key={tour.id}
              customer={customer}
              tour={tour}
              availablePassengers={availablePassengers}
              activeSearch={!!searchString}
            />
          ))}
          {filteredUnplannedOrderTours.length === 0 && searchString && <NoSearchResult>Keine passenden Fahrten gefunden.</NoSearchResult>}
        </AvailablePassengersList>
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [unplannedOrderTours, filteredUnplannedOrderTours, orderTours.length, customer, availablePassengers],
  );

  const plannedToursList = useMemo(
    () => (
      <>
        <ListTitle style={{ zIndex: 3 }}>
          geplante Vertragstouren ({plannedOrderTours.length}/{orderTours.length})
        </ListTitle>
        <AvailablePassengersList inactive>
          {filteredPlannedOrderTours.map((tour) => (
            <TourplanOrderTourView
              key={tour.id}
              customer={customer}
              tour={tour}
              availablePassengers={availablePassengers}
              activeSearch={!!searchString}
            />
          ))}
          {filteredPlannedOrderTours.length === 0 && searchString && <NoSearchResult>Keine passenden Fahrten gefunden.</NoSearchResult>}
        </AvailablePassengersList>
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [plannedOrderTours, filteredPlannedOrderTours, orderTours.length, customer, availablePassengers],
  );

  const revertButtonView = useMemo(
    () => (
      <>
        {moveItemsState !== null && moveItemsState.srcContainer !== 'revert' && (
          <ButtonsWrapper>
            <ButtonPrimary
              onClick={(e: Event) => {
                e.stopPropagation();
                moveItems('revert');
              }}
            >
              In Planung verschieben
            </ButtonPrimary>
          </ButtonsWrapper>
        )}
      </>
    ),
    [moveItemsState, moveItems],
  );

  const moveSelectionButtonsView = useMemo(
    () => (
      <>
        {selectedItems.length > 0 && (
          <ButtonsWrapper>
            <ButtonTertiary
              disabled={moveItemsState !== null}
              onClick={(e: Event) => {
                e.stopPropagation();
                resetSelectedItems();
              }}
            >
              Auswahl verwerfen
            </ButtonTertiary>
            <ButtonPrimary
              onClick={(e: Event) => {
                e.stopPropagation();
                if (moveItemsState !== null) {
                  setMoveItemsState(null);
                } else {
                  setMoveItemsState({ srcContainer: 'revert', items: selectedItems });
                }
              }}
            >
              {moveItemsState !== null
                ? 'Verschieben abbrechen'
                : `${selectedItems.length} ${selectedItems.length === 1 ? 'Fahrgast' : 'Fahrgäste'} verschieben`}
            </ButtonPrimary>
          </ButtonsWrapper>
        )}
      </>
    ),
    [selectedItems, moveItemsState, resetSelectedItems, setMoveItemsState],
  );

  return (
    <TourplanOverlay title={'Vertragstouren'} count={orderTours.length} searchString={searchString} handleSearchChange={handleSearchChange}>
      {overplannedPassengersList}
      {unplannedToursList}
      {plannedToursList}

      {revertButtonView}
      {moveSelectionButtonsView}
      <BlockedByBookingCompletionOverlay timestamp={timestamp} />
    </TourplanOverlay>
  );
});
