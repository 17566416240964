import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { TextInput } from 'components/common/inputs/TextInput';
import { Dialog } from '../Dialog';
import { ButtonPrimary, ButtonSecondary } from 'components/common/inputs/Button';
import { LoadingSpinner } from 'components/common/loaders/LoadingSpinner';
import { Dropdown } from 'components/common/inputs/Dropdown';
import { useForm } from 'react-hook-form';
import { Body4 } from 'styles/FontStyles';
import { Colors } from 'styles/Colors';
import { TIME_SUPPLEMENTS } from '../employee/EmployeeInvoicesTimeSupplements';
import { BREAKPOINT_LG } from 'styles/Breakpoints';

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;

  text-align: start;

  margin: -0.5rem;
  padding: 0.5rem;
`;

const GridRow = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;

  ${BREAKPOINT_LG} {
    grid-template-columns: repeat(2, 1fr);
  }

  label {
    margin-block-end: 0.25rem;
  }

  select,
  input {
    block-size: 3rem;
  }
`;

const CommentInput = styled(TextInput)`
  label {
    margin: 0;
  }

  textarea {
    max-width: none;
  }
`;

const InputAbsoluteWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  input {
    padding-inline-end: 3rem;
  }

  & > span {
    ${Body4};
    color: ${Colors.grey700};

    position: absolute;
    inset-block: 0;
    inset-inline-end: 0;
  }
`;

const INITIAL_PAYLOAD = {
  paymentKey: '',
  type: 'tour_outwards',
  title: '',
  amount: 0,
  paymentFactor: 12,
  comment: '',
} as ICreatePaymentInfoSubmitPayload;

const PAYMENT_INFO_TYPES = [
  { value: 'tour_outwards', label: 'Tour Hinfahrt' },
  { value: 'tour_return', label: 'Tour Rückfahrt' },
  { value: 'vacation', label: 'Urlaubstag' },
  { value: 'disease', label: 'Krankheitstag' },
  { value: 'public_holiday', label: 'Feiertag' },
  ...TIME_SUPPLEMENTS.map(({ key, title }) => ({ value: key, label: `Zeitzuschlag ${title}` })),
];

export interface ICreatePaymentInfoSubmitPayload {
  paymentKey: string;
  type: 'tour_outwards' | 'tour_return';
  title: string;

  amount: number;
  paymentFactor: number;

  comment: string;
}
interface ICreatePaymentInfoDialogProps {
  open: boolean;
  defaultValues?: ICreatePaymentInfoSubmitPayload;
  setOpen: (open: boolean) => void;
  onSubmit: (config: ICreatePaymentInfoSubmitPayload, setLoading: (loading: boolean) => void) => void;
}
export default function CreatePaymentInfoDialog(props: ICreatePaymentInfoDialogProps) {
  const { open, defaultValues = INITIAL_PAYLOAD, setOpen, onSubmit } = props;

  const [loading, setLoading] = useState(false);

  const form = useForm({
    defaultValues,
  });

  const [amountOfTime, setAmountOfTime] = useState('');
  const [paymentFactor, setPaymentFactor] = useState('12,00');

  const type = form.watch('type');

  const escapeTimeString = useCallback((time: string) => {
    return time.match(/[,.:]$/g) ? time.substring(0, time.length - 1) : time;
  }, []);

  const parseValue = useCallback((value: string) => {
    return Number.parseFloat((value || '0').replace(',', '.'));
  }, []);

  const parseMinutesFromTimeString = useCallback(
    (time: string) => {
      return time.includes(':') ? +time.split(':')[0] * 60 + +time.split(':')[1] : parseValue(time) * (time.match(/[,.]/g) ? 60 : 1);
    },
    [parseValue],
  );

  useEffect(() => {
    const time = escapeTimeString(amountOfTime);
    form.setValue('amount', parseMinutesFromTimeString(time));
    form.setValue('paymentFactor', parseValue(paymentFactor));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amountOfTime, paymentFactor]);

  useEffect(() => {
    if (open) {
      form.reset(defaultValues);
      setAmountOfTime((+defaultValues.amount).toString());
      setPaymentFactor((+defaultValues.paymentFactor).toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, defaultValues]);

  useEffect(() => {
    if (!['tour_outwards', 'tour_return'].includes(type)) {
      form.unregister('title');
      form.unregister('amount');
    }
  }, [type, form]);

  const isEditMode = defaultValues !== INITIAL_PAYLOAD;

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      form={form}
      onSubmit={(values) => {
        let title = values.title;
        if (!['tour_outwards', 'tour_return'].includes(values.type)) {
          title = PAYMENT_INFO_TYPES.find(({ value }) => value === values.type)?.label || 'Lohnart';
        }

        onSubmit(
          {
            ...values,
            title,
            amount: !['tour_outwards', 'tour_return'].includes(values.type) ? 0 : values.amount,
          },
          setLoading,
        );
      }}
      headline={isEditMode ? 'Lohnart bearbeiten' : 'Lohnart erstellen'}
      text={
        <Body>
          <GridRow>
            <TextInput
              id={'paymentKey'}
              label={'Lohnart'}
              placeholder={'Lohnart...'}
              {...form.register('paymentKey', { required: 'Bitte wählen Sie einen Namen an.' })}
            />
          </GridRow>
          <GridRow>
            <Dropdown id={'type'} label={'Bezeichnung'} items={PAYMENT_INFO_TYPES} />
            {['tour_outwards', 'tour_return'].includes(type) && (
              <TextInput
                id={'title'}
                label={'Tournummer'}
                placeholder={'z.B. F E-HEKE 01...'}
                {...form.register('title', { required: 'Bitte wählen Sie eine Bezeichnung an.' })}
              />
            )}
          </GridRow>
          <GridRow>
            {['tour_outwards', 'tour_return'].includes(type) && (
              <InputAbsoluteWrapper>
                <TextInput
                  id={'amount'}
                  independent
                  value={amountOfTime}
                  onValueChange={(value) => {
                    const val = value.replace(/^[,.:]/g, '').replace(/[^\d,.:]/g, '');
                    const segments = val.split(/[,.:]/g);
                    let decimalPlaces = '';
                    if (segments.length > 1) {
                      decimalPlaces = segments[1].substring(0, 2);
                      decimalPlaces = val.charAt(segments[0].length) + decimalPlaces;
                    }
                    setAmountOfTime(segments[0] + decimalPlaces);
                  }}
                  label={'Zeitpauschale'}
                  placeholder={'Zeitpauschale...'}
                  {...form.register('amount', { required: 'Bitte wählen Sie ein Zeitpauschale an.', validate: (value) => value > 0 })}
                />
                {amountOfTime && (
                  <span>
                    {escapeTimeString(amountOfTime)} {amountOfTime.match(/[:,.]/g) ? 'Std.' : 'Min.'}
                  </span>
                )}
              </InputAbsoluteWrapper>
            )}
            {!['disease', 'vacation', 'public_holiday'].includes(type) && (
              <InputAbsoluteWrapper>
                <TextInput
                  id={'paymentFactor'}
                  independent
                  value={paymentFactor}
                  onValueChange={(value) => {
                    const val = value.replace(/^[,.]/g, '').replace(/[^\d,.]/g, '');
                    const segments = val.split(/[,.]/g);
                    let decimalPlaces = '';
                    if (segments.length > 1) {
                      decimalPlaces = segments[1].substring(0, 2);
                      decimalPlaces = val.charAt(segments[0].length) + decimalPlaces;
                    }
                    setPaymentFactor(segments[0] + decimalPlaces);
                  }}
                  label={'Stundenlohn'}
                  placeholder={'Stundenlohn...'}
                />
                {paymentFactor && (
                  <span>
                    {parseValue(paymentFactor).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}€/Std.
                  </span>
                )}
              </InputAbsoluteWrapper>
            )}
            {['disease', 'vacation', 'public_holiday'].includes(type) && (
              <InputAbsoluteWrapper>
                <TextInput
                  id={'paymentFactor'}
                  independent
                  value={paymentFactor}
                  onValueChange={(value) => {
                    const val = value.replace(/^[,.]/g, '').replace(/[^\d,.]/g, '');
                    const segments = val.split(/[,.]/g);
                    let decimalPlaces = '';
                    if (segments.length > 1) {
                      decimalPlaces = segments[1].substring(0, 2);
                      decimalPlaces = val.charAt(segments[0].length) + decimalPlaces;
                    }
                    setPaymentFactor(segments[0] + decimalPlaces);
                  }}
                  label={'Lohnpauschale'}
                  placeholder={'Lohnpauschale...'}
                />
                {paymentFactor && (
                  <span>
                    {parseValue(paymentFactor).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}€
                  </span>
                )}
              </InputAbsoluteWrapper>
            )}
          </GridRow>
          <CommentInput optional id={'comment'} type={'textarea'} label={'Kommentar'} placeholder={'Kommentar hinzufügen...'} />
        </Body>
      }
      buttons={
        <>
          <ButtonSecondary onClick={() => setOpen(false)}>Abbrechen</ButtonSecondary>
          <ButtonPrimary type={'submit'}>{loading ? <LoadingSpinner /> : isEditMode ? 'Speichern' : 'Erstellen'}</ButtonPrimary>
        </>
      }
    />
  );
}
