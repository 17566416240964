import styled from 'styled-components';

import { PassengersList } from 'components/content/passengers/PassengersList';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  padding: 2.25rem var(--content-indent) 4.75rem;
`;

export const Passengers = () => {
  return (
    <Wrapper>
      <PassengersList />
    </Wrapper>
  );
};
