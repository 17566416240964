import { Info, InfoBold, ListRowControlsWrapper, ListRowWrapper, OptionsWrapper, Row } from 'components/common/atoms/List';
import { TourLabel } from 'components/common/labels/TourLabel';
import { getCurrentDayDate, getISODate } from 'utils/dateUtils';
import { Checkmark } from 'components/icons/Checkmark';
import { ChevronDown } from 'components/icons/ChevronDown';
import { FC, Fragment, useMemo, useState } from 'react';
import { IScheduledTour } from 'api/models/ScheduledTour';
import styled, { css } from 'styled-components';
import { Colors } from 'styles/Colors';
import { Close } from 'components/icons/Close';
import { Body3, Body4 } from 'styles/FontStyles';
import { Collapsible } from 'components/common/atoms/Collapsible';
import { formatAddress } from 'utils/addressUtils';
import { Card } from 'components/common/atoms/Card';
import { IPassenger } from 'api/models/Passenger';
import { Wheelchair } from 'components/icons/Wheelchair';
import { Walker } from 'components/icons/Walker';
import { Facilities } from 'components/icons/Facilities';
import { Clickable } from 'components/common/atoms/Clickable';
import { formatName } from 'utils/nameUtils';
import SelectedDriverView from './molecules/SelectedDriverView';
import { getExceptionsForDay, getLatestException } from 'utils/exceptionUtils';
import { IConflict } from 'api/models/Conflict';
import { DIRECTION_LABEL } from 'utils/directionLabelUtil';
import ConflictsForTourTooltips from './ConflictsForTourTooltips';

const StyledRow = styled(Row)<{ lineThrough?: boolean }>`
  align-items: center;

  em {
    color: ${Colors.signalRed900};
    font-style: normal;
  }

  i {
    color: ${Colors.signalGreen900};
    font-style: normal;
  }

  i {
    color: ${Colors.signalGreen900};
    font-style: normal;
  }

  mark {
    color: ${Colors.signalOrange};
    font-style: normal;
    background-color: transparent;
  }
`;

const TourRow = styled(StyledRow)<{ active: boolean }>`
  margin-inline: -0.5rem;
  padding-inline: 0.5rem;
  margin-block: -1rem -0.5rem;
  padding-block: 1rem 0.5rem;

  transition: background-color 100ms ease-out;
  background-color: ${({ active }) => (active ? Colors.grey400 : 'transparent')};

  ${({ lineThrough = false }) =>
    lineThrough &&
    css`
      & > *:not(:first-child) {
        text-decoration: line-through;
        text-decoration-color: ${Colors.signalRed900};
      }
    `};
`;

const PassengerRow = styled(StyledRow)`
  grid-template-columns: 1.25fr 1fr 1fr;

  ${({ lineThrough = false }) =>
    lineThrough &&
    css`
      & > * {
        text-decoration: line-through;
        text-decoration-color: ${Colors.signalRed900};
      }
    `};
`;

const TrackLayout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;

  &:first-child {
    margin-block-start: 0.5rem;
  }

  &:last-child > * {
    border-bottom-color: transparent;
  }
`;

const TrackContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  padding-block: 1rem;
  border-bottom: 1px solid ${Colors.grey500};
`;

const Track = styled.div<{ showBefore?: boolean; showAfter?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  inline-size: 1.5rem;

  color: ${Colors.grey600};

  &::before {
    content: '';
    inline-size: 0;
    flex: 1;
    border-left: 1px dashed ${({ showBefore }) => (showBefore ? Colors.grey600 : 'transparent')};
  }

  &::after {
    content: '';
    inline-size: 0;
    flex: 1;
    border-left: 1px dashed ${({ showAfter }) => (showAfter ? Colors.grey600 : 'transparent')};
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-block: 0.5rem;
  }
`;

export const TrackStop = styled.div`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  flex-shrink: 0;
  background: ${Colors.grey600};
`;

const TargetInfo = styled(Info)`
  padding-inline: 0.5rem;
  color: ${Colors.grey600};
`;

const InfoColumn = styled(Info)`
  flex-direction: column;
  align-items: flex-start;
`;

const CancelledIcon = styled(Close)`
  width: 1.5rem;
  height: 1.5rem;
  color: ${Colors.signalRed900};
`;

const DriverInfo = styled(Info)`
  ${Body3};
  overflow: visible;
`;

const CapacityInfo = styled(Info)`
  svg {
    margin-inline-start: -0.25rem;
  }
`;

const PassengerCommentInfo = styled(Card).attrs({ as: 'div' })`
  grid-column: -2 / -1;
  margin-inline-start: -6.25rem;
  justify-self: flex-end;

  min-inline-size: 10rem;
  max-inline-size: 16rem;

  padding-inline: 1rem;
  padding-block: 0.5rem;

  ${Body4};
  text-align: start;

  box-shadow: 0 0 24px rgba(0, 0, 0, 0.04);
`;

const TourRowCollapsible = styled(Collapsible)`
  overflow: visible;
`;

const TextBlue = styled.span`
  color: ${Colors.secondary};
`;

const LargeClickArea = styled(Clickable)`
  display: flex;
  margin: -1.25rem;
  padding: 1.25rem;
`;

export const TourListRow: FC<{ day: string; schedule: IScheduledTour; conflictsForTour?: IConflict[] }> = (props) => {
  const { day, schedule, conflictsForTour = [] } = props;
  const date = new Date(day);

  const {
    id,
    name,
    endDate,
    departureDate,
    arrivalDate,
    direction,
    customer,
    driver,
    companion,
    passengers = [],
    exceptions = [],
    isException = false,
    exceptionFor,
  } = schedule;

  // @ts-ignore
  const startTime = ((direction === 'return' ? departureDate : passengers[0]?.ScheduledTourPassenger?.pickupDate) || {})[date.getDay()];
  // @ts-ignore
  const endTime = ((direction === 'return' ? passengers[passengers.length - 1]?.ScheduledTourPassenger?.pickupDate : arrivalDate) || {})[
    date.getDay()
  ];

  const [showDetails, setShowDetails] = useState(false);

  const [passengersWheelchair, passengersWalking] = useMemo(() => {
    return (passengers || []).reduce(
      (res, item) => {
        res[item.hasWheelchair ? 0 : 1].push(item);
        return res;
      },
      [[], []] as Partial<IPassenger>[][],
    );
  }, [passengers]);

  const activeException = useMemo(() => {
    const activeException = getLatestException(getExceptionsForDay(exceptions, day));

    if (activeException?.replacementScheduledTourId === id) {
      return null; // is exception tour
    }
    return activeException;
  }, [exceptions, day, id]);

  const isTourCancelled = useMemo(() => {
    return activeException?.reason === 'cancelled';
  }, [activeException]);

  const tourId = (isException && exceptionFor?.scheduledTourId) || id;

  return (
    <ListRowWrapper href={`/tours/${tourId}?date=${getISODate(day)}`}>
      <TourRow lineThrough={isTourCancelled} active={showDetails}>
        <InfoColumn style={{ overflow: 'visible' }}>
          <Info style={{ overflow: 'visible' }}>
            <TourLabel>
              {
                // finished
                !isTourCancelled && date < getCurrentDayDate() && <Checkmark />
              }
              {
                // cancelled
                isTourCancelled && <CancelledIcon />
              }
              {name}
            </TourLabel>
            <ConflictsForTourTooltips
              conflictsForTour={conflictsForTour}
              date={date}
              isException={isException}
              isTourCancelled={isTourCancelled}
              activeException={activeException}
            />
            {DIRECTION_LABEL[direction || 'na']}
          </Info>
          {isTourCancelled && activeException && (
            <Info>
              <em>
                fällt aus
                {activeException?.endDate &&
                  ` bis ${new Date(activeException?.endDate!).toLocaleDateString(undefined, { dateStyle: 'medium' })}`}
              </em>
            </Info>
          )}
          {!isTourCancelled && !isException && activeException && (
            <Info>
              <mark>
                geänderte Version
                {activeException?.endDate &&
                  ` bis ${new Date(activeException?.endDate!).toLocaleDateString(undefined, { dateStyle: 'medium' })}`}
              </mark>
            </Info>
          )}
          {!isTourCancelled && isException && (
            <Info>
              <i>vorübergehend{endDate && ` bis ${new Date(endDate).toLocaleDateString(undefined, { dateStyle: 'medium' })}`}</i>
            </Info>
          )}
        </InfoColumn>
        <InfoColumn>
          <InfoBold>{customer?.displayName}</InfoBold>
          <div>
            <Info>{customer?.address?.addressLine1}</Info>
            <Info>
              {customer?.address?.zipCode} {customer?.address?.city}
            </Info>
          </div>
        </InfoColumn>
        <DriverInfo>
          <SelectedDriverView
            title={'Fahrer'}
            driver={driver}
            conflict={
              (day &&
                driver &&
                conflictsForTour.find(({ reason }) =>
                  ['driver_duplicated', 'driver_unavailable', 'driver_not_set', 'unset_pickupdate'].includes(reason),
                )) ||
              undefined
            }
          />
        </DriverInfo>
        <DriverInfo>
          {companion && (
            <SelectedDriverView
              title={'Begleitung'}
              driver={companion}
              conflict={
                (day &&
                  driver &&
                  conflictsForTour.find(({ reason }) => ['companion_duplicated', 'companion_unavailable'].includes(reason))) ||
                undefined
              }
            />
          )}
        </DriverInfo>
        <Info>
          {[startTime, endTime]
            .filter(Boolean)
            .map((date) => new Date(date!).toLocaleTimeString(undefined, { timeStyle: 'short' }))
            .join(' - ')}
        </Info>
        <CapacityInfo>
          {passengersWheelchair.length > 0 && (
            <>
              {passengersWheelchair.length} <Wheelchair />
            </>
          )}
          {passengersWalking.length > 0 && (
            <>
              {passengersWalking.length} <Walker />
            </>
          )}
        </CapacityInfo>

        <ListRowControlsWrapper>
          <OptionsWrapper>
            <LargeClickArea
              onClick={(e) => {
                e.preventDefault();
                setShowDetails(!showDetails);
              }}
            >
              <ChevronDown invert={showDetails} />
            </LargeClickArea>
          </OptionsWrapper>
        </ListRowControlsWrapper>
      </TourRow>
      <TourRowCollapsible open={showDetails}>
        {direction === 'return' && (
          <TrackLayout>
            <Track showBefore={false} showAfter={true}>
              <Facilities />
            </Track>
            <TrackContent>
              <TargetInfo>
                <b>Start: {customer?.displayName}</b>
              </TargetInfo>
            </TrackContent>
          </TrackLayout>
        )}
        {passengers.map((passenger, index) => (
          <TrackLayout key={passenger.id}>
            <Track showBefore={index > 0 || direction === 'return'} showAfter={index < passengers.length - 1 || direction === 'outwards'}>
              <TrackStop />
            </Track>
            <TrackContent>
              <PassengerRow lineThrough={isTourCancelled}>
                <Info>
                  <b>{index + 1}</b>
                  {
                    // passenger specific pickup time
                    passenger.ScheduledTourPassenger?.pickupDate?.[getCurrentDayDate(day).getDay()] && (
                      <TextBlue>
                        {new Date(passenger.ScheduledTourPassenger?.pickupDate?.[getCurrentDayDate(day).getDay()]).toLocaleTimeString(
                          undefined,
                          { timeStyle: 'short' },
                        )}
                      </TextBlue>
                    )
                  }
                  {passenger?.hasWheelchair ? <Wheelchair /> : <Walker />}
                  {formatName(passenger)}
                </Info>
                <InfoBold>
                  {formatAddress((passenger?.addresses || [])[0])
                    ?.split(', ')
                    .map((line, index) => (
                      <Fragment key={index}>
                        {index > 0 && (
                          <>
                            ,<br />
                          </>
                        )}
                        {line}
                      </Fragment>
                    ))}
                </InfoBold>
                {passenger.comment && <PassengerCommentInfo>{passenger.comment}</PassengerCommentInfo>}
              </PassengerRow>
            </TrackContent>
          </TrackLayout>
        ))}
        {direction === 'outwards' && (
          <TrackLayout>
            <Track showBefore={true} showAfter={false}>
              <Facilities />
            </Track>
            <TrackContent>
              <TargetInfo>
                <b>Ziel: {customer?.displayName}</b>
              </TargetInfo>
            </TrackContent>
          </TrackLayout>
        )}
      </TourRowCollapsible>
    </ListRowWrapper>
  );
};
