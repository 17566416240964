import styled from 'styled-components';

import { CustomerCategoryList } from 'components/content/customer-categories/CustomerCategoryList';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  padding: 2.25rem 0 0 0;
`;

export const CustomerCategories = () => {
  return (
    <Wrapper>
      <CustomerCategoryList />
    </Wrapper>
  );
};
