import styled from 'styled-components';
import { TextInput } from 'components/common/inputs/TextInput';
import { Button } from 'components/common/inputs/Button';
import { LoadingSpinner } from 'components/common/loaders/LoadingSpinner';
import { useLocation, useNavigate } from 'react-router-dom';
import { HighlightedLink } from 'components/common/atoms/Link';
import { useAuthAPI } from 'api/controllers/AuthAPI';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { Form } from 'components/common/inputs/Form';
import { BREAKPOINT_MD, BREAKPOINT_XL } from 'styles/Breakpoints';
import { Headline2 } from 'styles/FontStyles';
import { IconWrapper, LoginScreenWrapper } from 'components/pages/auth/Login';
import { PasswordLogin } from 'components/icons/Password';
import { ArrowleftIcon } from 'components/icons/ArrowLeft';

const Content = styled.div`
  grid-column: span 12;
  margin-block-start: 5rem;

  ${BREAKPOINT_MD} {
    margin-block-start: unset;
    grid-column: 3 / span 8;
    min-block-size: 100vh;
  }

  ${BREAKPOINT_XL} {
    grid-column: 5 / span 4;
  }
`;

const ContentInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-block-size: 100%;
  text-align: center;

  form {
    inline-size: 100%;
    align-items: center;
    margin-block-start: 1.5rem;
    gap: 1.5rem;

    > div {
      inline-size: 100%;
    }
  }
`;

const Title = styled.h1`
  ${Headline2};
  margin-block-end: 1rem;
`;

export const ResetPassword = () => {
  const navigate = useNavigate();
  const token = new URLSearchParams(useLocation().search).get('token') as string;

  const authAPI = useAuthAPI();
  const form = useForm({ defaultValues: { token, password: '' } });
  const [loading, setLoading] = useState(false);

  return (
    <LoginScreenWrapper>
      <Content>
        <ContentInner>
          <IconWrapper>
            <PasswordLogin />
          </IconWrapper>
          <Title>Neues Passwort vergeben</Title>
          <p>Bitte geben Sie Ihr neues Passwort ein. Mit diesem Passwort werden Sie sich zukünftig in das System einloggen.</p>
          <Form
            form={form}
            onSubmit={(values) => {
              setLoading(true);
              authAPI
                .resetPassword(values)
                .then((res) => {
                  console.log('reset password', res.data);
                  navigate('/login');
                })
                .catch((e) => {
                  form.setError('password', {
                    message:
                      e.response.status === 403
                        ? 'Der Link zum Zurücksetzen des Passworts ist bereits abgelaufen. Bitte verwende die Funktion Passwort vergessen, um einen neuen Link anzufordern.'
                        : 'Das Passwort erfüllt nicht die Kriterien. Bitte verwende mindestens 6 Zeichen.',
                  });
                  console.error('reset password', e);
                })
                .finally(() => setLoading(false));
            }}
          >
            <TextInput
              id={'new-password'}
              name={'password'}
              autoComplete={'new-password'}
              type={'password'}
              label={'Passwort'}
              placeholder={'Neues Passwort eingeben'}
            />
            <Button type={'submit'}>{loading ? <LoadingSpinner /> : 'Passwort speichern'}</Button>

            <HighlightedLink href={'/login'}>
              <ArrowleftIcon /> Zum Login
            </HighlightedLink>
          </Form>
        </ContentInner>
      </Content>
    </LoginScreenWrapper>
  );
};
