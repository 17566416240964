import { IInputProps, TextInput } from 'components/common/inputs/TextInput';
import { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Clickable } from '../atoms/Clickable';
import { Checkmark } from 'components/icons/Checkmark';

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.5rem;
  flex-wrap: nowrap;

  margin-block: -0.125rem;
  pointer-events: auto;

  input {
    cursor: text;
  }
`;

const CheckButton = styled(Clickable)`
  svg {
    width: 1.25rem;
    height: 1.25rem;

    margin-block-start: 0.375rem;
  }
`;

interface IQuickInputProps {
  defaultValue: string | undefined;
  onSubmit: (value: string) => void;
  onBlur?: () => void;
  placeholder?: string;
}

export const QuickInput: FC<IQuickInputProps & IInputProps> = (props) => {
  const { defaultValue, onSubmit, onBlur, ...fieldProps } = props;

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState(defaultValue || '');

  useEffect(() => {
    let listener = (e: MouseEvent) => {
      if (!(e.target === wrapperRef.current || wrapperRef.current?.contains(e.target as Node))) {
        setFocused(false);
        onBlur?.();
      }
    };

    if (focused) {
      setTimeout(() => {
        document.addEventListener('click', listener, { passive: true });
      }, 100);
    }

    return () => {
      document.removeEventListener('click', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focused]);

  return (
    <InputWrapper ref={wrapperRef}>
      <TextInput
        {...fieldProps}
        onFocus={() => setFocused(true)}
        optional
        $plain
        autoFocus
        independent
        value={value}
        onValueChange={setValue}
        onSubmit={onSubmit}
      />
      <CheckButton onClick={() => onSubmit?.(value)}>
        <Checkmark />
      </CheckButton>
    </InputWrapper>
  );
};
