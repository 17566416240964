import { useState } from 'react';
import styled, { css } from 'styled-components';
import { Colors } from 'styles/Colors';
import { TextInput } from 'components/common/inputs/TextInput';
import { Dialog } from '../Dialog';
import { ButtonPrimary, ButtonSecondary } from 'components/common/inputs/Button';
import { LoadingSpinner } from 'components/common/loaders/LoadingSpinner';
import { TUserPermission, USER_PERMISSION_TITLES } from 'api/models/User';
import { useForm } from 'react-hook-form';
import { Body5 } from 'styles/FontStyles';
import { PlainCheckbox } from 'components/common/atoms/Checkbox';
import { BREAKPOINT_MD } from 'styles/Breakpoints';

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;

  text-align: start;
`;

const GridRow = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;

  ${BREAKPOINT_MD} {
    grid-template-columns: 1fr 1fr;
  }
`;

const PermissionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
`;

const PermissionChip = styled.p<{ active?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-inline: 0.625rem;
  padding-block: 0.375rem;

  ${Body5};
  background-color: ${Colors.grey500};
  border-radius: 1rem;

  ${({ active = false }) =>
    active &&
    css`
      background-color: ${Colors.primary};
    `};
`;

type PermissionKey = keyof typeof USER_PERMISSION_TITLES;

export interface ICreateUserSubmitPayload {
  email: string;
  permissions: {
    [key in TUserPermission]?: boolean;
  };
}
interface ICreateBookingCompletionDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onSubmit: (config: ICreateUserSubmitPayload, setLoading: (loading: boolean) => void) => void;
}
export default function CreateUserDialog(props: ICreateBookingCompletionDialogProps) {
  const { open, setOpen, onSubmit } = props;

  const [loading, setLoading] = useState(false);

  const form = useForm({
    defaultValues: {
      email: '',
      permissions: {},
    } as ICreateUserSubmitPayload,
  });

  const permissions = form.watch('permissions');

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      form={form}
      onSubmit={(values: ICreateUserSubmitPayload) => {
        onSubmit(values, setLoading);
      }}
      headline={'Nutzer erstellen'}
      text={
        <Body>
          <GridRow>
            <TextInput
              id={'email'}
              label={'E-Mail-Adresse'}
              placeholder={'beispiel@gmail.com'}
              {...form.register('email', { required: 'Bitte wählen Sie eine E-Mail an.' })}
            />
          </GridRow>
          <p>
            <b>Berechtigungen</b>
          </p>
          <PermissionsWrapper>
            {Object.entries(USER_PERMISSION_TITLES).map(([permission, title]) => {
              const permissionKeys = Object.keys(permissions);
              let checked = false;
              let disabled = false;
              if (permissionKeys.includes(permission)) {
                checked = permissions[permission as PermissionKey] as boolean;
                disabled = permission !== 'admin' && permissionKeys.includes('admin');
              } else {
                // Really nothing to do here?
              }

              return (
                <PermissionChip key={permission} active={!disabled && checked} onClick={(e) => e.stopPropagation()}>
                  <PlainCheckbox
                    id={`permissions_${permission}`}
                    name={`permissions[${permission}]`}
                    checked={checked}
                    disabled={disabled}
                    value={permission}
                    label={title}
                    onCheckedChange={(value) => {
                      form.setValue(`permissions.${permission as PermissionKey}`, value);
                    }}
                  />
                </PermissionChip>
              );
            })}
          </PermissionsWrapper>
        </Body>
      }
      buttons={
        <>
          <ButtonSecondary onClick={() => setOpen(false)}>Abbrechen</ButtonSecondary>
          <ButtonPrimary type={'submit'}>{loading ? <LoadingSpinner /> : 'Erstellen'}</ButtonPrimary>
        </>
      }
    />
  );
}
