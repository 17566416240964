import { ReactNode, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

const ContentWrapper = styled.div<{ $isDraggable: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  flex: 1 1 0;
  margin: calc(-1 * var(--content-indent));
  margin-block-start: 0;

  position: relative;

  overflow: hidden;

  ${({ $isDraggable = false }) =>
    $isDraggable &&
    css`
      cursor: grab;

      &[data-dragging] {
        cursor: grabbing;
      }
    `};
`;

export function DnDScrollableContentWrapper({ isDraggable = false, children }: { isDraggable: boolean; children: ReactNode }) {
  const contentWrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const updateValues = () => {
      contentWrapperRef.current?.style?.setProperty('--offset-top', `${contentWrapperRef.current?.offsetTop || 0}px`);
      contentWrapperRef.current?.style?.setProperty('--offset-left', `${contentWrapperRef.current?.offsetLeft || 0}px`);
      contentWrapperRef.current?.style?.setProperty('--offset-nav', `${nav?.offsetWidth || 0}px`);
    };
    const observer = new ResizeObserver(updateValues);
    const nav = document.querySelector('nav');
    if (nav && contentWrapperRef.current) {
      observer.observe(nav);
      observer.observe(contentWrapperRef.current);
    }
    updateValues();
    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    const container = contentWrapperRef.current as HTMLDivElement;

    const onMouseDown = (e: MouseEvent) => {
      if (container) {
        container.setAttribute('data-dragging', 'true');
        container.setAttribute('data-dragstart', e.clientX.toString());

        const scrollableContainer = container.children.item(0) as HTMLDivElement;
        if (scrollableContainer) {
          scrollableContainer.setAttribute('data-dragstart', scrollableContainer.scrollLeft.toString());
        }
      }
    };

    const onMouseMove = (e: MouseEvent) => {
      if (container && container.hasAttribute('data-dragging')) {
        const scrollableContainer = container.children.item(0) as HTMLDivElement;
        if (scrollableContainer) {
          const dragStart = +(container.getAttribute('data-dragstart') || 0);
          const dragOffset = dragStart - e.clientX;

          const dragMultiplier = Math.max(0.95, Math.min(1.15, scrollableContainer.scrollWidth / scrollableContainer.offsetWidth));

          const scrollableContainerDragStart = +(scrollableContainer.getAttribute('data-dragstart') || 0);
          scrollableContainer.scrollLeft = scrollableContainerDragStart + dragOffset * dragMultiplier;
        }
      }
    };

    const onMouseUp = () => {
      if (container) {
        container.removeAttribute('data-dragging');
        container.removeAttribute('data-dragstart');

        const scrollableContainer = container.children.item(0) as HTMLDivElement;
        if (scrollableContainer) {
          scrollableContainer.removeAttribute('data-dragstart');
        }
      }
    };

    if (isDraggable) {
      container?.addEventListener('mousedown', onMouseDown, { passive: true });
      container?.addEventListener('mousemove', onMouseMove, { passive: true });
      document.addEventListener('mouseup', onMouseUp, { passive: true });
    }

    return () => {
      container?.removeEventListener('mousedown', onMouseDown);
      container?.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, [isDraggable]);

  return (
    <ContentWrapper ref={contentWrapperRef} $isDraggable={isDraggable}>
      {children}
    </ContentWrapper>
  );
}
